import { NetworkStatus } from '../network/NetworkStatus';
import { ITokenDB } from '../data_sources/database';
import { KeyCloakWrapper } from './KeyCloakWrapper';
import { User, IUserCapabilities } from '../models/User';
import { logError } from '../logging';
import { IIDTokens } from '../models/IDTokens';
import { CONFIG } from '../config';
import { TimeoutError } from '../errors/TimeoutError';
import { ENV, AMAZON_FIRE } from '../environment';
import { convertTokens } from '@date-fns/upgrade/v2';
import { UnauthorisedError } from '../errors/UnauthorisedError';
import { NotFoundError } from '../errors/NotFoundError';
import IRegistration from '../components/firebase/registration';
import { deleteFirebaseToken } from '../components/firebase/firebase';

export type AuthStatusChangeListener = () => void;
export type AuthStatus = 'offline' | 'cached_user' | 'connecting' | 'login_required' | 'online' | 'unknown';

export interface IOnePlaceAuth {
    user: User;
    cachedUser: boolean;
    status: AuthStatus;
    tokens: IIDTokens | null;

    authenticateUser(): Promise<boolean>;
    login(): Promise<boolean>;
    logout(): Promise<boolean>;
    forceLogout(): void;
    checkAuth(): Promise<boolean>;
    getUserCapabilities(): Promise<IUserCapabilities>;
    updateUserDatabase(databaseName: string): void;
    getRegistration(): Promise<IRegistration>;
    updateRegistration(registration: IRegistration): void;
    getToken():  Promise<IIDTokens | null>;
    addListener: (listener: AuthStatusChangeListener) => void;
    removeListener: (listener: AuthStatusChangeListener) => void;
}

export class OnePlaceAuth implements IOnePlaceAuth {
    _kcWrapper: KeyCloakWrapper;
    user: User;
    cachedUser: boolean;
    status: AuthStatus;
    tokens: IIDTokens | null;

    private listeners: AuthStatusChangeListener[] = [];
    private networkListnerAttached = false;

    constructor(
        private net: NetworkStatus,
        private db: ITokenDB
    ) {
        this._kcWrapper = new KeyCloakWrapper();
        this._kcWrapper.onTokenExpired = () => {
            this.refreshToken();
        };
        this.user = null as any;
        this.cachedUser = false;
        this.status = 'offline';
        this.tokens = null;
    }

    // Try to log the user in automatically
    // Return true if successful, otherwise false
    async authenticateUser(): Promise<boolean> {
        const offline = this.net.isOffline;

        this.status = 'connecting';
        this.notifyListeners();

        let authResult = false;
        if (offline) {
            authResult = await  this.offlineAuthenticate();
        }
        else {
            authResult = await this.onlineAuthenticate();
        }

        if (!this.networkListnerAttached) {
            this.net.addListener(this.onNetworkStatusChange);
            this.networkListnerAttached = true;
        }

        return authResult;
    }

     onNetworkStatusChange = ()  => {
        if (!this.net.isOffline && (this.status == 'offline' || this.status == 'cached_user')) {
            this.authenticateUser().then(result =>{
                console.log("onNetworkStatusChange" + String(result));
            }).catch(error => {
                console.log('rejected', error);
            });
        }
    }

    /**
     * KeyCloak init methods throw for a variety of reasons
     * Check KeyCloakWrapper for more details.
     */

    private async onlineAuthenticate() {
        let tokens: IIDTokens | null = null;
        let authenticated = false;
        try {
            tokens = await this.db.retrieveTokens();
        }
        catch (e) { logError(e); }
        if (tokens) {
            try {
                console.log("initWithTokens");
                authenticated = await this._kcWrapper.initWithTokens(tokens);
                console.log("initWithTokens " + authenticated );
                if (authenticated) {
                    authenticated = await this.refreshToken();
                }
                else {
                    await this.clearTokens();
                }
            }
            catch (e) { /* dont log this */ }
        }
        if (!authenticated) {
            // Try via SSO
            try {
                console.log("initViaSSO");
                authenticated = await this._kcWrapper.initViaSSO();
            }
            catch (e) {
                logError(e);
                return this.offlineAuthenticate();
            }
        }
        console.log("initViaSSO " + authenticated );
        if (authenticated) {
            console.log('Authenticated with KeyCloak server.');
            try {
                this.tokens = this._kcWrapper.getTokens();
                await this.storeTokens();
                await this.populateUser();
                this.status = 'online';
                this.notifyListeners();
                return true;
            }
            catch (e) {
                logError(e);
            }
        }
        this.status = 'login_required';
        this.notifyListeners();
        return false;
    }

    private async offlineAuthenticate() {
        let tokens: IIDTokens | null = null;
        let user: User | null = null;
        try {
            tokens = await this.db.retrieveTokens();
            if (tokens) {
                user = await this.db.retrieveUser(tokens.userId);
            }
        }
        catch (e) {
            logError(e);
        }
        if (tokens && user) {
            console.log('Authenticated via cached credentials');
            this.tokens = tokens;
            this.user = user;
            this.cachedUser = true;
            this.status = 'cached_user';
            this.notifyListeners();
            return true;
        }
        this.status = 'login_required';
        this.notifyListeners();
        return false;
    }

    async login() {
        const loggedIn = await this._kcWrapper.login();
        if (loggedIn) {
            this.tokens = this._kcWrapper.getTokens();
            await this.storeTokens();
            await this.populateUser();

            this.status = 'online';
            this.notifyListeners();
            return true;
        }
        return false;
    }

    isUnregister() : boolean {
        if ((ENV.platform == 'cordova' && ENV.os == 'browser') ||  ENV.platform === 'web') {
            return true
        } else if (ENV.platform == 'cordova' && ENV.os != 'windows') {
            const platformString  = device.platform.toLowerCase();
            console.log('platformString:' + String(platformString));
            if (platformString.includes(AMAZON_FIRE)) {
                return false
            } else {
                // add cordova-plugin-push listener for mobile app
                console.log('IOS or android');
                return true
            }

        } else{
            return false
        }

    }
    async preLogout(): Promise<void> {
        await this.db.clearTokens();
        this.status = 'offline';
        this.notifyListeners();

        if (this.isUnregister()) {
            try {
                const registration = await this.getRegistration();
                await this.unregister(registration.token);
                await deleteFirebaseToken();
            }
            catch (e) {
                console.log("failed to delete binding:"+e);
            }
        }

    }

    async logout(): Promise<boolean> {
        await this.preLogout()
        return this._kcWrapper.logout();
    }

    async forceLogout() {
        await this._kcWrapper.logoutIdenitityProvider()
        await this.preLogout()
        this._kcWrapper.clearToken();
    }

    async checkAuth() {
        try {
            if (this._kcWrapper.isTokenExpiring()) {
                return this.refreshToken();
            }
            return true;
        } catch (e){
            this.status = 'login_required';
            this.notifyListeners();
            return false;
        }
    }

    private async refreshToken() {
        let authenticated = false;
        if (this.net.isOffline == false) {
            console.log('Refreshing token...');
            this.status = 'connecting';
            this.notifyListeners();
            try {
                authenticated = await this._kcWrapper.refreshToken();
                if (authenticated) {
                    this.tokens = this._kcWrapper.getTokens();
                    try {
                        await this.storeTokens();
                    }
                    catch (e) {
                        logError(e);
                    }
                    this.status = 'online';
                    this.notifyListeners();
                }
                else {
                    this.status = 'login_required';
                    this.notifyListeners();
                }
            }
            catch (e) {
                logError(e);
                if (e instanceof TimeoutError) {
                    this.status = 'offline';
                    this.notifyListeners();
                }
                else {
                    // Token refresh has failed. We should assume the user needs to re-login
                    this.status = 'login_required';
                    this.notifyListeners();
                }
            }
        }
        else {
            console.log('Skipping refresh. Network is offline.');
        }
        return authenticated;
    }

    private async storeTokens() {
        await this.db.storeTokens(this.tokens!);
    }

    private async clearTokens() {
        await this.db.clearTokens();
    }

    private async populateUser() {
        const tokenData = this._kcWrapper.getIDTokenData();
        const capabilities = await this.getUserCapabilities();
        capabilities.dateTimeFormat = convertTokens(capabilities.dateTimeFormat);
        capabilities.dateFormat = convertTokens(capabilities.dateFormat);


        const storedUser = await this.db.retrieveUser(tokenData.sub);
        const databaseName = storedUser?.database || tokenData.email.substr(0, 15);
        this.user = new User({
            id: tokenData.sub,
            email: tokenData.email,
            givenName: tokenData.given_name,
            familyName: tokenData.family_name,
            database: databaseName,
            capabilities
        });
        this.cachedUser = false;
        try {
            await this.db.storeUser(this.user);
        }
        catch (e) {
            logError(e, 'Unable to store user details');
        }
    }

    async updateUserDatabase( databaseName: string) {
        try {
            this.user.database = databaseName
            await this.db.storeUser(this.user);
        }
        catch (e) {
            logError(e, 'Unable to store user details');
        }

    }

    async unregister( token: string) {
        const url = `${CONFIG.apiUrl}/bindings?token=${token}`;
        await this.checkAuth();
        const res = await fetch(url, {
            method: 'DELETE',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + this.tokens!.token,
                'X-App-Version': CONFIG.build
            }
        });
        return res.status;
    }

    async getUserCapabilities() {
        let url = CONFIG.apiUrl + '/capabilities'
        if (ENV.os == 'browser' || ENV.os =='android' || ENV.os =='ios' || ENV.platform === 'web') {
            if (ENV.platform == 'cordova') {
                url += '?platform=' + ENV.os
            } else{
                url += '?platform=browser'
            }
        }
        const res = await fetch(url, {
            headers: {
                'Authorization': 'Bearer ' + this.tokens!.token,
                'X-App-Version': CONFIG.build
            }
        });
        switch (res.status){
            case 401 : throw new UnauthorisedError('Unauthorised.  Your token has expired');
            case 0   : throw new UnauthorisedError('Unauthorised.  Your token has expired');
            case 404 : throw new NotFoundError('Capabilities endpoint was not found');
            case 408 : throw new TimeoutError('Capabilities endpoint did not return capabilities key');
        }
        const data = await res.json();
        if (!data.capabilities) {
            throw new Error('Capabilities endpoint did not return capabilities key');
        }
        return data.capabilities as IUserCapabilities;
    }

    async getRegistration() {
        return this.db.retrieveRegistration();
    }

    updateRegistration(registration: IRegistration) {
        this.db.storeRegistration(registration);
    }

    async getToken() {
        return this.db.retrieveTokens();
    }

    addListener(listener: AuthStatusChangeListener) {
        this.listeners.push(listener);
    }

    removeListener(listener: AuthStatusChangeListener) {
        const idx = this.listeners.indexOf(listener);
        if (idx > -1) {
            this.listeners.splice(idx, 1);
        }
    }

    notifyListeners() {
        this.listeners.forEach((listener) => listener());
    }

}
