import * as React from 'react';
import {RefreshIndicator} from './RefreshIndicator';

export interface IPullToRefreshProps extends React.HTMLAttributes<HTMLDivElement>{
  /**
   * The child nodes to render inside the element.
   *
   * **Note**: The refresh indicator will be prepended to the children.
   */
    children?: any;
  /**
   * How much to move the refresh indicator down compared to how much the user drags down.
   */
    dragMultiplier?: number;
  /**
   * The size of the drag indicator in pixels.
   */
    indicatorSize?: number;
  /**
   * The maximum amount that the refresh indicator can be dragged down.
   */
    maxDrag?: number;
  /**
   * A function to run when the indicator is pulled down and released.
   *
   * The function may either be synchronous or return a promise.
   */
    onRefresh: () => void;
  /**
   * Additional styling for the container element.
   *
   * `overflowY` will be set by this component.
   */
    style?: any;
  /**
   * The maximum offset which the user may have scrolled down.
   *
   * If this is set to a positive integer, the pulling down of the refresh indicator will start
   * before the user hits the top of the scrolled element.
   */
    topOffset?: number | null;
}

export interface IPullToRefreshState {
    busy: boolean;
    touchStart: any;
    drag: number;
    dragging: boolean;
}

const initialState = {
    busy: false,
    touchStart: null,
    drag: 0,
    dragging: false
};

/**
 * A component that can be pulled in order to refresh content.
 */
export class PullToRefresh extends React.Component<IPullToRefreshProps, IPullToRefreshState> {

    public static defaultProps = {
        children: null,
        dragMultiplier: 0.75,
        indicatorSize: 40,
        maxDrag: 350,
        style: {},
        topOffset: 0,
    };
    dragMultiplier: number;
    indicatorSize: number;
    maxDrag: number;
    topOffset: number;

    el: HTMLDivElement | null;

    constructor(props: any) {
        super(props);
        this.el = null;
        this.onTouchStart = this.onTouchStart.bind(this);
        this.onTouchMove = this.onTouchMove.bind(this);
        this.onTouchEnd = this.onTouchEnd.bind(this);
        this.state =  initialState;
        this.dragMultiplier = this.props.dragMultiplier ? this.props.dragMultiplier : 0.75;
        this.indicatorSize = this.props.indicatorSize ? this.props.indicatorSize : 40;
        this.maxDrag = this.props.maxDrag ? this.props.maxDrag : 350;
        this.topOffset = this.props.topOffset ? this.props.topOffset : 0;
    }

    onTouchStart = (event: React.TouchEvent<HTMLElement>) => {

        if (!this.state.busy && this.el!.scrollTop <= this.topOffset) {
            this.setState({
                touchStart: event.touches[0],
            });
        }
    }

    onTouchMove = (event: React.TouchEvent<HTMLElement>) => {
        if (!this.state.touchStart) {
            return;
        }
        const drag = Math.max(0, event.touches[0].clientY - this.state.touchStart.clientY);
        if (drag !== 0) {
            this.setState({
                drag: Math.min(drag, this.maxDrag),
                dragging: true,
        });
        } else if (!this.state.dragging) {
            this.reset();
        }
    }

    onTouchEnd = () => {
        const { onRefresh } = this.props;
        if (!this.state.busy) {
            if (this.state.drag >= this.maxDrag * 0.9) {
                this.setState({
                    busy: true,
                    drag: this.maxDrag / 2,
                    touchStart: null,
                });
                Promise.resolve()
                .then(onRefresh)
                .then(this.reset, this.reset);
            } else {
                this.reset();
            }
        }
    }

    ref = (element: HTMLDivElement) => {
        this.el = element;
    }

    reset = () => {
        this.setState(initialState);
    }

    render() {
        const {
            children,
            // eslint-disable-next-line @typescript-eslint/no-unused-vars
            dragMultiplier,
            // eslint-disable-next-line @typescript-eslint/no-unused-vars
            indicatorSize,
            // eslint-disable-next-line @typescript-eslint/no-unused-vars
            maxDrag,
            // eslint-disable-next-line @typescript-eslint/no-unused-vars
            onRefresh,
            style,
            // eslint-disable-next-line @typescript-eslint/no-unused-vars
            topOffset,
            ...props
            } = this.props;
        this.dragMultiplier = this.props.dragMultiplier ? this.props.dragMultiplier : 0.75;
        this.indicatorSize = this.props.indicatorSize ? this.props.indicatorSize : 40;
        this.maxDrag = this.props.maxDrag ? this.props.maxDrag : 350;
        this.topOffset = this.props.topOffset ? this.props.topOffset : 0;
        // overflowY causes a padding to the righ
        return (
        <div
            {...props}
            ref={this.ref}
            style={{
            ...style,
            overflowY: 'hidden',
            }}
            onTouchStart={this.onTouchStart}
            onTouchMove={this.onTouchMove}
            onTouchEnd={this.onTouchEnd}
        >
            <RefreshIndicator
                busy={this.state.busy}
                size={this.indicatorSize}
                drag={this.state.drag * this.dragMultiplier}
            />
            {children}
        </div>
    );
  }
}
