
import {format, parseISO, isDate} from 'date-fns';
import { CONFIG } from '../config';

export function formatDate(
    fmt: 'iso' | 'exif' | 'display_date' | 'display_datetime' | 'iso_dateonly' | 'datetime-local' | 'utc',
    date: Date | string,
    displayDateFormat?: string
) {
    let newDate: Date;
    if (isDate(date)) {
        newDate = date as Date;
    } else{
        newDate = parseISO(date as string);
    }
    if (fmt == 'exif') {
        return format(newDate, 'yyyy:MM:dd HH:mm:ss');
    }
    else if (fmt == 'display_date') {
        if (displayDateFormat)
            return format(newDate, displayDateFormat);
        else
            return format(newDate, CONFIG.displayDateFormat);
    }
    else if (fmt == 'display_datetime') {
        if (displayDateFormat)
            return format(newDate, displayDateFormat);
        else
            return format(newDate, CONFIG.displayDateTimeFormat);
    }
    else if (fmt == 'iso_dateonly') {
        return format(newDate, 'yyyy-MM-dd');
    }
    else if (fmt == 'datetime-local') {
        return format(newDate, 'yyyy-MM-dd\'T\'HH:mm');
    }
    else if (fmt == 'utc') {
        return format(newDate, 'yyyy-MM-dd\'T\'HH:mm:ssXX');
    }
    else {
        return format(newDate, 'yyyy-MM-dd\'T\'HH:mm:ss');
    }
}
export function convertDDMMYYYYToLocalDate(apiDate: string): string {
    const regex = /^\d\d\d\d-\d\d-\d\d$/;
    if (regex.test(apiDate)) {
        return apiDate
    } else {
        // Returns date string as a local date
        const datePart = apiDate.substr(6, 4) + '-' + apiDate.substr(3, 2) + '-' + apiDate.substr(0, 2);
        return format(parseISO(datePart), 'yyyy-MM-dd');
    }
}
export function sortByDate(
    items: Record<string, any>[],
    key: string
): any[] {
    if (!items.length) {
        return items;
    }

    // check if key exists on an array entry
    // return the base array if it does not exist
    // to avoid sorting errors
    const itemKeys = Object.keys(items[0]);
    if (!itemKeys.includes(key)) {
        return items;
    }

    return items.sort(function(a, b){
        return new Date(b[key]).getTime() - new Date(a[key]).getTime();
    });
}
export function formatLocalDate(
    inputDate: string,
    dateFormat: string
) : string {
    const parsedDate = new Date(inputDate);
  const formattedDate = format(parsedDate, dateFormat);
  return formattedDate;
}
