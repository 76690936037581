import { BUILD_INFO } from './buildinfo';
import { IAppConfig } from './types';

// Test Config
const CONFIG: IAppConfig = {
    build: BUILD_INFO.buildVersion,  // Don't change this string - it is used in the cordova build process
    authRealm: '1Place',
    authServerUrl: process.env.AUTH_SERVER_URL as string,
    authRedirectScheme: process.env.APP_REDIRECT_SCHEME as string,
    authClientId: 'franchise-manager-app',
    apiUrl: process.env.API_URL as string,
    imageMaxResolution: 1024,
    imageCompression: 0.5,
    imageThumbnailMaxResolution: 100,
    imageThumbnailCompression: 0.8,
    autoSaveDelay: 1000,
    franchiseeSearchDelay: 500,
    franchiseeSearchResults: 20,
    checklistsPerDashboardSection: 10,
    displayDateFormat: 'dd/MM/yyyy',
    displayDateTimeFormat: 'dd/MM/yyyy hh:mm:ss a',
    displayTimeFormat: 'hh:mm a',
    sentryEndpoint: 'https://542b29c22a88472394b7944f4d7710e8@sentry.io/1262508',
    sentryReleaseVersion: 'com.oneplaceonline.compliancemanager-' + BUILD_INFO.buildVersion,  // update version number so sentry event can match the release
    sentryEnvironment: process.env.SENTRY_ENV as string,    // Envrionment can be 'Test' or 'Prod'
    offlineSyncIntervalMins: 360,  // 6 hours
    offlineSyncAlertMins: 1440,  // 24 hours,
    appFlowChannelName: process.env.APPFLOW_CHANNEL_NAME as string
};

const firebaseConfig = {
    apiKey: process.env.FIREBASE_API_KEY as string,
    authDomain: process.env.FIREBASE_AUTH_DOMAIN as string,
    projectId: process.env.FIREBASE_PROJECT_ID as string,
    storageBucket: process.env.FIREBASE_STORAGE_BUCKET as string,
    messagingSenderId: process.env.FIREBASE_MESSAGING_SENDER_ID as string,
    appId: process.env.FIREBASE_APP_ID as string
};

const firebaseWebPushCertificate =
    process.env.FIREBASE_WEB_PUSH_CERTIFICATE as string;

export { CONFIG, firebaseConfig, firebaseWebPushCertificate };
