import * as React from 'react';
import { WithStyles, withStyles, Theme, createStyles } from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';
import MenuList from '@material-ui/core/MenuList';
import MenuItem from '@material-ui/core/MenuItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Divider from '@material-ui/core/Divider';

import ExitIcon from '@material-ui/icons/PowerSettingsNew';
import HazardIcon from '@material-ui/icons/ReportProblem';
import FavouriteIcon from '@material-ui/icons/StarBorder';
import { withAppContext, IAppContextProp } from '../AppContext';
import Typography from '@material-ui/core/Typography';
import { i18n } from '../../i18n';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import { getMainDashboardUrl } from '../dashboard/utils/getMainDashboardUrl';
import { clearPreviousPages } from '../../data_sources/PreviousPage';

const styles = (theme: Theme) => createStyles({
    navContent: {
        maxWidth: '90vw'
    },
    menuHeader: {
        padding: 10,
        textAlign: 'center',
        color: '#FFF',
        background: theme.palette.primary.dark
    },
    menuIcon: {
        fontFamily: 'oneplaceIcons',
        fontSize: 24,
    },
    userEmail: {
        width: '100%',
        overflow: 'hidden',
        textOverflow: 'ellipsis'
    },
    list: {
        width: 220,
    },
});

export interface ILeftNavProps extends WithStyles<typeof styles>, IAppContextProp, RouteComponentProps<any> {
    open: boolean;
    onClose: () => void;
}

export const LeftNav = withStyles(styles)(withRouter(withAppContext((props: ILeftNavProps) => {

    function navigateTo(url: string) {
        // reset previous page states
        void clearPreviousPages(props.ctx.db);

        props.history.push(url);
        props.onClose();
    }

    const t = i18n.t;
    const user = props.ctx.auth.user;
    const capabs = user.capabilities;
    const dashboardUrl = getMainDashboardUrl(user);

    return (
        <Drawer open={props.open} onClose={props.onClose}>
            <div className={props.classes.navContent}>
                <div className={props.classes.menuHeader}>
                    <img src="img/menu_logo.png" width="201" height="62" />
                    <Typography color="inherit" className={props.classes.userEmail}>
                        {user.email}
                    </Typography>
                </div>
                <MenuList className={props.classes.list}>
                    <MenuItem onClick={() => navigateTo(dashboardUrl)}>
                        <ListItemIcon className={props.classes.menuIcon}>
                            <span className="icon-1place-home" />
                        </ListItemIcon>
                        <ListItemText  primary={t('home')} />
                    </MenuItem>
                    {capabs.myFranchisees &&
                        <MenuItem onClick={() => navigateTo('/franchisees')}>
                            <ListItemIcon className={props.classes.menuIcon}>
                                <span className="icon-1place-franchise" />
                            </ListItemIcon>
                            <ListItemText  primary={t('customLabel_franchisees')} style={{whiteSpace: 'normal'}}/>
                        </MenuItem>
                    }
                    <MenuItem onClick={() => navigateTo('/sites')}>
                        <ListItemIcon className={props.classes.menuIcon}>
                            <span className="icon-1place-site" />
                        </ListItemIcon>
                        <ListItemText  primary={t('customLabel_sites')} style={{whiteSpace: 'normal'}}/>
                    </MenuItem>
                    {capabs.usePeople && !capabs.hidePeopleManager &&
                        <MenuItem onClick={() => navigateTo('/people')}>
                            <ListItemIcon className={props.classes.menuIcon}>
                                <span className="icon-1place-customer" />
                            </ListItemIcon>
                            <ListItemText  primary={t('customLabel_people')} style={{whiteSpace: 'normal'}}/>
                        </MenuItem>
                    }
                    {capabs.checklists &&
                        <MenuItem onClick={() => navigateTo('/checklists')}>
                            <ListItemIcon className={props.classes.menuIcon}>
                                <span className="icon-1place-checklist" />
                            </ListItemIcon>
                            <ListItemText  primary={t('customLabel_checklists')} style={{whiteSpace: 'normal'}}/>
                        </MenuItem>
                    }
                    {capabs.tickets &&
                        <MenuItem onClick={() => navigateTo('/tickets')}>
                            <ListItemIcon className={props.classes.menuIcon}>
                                <span className="icon-1place-tickets" />
                            </ListItemIcon>
                            <ListItemText  primary={t('customLabel_tickets')} style={{whiteSpace: 'normal'}}/>
                        </MenuItem>
                    }
                    {(capabs.hsFranchiseeMenu || capabs.hsMainMenu) &&
                        <MenuItem onClick={() => navigateTo('/incidents')}>
                            <ListItemIcon className={props.classes.menuIcon}>
                                <span className="icon-1place-health-and-safety" />
                            </ListItemIcon>
                            <ListItemText  primary={t('customLabel_incidents')} style={{whiteSpace: 'normal'}}/>
                        </MenuItem>
                    }
                    {(capabs.hsFranchiseeMenu || capabs.hsMainMenu) &&
                        <MenuItem onClick={() => navigateTo('/hazards')}>
                            <ListItemIcon className={props.classes.menuIcon}>
                                <HazardIcon />
                            </ListItemIcon>
                            <ListItemText  primary={t('customLabel_hazards')}  style={{whiteSpace: 'normal'}}/>
                        </MenuItem>
                    }
                </MenuList>
                <Divider />
                <MenuList className={props.classes.list}>
                    <MenuItem onClick={() => navigateTo('/drafts')}>
                        <ListItemIcon className={props.classes.menuIcon}>
                            <span className="icon-1place-save-draft" />
                        </ListItemIcon>
                        <ListItemText  primary={t('view_drafts')} />
                    </MenuItem>
                    {capabs.checklists &&
                        <MenuItem onClick={() => navigateTo('/favourites')}>
                            <ListItemIcon className={props.classes.menuIcon}>
                                <FavouriteIcon />
                            </ListItemIcon>
                            <ListItemText  primary={t('favourites')} />
                        </MenuItem>
                    }
                </MenuList>
                <Divider />
                <MenuList className={props.classes.list}>
                    <MenuItem onClick={() => navigateTo('/settings')}>
                        <ListItemIcon className={props.classes.menuIcon}>
                            <span className="icon-1place-settings" />
                        </ListItemIcon>
                        <ListItemText  primary={t('settings')} />
                    </MenuItem>
                    <MenuItem onClick={() => props.ctx.logOut()} id="menu-logout-btn">
                        <ListItemIcon>
                            <ExitIcon />
                        </ListItemIcon>
                        <ListItemText  primary={t('log_out')} />
                    </MenuItem>
                </MenuList>
            </div>
        </Drawer>
    );
})));
