
export interface IUserCapabilities {
    checklists: boolean;  // access to checklists - create
    completeIncidentMode: boolean;  // when starting incident, see all the tabs (otherwise just shows the details tab)
    customers: boolean;  // not used
    franchiseDashboard: boolean;  // - should show Overview dashboard on login
    franchiseHazards: boolean;  // access to hazards
    franchiseId: number;  // unique ID for franchise (for api)
    franchiseMytickets: boolean;  // access to My Franchise Tickets
    franchiseeMytickets: boolean;  // access to My Franchisee Tickets
    franchiseSettings: boolean;  // settings
    franchiseeId: number | null;  // unique ID for franchisee (for api)
    franchiseeOverview: boolean;   // similar to dashboard
    hsFranchiseeMenu: boolean;  // health and safety
    hsMainMenu: boolean;  // health and safety
    invoices: boolean;  // ignore
    leads: boolean;  // ignore
    myFranchisees: boolean;  // access franchisees link (true for franchises, false for sites normally)
    notes: boolean;  // access
    quotes: boolean;  // ignore
    retailOrganisation: boolean;  // multi-site: true, franchise: false
    sites: boolean;   // should be true, ignore ?
    superuser: boolean;  // super rights, e.g. see all franchises
    ticketCategoryCheckInOtherTickets: boolean;  // used on other ticket page
    ticketOnlyInApp: boolean;  // only see tickets menu (no checklists, etc)
    tickets: boolean;  // access to tickets menu
    updateHazard: boolean;  // hazards editable?
    updateTicketEmailField: boolean;  // email field readonly?
    updateTicketSubject: boolean;  // deprecated. check the new settings in ticket category
    useAutocomplete: boolean;  // select boxes for site / franchisee - enabled when customer has lots of sites
    usePhotoGallery: boolean;  // can select from gallery as well as taking new photos
    useTicketType: boolean;  // on ticket page, whether ticket type field is shown
    username: string;  // username
    cancelTicket: boolean;  // Whether the user can cancel tickets
    locale: string; // user locale
    timeZone: string;  // default timezone
    dateFormat: string; // date format for  date-fns
    dateTimeFormat: string;  // datetime format for date-fns;
    userHash?: string; // intercom userhash
    createdAt: string;
    profile: string; // profile type, Super, Normal, Survey
    companyName: string;
    industry: string;
    planType: 'Lite' | 'Metered' | 'Free' | 'Trial' | 'Freemium' | 'Paid';
    trialRemainingDays: number;
    partner: boolean;
    presetName: string;
    advisorCode: string;
    usePeople: boolean;
    peopleReadOnly: boolean;
    hidePeopleManager: boolean;
    operatorId: number;
    hideFranchiseeManager: boolean;
	franchiseeReadOnly: boolean;
    hideSiteManager: boolean;
	siteReadOnly: boolean;
    allowSendIncidentEmail: boolean;
    customButtonType: 'note' | 'hazard'
    hazardVersion: 'legacy' | 'G8'
}

export class User {
    id!: string;
    email!: string;
    givenName!: string;
    familyName!: string;
    database?: string;
    capabilities!: IUserCapabilities;

    constructor(data: Partial<User>) {
        Object.assign(this, data);
    }

    get fullName() {
        return `${this.givenName} ${this.familyName}`;
    }
}
