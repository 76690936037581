import { INetworkStatus } from '../../network/NetworkStatus';
import { IApi } from '../api';
import { OnePlaceAuth } from '../../auth/OnePlaceAuth';
import { IAppDataDB, ITokenDB } from '../database';
import { ChecklistData } from '../ChecklistData';
import { i18n } from '../../i18n';
import { sleep } from '../../__test_utils__/sleep';
import { logError } from '../../logging';
import { formatDate } from '../../utils/dates';
import { FranchiseData } from '../FranchiseData';
import { IncidentData } from '../IncidentData';
import { TicketData } from '../TicketData';
import { CONFIG } from '../../config';
import { IChecklist, IChecklistTemplates } from 'oneplace-components';
import { AssetCacheManager } from '../assets/AssetCacheManager';
import { User } from '../../models/User';

import { legacyParse, convertTokens } from '@date-fns/upgrade/v2';
import { UnauthenticatedError } from '../../errors/UnauthenticatedError';

const TASK_SYNC_INTERVAL_MS = 500;
const SYNC_CHECK_INTERVAL_MS = 60000;

export interface ISyncTask {
    taskName: string;
    taskLabel: (t: any) => string;
    taskEnabled: (user: User) => boolean;
    syncData: () => Promise<void>;
}

export type SyncStatus = 'disabled' | 'enabled' | 'in_progress';

export interface ISyncMetadata {
    lastSuccessfulSync: string;
    lastSync: {
        [itemName: string]: string;
    };
}


export interface ISyncSettings {
    fetchLimit: number;
    peopleFailedCount: number;
}

export interface ISyncOptions {
    forceFullSync: boolean;
}

export interface ISyncManager {
    initialise(): Promise<void>;

    syncStatus: SyncStatus;
    syncStatusLabel: string;
    shouldShowCacheAlert(): boolean;
    doSync(options: ISyncOptions): Promise<void>;
    cancelSync(): void;

    addListener: (listener: () => void) => void;
    removeListener: (listener: () => void) => void;
}

export class SyncManager implements ISyncManager {
    private listeners: (() => void)[] = [];
    private syncMeta: ISyncMetadata = null as any;
    private syncCancelFlag = false;
    private syncIntervalTimer: any = null;
    syncStatus: SyncStatus = 'disabled';
    syncStatusLabel = '';

    constructor(
        private net: INetworkStatus,
        private auth: OnePlaceAuth,
        private api: IApi,
        private tokenDB: ITokenDB,
        private db: IAppDataDB,
        private assetCache: AssetCacheManager
    ) {}

    async initialise(): Promise<void> {
        try {
            this.syncMeta = await this.db.getSyncStatus();
            this.syncStatusLabel = this.getLastSyncLabel();
            this.notifyListeners();
            this.onAuthStatusChange();
            this.auth.addListener(this.onAuthStatusChange);
        }
        catch (e) {
            logError(e, 'Error initialising SyncManager.');
        }

    }

    onAuthStatusChange = (): void => {
        if (this.auth.status == 'online' && this.syncStatus == 'disabled') {
            this.startSyncCheck();
        }
        else if (this.auth.status != 'online' && this.syncStatus != 'disabled') {
            this.stopSyncCheck();
        }
    }

    private startSyncCheck() {
        this.syncStatus = 'enabled';
        // eslint-disable-next-line @typescript-eslint/no-misused-promises
        this.syncIntervalTimer = setInterval(this.checkAndSync, SYNC_CHECK_INTERVAL_MS);
        console.log('SyncManager enabled.');
    }

    private stopSyncCheck() {
        this.syncStatus = 'disabled';
        clearInterval(this.syncIntervalTimer);
        console.log('SyncManager disabled.');
    }

    getLastSyncLabel(): string {
        const t = i18n.t;
        if (!this.syncMeta || !this.syncMeta.lastSuccessfulSync) {
            return t('last_synced') + ': ' + t('never');
        }
        else {
            const syncDate = this.syncMeta.lastSuccessfulSync;
            return t('last_synced') + ': ' + formatDate('display_datetime', syncDate, this.auth.user.capabilities.dateTimeFormat);
        }
    }

    updateStatus(status: SyncStatus, statusLabel: string): void {
        this.syncStatus = status;
        this.syncStatusLabel = statusLabel;
        this.notifyListeners();
    }

    syncTasks: ISyncTask[] = [
        {
            taskName: 'capabilities',
            taskLabel: (t): string => t('user_capabilities'),
            taskEnabled: (): boolean => true,
            syncData: async (): Promise<void> => {
                try {
                    const authenticated = await this.auth.checkAuth();
                    if (!authenticated) {
                        throw new UnauthenticatedError('User is not authenticated');
                    }
                } catch (e) {
                    logError(e);
                    throw new UnauthenticatedError('Error User is not authenticated');
                }


                const capabs = await this.auth.getUserCapabilities();
                if (capabs) {
                    capabs.dateTimeFormat = convertTokens(capabs.dateTimeFormat);
                    capabs.dateFormat = convertTokens(capabs.dateFormat);
                    this.auth.user.capabilities = capabs;
                    await this.tokenDB.storeUser(this.auth.user);
                }
            }
        },
        {
            taskName: 'franchise',
            taskLabel: (t): string => `${t('customLabel_franchise')} ${t('data')}`,
            taskEnabled: (): boolean => true,
            syncData: async (): Promise<void> => {
                const franchiseData = new FranchiseData(this.db, this.api, this.auth.user);
                await franchiseData.getFranchise().cacheData();
            }
        },
        {
            taskName: 'franchisees',
            taskLabel: (t): string => `${t('customLabel_franchisee')} ${t('data')}`,
            taskEnabled: (): boolean => true,
            syncData: async (): Promise<void> => {
                const franchiseId = this.auth.user.capabilities.franchiseId;
                const franchiseData = new FranchiseData(this.db, this.api, this.auth.user);
                await franchiseData.getFranchisees(franchiseId).cacheData();
            }
        },
        {
            taskName: 'sites',
            taskLabel: (t): string => `${t('customLabel_site')} ${t('data')}`,
            taskEnabled: (): boolean => true,
            syncData: async (): Promise<void> => {
                const franchiseId = this.auth.user.capabilities.franchiseId;
                const franchiseData = new FranchiseData(this.db, this.api, this.auth.user);
                await franchiseData.getSites(franchiseId).cacheData();
            }
        },
        {
            taskName: 'sites_archived',
            taskLabel: (t): string => `${t('customLabel_site')} archived ${t('data')}`,
            taskEnabled: (): boolean => true,
            syncData: async (): Promise<void> => {
                const franchiseId = this.auth.user.capabilities.franchiseId;
                const franchiseData = new FranchiseData(this.db, this.api, this.auth.user);
                await franchiseData.getSitesArchived(franchiseId).cacheData();
            }
        },
        {
            taskName: 'franchisee_sites',
            taskLabel: (t): string => `${t('customLabel_franchisee')} ${t('customLabel_sites')}`,
            taskEnabled: (): boolean => true,
            syncData: async (): Promise<void> => {
                const franchiseId = this.auth.user.capabilities.franchiseId;
                const franchiseData = new FranchiseData(this.db, this.api, this.auth.user);
                await franchiseData.syncFranchiseeSites(franchiseId).cacheData();
            }
        },
        {
            taskName: 'dashboard',
            taskLabel: (t): string => t('dashboard_data'),
            taskEnabled: (): boolean => true,
            syncData: async (): Promise<void> => {
                const capabs = this.auth.user.capabilities;
                const franchiseId = this.auth.user.capabilities.franchiseId;
                const checklistData = new ChecklistData(this.db, this.api, this.auth.user);
                if (capabs.franchiseDashboard == false
                    && capabs.retailOrganisation == true
                    && capabs.franchiseeId) {
                        await checklistData.getFranchiseeDashboard(
                            franchiseId, capabs.franchiseeId, null).cacheData();
                }
                else {
                    await checklistData.getOverviewDashboard(franchiseId, null).cacheData();
                }
            }
        },
        {
            taskName: 'todays_checklists',
            taskLabel: (t): string => t('checklists_due_today'),
            taskEnabled: (user): boolean => user.capabilities.checklists,
            syncData: async (): Promise<void> => {
                const checklistData = new ChecklistData(this.db, this.api, this.auth.user);
                await checklistData.getAllTodaysChecklists(true);
            }
        },
        {
            taskName: 'tags',
            taskLabel: (t): string => t('tags'),
            taskEnabled: (): boolean => true,
            syncData: async (): Promise<void> => {
                const franchiseId = this.auth.user.capabilities.franchiseId;
                const franchiseData = new FranchiseData(this.db, this.api, this.auth.user);
                await franchiseData.getTags(franchiseId).cacheData();
            }
        },
         // only run it if health safety module is enabled
         {
             taskName: 'incident_template',
            taskLabel: (t): string => t('incident_template'),
            taskEnabled: (user): boolean => user.capabilities.hsMainMenu || user.capabilities.hsFranchiseeMenu,
            syncData: async (): Promise<void> => {
                const franchiseId = this.auth.user.capabilities.franchiseId;
                const franchiseData = new FranchiseData(this.db, this.api, this.auth.user);
                const franchisees = await franchiseData.getFranchisees(franchiseId).getData();
                const franchiseeId = franchisees.franchisees[0].id;
                const incidentData = new IncidentData(this.db, this.api, this.auth.user);
                await incidentData.getIncidentTemplate(franchiseId, franchiseeId).cacheData();
            }
        },
         // only run it if health safety module is enabled
        {
            taskName: 'incident_type',
            taskLabel: (t): string => t('incident_types'),
            taskEnabled: (user): boolean => user.capabilities.hsMainMenu || user.capabilities.hsFranchiseeMenu,
            syncData: async (): Promise<void> => {
                const franchiseId = this.auth.user.capabilities.franchiseId;
                const incidentData = new IncidentData(this.db, this.api, this.auth.user);
                await incidentData.getIncidentTypes(franchiseId).cacheData();
            }
        },
        {
            taskName: 'checklist_templates',
            taskLabel: (t): string => t('checklist_template_data'),
            taskEnabled: (user): boolean => user.capabilities.checklists,
            syncData: async (): Promise<void> => {
                const franchiseId = this.auth.user.capabilities.franchiseId;
                // Get the first franchiseeId so we can get the templates
                const franchiseData = new FranchiseData(this.db, this.api, this.auth.user);
                const franchisees = await franchiseData.getFranchisees(franchiseId).getData();
                const franchiseeId = franchisees.franchisees[0].id;
                // Get the templates
                const checklistData = new ChecklistData(this.db, this.api, this.auth.user);
                const template_list = await checklistData.getChecklistTemplates(franchiseId).getData();
                await this.db.clearEntityCache('checklist_templates');
                for (const template of template_list.templates) {
                    if (this.syncCancelFlag) { break; }
                    await checklistData.getChecklistTemplate(
                        franchiseId, franchiseeId, template.id).cacheData();
                }
                const scheduled_template_list = await checklistData.getScheduleOnlyChecklistTemplates(franchiseId).getData();
                for (const template of scheduled_template_list.templates) {
                    if (this.syncCancelFlag) { break; }
                    await checklistData.getChecklistTemplate(
                        franchiseId, franchiseeId, template.id).cacheData();
                }
            }
        },
        {
            taskName: 'checklist_templates_parent',
            taskLabel: (t): string => t('checklist_template_data'),
            taskEnabled: (user): boolean => user.capabilities.checklists,
            syncData: async (): Promise<void> => {
                const franchiseId = this.auth.user.capabilities.franchiseId;
                // Get the templates
                const checklistData = new ChecklistData(this.db, this.api, this.auth.user);
                await checklistData.getChecklistTemplateList(franchiseId).getData();
            }
        },
        {
            taskName: 'help_images',
            taskLabel: (t): string => t('help_images'),
            taskEnabled: (user): boolean => user.capabilities.checklists,
            syncData: async (): Promise<void> => {
                // Get template list directly from cache
                const template_list = await this.db.getById<IChecklistTemplates>('checklist_template_list', '1');
                // get the schedule only ones
                const schedule_templates = await this.db.getById<IChecklistTemplates>('checklist_template_list', '2');

                const new_template_list = [...template_list.templates, ...schedule_templates.templates];

                for (const templateMeta of new_template_list) {
                    if (this.syncCancelFlag) { break; }
                    // Get template content from the cache
                    const template = await this.db.getById<IChecklist>('checklist_templates', String(templateMeta.id));
                    if (template) {
                        for (const group of template.groups) {
                            for (const field of group.fields) {
                                if (field.helpImages && field.helpImages.length) {
                                    for (const image of field.helpImages) {
                                        // Cache asset
                                        await this.assetCache.getLocalAssetUrl(
                                            'helpimg', image.url, undefined , false);
                                    }
                                }
                            }
                        }
                    }
                }
            }
        },
        {
            taskName: 'ticket_types',
            taskLabel: (t): string => t('ticket_type_data'),
            taskEnabled: (): boolean => true,
            syncData: async (): Promise<void> => {
                if (this.auth.user.capabilities.useTicketType) {
                    const franchiseId = this.auth.user.capabilities.franchiseId;
                    const ticketData = new TicketData(this.db, this.api, this.auth.user);
                    await ticketData.getTypes(franchiseId).cacheData();
                }
            }
        },
        {
            taskName: 'ticket_categories',
            taskLabel: (t): string => t('ticket_category_data'),
            taskEnabled: (): boolean => true,
            syncData: async (): Promise<void> => {
                const franchiseId = this.auth.user.capabilities.franchiseId;
                const ticketData = new TicketData(this.db, this.api, this.auth.user);
                await ticketData.getCategories(franchiseId).cacheData();
            }
        },
        {
            taskName: 'people_list',
            taskLabel: (t): string => `${t('customLabel_people')} ${t('data')}`,
            taskEnabled: (user): boolean => user.capabilities.usePeople,
            syncData: async (): Promise<void> => {
                let lastSyncTime;
                const personTableEmpty = await this.db.isTableEmpty('people');
                if(personTableEmpty) {
                    // force a full sync
                    lastSyncTime = null;
                }else{
                    lastSyncTime = await this.db.getLastSyncTimeForPeople();
                }
                const franchiseId = this.auth.user.capabilities.franchiseId;
                const franchiseData = new FranchiseData(this.db, this.api, this.auth.user);
                const settings = await this.db.getSyncSettings();
                //failed more than 4 time let give up for now
                if (settings.peopleFailedCount < 5) {
                    const limit = settings.fetchLimit? settings.fetchLimit : 100;
                    let offset = 0;
                    let hasMoreData = true;
                    while (hasMoreData) {
                        // call the pagination api and return result size
                        const resultSize = await franchiseData.syncPeople(franchiseId, limit, offset, lastSyncTime).cacheBatchedData();

                        if (resultSize < limit) {
                            hasMoreData = false;
                        } else {
                            offset += limit;
                            // refresh token?
                            await this.auth.checkAuth();
                        }
                    }
                }

            }
        },
        {
            taskName: 'person_types',
            taskLabel: (t): string => `${t('customLabel_personTypes')} ${t('data')}`,
            taskEnabled: (user): boolean => user.capabilities.usePeople,
            syncData: async (): Promise<void> => {
                let lastSyncTime;
                const personTypeTableEmpty = await this.db.isTableEmpty('person_types');
                if(personTypeTableEmpty) {
                    // force a full sync
                    lastSyncTime = null;
                }else{
                    lastSyncTime = await this.db.getLastSyncTimeForPersonTypes();
                }
                const franchiseId = this.auth.user.capabilities.franchiseId;
                const franchiseData = new FranchiseData(this.db, this.api, this.auth.user);
                await franchiseData.syncPersonTypes(franchiseId, lastSyncTime).cacheData();
            }
        }
    ];

    canSync(): boolean {
        return !this.net.isOffline && this.auth.status == 'online';
    }

    syncIsOlderThan(lastSyncDateStr: string | null, intervalMins: number): boolean {
        if (!lastSyncDateStr) {
            return true;
        }
        const lastSyncSate = legacyParse(lastSyncDateStr);
        const now = new Date();
        const maxIntervalMS = intervalMins * 60000;
        return now.getTime() - lastSyncSate.getTime() > maxIntervalMS;
    }

    shouldShowCacheAlert(): boolean {
        return this.syncIsOlderThan(this.syncMeta.lastSuccessfulSync, CONFIG.offlineSyncAlertMins);
    }

    checkAndSync = async (): Promise<void> => {
        console.log('Checking if we should sync...');
        const settings = await this.db.getSettings();
        if (settings.disableBackgroundSync) {
            console.log('Sync is disabled by settings.');
        }
        else if (this.syncIsOlderThan(this.syncMeta.lastSuccessfulSync, CONFIG.offlineSyncIntervalMins)) {
            if (this.canSync() && this.syncStatus == 'enabled') {
                console.log('Triggering automatic incremental background sync...');
                await this.doSync({ forceFullSync: false });
                console.log('Automatic background sync complete');
            }
            else {
                console.log('Sync needed but no connection available or sync in progress.');
            }
        }
        else {
            console.log('Cached data is up-to-date.');
        }
    }

    async doSync(options: ISyncOptions): Promise<void> {
        const t = i18n.t;
        if (this.syncStatus != 'enabled') {
            console.log('Sync is disabled or already in progress');
        }
        else if (!this.canSync()) {
            console.log('Not starting sync. Connection is not available.');
        }
        else {

            let hadErrors = false;
            const errors = [];
            const user = this.auth.user;

            for (const task of this.syncTasks) {
                if (
                    (options.forceFullSync
                        || this.syncIsOlderThan(this.syncMeta.lastSync[task.taskName], CONFIG.offlineSyncIntervalMins))
                    && task.taskEnabled(user)
                ) {
                    if (options.forceFullSync && task.taskName =='people_list') {
                        // reset the counter
                        const settings = await this.db.getSyncSettings();
                        settings.peopleFailedCount = 0
                        settings.fetchLimit = 100
                        this.syncMeta.lastSync[task.taskName] = ''
                        try {
                            await this.db.setSyncStatus(this.syncMeta);
                            await this.db.setSyncSettings(settings);
                        }
                        catch (e) {
                            logError(e, 'Error saving sync settings');
                        }
                    }
                    this.updateStatus('in_progress',
                        t('synchronising') + ' ' + task.taskLabel(t) + '...');
                    try {
                        await task.syncData();
                        if (this.syncCancelFlag) {
                            break;
                        }
                        this.syncMeta.lastSync[task.taskName] = formatDate('iso', new Date());
                        await this.db.setSyncStatus(this.syncMeta);
                    }
                    catch (e) {
                        logError(e, `Error synchronising ${task.taskName}.`);
                        hadErrors = true;
                        errors.push(task.taskName)
                    }
                    await sleep(TASK_SYNC_INTERVAL_MS);
                }
            }

            if (!this.syncCancelFlag && !hadErrors) {
                this.syncMeta.lastSuccessfulSync = formatDate('iso', new Date());
                await this.db.setSyncStatus(this.syncMeta);
                const settings = await this.db.getSyncSettings();
                settings.peopleFailedCount = 0
                settings.fetchLimit = 100
                try {
                    await this.db.setSyncSettings(settings);
                }
                catch (e) {
                    logError(e, 'Error saving settings');
                }
            } else if (hadErrors) {
                if (errors.includes('people_list')) {
                    const settings = await this.db.getSyncSettings();
                    if (settings.fetchLimit) {
                        switch (settings.fetchLimit) {
                            case 100:
                                settings.fetchLimit = 50
                                break;
                            case 50:
                                settings.fetchLimit = 25
                                break;
                            case 25:
                                settings.fetchLimit = 10
                                break;
                            case 10:
                                settings.fetchLimit = 5
                                break;
                        }
                    } else{
                        settings.fetchLimit = 100;
                    }
                    settings.peopleFailedCount += 1
                    try {
                        await this.db.setSyncSettings(settings);
                    }
                    catch (e) {
                        logError(e, 'Error saving settings');
                    }
                }
            }

            this.syncCancelFlag = false;
            this.updateStatus('enabled', this.getLastSyncLabel());
        }
    }

    cancelSync(): void {
        this.syncCancelFlag = true;
    }

    addListener(listener: () => void): void {
        this.listeners.push(listener);
    }
    removeListener(listener: () => void): void {
        const idx = this.listeners.indexOf(listener);
        if (idx > -1) {
            this.listeners.splice(idx, 1);
        }
    }
    notifyListeners(): void {
        this.listeners.forEach((listener) => listener());
    }

}
