import * as React from 'react';
import { PageLoader } from 'oneplace-components';
import {  Button, List, Accordion, AccordionSummary, Typography, AccordionDetails, createStyles, Theme, WithStyles, withStyles, AccordionActions, Badge } from '@material-ui/core';
import { IAppContextProp, withAppContext } from '../AppContext';
import { IncidentListItem } from './IncidentListItem';
import { IIncidentsListEntry, IIncidentsListEntryWrapper } from '../../models/Incident';
import { i18n } from '../../i18n';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

const styles = (_theme: Theme) => createStyles({
    badgeRoot: {
        marginTop: 14
    },
    typographyRoot: {
        marginLeft: 30
    },
    listItemTextRoot: {
        padding: '0 16px'
    }
});

interface IncidentsAccordionProps 
    extends IAppContextProp, 
    WithStyles<typeof styles>{
    incidents: IIncidentsListEntryWrapper[] | undefined
    summaryTitle: string
    notFoundMessage: string
    hasMoreIncidents: boolean
    loadingMoreIncidents: boolean
    onClickIncident: (incidentDetails: IIncidentsListEntry) => void
    onClickLoadMore: () => void
    incidentCount: number | undefined
    defaultExpanded: boolean
    onSwitchExpand: (expanded: boolean) => void
}

const IncidentsAccordionComponent = (props: IncidentsAccordionProps): JSX.Element => {
    const t = i18n.t
    return (     
        <Accordion 
            expanded={props.defaultExpanded}
            onChange={(event, expanded) => props.onSwitchExpand(expanded)}
        >
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                {!!props.incidentCount && 
                    <Badge color="secondary" 
                    badgeContent={props.incidentCount}
                    overlap="rectangular"
                    classes={{ root: props.classes.badgeRoot }}/>
                }
                <Typography variant="subtitle1" classes={{ root: props.classes.typographyRoot }}>
                    {props.summaryTitle}
                </Typography>
            </AccordionSummary>
            <AccordionDetails style={{ padding: '0 8px' }}>
                {!props.incidents && <PageLoader loading={true} paddingTop={0} />}
                {props.incidents?.length == 0 &&
                    <Typography style={{ width: '100%', textAlign: 'center' }}>
                        {props.notFoundMessage}
                    </Typography>
                }
                {props.incidents && 
                    props.incidents.length > 0 && 
                    <List
                        style={{ width: '100%' }}
                        disablePadding={true}
                    >
                        {props.incidents.map(incident => {
                            const idx = incident.incident.id
                            return <IncidentListItem
                                key={`incident-${idx}`}
                                incident={incident}
                                ctx={props.ctx}
                                goToIncident={props.onClickIncident}
                            />
                        })}
                    </List>
                }              
            </AccordionDetails>
            <AccordionActions style={{justifyContent: 'center'}}>
                {props.hasMoreIncidents && 
                    <Button
                        variant="contained"
                        onClick={props.onClickLoadMore}
                        disabled={props.loadingMoreIncidents}
                    >
                        {props.loadingMoreIncidents ? t('loading') : t('show_more')}
                    </Button>
                }      
            </AccordionActions>
        </Accordion>
    )
}

export const IncidentsAccordion = withAppContext(withStyles(styles)(IncidentsAccordionComponent))