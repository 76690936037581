import { CONFIG } from './config';

export interface IEnvironment {
    platform: 'web' | 'cordova';
    os: 'ios' | 'android' | 'windows' | 'browser' | '';  // Only set in Cordova at the moment
    target: 'prod' | 'test' | 'dev';
}

export const ENV: IEnvironment = {} as any;

export const AMAZON_FIRE = 'amazon-fireos';

export function waitForEnv() {
    return new Promise<void>((resolve) => {
        const environment = CONFIG.authServerUrl.toLowerCase();
        ENV.target = environment.includes('testlogin.1placeonline.com') ? 'test'
                    : environment.includes('login.1placeonline.com') ? 'prod'
                    : 'dev';
        if (window.Cordova || window.cordova) {
            ENV.platform = 'cordova';
            window.addEventListener('filePluginIsReady', () => {
                console.log('File plugin is ready');
            }, false);
            document.addEventListener('deviceready', () => {
                const platformString = device.platform.toLowerCase();
                ENV.os = platformString.includes('android') || platformString.includes('amazon-fireos') ? 'android'
                    : platformString.includes('ios') ? 'ios'
                    : platformString.includes('windows') ? 'windows' : 'browser';
                resolve();
            });
        }
        else {
            ENV.platform = 'web';
            ENV.os = '';
            resolve();
        }
    });
}

export function isUseSqliteDatabase() {
    return ENV.os == 'ios'  || ENV.os == 'android' ;
}
