
import * as React from 'react';
import Typography from '@material-ui/core/Typography';

export interface IReadOnlyFieldProps {
    label: string;
    content: any;
}

export const ReadOnlyField = (props: IReadOnlyFieldProps) => (
    <div style={{ marginTop: 10 }}>
        <Typography variant="caption">{props.label}</Typography>
        <Typography variant="subtitle1">{props.content}</Typography>
    </div>
);
