import { getMessaging, getToken, onMessage, deleteToken, Messaging } from 'firebase/messaging';
import {
    firebaseConfig,
    firebaseWebPushCertificate,
} from '../../config';
import { FirebaseApp, initializeApp } from 'firebase/app';
import IRegistration, { IRegistrationStatus } from './registration';

let app : FirebaseApp;
let messaging : Messaging;

const initFirebase = () =>{
    if(!app || !messaging){
        console.log('in init firebase, initing firebase');
        app = initializeApp(firebaseConfig);
        messaging = getMessaging(app);
    }
}
export const getFirebaseToken = (
    registration: IRegistration,
    subject: string,
): Promise<IRegistrationStatus>  => {
    // Get Instance ID token. Initially this makes a network call, once retrieved
    // subsequent calls to getToken will return from cache.
    const options = {
        vapidKey: firebaseWebPushCertificate,
    }
    initFirebase();
    return getToken(messaging, options)
        .then((currentToken) => {
            if (currentToken) {
                console.log('Token received: ', currentToken);
                // const decodedIdToken: any = jwt_decode(token.idToken);
                // always register device as user can change
                if (!registration ||
                    registration.token !== currentToken ||
                    registration.subject !== subject
                ) {
                    console.log('register device');
                    return { status:'register', token: currentToken}  as IRegistrationStatus
                } else {
                    console.log('Device already registered');
                    return { status:'ok', token: currentToken } as IRegistrationStatus
                }
            } else {
                console.log(
                    'No registration token available. Request permission to generate one.'
                );
                return { status:'error' }  as IRegistrationStatus
            }
        })
        .catch((err) => {
            console.log('An error occurred while retrieving token. ', err);
            return { status:'error'}  as IRegistrationStatus
        });
};

export const onMessageListener = (): Promise<any> => {
    initFirebase();
    return new Promise((resolve) => {
        onMessage(messaging, (payload: any) => {
            console.log('Message received. ', payload);
            resolve(payload);
        });
    });
};

export const deleteFirebaseToken = async (): Promise<void> => {
    if(messaging){
        await deleteToken(messaging);
    }
}

