import {
    Dialog,
    DialogTitle,
    DialogContent,
    DialogContentText,
    DialogActions,
    Button
} from '@material-ui/core';
import * as React from 'react';

interface IncidentPromptProps {
    show: boolean;
    onClose: () => void;
    title: string;
    message: string;
    closeButtonLabel: string;
    actions: any[];
}

export const IncidentPrompt = ({
    show = false,
    onClose,
    title,
    message,
    closeButtonLabel,
    actions,
}: IncidentPromptProps): JSX.Element => {
    return (
        <Dialog
            open={show}
            onClose={onClose}
        >
            <DialogTitle>{title}</DialogTitle>
            <DialogContent>
                <DialogContentText>
                    {message}
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button
                    onClick={onClose}
                    color="primary"
                >
                    {closeButtonLabel}
                </Button>
                {actions && actions.map((action, actionIdx) => (
                    <Button
                        key={actionIdx}
                        onClick={action.onClick}
                        color="primary"
                    >
                        {action.label}
                    </Button>))}
            </DialogActions>
        </Dialog>
    )
}