
import * as React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { i18n } from '../../i18n';
export interface LogoutDialogProps {
    open: boolean;
    logout: () => void
    stayLoggedIn: () => void
  }

export const  LogoutDialog = (props: LogoutDialogProps): JSX.Element => {
    const {open, logout, stayLoggedIn } = props;
    const t = i18n.t;
    const handleCancel = () => {
        stayLoggedIn();
      };

      const handleLogout = () => {
        logout();
      };

  return (
    <div>
      <Dialog
        open={open}

        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{t("logout_dialog_title")}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {t("logout_dialog_message")}

          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCancel} color="primary">
                {t("logout_cancel")}
          </Button>
          <Button  onClick={handleLogout} color="primary" autoFocus>
            {t("logout_completely")}

          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
