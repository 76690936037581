
import * as React from 'react';
import { useSnackbar } from 'notistack';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { NotificationDialog, IMyNotificationsWrapper, INotification, NotificationSnackbar,INotificationFCMData } from 'oneplace-components';
import { useEffect, useRef} from 'react';
import { IAppProviderContext } from '../AppContext';
import IRegistration, { IRegistrationStatus } from '../firebase/registration';
import { ENV, AMAZON_FIRE } from '../../environment';
import { logError } from '../../logging';
import {  getFirebaseToken, onMessageListener } from '../firebase/firebase';
import { Button, createStyles, makeStyles } from '@material-ui/core';
import { addNotificationListeners, setBadgeNumber } from './PushNotification';


const useStyles = makeStyles(() =>
  createStyles({
    snackbar: {
      borderLeft: '4px solid #f50057',
      backgroundColor: 'white',
      color: 'black'
    },
    snackbar_icon: {
      fontFamily: 'oneplaceIcons',
      fontSize: '3rem !important',
      color: '#f50057'
    },
    snackbar_link: {
      color: 'black !important',
      textTransform: 'none'
    }
  })
)

export interface INotificationCenterHomeProps extends RouteComponentProps<any> {
    ctx: IAppProviderContext;
}

export interface INotificationCenterState {
    unreadCount: number;
}
export default withRouter((props: INotificationCenterHomeProps) => {

    const classes = useStyles();
    const capabilities = props.ctx.auth.user.capabilities;
    const api = props.ctx.api;
    const [count, setCount] = React.useState(-1);
    const countRef = useRef(count);
    const setCountRef = (newCount: number) =>{
        countRef.current = newCount;
        setCount(newCount);
    }

    //saves snackbar ids so we can close the snackbar by clicking the link
    const snackbarIdsMap = useRef<Map<string, number>>(new Map());
    const {enqueueSnackbar, closeSnackbar}= useSnackbar();
    useEffect(() => {
        if(!props.ctx.auth.user){
            return;
        }
        async function loadUnreadCount() {
            const unReadCount = await api.getUnreadNotificationsCount(capabilities.franchiseId);
            setCountRef(unReadCount);
            setBadgeNumber(unReadCount);
        }
        loadUnreadCount();
        initFCM();
    }, [props.ctx.auth.user]);


    const clickSnackbarLink = (link: string, notificationId: string) => {
        const snackbarId = snackbarIdsMap.current.get(notificationId);
        if(snackbarId){
            closeSnackbar(snackbarId);
            snackbarIdsMap.current.delete(notificationId);
        }
        const newCount = countRef.current-1;
        setBadgeNumber(newCount);
        setCountRef(newCount);
        props.history.push(link);
    }

    const action = (snackbarId: number) => (
        <>
          <Button style={{color:'#f50057', textTransform:'none'}} size='small' variant="outlined" onClick={(e)=>{dismissNotificationSnackbar(e, snackbarId)} }>
            Dismiss
          </Button>
        </>
    );

    const registerForApp  = async (registrationId: string): Promise<void> => {
        let registrationStatus: IRegistrationStatus;
        const registration = await props.ctx.auth.getRegistration();
        if(registration){
            console.log('registration details:'+JSON.stringify(registration));
        }
        console.log('registrationId:'+registrationId);
        if (!registration ||
            registration.token !== registrationId ||
            registration.subject !== props.ctx.auth.user.id
        ) {
            console.log('register device');
            registrationStatus = { status:'register', token: registrationId}  as IRegistrationStatus
        } else {
            console.log('Device already registered');
            registrationStatus = { status:'ok', token: registrationId } as IRegistrationStatus
        }
        if (registrationStatus.status === 'register' && registrationStatus.token) {
            const result = await props.ctx.api.register(ENV.os, registrationStatus.token);
            if (result && registrationStatus.token) {
                props.ctx.auth.updateRegistration({token: registrationStatus.token, subject: props.ctx.auth.user.id});
            } else {
                console.log('Failed to register');

            }
        } else if (registrationStatus.status === 'ok') {
            console.log('NotificationManager: already registered')
        } else {
            console.log('NotificationManager: initialise error: registrationStatus:', registrationStatus);
        }
    }

    const registerForWeb= async (registration: IRegistration) =>{
        const registrationStatus = await getFirebaseToken(registration, props.ctx.auth.user.id)
        if (registrationStatus.status === 'register' && registrationStatus.token) {
            const result = await props.ctx.api.register('browser', registrationStatus.token);
            if (result && registrationStatus.token) {
                props.ctx.auth.updateRegistration({token: registrationStatus.token, subject: props.ctx.auth.user.id});
            } else {
                console.log('Failed to register');

            }
        } else if (registrationStatus.status === 'ok') {
            console.log('NotificationManager: already registered')
        } else {
            console.log('NotificationManager: initialise error: registrationStatus:', registrationStatus);
        }

        onMessageListener()
            .then((payload: any) => {
                console.log(payload);

                const newNotificationMsgData: INotificationFCMData = {'subject':payload.data.subject,
                                                                     'content': payload.data.content,
                                                                     'entityName':payload.data.entityName,
                                                                     'entityId':payload.data.entityId,
                                                                     'entityType':payload.data.entityType,
                                                                     'customFieldName':payload.data.customFieldName,
                                                                     'notificationId':payload.data.notificationId,
                                                                     'date':payload.data.date,
                                                                     'ticketStatus':payload.data.ticketStatus,
                                                                     'comment':payload.data.comment,
                                                                     'webLink':payload.data.webLink,
                                                                     'appLink':payload.data.appLink,
                                                                     'timezone':capabilities.timeZone} ;
                const newSnackId = enqueueSnackbar(<NotificationSnackbar notificationFCMData={newNotificationMsgData} clickLink={clickSnackbarLink}/>, { className: classes.snackbar, action });
                snackbarIdsMap.current.set(newNotificationMsgData.notificationId, Number(newSnackId));
                setCountRef(countRef.current+1);
        })
        .catch((err: any) => console.log('registerForWeb failed: ', err));
    }

    const dismissNotificationSnackbar = (e: any, snackbarId: number) => {
        e.preventDefault();
        closeSnackbar(snackbarId);
    };

    const validateNotificationPayload = (data: any): boolean =>{
        if(!data || !data.additionalData || !data.additionalData.entityName || !data.additionalData.entityType
             || !data.additionalData.appLink || !data.additionalData.notificationId
             || !data.additionalData.date){
            return false;
        }
        if(data.additionalData.entityType==='ticket'){
            if((!data.title || !data.additionalData.ticketStatus)){
                return false;
            }
        }else{
            // date custom field
            if((!data.additionalData.customFieldName || !data.message)){
                return false;
            }
        }
        return true;
    }

    const onNotificationFunction = (data : any) =>{

        // validate notification payload in mobile app
        const isValidPayload = validateNotificationPayload(data);

        if(!isValidPayload){
            return;
        }

        const newNotificationMsgData: INotificationFCMData = {
            'subject': data.title,
            'content': data.message,
            'entityName': data.additionalData.entityName,
            'entityId': data.additionalData.entityId,
            'entityType': data.additionalData.entityType,
            'customFieldName': data.additionalData.customFieldName,
            'notificationId': data.additionalData.notificationId,
            'date': data.additionalData.date,
            'ticketStatus': data.additionalData.ticketStatus,
            'comment': data.additionalData.comment,
            'webLink': data.additionalData.webLink,
            'appLink': data.additionalData.appLink,
            'timezone': capabilities.timeZone
        };
        const newSnackId = enqueueSnackbar(<NotificationSnackbar notificationFCMData={newNotificationMsgData} clickLink={clickSnackbarLink} />, { className: classes.snackbar, action });
        snackbarIdsMap.current.set(newNotificationMsgData.notificationId, Number(newSnackId));
        const newCount = countRef.current+1;
        setBadgeNumber(newCount);
        setCountRef(newCount);
    }
    const initFCM = async () => {
            try {
                const registration = await props.ctx.auth.getRegistration();
                if ((ENV.platform == 'cordova' && ENV.os == 'browser') ||  ENV.platform === 'web') {
                    registerForWeb(registration);
                } else if (ENV.platform == 'cordova' && ENV.os != 'windows') {
                    const platformString = device.platform.toLowerCase();
                    console.log('platformString:'+platformString);
                    if (platformString.includes(AMAZON_FIRE)) {
                        console.log('Amazon Fire OS doesn\'t support notification');
                    } else {
                        // add cordova-plugin-push listener for mobile app
                        if(registration && registration.token){
                            await registerForApp(registration.token);
                        }
                        console.log('IOS or android');
                        addNotificationListeners(onNotificationFunction, registerForApp);
                    }

                }
            }
            catch (e) {
                logError(e, 'Error initialising NotificationManager.');
            }
    }


    const fetchMyNotifications = async (batchSize: number, newOffset: number) => {
        const notificationsWrapper: IMyNotificationsWrapper = await api.getMyNotifications(capabilities.franchiseId, batchSize, newOffset);
        return notificationsWrapper;
    }
    const markNotificationsAsRead = async () => {
        const markSuccessful = await api.markNotificationsAsRead(capabilities.franchiseId);
        if(markSuccessful){
            setBadgeNumber(0);
            setCountRef(0);
        }
        return markSuccessful;
    }

    const clickLink = async (notification: INotification) => {
        if (notification.recipient.status === 'unread') {
            const markSuccessful = await api.markNotificationsAsRead(capabilities.franchiseId, notification.id);
            if(markSuccessful){
                const newCount = countRef.current-1;
                setBadgeNumber(newCount);
                setCountRef(newCount);
            }
        }
        props.history.push(notification.link);
    }

    return (
        <>
            <NotificationDialog
                timeZone={capabilities.timeZone}
                unreadCount={count}
                key={count}
                fetchMyNotifications={fetchMyNotifications}
                markAllNotificationsAsRead={markNotificationsAsRead}
                clickLink={clickLink}
            ></NotificationDialog>
        </>
    );
});
