import { IChecklist, IPhoto, ITag, SelectOptions, IPerson} from 'oneplace-components';

export interface ITicketTypes {
    ticketTypes: ITicketCategoryType[];
}

export enum TicketStatus {
    Pending = 100,
    Open = 200,
    Resolved = 300,
    Closed = 400
}

export interface ITicketCategoryType {
    id: number;
    label: string;
    categories: ITicketCategoryMeta[];
}

export interface ITicketCategoryMeta {
    id: number;
    name: string;
}

export interface ITicketCategories {
    categories: ITicketCategory[];
}

export interface ITicketCategory {
    id: number;
    label?: string;
    name?: string;
    description?: string;
    onlyOneOpener?: boolean;
    onlyOneResolver?: boolean;  // false = all resolvers needed
    onlyOneCloser?: boolean;  // false = all closers needed
    dueDateReadOnly?: boolean;
    templateChecklistId? : number
    checklist?: IChecklist | null;
    ticketEntitySetting?: string;
    prepopulatingContent?: boolean;
    prepopulatingSubject?: boolean;
    ticketPopOnly?: boolean;
}

export interface ITicketUpdate {
    id: number | null;
    dueDate: string;
    priorityLabel: string;
    status?: number;
    subject: string | null;
    content: string | null;
    emailTo: string;
    sendEmail?: boolean;
    cancelTicket?: boolean;
    tags: ITag[];
    site?: {
        id: number;
        name: string;
    };
    category?: {
        id: number;
    };
    statusComment?: string;
    gateKeeper?: {
        resolvers?: ITicketResolver[];
        categoryResolvers?: ITicketResolver[];
    };
    checklist?: IChecklist | null;
    people?: IPerson[];
    hardAssociation?: boolean;
    incidentNo?: number | null;
    hazardNo?: number | null;
}

export interface ITicketStatus {
    label: string;
    value: number | string;
}

export interface ITicket {
    id: number | null;
    ticketNo: number;
    date: string;
    dueDate: string;
    status: number;
    priority: number;
    statusLabel: string;
    statusProgress: string;
    statusComment?: string;
    priorityLabel: string;
    subject: string;
    content: string;
    trackingField?: boolean;
    attachments: IPhoto[];
    forFranchisee?: {
        id: number;
        name: string;
    };
    site?: {
        id: number;
        name: string;
    };
    sendEmail?: boolean;
    emailSentDate?: null | string;
    emailTo: string;
    cancelTicket?: boolean;
    category?: ITicketCategory;
    availableStatuses?: ITicketStatus[];
    statusTracker?: ITicketStatus[];
    conversation?: ITicketComment[];
    actionRequired?: {
        label: string;
        value: string;
    };
    gateKeeper?: {
        resolvers?: ITicketResolver[];
        categoryResolvers?: ITicketResolver[];
    };
    tags?: ITag[];
    franchiseeId: number;
    checklist: IChecklist | null;
    incidentNo?: number | null;
    hazardNo?: number | null;
    entityName?: string;
    requiredAction?: string;
    people?: IPerson[];
    hardAssociation?: boolean;
}

export interface ITicketResolver {
    id: number;
    name: string;
}

export interface ITicketComment {
    name: string;
    date: string;
    comment: string;
}

export interface INewTicketComment {
    conversation: {
        comment: string;
        emailCreator: boolean;
        emailOpener: boolean;
        emailResolver: boolean;
        emailCloser: boolean;
    };
}

export interface ITicketSearchParams {
    siteId?: number;
    status?: number;
    ticketCategoryId?: number;
    priority?: number;
    selectedPeople?: number[];
}

// We use 0 to indicate no id because null is not an indexed value in indexedDB
export interface IStoredTicket {
    localId?: number;
    ticketId: number;  // 0 = not submitted, >0 = submitted ticketId
    franchiseeId: number;
    siteId: number;
    ticket: ITicket;
}

export interface IStoredTicketMeta {
    localId: number;
    ticketId: number;
    franchiseeId: number;
    siteId: number;
    ticketNo: number;
    subject: string;
    categoryId: number;
}

export function convertResolversToSelectOptions(resolvers: ITicketResolver[]): SelectOptions {
    return resolvers.map((resolver) => ({
        label: resolver.name,
        value: String(resolver.id)
    }));
}

export function convertSelectedResolverIdsToResolvers(
        resolverOptions: ITicketResolver[], ids: string[]): ITicketResolver[] {
    return resolverOptions.filter((resolver) =>
        ids.includes(String(resolver.id)));
}

export function returnTicketStatusLabel(statusCode: number, t: any): string{
    const statusIndex = ticketStatusCodes.findIndex((status) => status == statusCode);
    if (statusIndex > -1) {
        if (t) {
            return t(ticketStatusLabels[statusIndex]) as string;
        }else{
            return ticketStatusLabels[statusIndex];
        }
    }
    return 'Unknown';
}

export function returnTicketPriorityLabel(priorityCode: number, t: any): string{
    const priorityIndex = ticketPriorityCodes.findIndex((priority) => priority == priorityCode);
    if (priorityIndex > -1) {
        if (t) {
            return t(ticketPriorityLabels[priorityIndex]) as string;
        }else{
            return ticketPriorityLabels[priorityIndex];
        }

    }
    return 'Unknown';
}

export const ticketStatusLabels = [
    'customLabel_ticketStatusPending', 'customLabel_ticketStatusOpen', 'customLabel_ticketStatusResolved', 'customLabel_ticketStatusClosed'
];
export const ticketStatusCodes = [100, 200, 300, 400];

export const ticketPriorityLabels = ['priority_high', 'priority_medium', 'priority_low'];
export const ticketPriorityCodes = [100, 200, 300];
