import * as React from 'react';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Badge from '@material-ui/core/Badge';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Avatar from '@material-ui/core/Avatar';
import NotDoneIcon from '@material-ui/icons/Clear';
import DraftIcon from '@material-ui/icons/Edit';
import CompletedIcon from '@material-ui/icons/Done';
import Typography from '@material-ui/core/Typography';
import { Theme, createStyles, WithStyles, withStyles } from '@material-ui/core/styles';
import { IDashboardChecklist } from '../../models/Dashboard';
import { formatDate } from '../../utils/dates';
import { User } from '../../models/User';
import Button from '@material-ui/core/Button';
import { i18n } from '../../i18n';
import { PageLoader } from 'oneplace-components';

const styles = (_theme: Theme) => createStyles({
    badgeRoot: {
        marginTop: 14
    },
    typographyRoot: {
        marginLeft: 30
    },
    listItemTextRoot: {
        padding: '0 16px'
    }
});

export interface IExpandingChecklistListProps extends WithStyles<typeof styles> {
    title: string;
    totalCount: number;
    checklists: IDashboardChecklist[];
    currentUser: User;
    openChecklist: (checklist: IDashboardChecklist) => void;
    onExpand?: () => void;
    onShowMore: () => void;
    defaultExpanded?: boolean;
    loading?: boolean;
    loadingMore?: boolean;
}

export const ExpandingChecklistList = withStyles(styles)((props: IExpandingChecklistListProps) => {
    const t = i18n.t;

    const badgeContent = props.totalCount == -1 ? '' : props.totalCount;
    const moreAvailable = props.totalCount == -1 || props.checklists.length < props.totalCount;

    return (
        <Accordion
            defaultExpanded={props.defaultExpanded}
            onChange={(event, expanded) => {
                if (expanded && props.onExpand) {
                    props.onExpand();
                }}}
        >
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <Badge color="secondary" badgeContent={badgeContent}
                        overlap="rectangular"
                        classes={{ root: props.classes.badgeRoot }}>
                    </Badge>

                <Typography variant="subtitle1" classes={{ root: props.classes.typographyRoot }}>
                    {props.title}
                </Typography>
            </AccordionSummary>
            <AccordionDetails style={{ padding: '0 8px 24px' }}>
                {props.loading == true &&
                    <PageLoader loading={true} paddingTop={0} />}
                {props.loading != true && props.checklists.length == 0 &&
                    <Typography style={{ width: '100%', textAlign: 'center' }}>{t('no_checklists')}</Typography>
                }
                {props.loading != true && props.checklists.length > 0 &&
                    <List style={{ width: '100%'}} disablePadding={true}>
                        {props.checklists.map((checklist) => {
                            let status = checklist.status;
                            if (checklist.localId) {
                                status = 'local_draft';
                            }
                            return (
                                <ListItem key={checklist.id}>
                                    <Avatar>
                                        {status == 'not_done' &&
                                            <NotDoneIcon />}
                                        {(status == 'inprogress' || status == 'local_draft') &&
                                            <DraftIcon />}
                                        {status == 'completed' &&
                                            <CompletedIcon />}
                                    </Avatar>
                                    <ListItemText
                                         classes={{ root: props.classes.listItemTextRoot }}
                                        primary={checklist.checklistFor}
                                        secondary={<span>
                                            {t('customLabel_checklist')}: {checklist.checklist} <br />
                                            Due: {formatDate('display_date', checklist.dueByDate, props.currentUser.capabilities.dateFormat)} &nbsp;
                                        </span>}
                                    />
                                    <div>
                                        <Button variant="contained" color="default"
                                            onClick={() => props.openChecklist(checklist)}>
                                            {status == 'not_done' ? t('start')
                                                : status == 'inprogress' ? t('edit')
                                                : status == 'local_draft' ? t('edit_draft')
                                                : status == 'completed' ? t('edit')
                                                : ''}
                                        </Button>
                                    </div>
                                </ListItem>
                            );
                        })}
                        {moreAvailable && <div style={{ textAlign: 'center' }}>
                            {!props.loadingMore &&
                                <Button
                                    variant="contained"
                                    onClick={props.onShowMore}
                                >{t('show_more')}</Button>}
                             {props.loadingMore &&
                                <Button
                                    variant="contained"
                                    disabled={true}
                                >{t('loading')}</Button>}
                        </div>}
                    </List>
                }
            </AccordionDetails>
        </Accordion>
    );
});
