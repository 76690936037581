
import { withTimeout } from '../utils';
import { IPerson, IPersonType } from 'oneplace-components';
// increase timeout to 5 Min
export const DEFAULT_API_TIMEOUT = 300000;

export interface IDataWithCache<T> {
    getData(): Promise<T>;
    cacheData(): Promise<void>;
    onStateUpdate?: (state: IDataWithCacheState) => void;
    onError?: (error: Error, level: 'error' | 'warning') => void;
}

export interface ICacheBackend {
    getById<T>(entityName: string, entityId: string): Promise<T>;
    setById(entityName: string, entityId: string, data: any): Promise<void>;
    clearEntityCache(entityName: string): Promise<void>;
    deleteById(entityName: string, entityId: string | number): Promise<void>;
    getAll(entityName: string): Promise<any[]>;
    setPersonTypes(entityId: string, data: any): Promise<void>;
    deletePersonTypes(entityId: string | number): Promise<void>;
}

export interface ICacheParams {
    strategy: 'db_first' | 'api_first';
    cache: ICacheBackend;
    entityName: string;
    entityId: string;
    updateCacheOlderThan?: number;
    apiTimeout?: number;
    getApiDataFn: () => Promise<any>;
    filterCacheResult?: (...args: any[]) => any;
    disableCacheUpdateFromApi?: boolean
}

export interface IDataWithCacheState {
    loadStatus: null | 'loading' | 'loaded' | 'load_error';
    refreshStatus: null | 'refreshing' | 'refreshed' | 'refresh_error';
}

export interface ICachedDataAttribs {
    __cache_timestamp: number;
}

// TODO: Tests!!

export class DataWithCache<T> implements IDataWithCache<T> {
    state: IDataWithCacheState;

    constructor(
        public params: ICacheParams,
        public onStateUpdate?: (state: IDataWithCacheState) => void,
        public onError?: (error: Error, level: 'error' | 'warning') => void
    ) {
        this.state = {
            loadStatus: null,
            refreshStatus: null
        };
    }

    updateState(newState: IDataWithCacheState): void {
        this.state = Object.assign({}, this.state, newState);
        if (this.onStateUpdate) {
            this.onStateUpdate(this.state);
        }
    }

    getData(): Promise<T> {
        if (this.state.loadStatus || this.state.refreshStatus) {
            throw new Error('There is already a data operation in progress');
        }
        if (!this.params.entityName || !this.params.entityId) {
            throw new Error('EntityName or EntityId not specified');
        }
        switch (this.params.strategy) {
            case 'api_first':
                return this.apiFirst();
            case 'db_first':
                return this.dbFirst();
            default:
                throw new Error('Unsupported strategy: ' + this.params.strategy);
        }
    }

    private async apiFirst(): Promise<T> {
        const p = this.params;
        this.updateState({
            loadStatus: 'loading',
            refreshStatus: null
        });
        let apiResult: any;
        try {
            apiResult = await this.getApiResult();
        }
        catch (e) {
            this.logError(e, 'warning');
        }
        if (apiResult) {
            // We got a result from the API. Trigger a cache update and return
            this.updateState({
                loadStatus: 'loaded',
                refreshStatus: null
            });
            if(!p.disableCacheUpdateFromApi){
                void this.updateCache(apiResult);
            } 
            return apiResult;
        }
        else {
            // We did not get a result from the API. Check the cache instead
            let cacheResult: any;
            try {
                cacheResult = await p.cache.getById(p.entityName, p.entityId);
            }
            catch (e) {
                // Error accessing the cache. We can only throw now
                this.logError(e, 'error');
                this.updateState({
                    loadStatus: 'load_error',
                    refreshStatus: null
                });
                throw e;
            }
            if (cacheResult) {
                this.updateState({
                    loadStatus: 'loaded',
                    refreshStatus: null
                });
                return p.filterCacheResult ? 
                    p.filterCacheResult(cacheResult) : cacheResult
            }
            else {
                this.updateState({
                    loadStatus: 'load_error',
                    refreshStatus: null
                });
                throw new Error(`No cached result for "${p.entityName}", id: "${p.entityId}"`);
            }
        }
    }

    private async dbFirst(): Promise<T> {
        const p = this.params;
        this.updateState({
            loadStatus: 'loading',
            refreshStatus: null
        });
        let dbResult: any = null;
        try {
            dbResult = await p.cache.getById<any>(p.entityName, p.entityId);
        }
        catch (e) {
            this.logError(e, 'warning');
        }
        if (dbResult) {
            // We got a result from the DB. Check if we should update the cache (e.g. db result too old)
            const timestamp = Number(dbResult.__cache_timestamp);
            const now = Date.now();
            if (!p.updateCacheOlderThan || !timestamp || (now - timestamp) > p.updateCacheOlderThan) {
                // We should refresh the cache out-of-band
                this.getApiResult()
                    .then((res) => {
                        return this.updateCache(res);
                    })
                    .then(() => {
                        this.updateState({
                            loadStatus: 'loaded',
                            refreshStatus: 'refreshed'
                        });
                    })
                    .catch((e) => {
                        this.logError(e, 'warning');
                        this.updateState({
                            loadStatus: 'loaded',
                            refreshStatus: 'refresh_error'
                        });
                    });
                this.updateState({
                    loadStatus: 'loaded',
                    refreshStatus: 'refreshing'
                });
            }
            else {
                // Cache is up-to-date
                this.updateState({
                    loadStatus: 'loaded',
                    refreshStatus: null
                });
            }

            return p.filterCacheResult ? 
                    p.filterCacheResult(dbResult) : dbResult
        }
        else {
            // No result from the DB. We'll need to go straight to the API
            this.updateState({
                loadStatus: 'loading',
                refreshStatus: 'refreshing'
            });
            let apiResult: any = null;
            try {
                apiResult = await this.getApiResult();
            }
            catch (e) {
                // Couldn't retrieve from the API either. Abort!
                this.logError(e, 'error');
                this.updateState({
                    loadStatus: 'load_error',
                    refreshStatus: 'refresh_error'
                });
                throw e;
            }
            // Update the cache out-of-band
            this.updateCache(apiResult)
                .catch((e) => {
                    this.logError(e, 'warning');
                });
            this.updateState({
                loadStatus: 'loaded',
                refreshStatus: 'refreshed'
            });
            return apiResult;
        }
    }

    // Retrieve data and add to cache. Do not return it.
    // Throws if data cannot be retrieved
    async cacheData(): Promise<void> {
        const p = this.params;
        this.updateState({
            loadStatus: 'loading',
            refreshStatus: null
        });
        let apiResult: any;
        try {
            apiResult = await this.getApiResult();
        }
        catch (e) {
            this.logError(e, 'warning');
        }
        if (apiResult) {
            // We got a result from the API. Trigger a cache update and return
            this.updateState({
                loadStatus: 'loaded',
                refreshStatus: null
            });
            await this.updateCache(apiResult);
        }
        else {
            this.updateState({
                loadStatus: 'load_error',
                refreshStatus: null
            });
            throw new Error(`Unable to get result for "${p.entityName}", id: "${p.entityId}"`);
        }
    }

    async cacheBatchedData(): Promise<number> {
        const p = this.params;
        this.updateState({
            loadStatus: 'loading',
            refreshStatus: null
        });
        let apiResult: any;
        try {
            apiResult = await this.getApiResult();
        }
        catch (e) {
            this.logError(e, 'warning');
        }
        if (apiResult) {
            // We got a result from the API. Trigger a cache update and return
            this.updateState({
                loadStatus: 'loaded',
                refreshStatus: null
            });
            await this.updateCache(apiResult);
            return apiResult.length;
        }
        else {
            this.updateState({
                loadStatus: 'load_error',
                refreshStatus: null
            });
            throw new Error(`Unable to get result for "${p.entityName}", id: "${p.entityId}"`);            
        }
    }

    private getApiResult() {
        return withTimeout(
            this.params.apiTimeout || DEFAULT_API_TIMEOUT,
            this.params.getApiDataFn()
        );
    }

    private async updateCache(data: any) {
        if (!data || typeof data != 'object') {
            throw new Error(`Unable to cache non-object: '${data}'`);
        }
        const p = this.params;
        if(data instanceof Array){
            if(p.entityName == 'people'){
                data.forEach((person: IPerson) => {
                    if (person.entityAction === 'UPDATE') {
                        void p.cache.setById('people', `${person.id}`, person);
                    }else if(person.entityAction === 'DELETE'){
                        // remove inactive people
                        void p.cache.deleteById('people', person.id);
                    }
                });
            }
            else if(p.entityName == 'person_types'){
                data.forEach((personType: IPersonType) => {
                    if (personType.entityAction === 'UPDATE') {
                        void p.cache.setPersonTypes(String(personType.id), personType);
                    }else if(personType.entityAction === 'DELETE'){
                        // remove inactive person types
                        void p.cache.deletePersonTypes(personType.id);
                    }
                });
            }
            else{
                // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
                throw new Error(`Unable to cache non-object: '${data}'`);
            }
        }else{
            data.__cache_timestamp = Date.now();
            // do we need to await?
            await p.cache.setById(p.entityName, p.entityId, data);
        }
    }

    private logError(error: Error, level: 'error' | 'warning') {
        if (this.onError) {
            this.onError(error, level);
        }
        if (process && process.env && process.env.DEBUG) {
            if (level == 'error') {
                console.error(error)
            } else{
                console.warn(error);
            }
        }
    }
}
