import * as React from 'react';
import { logError } from '../logging';
import { PageLoader } from 'oneplace-components';
import { i18n } from '../i18n';

export interface IErrorBoundaryState {
    hasError: boolean;
}

export class ErrorBoundary extends React.Component<any, IErrorBoundaryState> {

    constructor(props: any) {
        super(props);
        this.state = { hasError: false };
    }

    componentDidCatch(error: Error, _info: any) {
        this.setState({ hasError: true });
        logError(error, 'Unexpected error while rendering');
    }

    render() {
        if (this.state.hasError) {
            let message = 'There was a problem displaying this page. Please try again and'
                + ' if the problem persists, contact support.';
            try {
                const t = i18n.t;
                message = t('rendering_error');
            }
            catch (e) {
                // just use default message
            }

            return <PageLoader loading={false} status={message} />;
        }
        return this.props.children;
    }
}
