import { IIDAndName, IIDAndLabel } from './entity';
import { IHSCustomField } from '../components/health_safety/hsFields';
import { IPhoto,IEmailFields, IPerson, ICustomField } from 'oneplace-components';

export type IncidentExcludableField = 'hazardsList'

export interface IIncidentEmailResponse {
    message: IEmailFields;
}

export enum IncidentStatus {
    Pending = 100,
    Open = 200,
    Resolved = 300,
}

export interface IIncident {
    id: number | null;
    type: IIDAndName;
    ticketsNo?: string;
    incidentNo: null | number;
    status: IncidentStatus;
    site: null | IIDAndName;
    name: string;
    franchise?: IIDAndName;
    customFields?: IHSCustomField[];
    conversations?: IIncidentComment[];
    [fieldName: string]: any;
    people: IPerson[] | null;
    hardAssociation: boolean;
    personFields?: IIncidentPersonFields | null;
    incidentCategory?: IIDAndName;
    path?: string;
    hazard?: IIDAndName
}

export interface IIncidentEmailOptions {
    sendPDF: boolean;
    sendURLSign: boolean;
    invalidateURL?: boolean;
}

// We use 0 to indicate no id because null is not an indexed value in indexedDB
export interface IStoredIncident {
    localId?: number;
    incidentId: number;
    siteId: number;
    incident: IIncident;
    date: string;
    attachments: IPhoto[];
}

export interface IStoredIncidentMeta {
    // Header information returned by e.g. getDraftsList()
    localId: number;
    incidentId: number; // 0 = not submitted, >0 = submitted incidentId
    siteId: number;
    incidentNo: number;
    incidentName: string;
    date: string;
}

export interface IIncidentComment {
    name: string;
    date: string;
    comment: string;
}

export interface IHelpInfo {
    helpType: string;
    label: string;  // use to map to field
    description: string;
    helpLinks: [
        {
            link: string;
            type: string;
            id: number;
        }
    ];
    id: number;
}

export interface IIncidentTypeCustomField {
    incidentDetailTab: boolean;
    showField: boolean;
    sortOrder: null | number;
    id: number;
    customField: IIDAndName;
    incidentType: IIDAndName;
}

export interface IIncidentType {
    id: number;
    name: string;
    incidentTypeCustomFields: IIncidentTypeCustomField[];
    incidentCustomFieldsByType: IHSCustomField[];
    [fieldName: string]: any;
    personFieldsConfiguration: IIncidentTypePersonFieldsConfiguration;
    peopleSelectionRequired?: boolean;
    allowPhotos: boolean;
    allowAttachments: boolean;
}

export interface IIncidentStatus {
    label: string;
    value: number;
}

export interface IIncidentResponse {
    attachments: IPhoto[];
    incident: IIncident;
    showInvestigationTab: boolean;
    hazardsList: IIDAndName[] | null;
    helpInfos: IHelpInfo[];
    healthSafetyClassList: IIDAndLabel[];
    severityOptionsList: string[];
    injuryTypeOptionsList: string[];
    badCouldBeOptionsList: string[];
    chanceHappenAgainOptionsList: string[];
    incidentTypesList: IIncidentType[];
    photoCanvasTemplates: IHSCustomField[];
    incidentChangeTracker: any;  // comes back with existing incident data
    availableStatuses: IIncidentStatus[];
    detailsCategories?: IIncidentCategory[]; // categories displayed on details tab (available for reporter)
    categories: IIncidentCategory[];         // categories displayed on investigation tab
    emailFieldMapping?: IIncidentEmailFieldMapping;
}

export interface IIncidentsListEntryWrapper{
    incident: IIncidentsListEntry;
}

export interface IIncidentsListEntry{
    name: string;
    type: string;
    desc: string;
    site: IIDAndName;
    franchisee: IIDAndName
    id: number;
    incidentNo: string;
    status: number;
    incidentCategory?: IIDAndName;
    date: string;
}

export interface IIncidentCategory {
    description: string;
    id: number;
    label: string;
    openers: IIDAndName[];
    resolvers: IIDAndName[];
    automaticResolve?: boolean; // automatic resolve incident (show all recStatus btns)
    showInDetails?: boolean;    // display in details tab?
    showInvTab?: boolean;       // show investigation tab for all users(this overwrites normal user's profile permission)
}

export interface IIncidentCategories {
    categories: IIncidentCategory[];
}

export interface IIncidentTypes {
    incidentTypes: IIDAndName[];
}

export interface IIncidentSearchParams {
    name: string;
    siteId?: number;
    incidentTypeId? : number;
    fromDate? : Date | string;
    toDate? : Date | string;
    selectedPeople?: string[];
    franchiseeId?: number;
    status?: string | number;
    signed?: boolean
}

export interface IIncidentTypePersonFieldsConfiguration {
    id: number;
    firstName: boolean;
    midName: boolean;
    lastName: boolean;
    email: boolean;
    homePhoneNumber: boolean;
    workPhoneNumber: boolean;
    mobileNumber: boolean;
    externalId: boolean;
    customFieldsJson: IHSCustomField[];
    anyFieldSelected: boolean;
}

export interface IIncidentPersonFields{
    id: number;
    personId: number;
    firstName: string;
    midName: string;
    lastName: string;
    email: string;
    workPhoneNumber: string;
    homePhoneNumber: string;
    mobileNumber: string;
    externalId: string;
    customFields: ICustomField[]; // from oneplacecomponents
}

export interface IIncidentEmailFieldMapping{
    id: number;
    email: boolean;
    customFieldIds: number[];
}

export const incidentStatusLabels = [
    'customLabel_incidentStatusPending', 'customLabel_incidentStatusOpen', 'customLabel_incidentStatusResolved'
];
export const incidentStatusCodes = [100, 200, 300];

export function findIncidentStatus(status: number): string {
    const activeStepIdx = incidentStatusCodes.indexOf(status);
    return incidentStatusLabels[activeStepIdx];
}

export interface IIncidentTicketsCount {
    incidentTicketCount: number;
}
