
import { IImageStorage } from './';
import { STORED_IMAGE_PREFIX, ImageStorageType, TEMP_IMAGE_PREFIX, getImageStorageType } from 'oneplace-components'
import Dexie from 'dexie';
import { exportDB, ExportOptions, importInto } from 'dexie-export-import'

interface IStoredImage {
    imageId?: number;
    timestamp: number;
    data: Blob;
}

class DexieImageDB extends Dexie {
    images!: Dexie.Table<IStoredImage, number>;

    constructor() {
        super('OnePlace_images');
        this.version(1).stores({
            images: '++imageId'
        });
    }
}

// Future Enhancement: Implement a shared superclass for Image Storage, since the logic is pretty much the same
export class DexieImageStorage implements IImageStorage {
    db: DexieImageDB;
    objectUrls: {
        [objectUrl: string]: Blob;
    } = {};
    storedImageObjectUrls: {
        [imageId: number]: string;
    } = {};

    constructor() {
        this.db = new DexieImageDB();
    }

    getDB() {
        if (!this.db) {
            this.db = new DexieImageDB();
        }

        const idb = this.db.backendDB();

        if (idb) {
            try {
                // Check if if connection to idb did not close in the meantime
                idb.transaction('images').abort();
            } catch (e) {
                this.db.close();
                this.db = new DexieImageDB();
            }
        }
        return this.db;
    }

    async storeImage(imageData: Blob, storageType: ImageStorageType): Promise<string> {
        console.log('storing image: ' + storageType);
        if (storageType == 'temporary') {
            // just create objectURL and keep a reference to the blob in memory
            const objectURL = window.URL.createObjectURL(imageData);
            this.objectUrls[objectURL] = imageData;
            return TEMP_IMAGE_PREFIX + objectURL;
        }
        else {
            // Store image on filesystem and keep a reference in indexedDB
            const storedImage: IStoredImage = {
                timestamp: Date.now(),
                data: imageData
            };
            const imageId = await this.getDB().images.add(storedImage);
            return STORED_IMAGE_PREFIX + imageId;
        }
    }

    async getImageObjectUrl(localImageUrl: string, reloadImageUrl?: boolean): Promise<string> {
        const [storageType, imageId] = getImageStorageType(localImageUrl);
        if (storageType == 'temporary') {
            return imageId;
        }
        else {
            const reloadImageUrlFromDB = typeof reloadImageUrl == 'undefined'? false: reloadImageUrl;
            if (reloadImageUrlFromDB == false && imageId in this.storedImageObjectUrls) {
                return this.storedImageObjectUrls[Number(imageId)];
            }
            else {
                const imageData = await this.getImageData(localImageUrl);
                const objectURL = window.URL.createObjectURL(imageData);
                this.objectUrls[objectURL] = imageData;
                this.storedImageObjectUrls[Number(imageId)] = objectURL;
                return objectURL;
            }
        }
    }

    async getImageData(localImageUrl: string): Promise<Blob> {
        const [storageType, imageId] = getImageStorageType(localImageUrl);
        if (storageType == 'temporary') {
            return this.objectUrls[imageId];
        }
        else {
            const image = await this.getDB().images.get(Number(imageId));
            return image!.data;
        }
    }

    async makePermenant(tempImageUrl: string): Promise<string> {
        const [storageType, imageId] = getImageStorageType(tempImageUrl);
        if (storageType != 'temporary') {
            throw new Error('makePermenant() called on a non-temporary image');
        }
        const imageData = this.objectUrls[imageId];
        const storedUrl = await this.storeImage(imageData, 'permanent');
        const storedImageId = getImageStorageType(storedUrl)[1];
        this.storedImageObjectUrls[Number(storedImageId)] = imageId;
        return storedUrl;
    }

    async removeImage(localImageUrl: string): Promise<void> {
        console.log('removing image', localImageUrl);
        const [storageType, imageId] = getImageStorageType(localImageUrl);
        if (storageType == 'temporary') {
            window.URL.revokeObjectURL(imageId);
            delete this.objectUrls[imageId];
        }
        else {
            // Revoke ObjectURL
            const objectUrl = this.storedImageObjectUrls[Number(imageId)];
            if (objectUrl) {
                window.URL.revokeObjectURL(objectUrl);
            }
            console.log('removing image id', imageId);
            // Delete database record
            await this.getDB().images.delete(Number(imageId));
        }
    }

    revokeObjectUrls(): void {
        const objectUrls = Object.keys(this.objectUrls);
        console.log(`revoking ${objectUrls.length} object URLs`);
        for (const objectUrl of objectUrls) {
            window.URL.revokeObjectURL(objectUrl);
        }
        this.storedImageObjectUrls = {};
        this.objectUrls = {};
    }

    async export(option: ExportOptions ): Promise<Blob> {
        // @ts-ignore https://github.com/dfahlander/Dexie.js/issues/1262
        return exportDB(this.getDB(), option);
    }

    async import(exportedData: Blob): Promise<void> {
        const data = await (new Response(exportedData)).text();
        const json = JSON.parse(data);
        if (json.data?.databaseName =='OnePlace_images' && json.data?.databaseVersion == this.getDB().verno) {
            // @ts-ignore https://github.com/dfahlander/Dexie.js/issues/1262
            return importInto(this.getDB(), exportedData, {overwriteValues: true,  noTransaction:true});
        } else {
            throw new Error('invalid database file');
        }
    }

    removeFiles(_assetList: string[]) {
        // files are stored in indexedDB so nothting to do
        return
    }

}
