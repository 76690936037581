
import { IIDTokens } from '../../../models/IDTokens';
import { User } from '../../../models/User';
import Dexie from 'dexie';
import IRegistration from '../../../components/firebase/registration';

export interface ITokenDB {
    initialise(): Promise<void>;
    retrieveTokens(): Promise<IIDTokens | null>;
    storeTokens(tokens: IIDTokens): Promise<void>;
    clearTokens(): Promise<void>;
    retrieveUser(userId: string): Promise<User | null>;
    storeUser(user: User): Promise<void>;
    retrieveRegistration(): Promise<IRegistration>;
    storeRegistration(registration: IRegistration): Promise<void>;
}

export class TokenDB implements ITokenDB {
    db!: Dexie;

    initialise() {
        this.db = new Dexie('OnePlace_tokens');
        this.db.version(2).stores({
                tokens: '',
                users: '',
        });
        return new Promise<void>((resolve, _reject) => {
            resolve()
        })
    }

    getDB() {
        if (!this.db) {
            this.initialise();
        }

        const idb = this.db.backendDB();

        if (idb) {
            try {
                // Check if if connection to idb did not close in the meantime
                idb.transaction('tokens').abort();
            } catch (e) {
                this.db.close();
                this.initialise();
            }
        }
        return  this.db;
    }

    async retrieveTokens() {
        const tokens: IIDTokens = await this.getDB().table('tokens').get('user1');
        return tokens;
    }

    async storeTokens(tokens: IIDTokens) {
        await this.getDB().table('tokens').put(tokens, 'user1');
    }

    async clearTokens() {
        await this.getDB().table('tokens').delete('user1')
    }

    async retrieveUser(userId: string) {
        const user: User = await this.getDB().table('users').get(userId);
        return user;
    }

    async storeUser(user: User) {
        await this.getDB().table('users').put(user, user.id);
    }

    async retrieveRegistration(): Promise<IRegistration> {
        return this.getDB().table('tokens').get('registration');
    }
    async storeRegistration(registration: IRegistration) {
        await this.getDB().table('tokens').put(registration, 'registration');
    }
}
