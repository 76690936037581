import * as React from 'react';
import { TopNav } from './TopNav';
import { LeftNav } from './LeftNav';
import { withStyles, Theme, createStyles, WithStyles } from '@material-ui/core/styles';
import { RouteComponentProps, withRouter } from 'react-router';
import { getMainDashboardUrl } from '../dashboard/utils/getMainDashboardUrl';
import { IAppContextProp, withAppContext } from '../AppContext';
import { ENV } from '../../environment';
import { IPromptsProp, withPrompts } from '../common/PromptProvider';
import { i18n } from '../../i18n';
import * as serviceWorker from '../../network/serviceWorker';
import { Snackbar } from '@material-ui/core';
import { LogoutDialog } from './LogoutDialog';

const styles = (theme: Theme) => createStyles({
    appWrapper: {
        marginTop: 64,
        marginLeft: 'auto',
        marginRight: 'auto',
        maxWidth: 800,
        [theme.breakpoints.down('xs')]: {
            marginTop: 52,
        },
    },
    root: {
        background: theme.palette.secondary.main
    }
});

export interface INavigationUpdate {
    title: string;
    showBackButton?: boolean;
    mainActionLabel?: string;
    backButtonConfirmationMsg?: string;
    onMainAction?: () => void;
}

export interface INavigationState extends INavigationUpdate {
    leftNavOpen: boolean;
    showUpdateBar: boolean;
}

export interface INavigationProps extends
    IAppContextProp,
    WithStyles<typeof styles>,
    RouteComponentProps<any>,
    IPromptsProp
{}

export interface INavigationContext {
    updateNavigation: (update: INavigationUpdate) => void;
    goBack: () => void;
    goTo: (url: string) => void;
}

export interface INavigationContextProp {
    nav: INavigationContext;
}

export const NavigationContext = React.createContext<INavigationContext>(null as any);

export const Navigation = withStyles(styles)(withAppContext(withPrompts(withRouter(
    class extends React.Component<INavigationProps, INavigationState> {

    message: string;
    constructor(props: any) {
        super(props);
        this.state = {
            showUpdateBar: false,
            leftNavOpen: false,
            title: ''
        };
        this.onMainButtonPress = this.onMainButtonPress.bind(this);
        this.goBack = this.goBack.bind(this);
        this.updateNavigation = this.updateNavigation.bind(this);

        const currentUrl = this.props.history.location.pathname;
        console.log('Nav Constructed. Current route: ' + currentUrl);
        if (currentUrl == '/') {
            const dashboardUrl = getMainDashboardUrl(this.props.ctx.auth.user);
            console.log('Redirecting to: ' + dashboardUrl);
            this.props.history.replace(dashboardUrl);
        }
        const t = i18n.t;
        this.message = t('new_update_available');
    }

    componentDidMount() {
        if (ENV.platform == 'cordova') {
            document.addEventListener('backbutton', this.onDeviceBackButtonPress);
            if (ENV.platform == 'cordova' && ENV.os == 'browser') {
                const config = {
                    onSuccess: undefined,
                    onUpdate: this.showSnackbar,
                };
                serviceWorker.register(config);
            }
        }
    }

    componentWillUnmount() {
        if (ENV.platform == 'cordova') {
            document.removeEventListener('backbutton', this.onDeviceBackButtonPress);
        }
    }

    setLeftNavOpen(open: boolean) {
        this.setState({
            leftNavOpen: open
        });
    }

    onMainButtonPress() {
        if (this.state.showBackButton) {
            this.goBack();
        }
        else {
            this.setLeftNavOpen(true);
        }
    }

    onDeviceBackButtonPress = () => {
        const t = i18n.t;
        if (this.state.backButtonConfirmationMsg) {
            this.props.prompts.showPrompt({
                title: this.state.title,
                message: this.state.backButtonConfirmationMsg,
                closeButtonLabel: t('no'),
                actions: [{
                    label: t('yes_go_back'),
                    onClick: () => { this.goBack(); }
                }]
            });
        }
        else {
            this.goBack();
        }
    }

    goBack() {
        this.props.history.goBack();
    }

    goTo = (url: string) => {
        this.props.history.push(url);
    }

    updateNavigation(update: INavigationUpdate) {
        this.setState({
            title: update.title || '',
            showBackButton: update.showBackButton || undefined,
            mainActionLabel: update.mainActionLabel || undefined,
            onMainAction: update.onMainAction || undefined,
            backButtonConfirmationMsg: update.backButtonConfirmationMsg || undefined
        });
    }

    hideSnackbar = () => {
        this.setState({
            showUpdateBar: false,
        });
    }

    showSnackbar = (_registration: ServiceWorkerRegistration) => {
        this.setState({
            showUpdateBar: true,
        });
    }

    render() {
        //if free plan, we show alert at the top, in this case we have add more vertical space between header and body
        const isFreePlan = ['Trial', 'Freemium'].includes(this.props.ctx.auth.user.capabilities.planType)
        return (
            <NavigationContext.Provider value={{
                    goBack: this.goBack,
                    updateNavigation: this.updateNavigation,
                    goTo: this.goTo
                }}>
                <TopNav
                    title={this.state.title}
                    showBackButton={this.state.showBackButton}
                    onMainButtonClicked={this.onMainButtonPress}
                    mainActionLabel={this.state.mainActionLabel}
                    onMainActionClicked={this.state.onMainAction}

                />
                <LeftNav open={this.state.leftNavOpen} onClose={() => this.setLeftNavOpen(false)} />
                <Snackbar color="secondary"
                    ContentProps={{
                        classes: {
                            root: this.props.classes.root
                        }
                    }}
                    anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
                    open={this.state.showUpdateBar}
                    onClose={this.hideSnackbar}
                    message={this.message}
                />
                <LogoutDialog open={ this.props.ctx.appStatus =='logout'}
                    logout={this.props.ctx.forceLogout}
                    stayLoggedIn={this.props.ctx.stayLoggedIn}
                    />
                <div 
                    className={this.props.classes.appWrapper} 
                    style={isFreePlan? {paddingTop: 36} : {}}>
                    {this.props.children}
                </div>
            </NavigationContext.Provider>
        );
    }

}))));

export function withNavigationContext<TComponentProps extends INavigationContextProp>(Component: React.ComponentType<TComponentProps>){
    return function NavigationContextComponent(props: Pick<TComponentProps, Exclude<keyof TComponentProps, keyof INavigationContextProp>>) {
        return (
            <NavigationContext.Consumer>{(nav) => (
                <Component {...props as TComponentProps}  nav={nav} />
            )}</NavigationContext.Consumer>
        );
    };
}
