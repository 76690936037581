/* eslint-disable @typescript-eslint/require-await */
/* eslint-disable @typescript-eslint/no-misused-promises */
import { CONFIG } from '../config';
import { isUseSqliteDatabase } from '../environment';
import Keycloak, { KeycloakInitOptions, KeycloakLoginOptions } from 'keycloak-js';
import { TimeoutError } from '../errors/TimeoutError';
import { IIDTokenData, IIDTokens } from '../models/IDTokens';
import { NetworkError } from '../errors/NetworkError';

const KC_SERVER_TIMEOUT = 30000; // 30 seconds

// Time after which the login() promise will be time out
// (so it doesnt wait for ever)
const KC_LOGIN_WAIT_TIMEOUT = 600000;  // 10 minutes

export interface IKeycloakConfig {
    url: string;
    realm: string;
    clientId: string;
}

function getKeycloakConfig(): IKeycloakConfig {
    return {
        url: CONFIG.authServerUrl,
        realm: CONFIG.authRealm,
        clientId: CONFIG.authClientId
    };
}

function getAdapter(): 'cordova-native'| 'default' {
    if (isUseSqliteDatabase()) {
        return 'cordova-native';
    } else {
        // keycloak doesn't support cordova using the browser mode so use default adapter
       return  'default';
    }
}

function getRedirectUrl() : string {
    return CONFIG.authRedirectScheme + "://oauth_callback";
}

function getKeycloakOptions(): KeycloakInitOptions {
    const options: KeycloakInitOptions = {
        onLoad: 'check-sso',
        adapter: getAdapter(),
        responseMode: 'query',
    };
    if (isUseSqliteDatabase()) {
        console.log('redirect')
        options.redirectUri = getRedirectUrl()
        options.pkceMethod = 'S256'
        // 'android-app://org.keycloak.examples.cordova/https/keycloak-cordova-example.github.io/login'
    }
    return options

}

export class KeyCloakWrapper {
    _kc: Keycloak;
    _kcInited: boolean;
    onTokenExpired?: () => void;

    constructor() {
        this._kc = new Keycloak(getKeycloakConfig());
        this._kcInited = false;

        // KeyCloak events
        this._kc.onReady = (authenticated: boolean) => console.log('KeyCloak.onReady(). Authenticated:', authenticated);
        this._kc.onAuthSuccess = () => console.log('KeyCloak.onAuthSuccess()');
        this._kc.onAuthError = () => console.log('KeyCloak.onAuthError()');
        this._kc.onAuthRefreshSuccess = () => console.log('KeyCloak.onAuthRefreshSuccess()');
        this._kc.onAuthRefreshError = () => console.log('KeyCloak.onAuthRefreshError()');
        this._kc.onAuthLogout = () => console.log('KeyCloak.onAuthLogout()');
        this._kc.onTokenExpired = () => {
            console.log('KeyCloak.onTokenExpired()');
            if (this.onTokenExpired) {
                this.onTokenExpired();
            }
        };
    }

    /**
     * Error conditions for these methods include:
     * - No connection (throws in cordova)
     * - Token refresh fails
     * - Timeout
     */

    initViaSSO() {
        return new Promise<boolean>((resolve, reject) => {
            if (this._kcInited) {
                // Already initialized
                console.log('Keycloak is already initialized.');
                resolve(true);
                return;
            }
            this._kcInited = true;
            const option = getKeycloakOptions();

            console.log(JSON.stringify(option));

            this._kc.init(option)
                .then(async (authenticated) => {
                    if (!authenticated) {
                        resolve(false);
                    }
                    resolve(true);
                })
                .catch((error) => {
                    // Generally occurs due to connection error
                    reject(new Error('initViaSSO() error: ' + JSON.stringify(error)));
                });

            setTimeout(() => {
                reject(new TimeoutError('initViaSSO() timed out'));
            }, KC_SERVER_TIMEOUT);
        });
    }

    async logoutIdenitityProvider() {
        const response = await fetch(getKeycloakConfig().url  +  String("/realms/1Place/logout"), {
            method: 'DELETE',
            headers: {
                'Authorization': 'Bearer ' + this.getTokens().token,
            },

        });
        if (!response.ok) {
            const message = `An error has occured: ${response.status}`;
            console.log(message)
          }

        const result = await response.text();
        console.log(result);
    }

    initWithTokens(tokens: IIDTokens) {
        return new Promise<boolean>((resolve, reject) => {
            this._kcInited = true;
            const option: any = {
                token: tokens.token,
                idToken: tokens.idToken,
                refreshToken: tokens.refreshToken
            };
            option['adapter'] = getAdapter();
            if (isUseSqliteDatabase()) {
                option['redirectUri'] = getRedirectUrl()
                option['pkceMethod'] = 'S256'
            // 'android-app://org.keycloak.examples.cordova/https/keycloak-cordova-example.github.io/login'
            }
            this._kc.init(option)
                .then(async (authenticated) => {
                    if (!authenticated) {
                        resolve(false);
                    }
                    resolve(true);
                })
                .catch((error) => {
                    // Generally occurs due to connection error
                    reject(new Error('initWithTokens() error: ' + error));
                });

            setTimeout(() => {
                reject(new TimeoutError('initWithTokens() timed out'));
            }, KC_SERVER_TIMEOUT);
        });
    }

    refreshToken() {
        return new Promise<boolean>((resolve, reject) => {
            this._kc.updateToken(30)
                .then(() => resolve(true))
                .catch(() => resolve(false));
            setTimeout(() => {
                reject(new TimeoutError('refreshToken() timed out'));
            }, KC_SERVER_TIMEOUT);
        });
    }
    // 2018-11-27: refresh token if it's expiring in 30 sec
    isTokenExpiring() {
        return this._kc.isTokenExpired(30);
    }

    getTokens(): IIDTokens {  
        return {
            userId: (this._kc.idTokenParsed as any).sub,
            token: this._kc.token!,
            idToken: this._kc.idToken!,
            refreshToken: this._kc.refreshToken!,
            identityProvider: (this._kc.idTokenParsed as any).identity_provider!,
        };
    }

    getLoginOptions(): KeycloakLoginOptions {
        return {
            cordovaOptions : {
                footer: 'yes'
            }
        };
    }

    getIDTokenData() {
        return this._kc.idTokenParsed as IIDTokenData;
    }

    logout(): Promise<boolean> {
        return new Promise<boolean>((resolve) => {
            let redirectUri: string| undefined = undefined;
            if (!isUseSqliteDatabase()) {
                redirectUri = window.location.protocol + '//' + window.location.host + '/';
            }
            if (this._kcInited) {
                this._kc.logout(redirectUri? { redirectUri } : undefined)
                    .then(() => resolve(true))
                    .catch(() => resolve(false));
            } else {
                console.log('Cant logout. KeyCloak not inited.');
                resolve(true)
            }
        });
    }

    clearToken(): void {
        this._kc.clearToken();
    }

    login(): Promise<boolean> {
        return new Promise<boolean>((resolve, reject) => {

            const loginTimeout = setTimeout(() => {
                reject(new TimeoutError('login() timed out'));
            }, KC_LOGIN_WAIT_TIMEOUT);

            if (this._kcInited) {
                this._kc.login(this.getLoginOptions())
                    .then(() => {
                        clearTimeout(loginTimeout);
                        resolve(true);
                    })
                    .catch(() => {
                        clearTimeout(loginTimeout);
                        resolve(false);
                    });
            }
            else {
                console.log('Cant login. KeyCloak not inited.');
                clearTimeout(loginTimeout);
                reject(new NetworkError('Error sending request'));

            }
        });
    }

}
