import { ListItem, Avatar, ListItemText } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import IncidentIcon from '@material-ui/icons/LocalHospital';
import * as React from 'react';
import { CONFIG } from '../../config';
import { findIncidentStatus, IIncidentsListEntryWrapper, IIncidentsListEntry } from '../../models/Incident';
import { IAppProviderContext } from '../AppContext';
import { formatDate } from '../../utils/dates';
import { i18n } from '../../i18n';
import { PDFButton } from '../common/PDFButton';

interface IncidentListItemProps {
    incident: IIncidentsListEntryWrapper;
    ctx: IAppProviderContext;
    goToIncident: (incident: IIncidentsListEntry) => void;
}

export const IncidentListItem = ({
    incident,
    ctx,
    goToIncident
}: IncidentListItemProps): JSX.Element => {
    const styles = useStyles();

    const getPDFUrl = (incidentId: number) =>{
        const token = ctx.auth.tokens!.token; // asserting token here since user will always be authenticated when they are on this page
        const franchiseId = ctx.auth.user.capabilities.franchiseId;
        const franchiseeId = incident.incident.franchisee.id
        return `${CONFIG.apiUrl}/franchise/${franchiseId}/franchisee/${franchiseeId}/incident/${incidentId}/incident.pdf?access_token=${token}`;
    }

    return (
        <ListItem
            button
            onClick={() => goToIncident(incident.incident)}
        >
            <Avatar>
                <IncidentIcon />
            </Avatar>
            <ListItemText
                classes={{ root: styles.otherDetails }}
                primary={`${incident.incident.incidentNo}: ${incident.incident.name}`}
                secondary={
                    <IncidentListItemSecondary
                        incident={incident}
                        dateFormat={ctx.auth.user.capabilities.dateFormat}
                    />
                }
            />
            <PDFButton url={getPDFUrl(incident.incident.id)} />
        </ListItem>
    );
}

const IncidentListItemSecondary = ({
    incident,
    dateFormat,
}: { incident: IIncidentsListEntryWrapper, dateFormat: string; }) => {
    const t = i18n.t;
    return (
        <span>
            Type: {incident.incident.type} &nbsp; {t('customLabel_incidentCategory')}: {(incident.incident.incidentCategory && incident.incident.incidentCategory.name)}
            <br />
            Site: {(incident.incident.site && incident.incident.site.name)}
            <br />
            Date: {formatDate('display_date', incident.incident.date, dateFormat)}
            <br />
            Status: {t(findIncidentStatus(incident.incident.status))}
        </span>
    )
}

const useStyles = makeStyles({
    otherDetails: {
       paddingLeft: 16,
       paddingRight: 16,
    }
});
