import { IAppDataDB } from '../database';
import { IImageStorage } from '../imagestorage';
import { withTimeout } from '../../utils';
import { DEFAULT_API_TIMEOUT } from '../cache';
import { UnauthenticatedError } from '../../errors/UnauthenticatedError';
import { logError } from '../../logging';
import { INetworkStatus } from '../../network/NetworkStatus';
import { IOnePlaceAuth } from '../../auth/OnePlaceAuth';
import { CONFIG } from '../../config';
import { AssetType } from 'oneplace-components';

export class AssetCacheManager {
    private cacheTable = 'asset_cache';

    constructor(
        private net: INetworkStatus,
        private auth: IOnePlaceAuth,
        private db: IAppDataDB,
        private imageStorage: IImageStorage
    ) { }

    // same as Api.validateAuth
    async validateAuth() {
        if (this.net.isOffline) {
            throw new Error('Network is offline');
        }
        else if (this.auth.cachedUser) {
            throw new Error('Currently working offline');
        }
        else {
            try {
                const authenticated = await this.auth.checkAuth();
                if (!authenticated) {
                    throw new UnauthenticatedError('User is not authenticated');
                }
            } catch (e) {
                logError(e);
                throw new UnauthenticatedError('Error User is not authenticated');
            }
        }
    }
    // same as Api.getAuthHeaders
    getAuthHeaders(): any {
        if (this.auth.tokens) {
            return {
                'Authorization': 'Bearer ' + this.auth.tokens.token,
                'X-App-Version': CONFIG.build
            };
        }
        else {
            return {};
        }
    }

    _writeFile(location: string, fileName: string, data: Blob) {
        return new Promise<void>((resolve, reject) => {
            window.requestFileSystem(window.LocalFileSystem.PERSISTENT, 0, (_fsd: any) => {
                window.resolveLocalFileSystemURL(location , (fs: any) => {
                    console.log('_writeFile  file system open: ');
                    fs.getFile(fileName, { create: true }, (fileEntry: any) => {
                        console.log('path:', fileEntry.fullPath);
                        fileEntry.createWriter((fileWriter: any) => {
                            fileWriter.onwriteend = () => {
                                console.log('Successful file write!');
                                resolve();
                            };
                            fileWriter.onerror = (e: any) => {
                                console.error('Failed file write: ', e);
                                reject(e);
                            };
                            fileWriter.write(data);
                        });
                    }, (err: any) => {
                        reject(err);
                    });
                }, (err: any) => {
                    reject(err);
                });
            }, (err: any) => {
                reject(err);
            });
        });
    }

    _readDir(location: string) {
        return new Promise<void>((resolve, reject) => {
            window.resolveLocalFileSystemURL(location, (fs: any) => {
                    // console.log('file system open: ');
                    fs.createReader().readEntries((reader: any) => {
                        reader.forEach((file: any) => {
                            console.log(file);
                        })
                        resolve();
                    },
                    (err: any) => {
                            reject(err);
                    });
            },
            (err: any) => {
                 reject(err);
            });

        });
    }

    _copyFile(location: string, fileName: string, targetLocation: string, targetFileName: string) {
        return new Promise<void>((resolve, reject) => {
            window.resolveLocalFileSystemURL(location, (fs: any) => {
                console.log('_copyFile file system open: ');
                fs.getFile(fileName, {}, (fileEntry: any) => {
                    console.log('path:', fileEntry.fullPath);
                    if (fileEntry.isFile) {
                        window.resolveLocalFileSystemURL(targetLocation, (targetFS: any) => {
                            fileEntry.copyTo(targetFS, targetFileName,  () => {
                                console.log('copiedfile completed');
                                resolve();
                            },
                            (err: any) => {
                                console.log(err)
                                reject(err);
                            })
                        }
                        ,(err: any) => {
                            reject(err);
                            }
                        )
                    } else {
                        resolve();
                    }
                }, (err: any) => {
                    reject(err);
                })
            }, (err: any) => {
                reject(err);
            });
        });
    }

    async getLocalAssetUrl(type: AssetType, webUrl: string, accessToken?: string, createObjectUrl = true): Promise<string> {
        let cacheKey: string;
        if (webUrl.includes('/checklistHelpImage/')) {
            // special case for help images because their URL includes changing IDs for the same image
            const cacheKeyUrl = webUrl.substr(webUrl.indexOf('/checklistHelpImage/'));
            cacheKey = `${type}_${cacheKeyUrl}`;
        }
        else {
            // Use the full URL as the cache key
            cacheKey = `${type}_${webUrl}`;
        }
        let cacheLocalUrl = await this.db.getById<string>(this.cacheTable, cacheKey);
        if (cacheLocalUrl) {
            console.log('from cache:', cacheKey);
        }
        else {
            let fetchUrl = webUrl;
            let httpOptions = {};
            await this.validateAuth();
            if (accessToken) {
                fetchUrl += (webUrl.includes('?') ? '&' : '?') + `access_token=${this.auth.tokens!.token}`;
            } else{
                httpOptions = {
                    headers: this.getAuthHeaders()
                };
            }
            try {
                const res = await withTimeout(DEFAULT_API_TIMEOUT, fetch(fetchUrl, httpOptions));
                if (res.ok) {
                    const data = await res.blob();
                    cacheLocalUrl = await this.imageStorage.storeImage(data, 'permanent');
                    await this.db.setById(this.cacheTable, cacheKey, cacheLocalUrl);
                    console.log('newly cached:', cacheKey);
                }
                else {
                    throw new Error(`Got status ${res.status} while downloading ${fetchUrl}`);
                }
            } catch (e) {
                logError(e);
                throw new Error(e);
            }
        }
        if (createObjectUrl) {
            return this.imageStorage.getImageObjectUrl(cacheLocalUrl);
        }
        else {
            return cacheLocalUrl;
        }
    }

    async clearCache() {
        const assetList = await this.db.getAssetCacheList()
        this.imageStorage.removeFiles(assetList);
        await this.db.clearEntityCache(this.cacheTable);
    }
}
