import { ENV } from './environment';

let Sentry: any = null;

function checkSentry() {
    if (!Sentry) {
        Sentry = cordova.require('sentry-cordova.Sentry');
    }
}

export function logInfo(info: string) {
    if (ENV.platform == 'cordova') {
        checkSentry();
        Sentry.captureMessage(info);
    }
    console.log(info);
}

export function logError(error: any, message?: string) {
    if (ENV.platform == 'cordova') {
        checkSentry();
        const sentryData: any = {};
        if (message) {
            sentryData.extra = { message };
        }
        Sentry.captureException(error, sentryData);
    }
    console.error(error, message);
}
