
import * as React from 'react';
import { withStyles, Theme, createStyles, WithStyles } from '@material-ui/core/styles';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import LinearProgress from '@material-ui/core/LinearProgress';
import { i18n } from '../../i18n';
import { withAppContext, IAppContextProp } from '../AppContext';
import { withNavigationContext, INavigationContextProp } from '../navigation/Navigation';
import { ISettings } from '../../settings/settings';
import { Formik, Form, Field } from 'formik';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Divider from '@material-ui/core/Divider';
import Typography from '@material-ui/core/Typography';
import { CONFIG } from '../../config';
import { logError } from '../../logging';
import { FormControl, FormLabel, IconButton, List, ListItem, ListItemAvatar, ListItemText } from '@material-ui/core';
import { ENV } from '../../environment';
import Button from '@material-ui/core/Button';
import { CheckboxControl, PageLoader, NumberControl } from 'oneplace-components';


const styles = (_theme: Theme) => createStyles({
    root: {
    },
    uploadInput: {
        opacity: 0,
        position: 'absolute',
        zIndex: -10,
        right: 0
      }
});

export interface ISettingsPageProps extends
        WithStyles<typeof styles>,
        IAppContextProp,
        INavigationContextProp {
}

export interface ISettingsPageState {
    loadState: 'loading' | 'loaded';
    backupState: 'none' | 'exporting' | 'backup_error' | 'exported' | 'importing' | 'imported';
    importType: 'none' | 'images' | 'data';
    dateValue: boolean;
    message: string;
}

export const SettingsPage = withStyles(styles)(withAppContext(withNavigationContext(
    class extends React.Component<ISettingsPageProps, ISettingsPageState> {
        settings: ISettings;
        private fileInputRef: React.RefObject<HTMLInputElement>
        constructor(props: any) {
            super(props);
            this.fileInputRef = React.createRef()
            this.onSettingsChanged = this.onSettingsChanged.bind(this);
            this.onHandleChanged = this.onHandleChanged.bind(this);
            this.export = this.export.bind(this);
            this.exportImages = this.exportImages.bind(this);
            this.exportData = this.exportData.bind(this);
            this.import = this.import.bind(this);
            this.importImages = this.importImages.bind(this);
            this.importFile = this.importFile.bind(this);
            this.openPrivacyPolicy = this.openPrivacyPolicy.bind(this);

            this.state = {
                loadState: 'loading',
                backupState: 'none',
                dateValue: false,
                message:'',
                importType: 'none'
            };

            this.settings = null as any;

            this.props.nav.updateNavigation({
                title: i18n.t('settings')
            });

            this.loadData();
        }
        onHandleChanged(field: any, value: any) {
            console.log(field);
            console.log(value);
            this.setState({
              dateValue: value
            });
          }
        async loadData() {
            try {
                this.settings = await this.props.ctx.db.getSettings();
                this.setState({
                    loadState: 'loaded'
                });
            }
            catch (e) {
                logError(e, 'Error loading settings');
            }
        }

        async onSettingsChanged(values: ISettings) {
            let updateBackup = false;
            if (this.settings.exportDatabase != values.exportDatabase || this.settings.exportFrequency != values.exportFrequency) {
                updateBackup = true
            }
            Object.assign(this.settings, values);
            console.log('New Settings', this.settings);
            try {
                await this.props.ctx.db.saveSettings(this.settings);
                if (updateBackup) {
                    this.props.ctx.backupManager.updateBackup();
                }
            }
            catch (e) {
                logError(e, 'Error saving settings');
            }
        }

        async export() {
            this.setState({backupState: 'exporting' });

            try {
                await this.props.ctx.backupManager.exportDatabase('both');
                this.setState({ backupState: 'exported', message:'' });
            }
            catch (e) {
                logError(e, 'Error exporting database');
                if (typeof e === 'string') {
                    this.setState({ backupState: 'backup_error', message: e });
                } else{
                    this.setState({ backupState: 'backup_error', message: e.message });
                }
            }
        }

        async exportData() {
            this.setState({backupState: 'exporting' });

            try {
                await this.props.ctx.backupManager.exportDatabase('data');
                this.setState({ backupState: 'exported', message:'' });
            }
            catch (e) {
                logError(e, 'Error exporting database');
                if (typeof e === 'string') {
                    this.setState({ backupState: 'backup_error', message: e });
                } else{
                    this.setState({ backupState: 'backup_error', message: e.message });
                }
            }
        }
        async exportImages() {
            this.setState({backupState: 'exporting' });

            try {
                await this.props.ctx.backupManager.exportDatabase('images');
                this.setState({ backupState: 'exported', message:'' });
            }
            catch (e) {
                logError(e, 'Error exporting database');
                if (typeof e === 'string') {
                    this.setState({ backupState: 'backup_error', message: e });
                } else{
                    this.setState({ backupState: 'backup_error', message: e.message });
                }
            }
        }

        async importFile(event: any) {
            this.setState({backupState: 'importing' });

            const files: FileList = event.target.files
            if (files && files[0]) {
              try {
                if (this.state.importType == 'data') {
                    const db = this.props.ctx.db;
                    await db.import(files[0])
                } else if (this.state.importType == 'images') {
                    const db = this.props.ctx.imageStorage;
                    await db.import(files[0])
                    // just in case they import onto another device
                    this.props.ctx.assetCache.clearCache()
                }
                this.setState({ backupState: 'imported', message:'' });
              } catch (e) {
                logError(e, 'Error importing database');
                if (typeof e === 'string') {
                    this.setState({ backupState: 'backup_error', message: e });
                } else{
                    this.setState({ backupState: 'backup_error', message: e.message });
                }
              }
            }
            if (this.fileInputRef.current) {
                /* this allows the same file to be uploaded twice
                (chrome only add the file when the field changes) */
                this.fileInputRef.current.value = ''
              }
        }

        import (): void {
            this.setState({ importType : 'data'});
            // eslint-disable-next-line no-unused-expressions
            this.fileInputRef.current?.click()
        }
        importImages() {
            this.setState({ importType : 'images'});
            // eslint-disable-next-line no-unused-expressions
            this.fileInputRef.current?.click()
        }

        openPrivacyPolicy() {
            const url ="https://www.1placeonline.com/privacy-policy/"
            if (ENV.platform == 'cordova') {
                cordova.InAppBrowser.open(url, '_system');
            }
            else {
                window.open(url, '_blank')!.opener = null;
            }

        }

        render() {
            const t = i18n.t;
            const user = this.props.ctx.auth.user;

            if (this.state.loadState == 'loaded') {

                return (
                    <div className={this.props.classes.root}>
                        <Card>
                            <CardHeader
                                title={t('settings')}
                                subheader={t('user') + ': ' + user.email}
                            />
                            <CardContent>
                                <Formik
                                    initialValues={this.settings}
                                    validate={this.onSettingsChanged}
                                    onSubmit={(_values, _actions) => {
                                return;
                            }}
                                    render={(props) => (
                                        <Form>
                                            <FormControl fullWidth>
                                                <FormControlLabel
                                                    control={
                                                        <Field name="addPhotosToGallery" component={CheckboxControl} />
                                                    }
                                                    label={t('setting_addPhotosToGallery')}
                                                />
                                            </FormControl>
                                            <FormControl fullWidth>
                                                <FormControlLabel
                                                    control={
                                                        <Field name="disableBackgroundSync" component={CheckboxControl} />
                                                    }
                                                    label={t('setting_disableBackgroundSync')}
                                                />
                                            </FormControl>
                                            {ENV.platform =='cordova' && ENV.os !=='browser' && (
                                            <FormControl fullWidth>
                                                <FormControlLabel
                                                    control={
                                                        <Field name="exportDatabase" component={CheckboxControl} />
                                                    }
                                                    label={t('setting_exportDatabase')}
                                                />
                                            </FormControl>)}
                                            {ENV.platform =='cordova' && ENV.os !=='browser' && props.values.exportDatabase === true && (
                                            <FormControl>
                                                <FormLabel htmlFor="exportFrequency">{t('setting_exportFrequency')}</FormLabel>
                                                <Field  id ="exportFrequency" name="exportFrequency" component={NumberControl} />
                                            </FormControl>)}

                                        </Form>
                                    )} />
                            </CardContent>
                            <List style={{ width: '100%'}} disablePadding={true}>
                                <ListItem button onClick={this.openPrivacyPolicy}>
                                    <ListItemText>
                                        Privacy Policy
                                    </ListItemText>
                                    <ListItemAvatar>

                                            <IconButton>
                                                <ChevronRightIcon />
                                            </IconButton>

                                    </ListItemAvatar>

                                </ListItem>


                        </List>
                            {(this.state.backupState == 'exporting' || this.state.backupState == 'importing')  &&
                                <LinearProgress />
                            }
                            {this.state.backupState == 'backup_error' &&
                                <Typography style={{ padding: 8, textAlign: 'center' }}>
                                    Error: {this.state.message}
                                </Typography>
                            }
                            {this.state.backupState == 'imported' &&
                                <Typography style={{ padding: 8, textAlign: 'center' }}>
                                    Imported Successfully
                                </Typography>
                            }
                            <div style={{ padding: 12, textAlign: 'right' }}>
                                {ENV.platform =='cordova' && ENV.os !=='browser' && (
                                    <Button color="primary" onClick={this.export}
                                    disabled={this.state.backupState == 'exporting' || this.state.backupState == 'importing'}
                                    >{t('export_database')}</Button>)}
                                {(ENV.platform =='web'  || (ENV.platform =='cordova' && ENV.os ==='browser')) && (
                                    <Button color="primary" onClick={this.exportData}
                                    disabled={this.state.backupState == 'exporting' || this.state.backupState == 'importing'}
                                    >{t('export_database')}</Button>
                                    )}
                                {(ENV.platform =='web'  || (ENV.platform =='cordova' && ENV.os ==='browser')) && (
                                    <Button color="primary" onClick={this.exportImages}
                                    disabled={this.state.backupState == 'exporting' || this.state.backupState == 'importing'}
                                    >{t('export_image_database')}</Button>
                                    )}

                                <Button color="primary" onClick={this.import}
                                    disabled={this.state.backupState == 'exporting' || this.state.backupState == 'importing'}
                                >{t('import_database')}</Button>
                                <Button color="primary" onClick={this.importImages}
                                    disabled={this.state.backupState == 'exporting' || this.state.backupState == 'importing'}
                                >{t('import_image_database')}</Button>
                                <input
                                    ref={this.fileInputRef}
                                    type='file'
                                    accept='application/json, application/JSON, .json'
                                    id='file-import'
                                    className={this.props.classes.uploadInput}
                                    onChange={this.importFile}
                                />
                            </div>
                            <div style={{ height: 20 }} />
                            <Divider />
                            <Typography style={{ padding: 8, textAlign: 'center' }}>
                                Version: {CONFIG.build} {ENV.target != 'prod' ? (ENV.target) : ''}
                            </Typography>
                        </Card>

                    </div>
                );
            }
            else if (this.state.loadState == 'loading'){
                return (
                    <PageLoader loading={true} />
                );
            }else{
                return (
                    <div>
                        <PageLoader loading={false} status={t('data_load_error')} />
                    </div>
                );
            }
        }
    }
)));
