import { IncidentsState } from '../components/dashboard/Incidents';
import { I18nHelper } from '../i18n';
import { IIncident } from '../models/Incident';
import { IIncidentsListEntry, IIncidentsListEntryWrapper } from '../models/Incident';
import { ITicket, returnTicketStatusLabel } from '../models/Tickets';
import { IAppDataDB } from './database/app/app_data';

/* currently we only keep the previous page stats for one page,
 * if you want to cache more pages, just comment out the clearPreviousPages
 * calls.
 */

export type PreviousPageID = 'ticketSearch'
                           | 'overviewDashboardTickets'
                           | 'overviewDashboardIncidents'
                           | 'editTicketPage'
                           | 'editIncidentPage'
                           | 'franchiseeDashboardTickets'
                           | 'franchiseeDashboardIncidents'
                           | 'siteDashboardTickets'
                           | 'siteDashboardIncidents'
                           | 'checklistSearch'
                           | 'franchiseDashboard'
                           | 'franchiseeDashboard'
                           | 'siteDashboard'
                           | 'incidentSearch'
                           | 'peopleSearch'
                           | 'personDetails'
                           | 'editHazardPage';

export interface IPreviousPage {
    scrollToPosition(scrollPositionYValue: number): void;
}
export interface IStockedPreviousPage {
    pageId: PreviousPageID;           // the page we are saving the states
    states: any;
    scrollPositionY: number;
    lastEditedItem: any;       // the item we edited, so we need to refresh it the list view
    searchFields: any;
    targetPage: string;        // the page which will be redirected to
}

export async function loadPreviousPageState(db: IAppDataDB, pageId: PreviousPageID): Promise<IStockedPreviousPage | null> {
    try{
        const previousState: IStockedPreviousPage| undefined = await db.loadPreviousPage(pageId);
        if (previousState){
            return previousState;
        }else{
            return null;
        }
    }catch (e) {
        return null;
    }
}

export async function loadPreviousPageStateByTargetPage(db: IAppDataDB, targetPage: PreviousPageID): Promise<IStockedPreviousPage | null>  {
    try{
        const previousState: IStockedPreviousPage| undefined = await db.loadPreviousPageByTargetPage(targetPage);
        if (previousState){
            return previousState;
        }else{
            return null;
        }
    }catch (e) {
        return null;
    }
}

export function scrollToPosition(scrollPositionYValue: number): void {
    // scroll on previous page, we don't need to check height
    setTimeout(() => {
        // delay scroll so the DOM has time to update
        window.scrollTo(0, scrollPositionYValue);
    }, 100);
}

export async function updatePreviousPage(db: IAppDataDB, targetPage: PreviousPageID, lastEditItemJson: any): Promise<void> {
    try{
        const previousState: IStockedPreviousPage| undefined = await db.loadPreviousPageByTargetPage(targetPage);
        if (previousState){
            previousState.lastEditedItem = lastEditItemJson;
            await db.savePreviousPage(previousState);
        }
    }catch (e) {
        console.log('can\'t find previous page state');
    }
}

export async function removePreviousPage(db: IAppDataDB, pageId: PreviousPageID): Promise<void> {
    try{
        const previousState: IStockedPreviousPage| undefined = await db.loadPreviousPage(pageId);
        // delete previous state if exist
        if (previousState){
            await db.removePreviousPage(pageId);
        }
    }catch (e) {
        return;
    }
}

// reset all previous pages states
// used on side menu, selecting franchisee, selecting site
export async function clearPreviousPages(db: IAppDataDB): Promise<void> {
    try{
        // delete all previous states
        await db.emptyPreviousPage();
    }catch (e) {
        return;
    }
}

export function updateMyTicketsView(previousState: IStockedPreviousPage, i18n: I18nHelper): void {
    previousState.states.myTickets
    .filter((iticket: ITicket) => iticket.id == previousState.lastEditedItem.id)
    .map((iticket: ITicket) => {
        // Tony: sometimes the statusLabel doesn't match the latest status,
        //       then we will need to fix it here.
        const newStatusLabel = returnTicketStatusLabel(Number(previousState.lastEditedItem.status), i18n.t);
        iticket.priorityLabel = previousState.lastEditedItem.priorityLabel;
        iticket.statusLabel = newStatusLabel;
        iticket.subject = previousState.lastEditedItem.subject;
        iticket.dueDate = previousState.lastEditedItem.dueDate;
    });
}

export function updateMyIncidentsView(previousState: IStockedPreviousPage): void {
    const incidentsState = previousState.states.incidentsState as IncidentsState
    if(!incidentsState) return

    const incidents = (incidentsState.signedIncidents || []).concat(incidentsState.unsignedIncidents || [])

    const incidentToUpdate = incidents
    .map((entry: IIncidentsListEntryWrapper) => entry.incident)
    .find((incident: IIncidentsListEntry) => incident.id === previousState.lastEditedItem.id)
    
    //we only need to update info that is shown in the incident list
    if(incidentToUpdate){
        const incident = previousState.lastEditedItem as IIncident
        incidentToUpdate.name = incident.name
        incidentToUpdate.date = incident.date as string
        incidentToUpdate.status = Number(incident.status)
        incidentToUpdate.incidentCategory = incident.incidentCategory
    }
}