import { logInfo, logError } from '../../logging';


export interface ISqliteDBConfig {
    version: number;
    onVersionUpdate: (prevVersion: number) => Promise<number>;
}

export default class SqliteDB {
    sqlite: any;
    config: ISqliteDBConfig;
    dbName: string;

    constructor(dbName: string,  config: ISqliteDBConfig) {
        this.sqlite = window.sqlitePlugin.openDatabase({name: dbName +'.db', location: 'default'});
        this.config = config;
        this.dbName = dbName;
    }

    executeSql(query: any, args?: any[]): Promise<any> {
        return new Promise((resolve, reject) => {
            this.sqlite.transaction((transaction: any) => {
                // console.log(this.dbName + ' :  query ' + query)
                // console.log('args' +  args)
                transaction.executeSql(query, args,
                    (t: any, result: any) => {
                        resolve(result);
                    },
                    (tx: any, error: any) => {
                        console.log(error);
                        reject(error);
                    }
                );
            },
            (tx_err: any) =>{
                logError(tx_err)
                reject(tx_err);
            },
            () =>{
                // console.log('Transaction started')
            }
            );
        })
    }

    sqlBatch(sqlStatements: (string|[string, any[]])[], success?: () => void, error?: (err: Error) => void ) {
        this.sqlite.sqlBatch(sqlStatements, success, error)
    }

    count(query: any, args?: any[]): Promise<number> {
        return new Promise((resolve, reject) => {
            this.executeSql(query,args)
            .then((result: any) => {
                // console.log('get rowsAffected: ' + result.rowsAffected);
                resolve (result.rows.item(0).count)

            }).catch((err: Error) => {
                console.log(err)
                reject(err)
            })
        })
    }

    async get<T>(table: string, key: string) {
        const json: T =  await this.executeSql('select data FROM ' + table + ' where id = ?', [key])
        .then((result: any) => {
            // console.log('get rowsAffected: ' + result.rowsAffected);
            if (result.rows.length === 1) {
                const token = result.rows.item(0).data
                if (token == null) {
                    return undefined;
                } else {
                    return JSON.parse(token);
                }
            } else{
                return undefined
            }
        }).catch((err: Error) => {
            console.log(err)
            return undefined
        })
        return json;
    }

    async put(table: string, value: any, key: string): Promise<string> {
        return this.executeSql('select id FROM ' + table + ' where id = ?', [key])
        .then(async (result) => {
            return  new Promise<string>((response, reject) => {
                if (result.rows.length === 1) {
                    this.executeSql('UPDATE ' + table + '  set data = ?  WHERE id = ?', [JSON.stringify(value), result.rows.item(0).id])
                        .then((_result_1) => {
                            response(key)
                        }).catch((err) => {
                                console.log(err);
                                reject(err)
                        });
                } else{
                    this.executeSql('INSERT INTO ' + table + ' (id, data) VALUES (?, ?)', [key, JSON.stringify(value)])
                    .then((_result_1) => {
                        response(key)
                    }).catch((err) => {
                        console.log(err);
                        reject(err)
                    });
                }
            })
        }).catch((err) => {
            logError(err)
            throw Error(err)
        })
    }

    async add(table: string, value: any): Promise<string|number> {
        return this.executeSql('INSERT INTO ' + table + ' (data) VALUES (?)', [JSON.stringify(value)])
        .then((result_1) => {
            return result_1.insertId
        }).catch((err) => {
            logError(err)
            throw Error(err)
        });
    }

    //
    async update(table: string, key: string|number, changes: any): Promise<number> {
        return this.executeSql('select data FROM ' + table + ' where id = ?', [key])
        .then(async (result) => {
            return  new Promise<number>((resolve, reject) => {
                if (result.rows.length === 1) {
                    const json = JSON.parse(result.rows.item(0).data)
                    const mergedObject = {
                        ...json,
                        ...changes
                      };
                    this.executeSql('UPDATE ' + table + '  set data = ?  WHERE id = ?', [JSON.stringify(mergedObject), key])
                        .then((_result_1) => {
                            resolve(1)
                        }).catch((err) => {
                            console.log(err);
                            reject(err)
                        });
                } else{
                    resolve(0)
                }
            }).catch((err) => {
                logError(err)
                throw Error(err)
            })
        }).catch((err) => {
            logError(err)
            throw Error(err)
        })
    }

    async delete(table: string, key: string | number): Promise<undefined>{
        return this.executeSql('delete FROM ' + table + ' where id = ?', [key])
        .then((_result: any) => {
            return  new Promise<undefined>((resolve, _reject) => {
                // console.log('rowsAffected: ' + result.rowsAffected);
                resolve(undefined)
            }).catch((err) => {
                logError(err)
                throw Error(err)
            })
        }).catch((err) => {
            console.log(err)
            throw Error(err)
        })
    }

    async deleteByIndex(entityName: string, indexName: string,  id: number|string) {
        const json  =  await this.executeSql('delete  FROM ' + entityName + ' where ' + indexName + ' = ?', [id])
        .then((result: any) => {
            // console.log('get rowsAffected: ' + result.rowsAffected);
            return result.rowsAffected
        }).catch((err: Error) => {
            logError(err, 'delete  FROM ' + entityName + ' where ' + indexName + ' = ' + id)
            return undefined
        })
        return json;
    }

    async clear(table: string): Promise<undefined>{
        return this.executeSql('delete FROM ' + table)
        .then((_result: any) => {
            return  new Promise<undefined>((resolve, _reject) => {
                // console.log('rowsAffected: ' + result.rowsAffected);
                resolve(undefined)
            }).catch((err) => {
                logError(err)
                throw Error(err)
            })
        }).catch((err) => {
            console.log(err)
            throw Error(err)
        })
    }

    async getByIndex<T>(entityName: string, indexName: string,  id: number|string) {
        const json  =  await this.executeSql('select data FROM ' + entityName + ' where ' + indexName + ' = ?', [id])
        .then((result: any) => {
            if (result.rows.length === 1) {
                return JSON.parse(result.rows.item(0).data) as T
            } else{
                return  undefined
            }
        }).catch((err: Error) => {
            console.log(err)
            throw new Error('not a valid record')
        })
        return json;
    }

    async getAllByIndex(entityName: string, indexName: string,  id: number|string) {
        const jsonArray  =  await this.executeSql('select data FROM ' + entityName + ' where ' + indexName + ' = ?', [id])
        .then((result: any) => {
            const data = [];
            for (let i = 0; i < result.rows.length; i++){
                data.push(JSON.parse(result.rows.item(i).data));
            }
            return data;
        }).catch((err: Error) => {
            console.log(err)
            return undefined
        })
        return jsonArray;
    }

    checkDatabaseOwner(id: string): Promise<void> {
        // select UTIL table to see if new database or existing database
        return this.executeSql('SELECT name FROM sqlite_master WHERE type = "table" AND name = ?',['_owner'])
        .then((result) => {
            return new Promise<void>( (resolve, reject) => {
                // console.log(result)
                if (result.rows.length === 0) {
                    // new database
                    logInfo('new database')
                    resolve();
                } else{
                    // existsing check if it is theirs
                    this.executeSql('SELECT data FROM "_owner" WHERE id = ?',[1])
                        .then((dataResult) => {
                            // console.log(dataResult)
                            if (dataResult.rows.length === 0) {
                                console.log('no records in _owner database')
                                resolve();
                            } else{
                                // existsing check if it is theirs
                                if (dataResult.rows.item(0).data !== id) {
                                    reject(Error('Database exists'))
                                } else{
                                    logInfo(id + ' database')
                                    resolve();
                                }
                            }
                    })
                }
            })
        })
    }

    async upgradeDatabase(): Promise<boolean> {
        // create or udpate datebase
        let currentVersion = 0 ;
        const versionTableExist = await this.executeSql('SELECT name FROM sqlite_master WHERE type = "table" AND name = ?', ['_version'])
        if (versionTableExist.rows.length === 0) {
            currentVersion = 0
        } else {
            const dbVersionResult = await this.executeSql('SELECT version FROM _version WHERE id = ?', ['version'])

            if (dbVersionResult.rows.length === 0) {
                currentVersion = 0;
            } else{
                currentVersion =  dbVersionResult.rows.item(0).version;
            }
        }
        console.log('version ' + currentVersion)
        const saveVersion = async (version_1: number, complete: any, error: any) => {
            return this.executeSql('UPDATE _version set version = ?  WHERE id = ?', [version_1, 'version'])
                .then((_result_1) => {
                    console.log(this.dbName + ' updated to version ' + version_1);
                    complete(true);
                }).catch((err) => {
                    console.log(err);
                    error(err);
                });
            };
        return new Promise<boolean>((resolve, reject) => {
            // nothing to migrate, just set version
            console.log(this.dbName + ' current version ' + currentVersion);
            if (currentVersion === this.config.version) {
                saveVersion(currentVersion, resolve, reject);
            } else {
                // eslint-disable-next-line @typescript-eslint/require-await
                const upgrade = async () => {
                    // console.log('upgrade')
                    // console.log(currentVersion)
                    // console.log(this.config)
                    if (currentVersion === this.config.version) {
                        console.log(this.dbName + ' upgrade to ' + currentVersion);
                        saveVersion(currentVersion, resolve, reject);
                    } else {
                        const updateVersion = this.config.onVersionUpdate;
                        if (!updateVersion) {
                            console.log(this.dbName + ' no updateVersion function')
                            saveVersion(this.config.version, resolve, reject);
                            return
                        }
                        // eslint-disable-next-line @typescript-eslint/require-await
                        updateVersion(currentVersion).then(async (newVersion: any) => {
                            console.log(this.dbName + ' new version ' + newVersion)
                            currentVersion = newVersion;
                            saveVersion(currentVersion, () => {
                                console.log(this.dbName + ' oncomplete save version')
                                upgrade(); // setFast()
                            }, reject);
                        }).catch((err: Error) => {
                            logError(err);
                            reject(err)
                        });
                    }
                };
                upgrade();
            }
        })
    }
}
