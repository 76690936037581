/* eslint-disable @typescript-eslint/unbound-method */ // since we're using bind to handle 'this', we can ignore this warning
import * as React from 'react';
import { withStyles, Theme, createStyles, WithStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import { TabsComponent, ITabDefinition, PageLoader, ISite, getSite, getSiteFranchiseeId, IStoredChecklistMeta } from 'oneplace-components';
import FavouriteIcon from '@material-ui/icons/StarBorder';
import { NewItemFAB } from '../common/NewItemFAB';
import { INavigationContextProp, withNavigationContext } from '../navigation/Navigation';
import { i18n } from '../../i18n';
import { IFranchiseData, FranchiseData } from '../../data_sources/FranchiseData';
import { withAppContext, IAppContextProp } from '../AppContext';
import { withRouter, RouteComponentProps } from 'react-router';
import { IChecklistData, ChecklistData } from '../../data_sources/ChecklistData';
import { IDashboardData, IDashboardChecklist, IDashboardTicket, IDashboardNote, IChecklistDashboardFilter} from '../../models/Dashboard';
import { ExpandingChecklistList } from '../dashboard/ExpandingChecklistList';
import { getChecklistUrl } from '../dashboard/utils/getChecklistUrl';
import { addLocalIdsToCLList } from '../dashboard/utils/addLocalIdsToCLList';
import { logError } from '../../logging';
import { TicketList } from '../dashboard/TicketList';
import { getTicketUrl } from '../dashboard/utils/getTicketUrl';
import Button from '../../../node_modules/@material-ui/core/Button';
import { NotesList } from '../dashboard/NotesList';
import { getNoteUrlFromDashboardPage } from '../../models/Note';
import { CONFIG } from '../../config';
import Badge from '@material-ui/core/Badge';
import { loadPreviousPageState, scrollToPosition, removePreviousPage, IStockedPreviousPage, updateMyTicketsView, clearPreviousPages, updateMyIncidentsView } from '../../data_sources/PreviousPage';
import SiteDetails from './SiteDetails';
import { Incidents, IncidentsState } from '../dashboard/Incidents';
import ChecklistDashboardHeaderPanel from '../dashboard/ChecklistDashboardHeaderPanel';

const styles = (theme: Theme) => createStyles({
    root: {
        backgroundColor: theme.palette.primary.main,
        color: '#FFF',
        padding: 16,
        paddingBottom: 8,
    },
    badgeItem: {
        padding: '0 10px 0 0'
    },
    button: {
        color: '#fff'
    }
});

type TParams = { siteId: string };

export interface ISiteDashboardProps extends
        WithStyles<typeof styles>,
        IAppContextProp,
        INavigationContextProp,
        RouteComponentProps<TParams> {
    mock_franchiseData?: IFranchiseData;
    mock_checklistData?: IChecklistData;
}

type LoadState = 'not_loaded' | 'loading' | 'loaded' | 'load_error';

export interface ISiteDashboardState {
    loadState: LoadState;
    relatedDataLoadState: LoadState;
    franchiseeId: number;
    siteId: number;
    site: ISite;
    dashboard: IDashboardData | null;
    drafts: IStoredChecklistMeta[];

    todaysChecklists_loadState: LoadState;
    todaysChecklists: IDashboardChecklist[] | null;
    todaysChecklists_totalCount: number;
    todaysChecklists_loadingMore: boolean;
    todaysCompletedChecklists_loadState: LoadState;
    todaysCompletedChecklists: IDashboardChecklist[];
    todaysCompletedChecklists_loadingMore: boolean;
    overdueChecklists_loadState: LoadState;
    overdueChecklists: IDashboardChecklist[];
    overdueChecklists_loadingMore: boolean;

    myTickets_loadState: LoadState;
    myTickets: IDashboardTicket[];
    myTickets_loadingMore: boolean;

    hideNotes: boolean;
    myNotes_loadState: LoadState;
    myNotes: IDashboardNote[] | null;
    myNotes_loadingMore: boolean;
    myNotes_hasMore: boolean;
    totalDrafts: number;
    totalFavourites: number;
    totalIncidents: number;
    incidentsState: IncidentsState | null;

    checklistFilter: IChecklistDashboardFilter | null;
}

export const SiteDashboard = withStyles(styles)(withRouter(withAppContext(withNavigationContext(
    class extends React.Component<ISiteDashboardProps, ISiteDashboardState> {
        franchiseData: IFranchiseData;
        checklistData: IChecklistData;
        componentActive = true;

        // by default load checklist tab
        currentTabIndex = 1;

        constructor(props: ISiteDashboardProps) {
            super(props);
            this.openChecklist = this.openChecklist.bind(this);
            this.openTicket = this.openTicket.bind(this);
            this.searchChecklists = this.searchChecklists.bind(this);
            this.searchTickets = this.searchTickets.bind(this);
            this.onTodaysChecklistsShowMore = this.onTodaysChecklistsShowMore.bind(this);
            this.onTodaysCompletedChecklistsExpand = this.onTodaysCompletedChecklistsExpand.bind(this);
            this.onTodaysCompletedChecklistsShowMore = this.onTodaysCompletedChecklistsShowMore.bind(this);
            this.onOverdueChecklistsExpand = this.onOverdueChecklistsExpand.bind(this);
            this.onOverdueChecklistsShowMore = this.onOverdueChecklistsShowMore.bind(this);
            this.onMyTicketsShowMore = this.onMyTicketsShowMore.bind(this);
            this.goToFranchisees = this.goToFranchisees.bind(this);
            this.goToSites = this.goToSites.bind(this);
            this.reloadTickets = this.reloadTickets.bind(this);

            this.franchiseData = this.props.mock_franchiseData
                || new FranchiseData(this.props.ctx.db, this.props.ctx.api, this.props.ctx.auth.user);
            this.checklistData = this.props.mock_checklistData
                || new ChecklistData(this.props.ctx.db, this.props.ctx.api, this.props.ctx.auth.user);

            const accessNotes = this.props.ctx.auth.user.capabilities.notes;

            const capabs = this.props.ctx.auth.user.capabilities;
            if(capabs.hideSiteManager){
                // site details tab is hidden, the checklist tab is the first one
                this.currentTabIndex = 0;
            }

            this.state = {
                loadState: 'loading',
                relatedDataLoadState: 'not_loaded',
                franchiseeId: null as any,
                siteId: Number(this.props.match.params.siteId),
                site: null as any,
                dashboard: null as any,
                drafts: [],

                todaysChecklists_loadState: 'loading',
                todaysChecklists: [],
                todaysChecklists_totalCount: -1,
                todaysChecklists_loadingMore: false,
                todaysCompletedChecklists_loadState: 'not_loaded',
                todaysCompletedChecklists: [],
                todaysCompletedChecklists_loadingMore: false,
                overdueChecklists_loadState: 'not_loaded',
                overdueChecklists: [],
                overdueChecklists_loadingMore: false,

                myTickets_loadState: 'not_loaded',
                myTickets: [],
                myTickets_loadingMore: false,

                myNotes_loadState: 'not_loaded',
                myNotes: null,
                myNotes_loadingMore: false,
                myNotes_hasMore: true,
                hideNotes: !accessNotes,
                totalDrafts: 0,
                totalFavourites: 0,
                totalIncidents: 0,
                incidentsState: null,

                checklistFilter: null
            };

            this.props.nav.updateNavigation({
                title: i18n.t('customLabel_site')
            });

            void this.initPage();
        }

        componentWillUnmount() {
            this.componentActive = false;
        }

        async reloadTickets() {
            this.setState({
                myTickets_loadState: 'not_loaded',
                myTickets: [],
                myTickets_loadingMore: false,
                relatedDataLoadState: 'loaded'
            });
            await this.loadData();
        }

        initPage = async () => {
            const ticketState = await loadPreviousPageState(this.props.ctx.db, 'siteDashboardTickets');
            const incidentState = await loadPreviousPageState(this.props.ctx.db, 'siteDashboardIncidents')
            if (!ticketState && !incidentState) {
                await this.loadData();
            }else{
                if(ticketState){ //restore ticket state
                    this.currentTabIndex = this.props.ctx.auth.user.capabilities.checklists ? 2 : 1
                    if (ticketState.lastEditedItem) {
                        // update the view for last edited item
                        updateMyTicketsView(ticketState, i18n)
                    }
                    this.setState(ticketState.states)
                    scrollToPosition(ticketState.scrollPositionY)
                } else if(incidentState) {//restore incident state
                    // possible tabs: details,checklists, tickets, incidents, notes
                    let defaultIncidentTabIndex = 3;
                    if (this.props.ctx.auth.user.capabilities.hideSiteManager) defaultIncidentTabIndex--;
                    if (!this.props.ctx.auth.user.capabilities.checklists) defaultIncidentTabIndex--;
                    if (!this.props.ctx.auth.user.capabilities.tickets) defaultIncidentTabIndex--;
                    this.currentTabIndex = defaultIncidentTabIndex
                    if(incidentState.lastEditedItem){
                        updateMyIncidentsView(incidentState)
                    }
                    this.setState(incidentState.states)
                    scrollToPosition(incidentState.scrollPositionY)
                }
                void clearPreviousPages(this.props.ctx.db)//after restored state, we can clear previous page
            }
        }
        async loadData() {
            // Data required for initial render
            const franchiseId = this.props.ctx.auth.user.capabilities.franchiseId;
            const siteId = this.state.siteId;
            const sites = await this.franchiseData.getSites(franchiseId).getData();
            const site = getSite(sites.sites, siteId);
            const franchiseeId = getSiteFranchiseeId(site);
            const totalDrafts = await this.props.ctx.db.getDraftsTotal();
            this.setState({
                site,
                franchiseeId,
                totalDrafts,
                loadState: 'loaded',
            });
            await this.checkAndLoadChecklistDashboardFilter()
            await this.loadRelatedData();
        }

        async loadRelatedData() {
            // Other Data
            this.setState({
                relatedDataLoadState: 'loading',
            });
            const capabs = this.props.ctx.auth.user.capabilities;
            const franchiseId = capabs.franchiseId;
            const franchiseeId = this.state.franchiseeId;
            const siteId = this.state.siteId;
            let dashboard: IDashboardData | null = null;
            let todaysChecklists: IDashboardChecklist[] | null = null;
            let todaysChecklists_totalCount = -1;
            let myTickets: IDashboardTicket[] = [];
            let totalFavourites = 0;
            let totalIncidents = 0;
            if (this.componentActive) {
                try {
                    dashboard = await this.checklistData.getSiteDashboard(franchiseId,
                        franchiseeId, siteId, this.state.checklistFilter).getData();
                    // favourites are only available in online mode
                    totalFavourites = this.props.ctx.net && this.props.ctx.net.isOffline ? 0 : dashboard.dashboard.favouriteChecklistNumber;
                    totalIncidents = dashboard.dashboard.incidentSummary?.unresolved || 0;
                    myTickets = dashboard.dashboard.myTickets || [];
                }
                catch (e) {
                    console.error(e);
                }
            }
            if (this.componentActive && capabs.checklists) {
                try {
                    const checklists = await this.checklistData.getTodaysChecklists(
                        franchiseeId, siteId, this.state.checklistFilter, CONFIG.checklistsPerDashboardSection);
                    todaysChecklists = checklists.checklists;
                    if (dashboard) {
                        todaysChecklists_totalCount = dashboard.dashboard.checklistSummary.dueToday;
                    }
                    else {
                        todaysChecklists_totalCount = checklists.totalCount;
                    }

                }
                catch (e) {
                    console.error(e);
                    logError(e, 'Unable to load site dashboard getTodaysChecklists');
                    if (todaysChecklists == null) {
                        todaysChecklists =[]
                    }
                }
            }
            if (this.componentActive) {
                const drafts = await this.props.ctx.db.getDraftChecklistList();
                if (todaysChecklists) {
                    addLocalIdsToCLList(todaysChecklists, drafts);
                }
                this.setState({
                    dashboard,
                    drafts,
                    relatedDataLoadState: 'loaded',
                    todaysChecklists_loadState: 'loaded',
                    todaysChecklists,
                    todaysChecklists_totalCount,
                    myTickets_loadState: 'loaded',
                    myTickets,
                    totalFavourites,
                    totalIncidents
                });
            }
        }

        goTo =  (location: string) :void => this.props.history.push(location)

        openChecklist(checklist: IDashboardChecklist) {
            this.savePageDetails('editChecklistPage')
            const url = getChecklistUrl(checklist);
            this.goTo(url);
        }

        openTicket(ticket: IDashboardTicket) {
            const previousPageData = {pageId: 'siteDashboardTickets',
                states: this.state,
                scrollPositionY: window.scrollY,
                searchFields: null,
                targetPage: 'editTicketPage',
                lastEditedItem: null
                } as IStockedPreviousPage;
            void this.props.ctx.db.savePreviousPage(previousPageData);

            const url = getTicketUrl(ticket);
            this.goTo(url);
        }

        onOpenIncident = (incidentsState: IncidentsState) => {
            const previousPageData = {pageId: 'siteDashboardIncidents',
                states: {...this.state, incidentsState: incidentsState},
                scrollPositionY: window.scrollY,
                searchFields: null,
                targetPage: 'editIncidentPage',
                lastEditedItem: null
                } as IStockedPreviousPage;
            void this.props.ctx.db.savePreviousPage(previousPageData);
        }

        searchChecklists() {
            void removePreviousPage(this.props.ctx.db,'checklistSearch');
            this.savePageDetails('checklistSearch')
            const url = `/checklists?assignee=site&assigneeId=${this.state.siteId}`;
            this.goTo(url);
        }

        searchTickets() {
            const url = `/tickets?assignee=site&assigneeId=${this.state.siteId}`;
            this.goTo(url);
        }

        searchIncidents = () => {
            void removePreviousPage(this.props.ctx.db,'incidentSearch');
            this.savePageDetails('incidentSearch');// this is not the pageId above but the target page
            const url = '/incidents';
            this.goTo(url);
        }

        goToFranchisees() {
            this.goTo('/franchisees');
        }

        goToSites() {
            this.goTo('/sites');
        }

        async onTodaysChecklistsShowMore() {
            if (!this.state.todaysChecklists_loadingMore && this.state.todaysChecklists) {
                const lastId = this.state.todaysChecklists[this.state.todaysChecklists.length - 1].id;
                this.setState({
                    todaysChecklists_loadingMore: true
                });
                try {
                    const checklists = await this.checklistData
                        .getTodaysChecklists(
                            this.state.franchiseeId,
                            this.state.siteId,
                            this.state.checklistFilter,
                            CONFIG.checklistsPerDashboardSection, lastId);
                    addLocalIdsToCLList(checklists.checklists, this.state.drafts);
                    const newList = [...this.state.todaysChecklists, ...checklists.checklists];
                    this.setState({
                        todaysChecklists: newList,
                        todaysChecklists_loadingMore: false
                    });
                }
                catch (e) {
                    logError(e, 'Error getting more franchisee checklists due today');
                    this.setState({
                        todaysChecklists_loadingMore: false
                    });
                }
            }
        }

        async onTodaysCompletedChecklistsExpand() {
            const franchiseId = this.props.ctx.auth.user.capabilities.franchiseId;
            const franchiseeId = this.state.franchiseeId;
            const siteId = this.state.siteId;
            if (this.state.todaysCompletedChecklists_loadState == 'not_loaded') {
                this.setState({
                    todaysCompletedChecklists_loadState: 'loading'
                });
                try {
                    const schedule = await this.checklistData
                        .getSiteSchedule(franchiseId, franchiseeId, siteId,
                            this.state.checklistFilter, 'completed').getData();
                    addLocalIdsToCLList(schedule.checklists, this.state.drafts);
                    this.setState({
                        todaysCompletedChecklists: schedule.checklists,
                        todaysCompletedChecklists_loadState: 'loaded'
                    });
                }
                catch (e) {
                    logError(e, 'Error getting completed schedule');
                    this.setState({
                        todaysCompletedChecklists_loadState: 'load_error'
                    });
                }
            }
        }

        async onTodaysCompletedChecklistsShowMore() {
            const franchiseId = this.props.ctx.auth.user.capabilities.franchiseId;
            const franchiseeId = this.state.franchiseeId;
            const siteId = this.state.siteId;
            if (!this.state.todaysCompletedChecklists_loadingMore) {
                const lastId = this.state.todaysCompletedChecklists[this.state.todaysCompletedChecklists.length - 1].id;
                this.setState({
                    todaysCompletedChecklists_loadingMore: true
                });
                try {
                    const schedule = await this.checklistData
                        .getSiteSchedule(franchiseId, franchiseeId, siteId,
                            this.state.checklistFilter, 'completed', lastId).getData();
                    addLocalIdsToCLList(schedule.checklists, this.state.drafts);
                    const newList = [...this.state.todaysCompletedChecklists, ...schedule.checklists];
                    this.setState({
                        todaysCompletedChecklists: newList,
                        todaysCompletedChecklists_loadingMore: false
                    });
                }
                catch (e) {
                    logError(e, 'Error getting more completed schedule items');
                    this.setState({
                        todaysCompletedChecklists_loadingMore: false
                    });
                }
            }
        }

        async onOverdueChecklistsExpand() {
            const franchiseId = this.props.ctx.auth.user.capabilities.franchiseId;
            const franchiseeId = this.state.franchiseeId;
            const siteId = this.state.siteId;
            if (this.state.overdueChecklists_loadState == 'not_loaded') {
                this.setState({
                    overdueChecklists_loadState: 'loading'
                });
                try {
                    const schedule = await this.checklistData
                        .getSiteSchedule(franchiseId, franchiseeId, siteId,
                            this.state.checklistFilter, 'overdue').getData();
                    addLocalIdsToCLList(schedule.checklists, this.state.drafts);
                    this.setState({
                        overdueChecklists: schedule.checklists,
                        overdueChecklists_loadState: 'loaded'
                    });
                }
                catch (e) {
                    logError(e, 'Error getting overdue schedule');
                    this.setState({
                        overdueChecklists_loadState: 'load_error'
                    });
                }
            }
        }

        async onOverdueChecklistsShowMore() {
            const franchiseId = this.props.ctx.auth.user.capabilities.franchiseId;
            const franchiseeId = this.state.franchiseeId;
            const siteId = this.state.siteId;
            if (!this.state.overdueChecklists_loadingMore) {
                const lastId = this.state.overdueChecklists[this.state.overdueChecklists.length - 1].id;
                this.setState({
                    overdueChecklists_loadingMore: true
                });
                try {
                    const schedule = await this.checklistData
                        .getSiteSchedule(franchiseId, franchiseeId, siteId,
                            this.state.checklistFilter, 'overdue', lastId).getData();
                    addLocalIdsToCLList(schedule.checklists, this.state.drafts);
                    const newList = [...this.state.overdueChecklists, ...schedule.checklists];
                    this.setState({
                        overdueChecklists: newList,
                        overdueChecklists_loadingMore: false
                    });
                }
                catch (e) {
                    logError(e, 'Error getting more overdue schedule items');
                    this.setState({
                        overdueChecklists_loadingMore: false
                    });
                }
            }
        }

        async onMyTicketsShowMore() {
            const api = this.props.ctx.api;
            const franchiseId = this.props.ctx.auth.user.capabilities.franchiseId;
            const franchiseeId = this.state.franchiseeId;
            const siteId = this.state.siteId;
            if (!this.state.myTickets_loadingMore) {
                const lastId = this.state.myTickets[this.state.myTickets.length - 1].id;
                this.setState({
                    myTickets_loadingMore: true
                });
                try {
                    const tickets = await api.getSiteMyTickets(franchiseId, franchiseeId, siteId, lastId);
                    const newList = [...this.state.myTickets, ...tickets];
                    this.setState({
                        myTickets: newList,
                        myTickets_loadingMore: false
                    });
                }
                catch (e) {
                    logError(e, 'Error getting more myTickets items');
                    this.setState({
                        myTickets_loadingMore: false
                    });
                }
            }
        }
        onMyNotesShowMore = async () => {
            if (!this.state.myNotes) {
                return;
            }
            const api = this.props.ctx.api;
            const franchiseId = this.props.ctx.auth.user.capabilities.franchiseId;
            const franchiseeId = this.state.franchiseeId;
            const siteId = this.state.siteId;
            if (!this.state.myNotes_loadingMore) {
                const lastId = this.state.myNotes[this.state.myNotes.length - 1].id;
                this.setState({
                    myNotes_loadingMore: true
                });
                try {
                    const notes = await api.getSiteNotes(franchiseId, franchiseeId, siteId, lastId);
                    const newList = [...this.state.myNotes, ...notes.notes];
                    this.setState({
                        myNotes: newList,
                        myNotes_loadingMore: false,
                        myNotes_hasMore: notes.notes && notes.notes.length == 10
                    });
                }
                catch (e) {
                    logError(e, 'Error getting more notes');
                    this.setState({
                        myNotes_loadingMore: false
                    });
                }
            }
        }
        openNote = (note: IDashboardNote) => {
            const url = getNoteUrlFromDashboardPage(note, 'site', this.state.siteId);
            this.goTo(url);
        }
        clickNotesTab = async (tabName: string) => {
            // the click tab listener will be added to all tabs, we only want to trigger it for the notes tab
            if (tabName != 'notes') {
                return;
            }
            const ctx = this.props.ctx;
            const capabs = ctx.auth.user.capabilities;
            const franchiseeId = this.state.franchiseeId;
            const siteId = this.state.siteId;

            this.setState({ myNotes_loadingMore: true });
            try {
                const res = await ctx.api.getSiteNotes(capabs.franchiseId, franchiseeId, siteId, 0);

                this.setState({ myNotes_loadingMore: false,
                                myNotes_loadState: 'loaded',
                                myNotes: res == null ? [] : res.notes,
                                myNotes_hasMore: res.notes && res.notes.length == 10
                            });
            }
            catch (e) {
                logError(e, 'Error getting notes');
                this.setState({ myNotes_loadingMore: false, myNotes_loadState: 'loaded', myNotes_hasMore: false });
            }
        }

        onApplyChecklistFilter = async (appliedFilter: IChecklistDashboardFilter): Promise<void> => {
            this.setState({checklistFilter: appliedFilter,
                todaysCompletedChecklists_loadState: 'not_loaded', //force list reload
                overdueChecklists_loadState: 'not_loaded' //force list reload
            })
            await this.loadData();
        }

        checkAndLoadChecklistDashboardFilter = async(): Promise<void> => {
            const franchiseId = this.props.ctx.auth.user.capabilities.franchiseId;
            //if no filter is applied for current view, then we check local DB
            if(!this.state.checklistFilter){
                const checklistFilter = await this.checklistData.getChecklistDashboardFilter(franchiseId)
                if(checklistFilter) this.setState({checklistFilter})
            }
        }

        getDetailsTab = () => {
            return(
                <>
                    <SiteDetails
                        franchiseeId={this.state.franchiseeId}
                        siteId={this.state.siteId}
                    />
                </>
            )
        }

        getChecklistsTab() {
            const t = i18n.t;
            const dashboard = this.state.dashboard && this.state.dashboard.dashboard;
            return (<>
                {dashboard &&
                    <ChecklistDashboardHeaderPanel
                        filter={this.state.checklistFilter}
                        onClickSearchChecklist={this.searchChecklists}
                        onApplyChecklistFilter={this.onApplyChecklistFilter}
                    />
                }
                {this.state.todaysChecklists &&
                    // total count requires dashboard data. -1 = dont show (for now)
                    <ExpandingChecklistList
                        title={t('checklists_due_today')}
                        totalCount={this.state.todaysChecklists_totalCount}
                        checklists={this.state.todaysChecklists}
                        defaultExpanded={false}
                        onShowMore={this.onTodaysChecklistsShowMore}
                        openChecklist={this.openChecklist}
                        loadingMore={this.state.todaysChecklists_loadingMore}
                        currentUser={this.props.ctx.auth.user}
                    />}
                {!dashboard &&
                    <PageLoader loading={false} paddingTop={30}
                        status={
                            t('offline_data_not_available_for_entity', {
                                entityName: t('customLabel_site')
                            })
                        } />
                }
                {dashboard && <>
                    <ExpandingChecklistList
                        title={t('checklists_completed_today')}
                        totalCount={dashboard.checklistSummary.completedToday}
                        checklists={this.state.todaysCompletedChecklists}
                        onExpand={this.onTodaysCompletedChecklistsExpand}
                        onShowMore={this.onTodaysChecklistsShowMore}
                        openChecklist={this.openChecklist}
                        loading={this.state.todaysCompletedChecklists_loadState != 'loaded'}
                        loadingMore={this.state.todaysCompletedChecklists_loadingMore}
                        currentUser={this.props.ctx.auth.user}
                    />
                    <ExpandingChecklistList
                        title={t('checklists_overdue')}
                        totalCount={dashboard.checklistSummary.overDue}
                        checklists={this.state.overdueChecklists}
                        onExpand={this.onOverdueChecklistsExpand}
                        onShowMore={this.onOverdueChecklistsShowMore}
                        openChecklist={this.openChecklist}
                        loading={this.state.overdueChecklists_loadState != 'loaded'}
                        loadingMore={this.state.overdueChecklists_loadingMore}
                        currentUser={this.props.ctx.auth.user}
                    />
                </>}
            </>);
        }

        getTicketsTab() {
            const t = i18n.t;
            let ticketsCount = 0;
            try {
                if(this.state.dashboard && this.state.dashboard.dashboard.ticketSummary)
                    ticketsCount = this.state.dashboard.dashboard.ticketSummary.myTickets;
            }
            catch (e) { /* ignore */ }
            return (
                <TicketList
                    title={t('my_tickets')}
                    totalCount={ticketsCount || 0}
                    tickets={this.state.myTickets}
                    onShowMore={this.onMyTicketsShowMore}
                    openTicket={this.openTicket}
                    onAllTicketsTapped={this.searchTickets}
                    loading={this.state.myTickets_loadState != 'loaded'}
                    loadingMore={this.state.myTickets_loadingMore}
                    currentUser={this.props.ctx.auth.user}
                    onRefresh={this.reloadTickets}
                />
            );
        }

        getNotesTab() {
            return (
                this.state.myNotes &&
                    <NotesList
                        title="Notes"
                        notes={this.state.myNotes}
                        onShowMore={this.onMyNotesShowMore}
                        openNote={this.openNote}
                        loading={this.state.myNotes_loadState != 'loaded'}
                        loadingMore={this.state.myNotes_loadingMore}
                        hasMore={this.state.myNotes_hasMore}
                        currentUser={this.props.ctx.auth.user}
                    />
            );
        }

        onClickTab = async (tabname: string): Promise<void> => {
             /*Whenever user changes tabs (to or from incident) we want to make sure we
            clear incident state and force API call. As incidents can be signed
            (thus moving from 'unsigned' to 'signed'), we could end up showing
            outdated data in the listing page if we keep using data from state.*/
            if(this.state.incidentsState){
                this.setState({incidentsState: null})
            }

            if(!this.state.myNotes){
                await this.clickNotesTab(tabname)
            }
        }

        getTabs() {
            const t = i18n.t;
            const capabs = this.props.ctx.auth.user.capabilities;
            let ticketsBadge: number | undefined;
            try {
                if(this.state.dashboard && this.state.dashboard.dashboard.ticketSummary)
                    ticketsBadge = this.state.dashboard.dashboard.ticketSummary.myTickets;
            }
            catch (e) { /* ignore */ }

            const tabs: ITabDefinition[] = [];

            if(!capabs.hideSiteManager){
                tabs.push({
                    name: 'details',
                    label: 'Details',
                    component: (
                        <div style={{ marginTop: 16 }}>
                            {this.state.relatedDataLoadState != 'loaded' &&
                                <PageLoader loading={true} />}
                            {this.state.relatedDataLoadState == 'loaded' &&
                                this.getDetailsTab()}
                        </div>
                    )
                })
            }

            if (capabs.checklists) {
                tabs.push({
                    name: 'checklists',
                    label: t('customLabel_checklists'),
                    component: (
                        <div style={{ marginTop: 16 }}>
                            {this.state.relatedDataLoadState != 'loaded' &&
                                <PageLoader loading={true} />}
                            {this.state.relatedDataLoadState == 'loaded' &&
                                this.getChecklistsTab()}
                        </div>
                    )
                });
            }

            if (capabs.tickets) {
                tabs.push({
                    name: 'tickets',
                    label: t('customLabel_tickets'),
                    badgeContent: ticketsBadge ? String(ticketsBadge) : undefined,
                    component: (
                        <div style={{ marginTop: 16 }}>
                            {this.state.relatedDataLoadState != 'loaded' &&
                                <PageLoader loading={true} />}
                            {this.state.relatedDataLoadState == 'loaded' &&
                                this.getTicketsTab()}
                        </div>
                    )
                });
            }
            if (capabs.hsFranchiseeMenu || capabs.hsMainMenu) {
                const incidentsTab = {
                    name: 'incidents',
                    label: t('customLabel_incidents'),
                    badgeContent: this.state.totalIncidents > 0 ? this.state.totalIncidents.toString() : undefined,
                    component: (
                        <div style={{ marginTop: 16 }}>
                            <Incidents
                                ctx={this.props.ctx}
                                navigate={this.props.history.push}
                                pageState={this.state.incidentsState}
                                siteId={this.state.siteId}
                                sumaryData={this.state.dashboard?.dashboard.incidentSummary}
                                onIncidentClick={this.onOpenIncident}
                            />
                        </div>
                    )
                }
                tabs.push(incidentsTab);
            }
            if (!this.state.hideNotes) {
                tabs.push({
                    name: 'notes',
                    label: 'Notes',
                    component: (
                        <div style={{ marginTop: 16 }}>
                            {this.state.relatedDataLoadState != 'loaded' &&
                                <PageLoader loading={true} />}
                            {this.state.relatedDataLoadState == 'loaded' &&
                                this.getNotesTab()}
                        </div>
                    )
                });
            }

            return (
                <TabsComponent
                    hasDetailsTab={false}
                    color="light"
                    tabs={tabs}
                    initialTabIdx={this.currentTabIndex}
                    onClickTab={this.onClickTab}/>
            );
        }

        savePageDetails = (targetPage: string) =>{
            const previousPageData = {
                pageId: 'siteDashboard',
                states: this.state,
                scrollPositionY: window.scrollY,
                searchFields: null,
                targetPage,
                lastEditedItem: null
            } as IStockedPreviousPage
            void this.props.ctx.db.savePreviousPage(previousPageData);
        }

        render() {
            const t = i18n.t;
            const user = this.props.ctx.auth.user;
            const capabs = user.capabilities;
            console.log('site dashboard state: ', this.state);
            if (this.state.loadState == 'loaded') {
                return (
                    <div>
                        <div className={this.props.classes.root}>
                            <Typography variant="h5" color="inherit">
                                {this.state.site.name}
                            </Typography>
                            <div style={{ padding: 8 }}>
                                {this.state.totalDrafts > 0 &&
                                    <Button className={this.props.classes.button}
                                        onClick={() => this.goTo('/drafts')}>
                                        <div style={{
                                            fontFamily: 'oneplaceIcons', fontSize: 20, marginRight: 8
                                        }}>
                                            <span className="icon-1place-save-draft" />
                                        </div>
                                        <Badge color="secondary"
                                            overlap="rectangular"
                                            className={this.props.classes.badgeItem}
                                            badgeContent= {this.state.totalDrafts}>
                                            {t('drafts')}
                                        </Badge>
                                    </Button>
                                }
                                {capabs.checklists
                                    && this.state.totalFavourites > 0 &&
                                    <Button className={this.props.classes.button}
                                        onClick={() => this.goTo('/favourites')}>
                                        <FavouriteIcon style={{ marginRight: 8 }} />
                                        <Badge color="secondary"
                                            overlap="rectangular"
                                            className={this.props.classes.badgeItem}
                                            badgeContent= {this.state.totalFavourites}>
                                            {t('favourites')}
                                        </Badge>
                                    </Button>
                                }
                            </div>
                        </div>
                        {this.getTabs()}
                        <div style={{ height: 100 }}></div>
                        <NewItemFAB
                            currentUser={user}
                            defaultAssignee={{
                                assignee: 'site',
                                assigneeId: this.state.siteId
                            }}
                            accessNotes={!this.state.hideNotes}
                            onNewChecklist={() => this.savePageDetails('newChecklistPage')}
                            onNewIncident={() => this.savePageDetails('newIncidentPage')}
                        />
                    </div>
                );
            }
            else {
                return (
                    <PageLoader loading={true} />
                );
            }
        }
    }
))));
