/* eslint-disable @typescript-eslint/no-misused-promises */

import * as React from "react";
import { IAppContextProp } from "../AppContext";
import { CONFIG } from "../../config";
import OfflineIcon from "@material-ui/icons/CloudOff";
import OnlineIcon from "@material-ui/icons/CloudQueue";
import IconButton from "@material-ui/core/IconButton";
import ContactSupportOutlined from "@material-ui/icons/ContactSupportOutlined";
import SettingsBackupRestoreIcon from "@material-ui/icons/SettingsBackupRestore";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import { i18n } from "../../i18n";
import Button from "@material-ui/core/Button";
import { ReadOnlyField } from "../common/ReadOnlyField";
import Snackbar from "@material-ui/core/Snackbar";
import { AuthStatus } from "../../auth/OnePlaceAuth";
import { withRouter, RouteComponentProps } from "react-router-dom";
import { logError } from "../../logging";
import { withPrompts, IPromptsProp } from "../common/PromptProvider";
import { ENV, isUseSqliteDatabase } from "../../environment";
import {
    Theme,
    createStyles,
    WithStyles,
    withStyles,
} from "@material-ui/core/styles";
import { BackupStatus } from "../../data_sources/backup/BackupManager";
import { Deploy } from "cordova-plugin-ionic";
import { ISnapshotInfo } from "cordova-plugin-ionic/dist/IonicCordova";
import { BUILD_INFO } from "../../buildinfo";

const styles = (theme: Theme) =>
    createStyles({
        root: {
            background: theme.palette.secondary.main,
        },
    });

export type ConnectionStates = "offline" | "online";

export interface IConnectionStatusProps
    extends WithStyles<typeof styles>,
        RouteComponentProps<any>,
        IAppContextProp,
        IPromptsProp {}

export interface IConnectionStatusState {
    isOnline: boolean;
    updateAvailable: boolean;
    updateStatusLabel: string;
    connectionDialogOpen: boolean;
    netStatus: ConnectionStates;
    syncStatusLabel: string;
    snackbarOpen: boolean;
    snackbarContent?: any;
    snackbarAction?: any;
    backupStatus: BackupStatus;
}

export const ConnectionStatus = withStyles(styles)(
    withRouter(
        withPrompts(
            class extends React.Component<
                IConnectionStatusProps,
                IConnectionStatusState
            > {
                netWasOffline: boolean;
                lastAuthStatus: AuthStatus;
                version: ISnapshotInfo | undefined;

                constructor(props: any) {
                    super(props);
                    this.checkForUpdates = this.checkForUpdates.bind(this);
                    this.performManualUpdate =
                        this.performManualUpdate.bind(this);
                    const status = this.getStatuses();
                    this.state = {
                        connectionDialogOpen: false,
                        updateAvailable: false,
                        updateStatusLabel: "1Place is up to date",
                        snackbarOpen: false,
                        syncStatusLabel: this.props.ctx.sync.syncStatusLabel,
                        backupStatus: this.props.ctx.backupManager.backupStatus,
                        ...status,
                    };
                    this.netWasOffline = this.props.ctx.net.isOffline;
                    this.lastAuthStatus = "unknown";
                    this.version = {
                        deploy_uuid: "",
                        versionId: "",
                        buildId: "",
                        channel: CONFIG.appFlowChannelName,
                        binary_version: CONFIG.build,
                        binaryVersion: CONFIG.build,
                        binaryVersionName: "",
                        binaryVersionCode: "",
                    };
                }

                onConnectionStatusChange = async () => {
                    const status = this.getStatuses();
                    this.setState({
                        ...status,
                    });

                    // Status-change toasts
                    if (
                        this.lastAuthStatus != "login_required" &&
                        this.props.ctx.auth.status == "login_required"
                    ) {
                        const t = i18n.t;
                        this.showSnackbar(
                            t("session_expired"),
                            <Button
                                size="small"
                                color="inherit"
                                onClick={this.onLoginClicked}
                            >
                                {t("log_in")}
                            </Button>
                        );
                    } else if (
                        this.netWasOffline &&
                        !this.props.ctx.net.isOffline &&
                        this.props.location.pathname != "/drafts"
                    ) {
                        const t = i18n.t;
                        try {
                            const hasDraft =
                                await this.props.ctx.db.isDraftExist();
                            if (hasDraft) {
                                this.showSnackbar(
                                    t("connected_with_drafts"),
                                    <Button
                                        size="small"
                                        color="inherit"
                                        onClick={() => {
                                            this.props.history.push("/drafts");
                                            this.setState({
                                                snackbarOpen: false,
                                            });
                                        }}
                                    >
                                        {t("view_drafts")}
                                    </Button>
                                );
                            }
                        } catch (e) {
                            logError(
                                e,
                                "Error loading drafts when connection came online"
                            );
                        }
                    }

                    // Offline login data warning popup
                    if (
                        this.props.ctx.auth.status == "cached_user" &&
                        this.lastAuthStatus != "cached_user"
                    ) {
                        setTimeout(this.showCacheAlert, 1000);
                    }

                    this.netWasOffline = this.props.ctx.net.isOffline;
                    this.lastAuthStatus = this.props.ctx.auth.status;
                };

                showCacheAlert = () => {
                    if (
                        this.props.ctx.auth.status == "cached_user" &&
                        this.props.ctx.sync.shouldShowCacheAlert()
                    ) {
                        const t = i18n.t;
                        this.props.prompts.showPrompt({
                            title: t("offline_data"),
                            message: t("offline_and_old_data"),
                            closeButtonLabel: t("ok"),
                        });
                    }
                };

                onSyncStatusChange = () => {
                    const syncStatusLabel = this.props.ctx.sync.syncStatusLabel;
                    this.setState({
                        syncStatusLabel,
                    });
                };

                onBackupStatusChange = () => {
                    const backupStatus =
                        this.props.ctx.backupManager.backupStatus;
                    this.setState({
                        backupStatus,
                    });
                };

                checkForUpdates = async () => {
                    const update = await Deploy.checkForUpdate();
                    console.log('checkForUpdates');
                    console.log(update);
                    this.setState({
                        updateAvailable: update.available,
                        updateStatusLabel: update.available ? 'update available' :this.state.updateStatusLabel
                    });
                };

                performManualUpdate = async () => {
                    const update = await Deploy.checkForUpdate();
                    if (update.available) {
                        await Deploy.downloadUpdate((progress) => {
                            console.log(progress);
                            this.setState({
                                updateStatusLabel:
                                    "Downloading: " + String(progress) + "%",
                            });
                        });
                        await Deploy.extractUpdate((progress) => {
                            console.log(progress);
                            this.setState({
                                updateStatusLabel:
                                    "Updating: " + String(progress) + "%",
                            });
                        });
                        await Deploy.reloadApp();
                    }
                    this.setState({
                        updateAvailable: update.available,
                        updateStatusLabel: "",
                    });
                };

                async componentDidMount() {
                    this.props.ctx.net.addListener(
                        this.onConnectionStatusChange
                    );
                    this.props.ctx.auth.addListener(
                        this.onConnectionStatusChange
                    );
                    this.props.ctx.sync.addListener(this.onSyncStatusChange);
                    if (ENV.platform == "cordova") {
                        document.addEventListener(
                            "resume",
                            this.showCacheAlert
                        );
                        if (isUseSqliteDatabase()) {
                            this.version = await Deploy.getCurrentVersion();
                            console.log('componentDidMount');
                            console.log(this.version);
                            await this.checkForUpdates();
                        }
                    }

                    this.props.ctx.backupManager.addListener(
                        this.onBackupStatusChange
                    );
                    this.onBackupStatusChange();

                    await this.onConnectionStatusChange();
                    this.onSyncStatusChange();
                }

                componentWillUnmount() {
                    this.props.ctx.net.removeListener(
                        this.onConnectionStatusChange
                    );
                    this.props.ctx.auth.removeListener(
                        this.onConnectionStatusChange
                    );
                    this.props.ctx.sync.removeListener(this.onSyncStatusChange);
                    if (ENV.platform == "cordova") {
                        document.removeEventListener(
                            "resume",
                            this.showCacheAlert
                        );
                    }

                    this.props.ctx.backupManager.removeListener(
                        this.onBackupStatusChange
                    );
                }

                getStatuses() {
                    let netStatus: ConnectionStates = "online";
                    if (this.props.ctx.net.isOffline) {
                        netStatus = "offline";
                    }
                    const apiStatus = this.props.ctx.auth.status;
                    const isOnline =
                        netStatus == "online" && apiStatus == "online";
                    return { isOnline, netStatus };
                }

                showIntercom = () => {
                    cordova.plugins.intercom.displayMessenger();
                };

                showConnectionDialog = () => {
                    this.setState({
                        connectionDialogOpen: true,
                    });
                };

                hideConnectionDialog = () => {
                    this.setState({
                        connectionDialogOpen: false,
                    });
                };

                forceOnlineEvent = () => {
                    if (ENV.platform == "cordova") {
                        cordova.fireDocumentEvent("online");
                    }
                    if (
                        this.props.ctx.auth.status == "offline" ||
                        this.props.ctx.auth.status == "cached_user"
                    ) {
                        this.props.ctx.auth.authenticateUser();
                    }
                };

                showSnackbar(content: any, action?: any) {
                    this.setState({
                        snackbarOpen: true,
                        snackbarContent: content,
                        snackbarAction: action,
                    });
                }

                hideSnackbar = () => {
                    this.setState({
                        snackbarOpen: false,
                    });
                };

                onLoginClicked = () => {
                    this.setState({ snackbarOpen: false });
                    this.props.ctx.auth.login();
                };

                render() {
                    const t = i18n.t;
                    const icon = this.state.isOnline ? (
                        <OnlineIcon />
                    ) : (
                        <OfflineIcon />
                    );
                    const apiStatus = this.props.ctx.auth.status;
                    const { classes } = this.props;
                    return (
                        <div>
                            {this.state.backupStatus == "in_progress" && (
                                <IconButton color="inherit" aria-label="Backup">
                                    <SettingsBackupRestoreIcon />
                                </IconButton>
                            )}
                            {ENV.os !== "browser" && ENV.os !== "" && (
                                <IconButton
                                    color="inherit"
                                    aria-label="Intercom"
                                    onClick={this.showIntercom}
                                >
                                    <ContactSupportOutlined />
                                </IconButton>
                            )}
                            <IconButton
                                color="inherit"
                                aria-label="Offline"
                                onClick={this.showConnectionDialog}
                            >
                                {icon}
                            </IconButton>
                            <Dialog
                                open={this.state.connectionDialogOpen}
                                onClose={this.hideConnectionDialog}
                                PaperProps={{
                                    style: { width: "95%", maxWidth: 450 },
                                }}
                            >
                                <DialogTitle>
                                    {t("connection_status")}
                                </DialogTitle>
                                <DialogContent>
                                    <ReadOnlyField
                                        label={t("network_status")}
                                        content={t(this.state.netStatus)}
                                    />
                                    {this.state.netStatus == "offline" && (
                                        <ReadOnlyField
                                            label={t("api_status")}
                                            content={t("no_connection")}
                                        />
                                    )}
                                    {this.state.netStatus == "online" && (
                                        <ReadOnlyField
                                            label={t("api_status")}
                                            content={
                                                <div
                                                    style={{
                                                        display: "flex",
                                                        justifyContent:
                                                            "space-between",
                                                        alignItems: "center",
                                                    }}
                                                >
                                                    <span>{t(apiStatus)}</span>
                                                    {(apiStatus ==
                                                        "login_required"  || apiStatus ==
                                                        "cached_user") && (
                                                        <Button
                                                            variant="contained"
                                                            onClick={
                                                                this
                                                                    .onLoginClicked
                                                            }
                                                        >
                                                            {t("log_in")}
                                                        </Button>
                                                    )}
                                                </div>
                                            }
                                        />
                                    )}
                                    <ReadOnlyField
                                        label={t("offline_data")}
                                        content={
                                            <div
                                                style={{
                                                    display: "flex",
                                                    justifyContent:
                                                        "space-between",
                                                    alignItems: "center",
                                                }}
                                            >
                                                <span>
                                                    {this.state.syncStatusLabel}
                                                </span>
                                                {this.props.ctx.sync
                                                    .syncStatus ==
                                                    "enabled" && (
                                                    <Button
                                                        variant="contained"
                                                        style={{
                                                            marginLeft: 10,
                                                        }}
                                                        onClick={() =>
                                                            this.props.ctx.sync.doSync(
                                                                {
                                                                    forceFullSync:
                                                                        true,
                                                                }
                                                            )
                                                        }
                                                    >
                                                        {t("sync_now")}
                                                    </Button>
                                                )}
                                                {this.props.ctx.sync
                                                    .syncStatus ==
                                                    "in_progress" && (
                                                    <Button
                                                        variant="contained"
                                                        style={{
                                                            marginLeft: 10,
                                                        }}
                                                        onClick={() =>
                                                            this.props.ctx.sync.cancelSync()
                                                        }
                                                    >
                                                        {t("cancel")}
                                                    </Button>
                                                )}
                                            </div>
                                        }
                                    />
                                    {ENV.os !== "browser" && ENV.os !== "" && (
                                        <React.Fragment>
                                            <ReadOnlyField
                                                label="Update Channel"
                                                content={
                                                    this.version
                                                        ? this.version.channel
                                                        : CONFIG.appFlowChannelName
                                                }
                                            />
                                            <ReadOnlyField
                                                label="Build Version"
                                                content={
                                                    <div
                                                        style={{
                                                            display: "flex",
                                                            justifyContent:
                                                                "space-between",
                                                            alignItems:
                                                                "center",
                                                        }}
                                                    >
                                                        <span>
                                                            {this.version
                                                                ? this.version
                                                                      .buildId
                                                                : CONFIG.build}
                                                        </span>
                                                        <span>
                                                            {
                                                                this.state
                                                                    .updateStatusLabel
                                                            }
                                                        </span>
                                                        {this.state
                                                            .updateAvailable ?
                                                            <Button
                                                                variant="contained"
                                                                style={{
                                                                    marginLeft: 10,
                                                                }}
                                                                onClick={() =>
                                                                    this.performManualUpdate()
                                                                }
                                                            >
                                                                Update App
                                                            </Button>
                                                        :
                                                        <Button
                                                            variant="contained"
                                                            style={{
                                                                marginLeft: 10,
                                                            }}
                                                            onClick={() =>
                                                                this.checkForUpdates()
                                                            }
                                                            >
                                                                Check for updates
                                                            </Button>
                                                        }
                                                    </div>
                                                }
                                            />
                                        </React.Fragment>
                                    )}
                                    {ENV.os !== "browser" && (
                                        <React.Fragment>
                                            <ReadOnlyField
                                                label="Build Version Date"
                                                content={
                                                    BUILD_INFO.buildDateStr
                                                }
                                            />
                                        </React.Fragment>
                                    )}
                                </DialogContent>
                                <DialogActions>
                                    <Button
                                        onClick={this.forceOnlineEvent}
                                        color="secondary"
                                    >
                                        {t("check_connection")}
                                    </Button>
                                    <Button
                                        onClick={this.hideConnectionDialog}
                                        color="primary"
                                    >
                                        {t("close")}
                                    </Button>
                                </DialogActions>
                            </Dialog>
                            <Snackbar
                                color="secondary"
                                ContentProps={{
                                    classes: {
                                        root: classes.root,
                                    },
                                }}
                                anchorOrigin={{
                                    vertical: "top",
                                    horizontal: "center",
                                }}
                                open={this.state.snackbarOpen}
                                onClose={this.hideSnackbar}
                                message={this.state.snackbarContent || ""}
                                action={this.state.snackbarAction || null}
                            />
                        </div>
                    );
                }
            }
        )
    )
);
