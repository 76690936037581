
import { IAppDataDB } from './database';
import { IApi, ScheduleType } from './api';
import { DataWithCache, IDataWithCache } from './cache';
import { IChecklistTemplates,  IChecklistTemplateList, IChecklist } from 'oneplace-components';
import { IDashboardData, IScheduleData, IDashboardChecklist, IChecklistsWithCount, IChecklistDashboardFilter, hasFilterApplied } from '../models/Dashboard';
import { User } from '../models/User';
import { formatDate } from '../utils/dates';
import { withTimeout } from '../utils';

const DEFAULT_API_TIMEOUT = 60000;

export interface IChecklistData {
    getScheduleOnlyChecklistTemplates(franchiseId: number): IDataWithCache<IChecklistTemplates>;
    getChecklistTemplates(franchiseId: number): IDataWithCache<IChecklistTemplates>;
    getChecklistTemplateList(franchiseId: number): IDataWithCache<IChecklistTemplateList>;
    getChecklistTemplate(franchiseId: number, franchiseeId: number, versionId: number,  request_type?: 'ticket' | 'checklist'): IDataWithCache<IChecklist>;
    getOverviewDashboard(franchiseId: number, checklistDashboardFilter: IChecklistDashboardFilter | null): IDataWithCache<IDashboardData>;
    getFranchiseeDashboard(franchiseId: number, franchiseeId: number, checklistDashboardFilter: IChecklistDashboardFilter | null): IDataWithCache<IDashboardData>;
    getSiteDashboard(franchiseId: number, franchiseeId: number, siteId: number, checklistDashboardFilter: IChecklistDashboardFilter | null): IDataWithCache<IDashboardData>;
    getOverviewSchedule(franchiseId: number, checklistDashboardFilter: IChecklistDashboardFilter | null, type: ScheduleType, fromId?: number): IDataWithCache<IScheduleData>;
    getFranchiseeSchedule(
        franchiseId: number, franchiseeId: number,
        checklistDashboardFilter: IChecklistDashboardFilter | null,
        type: ScheduleType, fromId?: number): IDataWithCache<IScheduleData>;
    getSiteSchedule(
        franchiseId: number, franchiseeId: number,
        siteId: number, checklistDashboardFilter: IChecklistDashboardFilter | null,
        type: ScheduleType, fromId?: number): IDataWithCache<IScheduleData>;
    getAllTodaysChecklists(): Promise<IScheduleData>;
    getTodaysChecklists(
        franchiseeId: number | null, siteId: number | null,
        checklistDashboardFilter: IChecklistDashboardFilter | null,
        howMany?: number, fromId?: number): Promise<IChecklistsWithCount>;
    getChecklistDashboardFilter(franchiseId: number): Promise<IChecklistDashboardFilter>;
    setChecklistDashboardFilter(franchiseId: number, filter: IChecklistDashboardFilter): Promise<void>;
    clearChecklistDashboardFilter(franchiseId: number): Promise<void>;
}

export class ChecklistData implements IChecklistData {

    constructor(
        public _db: IAppDataDB,
        public _api: IApi,
        public _user: User,
    ) {}

    private filterScheduleDataCacheResult(scheduleData: IScheduleData,
        checklistDashboardFilter: IChecklistDashboardFilter | null,
        pageSize: number, fromId?: number) :IScheduleData {

        const hasChecklists = scheduleData && scheduleData.checklists
        if(hasChecklists){ //we have to paginate
            let toBePaged: IDashboardChecklist[]
            let pagedChecklists: IDashboardChecklist[]

            if(hasFilterApplied(checklistDashboardFilter)){
                // @ts-ignore: Object is possibly 'null'.
                const templateIds = checklistDashboardFilter.templates.map(t => t.id)
                toBePaged = scheduleData.checklists.filter(c => templateIds.includes(c.checklistTemplateId))
            } else {
                toBePaged = scheduleData.checklists
            }

            //paginate
            if(fromId != undefined){
                const startIndex = toBePaged.findIndex(c => c.id === fromId) + 1 //we should start from the next index
                pagedChecklists = toBePaged.slice(startIndex, startIndex + pageSize)
            } else{
                pagedChecklists = toBePaged.slice(0, pageSize)
            }

            return {checklists: pagedChecklists} as IScheduleData
        }
        return scheduleData
    }

    // used to download scheduled only templates
    // this usess entityID 2
    getScheduleOnlyChecklistTemplates(franchiseId: number): DataWithCache<IChecklistTemplates> {
        const data = new DataWithCache<IChecklistTemplates>({
            strategy: 'api_first',
            cache: this._db,
            entityName: 'checklist_template_list',
            entityId: '2',
            getApiDataFn: () => this._api.getScheduleOnlyChecklistTemplates(franchiseId)
        });
        return data;
    }

    // used to show adhoc template
    getChecklistTemplates(franchiseId: number): DataWithCache<IChecklistTemplates> {
        const data = new DataWithCache<IChecklistTemplates>({
            strategy: 'api_first',
            cache: this._db,
            entityName: 'checklist_template_list',
            entityId: '1',
            getApiDataFn: () => this._api.getChecklistTemplates(franchiseId)
        });
        return data;
    }

    // used to load the template checklist for the search checklist page
    getChecklistTemplateList(franchiseId: number): DataWithCache<IChecklistTemplateList> {
        const data = new DataWithCache<IChecklistTemplateList>({
            strategy: 'api_first',
            cache: this._db,
            entityName: 'checklist_template_list_parent',
            entityId: '1',
            getApiDataFn: () => this._api.getChecklistTemplateList(franchiseId)
        });
        return data;
    }
    // large template takes long to download, sometimes even fail
    // so we change getChecklistTemplate to DB first. if the select template
    // not avialable in local storage, it will send api request
    getChecklistTemplate(franchiseId: number, franchiseeId: number, versionId: number,  request_type?: 'ticket' | 'checklist'): DataWithCache<IChecklist> {
        const data = new DataWithCache<IChecklist>({
            strategy: 'db_first',
            cache: this._db,
            entityName: 'checklist_templates',
            entityId: String(versionId),
            getApiDataFn: () => this._api.getChecklistTemplate(
                franchiseId, franchiseeId, versionId, request_type
            )
        });
        return data;
    }

    getOverviewDashboard(franchiseId: number, checklistDashboardFilter: IChecklistDashboardFilter | null): DataWithCache<IDashboardData> {
        const data = new DataWithCache<IDashboardData>({
            strategy: 'api_first',
            cache: this._db,
            entityName: 'overview_dashboard',
            entityId: '1',
            apiTimeout: 30000,
            getApiDataFn: () => this._api.getOverviewDashboardData(franchiseId, checklistDashboardFilter)
        });
        return data;
    }

    getFranchiseeDashboard(franchiseId: number, franchiseeId: number,
        checklistDashboardFilter: IChecklistDashboardFilter | null): DataWithCache<IDashboardData> {
        const data = new DataWithCache<IDashboardData>({
            strategy: 'api_first',
            cache: this._db,
            entityName: 'franchisee_dashboards',
            entityId: String(franchiseeId),
            apiTimeout: 30000,
            getApiDataFn: () => this._api.getFranchiseeDashboardData(franchiseId, franchiseeId, checklistDashboardFilter)
        });
        return data;
    }

    getSiteDashboard(franchiseId: number, franchiseeId: number, siteId: number,
        checklistDashboardFilter: IChecklistDashboardFilter | null): DataWithCache<IDashboardData> {
        const data = new DataWithCache<IDashboardData>({
            strategy: 'api_first',
            cache: this._db,
            entityName: 'site_dashboards',
            entityId: String(siteId),
            apiTimeout: 30000,
            getApiDataFn: () => this._api.getSiteDashboardData(franchiseId, franchiseeId, siteId, checklistDashboardFilter)
        });
        return data;
    }

    getOverviewSchedule(franchiseId: number, checklistDashboardFilter: IChecklistDashboardFilter | null, type: ScheduleType, fromId?: number): DataWithCache<IScheduleData> {
        const PAGE_SIZE = 10
        const data = new DataWithCache<IScheduleData>({
            strategy: 'api_first',
            cache: this._db,
            entityName: 'overview_schedule',
            entityId: '1_' + type,
            getApiDataFn: () => this._api.getOverviewSchedule(franchiseId, checklistDashboardFilter, type, PAGE_SIZE, fromId),
            filterCacheResult: (scheduleData: IScheduleData) : IScheduleData =>
                this.filterScheduleDataCacheResult(scheduleData, checklistDashboardFilter, PAGE_SIZE, fromId),
            disableCacheUpdateFromApi: hasFilterApplied(checklistDashboardFilter)//we don't update cache when filtering results
        });

        return data;
    }

    getFranchiseeSchedule(
        franchiseId: number, franchiseeId: number,
        checklistDashboardFilter: IChecklistDashboardFilter | null,
        type: ScheduleType, fromId?: number
    ): DataWithCache<IScheduleData> {
        const PAGE_SIZE = 10
        const data = new DataWithCache<IScheduleData>({
            strategy: 'api_first',
            cache: this._db,
            entityName: 'franchisee_schedules',
            entityId: `${franchiseeId}_${type}`,
            getApiDataFn: () => this._api.getFranchiseeSchedule(franchiseId,
                franchiseeId, checklistDashboardFilter, type, PAGE_SIZE, fromId),
            filterCacheResult: (scheduleData: IScheduleData) : IScheduleData =>
                this.filterScheduleDataCacheResult(scheduleData, checklistDashboardFilter, PAGE_SIZE, fromId),
            disableCacheUpdateFromApi: hasFilterApplied(checklistDashboardFilter)//we don't update cache when filtering results
        });
        return data;
    }

    getSiteSchedule(
        franchiseId: number, franchiseeId: number,
        siteId: number, checklistDashboardFilter: IChecklistDashboardFilter | null,
        type: ScheduleType, fromId?: number
    ): DataWithCache<IScheduleData> {
        const PAGE_SIZE = 10
        const data = new DataWithCache<IScheduleData>({
            strategy: 'api_first',
            cache: this._db,
            entityName: 'site_schedules',
            entityId: `${siteId}_${type}`,
            getApiDataFn: () => this._api.getSiteSchedule(franchiseId,
                franchiseeId, siteId, checklistDashboardFilter, type, PAGE_SIZE, fromId),
            filterCacheResult: (scheduleData: IScheduleData) : IScheduleData =>
                this.filterScheduleDataCacheResult(scheduleData, checklistDashboardFilter, PAGE_SIZE, fromId),
            disableCacheUpdateFromApi: hasFilterApplied(checklistDashboardFilter)//we don't update cache when filtering results
        });
        return data;
    }

    // Custom logic for caching Todays Checklists

    private async downloadTodaysChecklists() {
        const capabs = this._user.capabilities;
        if (capabs.franchiseDashboard == false
            && capabs.retailOrganisation == true
            && capabs.franchiseeId) {
                // Get todays checklists from Franchisee
                return this._api.getFranchiseeSchedule(capabs.franchiseId, capabs.franchiseeId,null, 'today', -1);
        }
        else {
            // Get todays checklists from Overview-level
            return this._api.getOverviewSchedule(capabs.franchiseId, null, 'today', -1);
        }
    }

    async getAllTodaysChecklists(forceDownload = false) {
        const todaysChecklistDateStr = await this._db.getById<string>('settings', 'todaysChecklistDate');
        const today = formatDate('iso_dateonly', new Date());
        if (!forceDownload && todaysChecklistDateStr && todaysChecklistDateStr == today) {
            try {
                const schedule = await this._db.getById<IScheduleData>('overview_schedule', 'todays_checklists');
                if (schedule && schedule.checklists) {
                    console.log('Todays checklists from cache.');
                    return schedule;
                }
            }
            catch (e) {
                console.error(e);
            }
        }
        try {
            // offline ?
            const schedule = await withTimeout(DEFAULT_API_TIMEOUT, this.downloadTodaysChecklists());
            if (schedule && schedule.checklists) {
                await this._db.setById('overview_schedule', 'todays_checklists', schedule);
                await this._db.setById('settings', 'todaysChecklistDate', today);
                return schedule;
            }
        }
        catch (e) {
            console.error(e);
        }
        throw new Error('Unable to retrieve todays checklists');
    }

    private getFilteredChecklists(
        checklists: IDashboardChecklist[],
        franchiseeId: number | null, siteId: number | null,
        checklistDashboardFilter: IChecklistDashboardFilter | null
    ) {
        let filteredChecklists: IDashboardChecklist[];
        if (siteId) {
            filteredChecklists = checklists
                .filter((cl) => cl.site && cl.site.id == siteId);
        }
        else if (franchiseeId) {
            filteredChecklists = checklists
                .filter((cl) => cl.franchisee && cl.franchisee.id == franchiseeId);
        }
        else {
            filteredChecklists = checklists;
        }

        if(hasFilterApplied(checklistDashboardFilter)){
            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            const templateIds = checklistDashboardFilter!.templates.map(t => t.id)
            filteredChecklists = filteredChecklists.filter(c => templateIds.includes(c.checklistTemplateId))
        }
        return filteredChecklists;
    }

    private getPagedChecklists(checklists: IDashboardChecklist[], howMany = 10, fromId?: number) {
        if (fromId) {
            const idx = checklists.findIndex((cl) => cl.id == fromId);
            checklists = checklists.slice(idx + 1);
        }
        if (howMany) {
            checklists = checklists.slice(0, howMany);
        }
        return checklists;
    }

    async getTodaysChecklists(franchiseeId: number | null, siteId: number | null,
        checklistDashboardFilter: IChecklistDashboardFilter | null, howMany = 10, fromId?: number): Promise<IChecklistsWithCount> {

        const franchiseId = this._user.capabilities.franchiseId;
        let checklists: IDashboardChecklist[] | null = null;
        let totalCount = -1;
        if (franchiseeId === null && siteId === null) {
            try {
                const res = await withTimeout(DEFAULT_API_TIMEOUT, this._api.getOverviewSchedule(franchiseId, checklistDashboardFilter, 'today', howMany, fromId));
                if (res && res.checklists) {
                    checklists = res.checklists;
                }
            }
            catch (e) {
                // failed to fetch
            }
            if (!checklists) {
                console.log('Overview checklists from cache.');
                const res = await this.getAllTodaysChecklists();
                if (res && res.checklists) {
                    checklists = this.getFilteredChecklists(res.checklists, franchiseeId, siteId, checklistDashboardFilter);
                    totalCount = checklists.length;
                    checklists = this.getPagedChecklists(checklists, howMany, fromId);
                }
            }
        }
        else if (franchiseeId !== null && siteId === null) {
            try {
                const res = await withTimeout(DEFAULT_API_TIMEOUT, this._api
                    .getFranchiseeSchedule(franchiseId, franchiseeId, checklistDashboardFilter, 'today', howMany, fromId));
                if (res && res.checklists) {
                    checklists = res.checklists;
                }
            }
            catch (e) {
                console.error(e);
            }
            if (!checklists) {
                console.log('Franchisee checklists from cache.');
                const res = await this.getAllTodaysChecklists();
                if (res && res.checklists) {
                    checklists = this.getFilteredChecklists(res.checklists, franchiseeId, siteId, checklistDashboardFilter);
                    totalCount = checklists.length;
                    checklists = this.getPagedChecklists(checklists, howMany, fromId);
                }
            }
        }
        else if (franchiseeId !== null && siteId !== null) {
            try {
                const res = await withTimeout(DEFAULT_API_TIMEOUT, this._api
                    .getSiteSchedule(franchiseId, franchiseeId, siteId, checklistDashboardFilter, 'today', howMany, fromId));
                if (res && res.checklists) {
                    checklists = res.checklists;
                }
            }
            catch (e) {
                console.error(e);
            }
            if (!checklists) {
                console.log('Sites checklists from cache.');
                const res = await this.getAllTodaysChecklists();
                if (res && res.checklists) {
                    checklists = this.getFilteredChecklists(res.checklists, franchiseeId, siteId, checklistDashboardFilter);
                    totalCount = checklists.length;
                    checklists = this.getPagedChecklists(checklists, howMany, fromId);
                }
            }
        }

        const result: IChecklistsWithCount = {
            checklists: checklists || [],
            totalCount
        };
        return result;
    }

    async getChecklistDashboardFilter(franchiseId: number): Promise<IChecklistDashboardFilter> {
        return await this._db.getById<IChecklistDashboardFilter>('checklist_dashboard_filter', String(franchiseId));
    }

    async setChecklistDashboardFilter(franchiseId: number, filter: IChecklistDashboardFilter): Promise<void> {
        return await this._db.setById('checklist_dashboard_filter', String(franchiseId), filter);
    }

    async clearChecklistDashboardFilter(franchiseId: number): Promise<void> {
        return await this._db.deleteById('checklist_dashboard_filter', String(franchiseId));
    }

}
