
import * as OneplaceComponents from 'oneplace-components';
import { IEnvironment } from '../../environment';
import { IAppDataDB } from '../../data_sources/database';
import { IApi } from '../../data_sources/api';
import { AssetCacheManager } from '../../data_sources/assets/AssetCacheManager';
import { AssetType, IAttachment } from 'oneplace-components';

export interface IOneplaceComponents  extends OneplaceComponents.IClient{
    initialise(): Promise<void>;
    getEnv(): OneplaceComponents.IEnvironment;
}


export class OneplaceComponentsManager implements IOneplaceComponents {

    onePlaceClient?: OneplaceComponents.IClient;
    constructor(
        private env: IEnvironment,
        private db: IAppDataDB,
        private api: IApi,
        private assetCache: AssetCacheManager
    ) {
    }

    async initialise() {
        const settings = await this.db.getSettings();
        this.onePlaceClient = new OneplaceComponents.Client({ api: this.api, settings});
    }

    getEnv(): OneplaceComponents.IEnvironment {
        return {
            platform: this.env.platform,
            os: this.env.os,
            target: this.env.target
        }
    }
    getAuthenticatedPhotoUrl(url: string): string {
        return this.api.getAuthenticatedPhotoUrl(url);
    }
    getLocalAssetUrl(type: AssetType, webUrl: string, accessToken?: string, createObjectUrl?: boolean): Promise<string> {
       return this.assetCache.getLocalAssetUrl(type, webUrl, accessToken , createObjectUrl)
    }
    getSettings(): Promise<OneplaceComponents.ISettings> {
        return this.db.getSettings();
    }

    async saveAttachment(attachment: IAttachment): Promise<any> {
        return this.api.saveAttachment(attachment)
    }

    viewAttachment(attachment: IAttachment): void {
        this.api.viewAttachment(attachment)
    }

    async deleteAttachment(attachment: IAttachment): Promise<any> {
        return this.api.deleteAttachment(attachment)
    }


}
