import { IStoredIncident } from './../../../models/Incident';
import { IChecklist, IPhoto, isLocalImage, getImageStorageType } from 'oneplace-components';
import { IImageStorage } from '../../../data_sources/imagestorage';
import { ITicket } from '../../../models/Tickets';

async function retrieveLocalPhoto(photo: IPhoto, imageStorage: IImageStorage) {
    if (isLocalImage(photo.url)) {
        const imgObjectUrl = await imageStorage.getImageObjectUrl(photo.url);
        const imgThumbnailObjectUrl = await imageStorage.getImageObjectUrl(photo.thumbnailUrl);
        photo.localObjectUrl = imgObjectUrl;
        photo.localThumbnaiObjectlUrl = imgThumbnailObjectUrl;
    }
}

export async function retrieveLocalPhotos(checklist: IChecklist, imageStorage: IImageStorage) {
    for (const group of checklist.groups) {
        if (group.photos) {
            for (const groupPhoto of group.photos) {
                try {
                    await retrieveLocalPhoto(groupPhoto, imageStorage);
                } catch (e) {
                    console.error(e)
                    console.log(groupPhoto)
                }
            }
        }
        for (const field of group.fields) {
            if (field.photos) {
                for (const fieldPhoto of field.photos) {
                    try {
                        await retrieveLocalPhoto(fieldPhoto, imageStorage);
                    } catch (e) {
                        console.error(e)
                        console.log(fieldPhoto)
                    }
                }
            }
            if (field.dataGrid && field.dataGrid.values && field.dataGrid.values.length) {
                for (const row of field.dataGrid.values) {
                    for (const gridField of row) {
                        if (gridField.photos) {
                            for (const gridPhoto of gridField.photos) {
                                try {
                                    await retrieveLocalPhoto(gridPhoto, imageStorage);
                                } catch (e) {
                                    console.error(e)
                                    console.log(gridPhoto)
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

async function purgeLocalPhoto(photo: IPhoto, imageStorage: IImageStorage) {
    if (isLocalImage(photo.url)) {
        await imageStorage.removeImage(photo.thumbnailUrl);
        await imageStorage.removeImage(photo.url);
    }
}

export async function purgeLocalPhotos(checklist: IChecklist, imageStorage: IImageStorage) {
    for (const group of checklist.groups) {
        if (group.photos) {
            for (const groupPhoto of group.photos) {
                await purgeLocalPhoto(groupPhoto, imageStorage);
            }
        }
        for (const field of group.fields) {
            if (field.photos) {
                for (const fieldPhoto of field.photos) {
                    await purgeLocalPhoto(fieldPhoto, imageStorage);
                }
            }
            if (field.dataGrid && field.dataGrid.values && field.dataGrid.values.length) {
                for (const row of field.dataGrid.values) {
                    for (const gridField of row) {
                        if (gridField.photos) {
                            for (const gridPhoto of gridField.photos) {
                                purgeLocalPhoto(gridPhoto, imageStorage);
                            }
                        }
                    }
                }
            }
        }
    }
}
export async function retrieveTicketLocalPhotos(ticket: ITicket, imageStorage: IImageStorage) {
    for (const photo of ticket.attachments) {
        try {
            await retrieveLocalPhoto(photo, imageStorage);
        } catch (e) {
            console.error(e)
            console.log(photo)
        }
    }
}
export async function purgeTicketLocalPhotos(ticket: ITicket, imageStorage: IImageStorage) {
    for (const photo of ticket.attachments) {
        await purgeLocalPhoto(photo, imageStorage);
    }
}

export async function purgeIncidentLocalPhotos(attachments: IPhoto[], imageStorage: IImageStorage) {
    for (const photo of attachments) {
        await purgeLocalPhoto(photo, imageStorage);
    }
}

export async function retrieveIncidentLocalPhotos(incident: IStoredIncident, imageStorage: IImageStorage) {
    for (const attachment of incident.attachments) {
        try {
            await retrieveLocalPhoto(attachment, imageStorage);
        } catch (e) {
            console.error(e)
            console.log(attachment)
        }
    }
}

function exportLocalPhoto(photo: IPhoto): number[] {
    const ids: number[]=[]
    if (isLocalImage(photo.url)) {
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        const [, imageId] = getImageStorageType(photo.url);
        ids.push(Number(imageId));
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        const [, thumbImageId] = getImageStorageType(photo.thumbnailUrl);
        ids.push(Number(thumbImageId));
    }
    return ids;
}

export function exportLocalPhotos(checklist: IChecklist): number[] {
    let ids: number[]=[]
    for (const group of checklist.groups) {
        if (group.photos) {
            for (const groupPhoto of group.photos) {
                ids = ids.concat(exportLocalPhoto(groupPhoto));
            }
        }
        for (const field of group.fields) {
            if (field.photos) {
                for (const fieldPhoto of field.photos) {
                    ids = ids.concat(exportLocalPhoto(fieldPhoto));
                }
            }
            if (field.dataGrid && field.dataGrid.values && field.dataGrid.values.length) {
                for (const row of field.dataGrid.values) {
                    for (const gridField of row) {
                        if (gridField.photos) {
                            for (const gridPhoto of gridField.photos) {
                                ids = ids.concat( exportLocalPhoto(gridPhoto));
                            }
                        }
                    }
                }
            }
        }
    }
    return ids;
}

export  function exportTicketLocalPhotos(ticket: ITicket): number[] {
    let ids: number[]=[]
    for (const photo of ticket.attachments) {
        ids = ids.concat(exportLocalPhoto(photo));
    }
    return ids;
}
export  function exportAttachmentLocalPhotos(attachments: IPhoto[]): number[] {
    let ids: number[]=[]
    for (const photo of attachments) {
        ids = ids.concat(exportLocalPhoto(photo));
    }
    return ids;
}
