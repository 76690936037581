import { getFranchiseeIdBySite } from 'oneplace-components';
import { Card, CardContent, Button, createStyles, Theme, WithStyles, withStyles } from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search';
import * as React from 'react';
import { IAppProviderContext } from '../AppContext';
import { IIncidentSearchParams, IIncidentsListEntry, IIncidentsListEntryWrapper } from '../../models/Incident';
import { FranchiseData } from '../../data_sources/FranchiseData';
import { i18n } from '../../i18n';
import { IncidentPrompt } from './IncidentPrompt';
import { purgeIncidentLocalPhotos } from '../checklists/utils/photos';
import { IncidentsAccordion } from './IncidentsAccordion';
import { IncidentSummaryData } from '../../models/Dashboard';

const styles = (_theme: Theme) => createStyles({
    badgeRoot: {
        marginTop: 14
    },
    typographyRoot: {
        marginLeft: 30
    },
    listItemTextRoot: {
        padding: '0 16px'
    }
});

interface IncidentsProps extends WithStyles<typeof styles>{
    ctx: IAppProviderContext;
    franchiseeId?: number;
    siteId?: number;
    pageState: IncidentsState | null;
    navigate: (to: string) => boolean;
    sumaryData: IncidentSummaryData | undefined
    onIncidentClick: (state: IncidentsState) => void
}

const INCIDENT_SEARCH_URL = '/incidents';
const PAGE_SIZE = 10
type SignatureStatus = 'signed' | 'unsigned'
export type IncidentsState = {
    unsignedIncidents: IIncidentsListEntryWrapper[],
    hasMoreUnsignedIncidents: boolean,
    unsignedExpanded: boolean,

    signedIncidents: IIncidentsListEntryWrapper[],
    hasMoreSignedIncidents: boolean,
    signedExpanded: boolean
}


const IncidentsComponent = (props: IncidentsProps): JSX.Element => {

    const {ctx, navigate} = props
    const franchiseData = new FranchiseData(ctx.db, ctx.api, ctx.auth.user)
    const t = i18n.t

    //unsigned
    const [unsignedIncidents, setUnsignedIncidents] = React.useState<IIncidentsListEntryWrapper[]>();
    const [hasMoreUnsignedIncidents, setHasMoreUnsignedIncidents] = React.useState<boolean>(false);
    const [loadingMoreUnsigned, setLoadingMoreUnsigned] = React.useState<boolean>(false);
    const [unsignedExpanded, setUnsignedExpanded] = React.useState<boolean>(props.pageState?.unsignedExpanded || false);

    //signed
    const [signedIncidents, setSignedIncidents] = React.useState<IIncidentsListEntryWrapper[]>();
    const [hasMoreSignedIncidents, setHasMoreSignedIncidents] = React.useState<boolean>(false);
    const [loadingMoreSigned, setLoadingMoreSigned] = React.useState<boolean>(false);
    const [signedExpanded, setSignedExpanded] = React.useState<boolean>(props.pageState?.signedExpanded || false);
    
    const [promptState, setPromptState] = React.useState<any>({});

    //helper method to make code cleaner
    const setHasMoreIncidents = (hasMore: boolean, status: SignatureStatus) => {
        if(status === 'signed')
            setHasMoreSignedIncidents(hasMore)
        else
            setHasMoreUnsignedIncidents(hasMore)
    }

    //helper method to make code cleaner
    const setLoadingMoreIncidents = (loadingMore: boolean, status: SignatureStatus) => {
        if(status === 'signed')
            setLoadingMoreSigned(loadingMore)
        else
            setLoadingMoreUnsigned(loadingMore)
    }

    const setIncidents = (incidents: IIncidentsListEntryWrapper[], status: SignatureStatus) => {
        if(status === 'signed')
            setSignedIncidents(incidents)
        else
            setUnsignedIncidents(incidents)
    }

    const constructSearchParams = (status :SignatureStatus): IIncidentSearchParams => {
        const searchParams = {
            //name: '',
            signed: status === 'signed',
            franchiseeId: props.franchiseeId,
            siteId: props.siteId,
            //status: 100,
        } as IIncidentSearchParams;
        return searchParams;
    }

    const fetchIncidentsApi = 
        async (fromId: number, signatureStatus :SignatureStatus): 
        Promise<IIncidentsListEntryWrapper[]> => {
        const searchParams = constructSearchParams(signatureStatus);
        try {
            const incidentsResponse = await ctx.api.searchIncidents(
                ctx.auth.user.capabilities.franchiseId,
                props.franchiseeId || -1,
                fromId,
                searchParams
            );
            return incidentsResponse            
        } catch(error) {
            console.log('#### error: ', error);
        } 
        return []
    }

    const goToIncident = async (incidentDetails: IIncidentsListEntry) => {

        props.onIncidentClick({
            unsignedIncidents,
            hasMoreUnsignedIncidents,
            unsignedExpanded,
            signedIncidents,
            hasMoreSignedIncidents,
            signedExpanded
        } as IncidentsState)// send current state to be saved

        const draftsNumber = await ctx.db.searchDraftNumbersByIncidentIdAssignee(incidentDetails.id, incidentDetails.site.id ? incidentDetails.site.id : -1);
        const sites = await franchiseData.getSites(ctx.auth.user.capabilities.franchiseId).getData();
        const siteFranchiseeId = getFranchiseeIdBySite(sites, incidentDetails.site.id);

        // 20201116, there was a bug: drafts are not linked to incident search result, every time you edit existing incident, it will create
        // a new draft. Some users end up with multiple drafts for the selected incident sitting in their draft page.
        if (draftsNumber > 1) {
            setPromptState({
                show: true,
                title: `${incidentDetails.incidentNo}: ${incidentDetails.name}`,
                message: t('draft_incident_exists_multiple_drafts'),
                closeButtonLabel: t('cancel'),
                actions: [{
                    label: t('open_draft_list'),
                    onClick: () => {
                        navigate('/drafts');
                    }},
                ],
                onClose: () => {
                    setPromptState({show:false})
                }
            });
        } else if (draftsNumber == 1) {
            // After client moving over to the new version, those old duplicate drafts will be cleaned up.
            // There should be only one draft for selected incident
            const existingId = await ctx.db.searchDraftByIncidentIdAssignee(incidentDetails.id, incidentDetails.site.id ? incidentDetails.site.id : -1);
            setPromptState({
                show: true,
                title: `${incidentDetails.incidentNo}: ${incidentDetails.name}`,
                message: t('draft_incident_exists_single_draft'),
                closeButtonLabel: t('cancel'),
                actions: [
                    {
                        label: t('delete_draft'),
                        onClick: async () => {
                            const draftIncident = await ctx.db.loadIncident(existingId);
                            if (draftIncident) {
                                await purgeIncidentLocalPhotos(draftIncident.attachments, ctx.imageStorage);
                            }
                            await ctx.db.removeDraftIncidentByIncidentId(incidentDetails.id);
                            navigate(
                                `/incidents/edit?incidentId=${incidentDetails.id}&franchiseeId=${siteFranchiseeId}`
                            );
                        }
                    },
                    {
                        label: t('open_draft'),
                        onClick: () => {
                            navigate(
                                `/incidents/edit?localId=${existingId}`
                            );
                        }
                    },
                ],
                onClose: () => {
                    setPromptState({show:false})
                }
            });
        } else {
            navigate(
                `/incidents/edit?incidentId=${incidentDetails.id}&franchiseeId=${siteFranchiseeId}`
            );
        }
    }

    const paginate = async (signStatus: SignatureStatus) => {
        const incidents = signStatus === 'signed' ? signedIncidents : unsignedIncidents

        if(!incidents) return

        const lastIncident = incidents.slice(-1).pop();
        if (!lastIncident) return

        try {
            setLoadingMoreIncidents(true, signStatus);
            const moreIncidents = await fetchIncidentsApi(lastIncident.incident.id, signStatus)
            if (moreIncidents) {
                setIncidents([
                    ...incidents,
                    ...moreIncidents,
                ], signStatus);
            }
            setLoadingMoreIncidents(false, signStatus);
            setHasMoreIncidents(moreIncidents.length === PAGE_SIZE, signStatus)
        } catch(error) {
            console.log('#### error', error);
        }
    }

    React.useEffect(() => {
        if(props.pageState){
            const restoredState = props.pageState
            //signed
            setSignedIncidents(restoredState.signedIncidents)
            setHasMoreSignedIncidents(restoredState.hasMoreSignedIncidents)
            setSignedExpanded(restoredState.signedExpanded)
            //unsigned
            setUnsignedIncidents(restoredState.unsignedIncidents)            
            setHasMoreUnsignedIncidents(restoredState.hasMoreUnsignedIncidents)
            setUnsignedExpanded(restoredState.unsignedExpanded)
        } else {
            void fetchIncidentsApi(0, 'signed')
            .then(incidentsResponse => {
                setSignedIncidents(incidentsResponse)
                setHasMoreSignedIncidents(incidentsResponse.length === PAGE_SIZE)
            })
            void fetchIncidentsApi(0, 'unsigned')
            .then(incidentsResponse => {
                setUnsignedIncidents(incidentsResponse)
                setHasMoreUnsignedIncidents(incidentsResponse.length === PAGE_SIZE)
            })
        }        
    }, []);

    return (
        <React.Fragment>

            <Card>
                <CardContent style={{ textAlign: 'right', padding: 8 }}>
                    <Button
                        color="primary"
                        onClick={() => {
                            navigate(INCIDENT_SEARCH_URL);
                        }}>
                        <SearchIcon style={{ marginRight: 8 }} />
                        {`All ${t('customLabel_incidents')}`}
                    </Button>
                </CardContent>
            </Card> 

            <IncidentsAccordion 
                summaryTitle={`Unsigned ${t('customLabel_incidents')}`}
                notFoundMessage={`No Unsigned ${t('customLabel_incidents')} found`}
                incidents={unsignedIncidents}
                hasMoreIncidents={hasMoreUnsignedIncidents}
                loadingMoreIncidents={loadingMoreUnsigned}
                onClickLoadMore={() => paginate('unsigned')}
                onClickIncident={goToIncident}
                incidentCount={props.sumaryData?.unsigned}
                defaultExpanded={unsignedExpanded}
                onSwitchExpand={(expanded) => setUnsignedExpanded(expanded)}
            /> 

            <IncidentsAccordion 
                summaryTitle={`Other ${t('customLabel_incidents')}`}
                notFoundMessage={`No Other ${t('customLabel_incidents')} found`}
                incidents={signedIncidents}
                hasMoreIncidents={hasMoreSignedIncidents}
                loadingMoreIncidents={loadingMoreSigned}
                onClickLoadMore={() => paginate('signed')}
                onClickIncident={goToIncident}
                incidentCount={props.sumaryData?.signed}
                defaultExpanded={signedExpanded}
                onSwitchExpand={(expanded) => setSignedExpanded(expanded)}
            /> 

            <IncidentPrompt {...promptState} />
        </React.Fragment>
    )
}

export const Incidents = withStyles(styles)(IncidentsComponent)