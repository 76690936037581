/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
import { logError } from '../../logging';
import { UserFacingError } from '../../errors/UserFacingError';
import { NetworkError } from '../../errors/NetworkError';
import { i18n } from '../../i18n';

export function postData(url: string, headers: any, data: any, timeout = 0) {
    return new Promise<[number, any]>((resolve, reject) => {
        // We have to use XHR because fetch() sets the origin header to null
        // in cordova on android, which causes a 403 in the API
        const xhr = new XMLHttpRequest();
        xhr.onreadystatechange = () => {
            if (xhr.readyState !== 4) return;

            if (xhr.status  >= 200 && xhr.status < 400) {
                resolve([xhr.status, xhr.response]);
            } else {
                let message
                if (xhr.responseType === 'text' && xhr.responseText) {
                    logError(`${xhr.status} - ${xhr.responseText}`)
                    message = xhr.responseText
                } else if (xhr.responseType === 'json' && xhr.response) {
                    logError(`${xhr.status} - ${xhr.response.data}`)
                    message = xhr.response.data
                }
                const response = {
                    success: false,
                    data: message
                }
                resolve([xhr.status, response]);
            }
        };
        xhr.open('POST', url);
        xhr.timeout = timeout;
        xhr.ontimeout = (e) => {
            logError(e, 'request timed out');
            reject(new UserFacingError(i18n.t('operation_timeout')));
        };

        xhr.onerror  = (e) => {
            logError(e, 'on error in POST XMLHttpRequest ' + url);
            // most likely a network error
            reject(new NetworkError('Error sending request'));
        };

        Object.keys(headers).forEach((header) => {
            xhr.setRequestHeader(header, headers[header]);
        });
        xhr.responseType = 'json';
        xhr.send(data);
    });
}

export function putData(url: string, headers: any, data: any, timeout = 0) {
    return new Promise<[number, any]>((resolve, reject) => {
        // We have to use XHR because fetch() sets the origin header to null
        // in cordova on android, which causes a 403 in the API
        const xhr = new XMLHttpRequest();
        xhr.onreadystatechange = () => {
            if (xhr.readyState === 4) {
                resolve([xhr.status, xhr.response]);
            }
        };
        xhr.open('PUT', url);
        xhr.timeout = timeout;
        xhr.ontimeout = (e) => {
            logError(e, 'request timed out');
            reject(new UserFacingError(i18n.t('operation_timeout')));
        };

        xhr.onerror  = (e) => {
            logError(e, 'on error in PUT XMLHttpRequest ' + url);
            // most likely a network error
            reject(new NetworkError('Error sending request'));
        };

        Object.keys(headers).forEach((header) => {
            xhr.setRequestHeader(header, headers[header]);
        });
        xhr.responseType = 'json';
        xhr.send(data);
    });
}
