import SqliteDB, { ISqliteDBConfig } from '../sqlite';
import { DatabaseError } from '../../../errors/DatabaseError';
import { ITokenDB, TokenDB } from '..';
import { IIDTokens } from '../../../models/IDTokens';
import { User } from '../../../models/User';
import Dexie from 'dexie';
import { logError } from '../../../logging';
import IRegistration from '../../../components/firebase/registration';

export class TokenSqliteDB implements ITokenDB {

    db!: SqliteDB;
    config: ISqliteDBConfig = {
        version: 1, // current schema/database version
        onVersionUpdate: (prevVersion: number) => { // migrate versions
            return new Promise((resolve, reject) => {
                switch(prevVersion) {
                    case 0:
                        // initialise
                        this.db.sqlBatch(
                            [
                                'CREATE TABLE IF NOT EXISTS "_version" (id TEXT PRIMARY KEY UNIQUE, version INTEGER)',
                                [ 'INSERT INTO _version (id, version) VALUES (?1,?2)', ['version', 0] ],
                                'CREATE TABLE IF NOT EXISTS "tokens" (id TEXT PRIMARY KEY UNIQUE, data TEXT)',
                                'CREATE TABLE IF NOT EXISTS "users" (id TEXT PRIMARY KEY UNIQUE, data TEXT)',
                            ],
                            () =>{
                                console.log('version 1 migration completed.');
                                resolve(1);
                            },
                            (error: any) =>{
                                console.log('version 1 migration error: ' + error.message);
                                reject()
                        });
                    break;
                }
            });
        }
    }
    async initialise() {
        this.db = new SqliteDB('OnePlace_tokens', this.config);

        const successful = await this.db.upgradeDatabase()

        return new Promise<void>((resolve, reject) => {
            if(successful) {
                console.log('OnePlace_tokens database create/upgraded')
                this.db.executeSql('SELECT version FROM _version WHERE id = ?', ['dexie'])
                .then((dbVersionResult: any) => {
                    if (dbVersionResult.rows.length === 0) {
                        return true
                    } else{
                        console.log('OnePlace_tokens no migration necessary')
                        return false
                    }
                }).then((checkDb: boolean) => {
                    if (checkDb) {
                        Dexie.getDatabaseNames()
                        .then((databases: any) => {
                            // console.log('DEXIE');
                            // console.log(databases)
                            if(databases.includes('OnePlace_tokens')) {
                                const oldDb = new TokenDB();
                                oldDb.initialise()
                                .then(() => {
                                    // console.log('DEXIE OnePlace_tokens');
                                    oldDb.retrieveTokens()
                                    .then((token) =>{
                                        return this.storeTokens(token)
                                    }).then(() => {
                                        // console.log('DEXIE users');
                                        const putUsers = (key: string) => {
                                            return new Promise<void>((userResolve, _userReject) => {
                                                oldDb.retrieveUser(key)
                                                .then((user: any) => {
                                                    if (user)
                                                        return this.storeUser(user)
                                                    else
                                                        return ;
                                                })
                                                .then((_result: any) => {
                                                    userResolve()
                                            });
                                            })
                                        }
                                        const promises: any[] = [];
                                        return oldDb.db.table('users').toCollection().primaryKeys()
                                        .then((userKey) => {
                                            userKey.forEach((key) => {
                                                promises.push(putUsers(key.toString()))
                                            })
                                            return Promise.all(promises)
                                        });
                                    }).then(() => {
                                        this.updateDexieVersion()
                                        .then((_result_1: any) => {
                                            console.log(this.db.dbName + ' migration completed');
                                            resolve()
                                        }).catch((err: any) => {
                                            logError(err);
                                            // resolve so user can conitinue working
                                            resolve()
                                        });
                                    })

                                })
                            } else{
                                this.updateDexieVersion()
                                .then((_result_1: any) => {
                                    console.log(this.db.dbName + ' no migration neccessary');
                                    resolve();
                                }).catch((err: any) => {
                                    logError(err);
                                    // resolve so user can conitinue working
                                    resolve()
                                });
                            }
                        })
                    } else {
                        resolve()
                    }
                })
            } else {
                reject(new DatabaseError('Database exists'))
            }
        })
    }

    updateDexieVersion() {
        return this.db.executeSql('INSERT INTO _version (id, version) VALUES (?1,?2)', ['dexie',1])
    }

    async retrieveTokens() {
        const tokens: IIDTokens = await this.db.get('tokens','user1');
        return tokens;
    }

    async storeTokens(tokens: IIDTokens) {
        await this.db.put('tokens', tokens, 'user1');
    }

    async clearTokens() {
        await this.db.executeSql('UPDATE tokens SET data = ? WHERE id = ?', [null, 'user1'])
        .then((result: any) => {
            console.log('insertId: ' + result.insertId);
            console.log('rowsAffected: ' + result.rowsAffected);
        }).catch((err: any) => {
            console.log(err)
            throw new Error(err)
        })
    }

    async retrieveUser(userId: string) {
        const user: User = await this.db.get('users',userId);
        return user;
    }

    async storeUser(user: User) {
        await this.db.put('users', user, user.id);
    }

    async retrieveRegistration(): Promise<IRegistration> {
        const tokens: IRegistration = await this.db.get('tokens','registration');
        return tokens;
    }
    async storeRegistration(registration: IRegistration) {
        await this.db.put('tokens', registration, 'registration');
    }
}
