
import * as React from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { withStyles, Theme, createStyles, WithStyles } from '@material-ui/core/styles';
import AddIcon from '@material-ui/icons/Add';
import Drawer from '@material-ui/core/Drawer';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import { IAssignment } from 'oneplace-components';
import { createQueryString } from '../../utils';
import { i18n } from '../../i18n';
import { User } from '../../models/User';
import Fab from '@material-ui/core/Fab';

const styles = (theme: Theme) => createStyles({
    fabBar: {
        position: 'fixed',
        bottom: 0,
        left: 0,
        right: 0
    },
    fabContainer: {
        maxWidth: 840,
        margin: 'auto',
        position: 'relative'
    },
    fab: {
        position: 'absolute',
        bottom: 20,
        right: 20
    },
    drawerContainer: {
        maxWidth: 800,
        width: '100%',
        margin: 'auto',
        paddingTop: 30,
        paddingBottom: 30
    },
    drawerButtonContainer: {
        textAlign: 'center',
        paddingBottom: 16
    },
    drawerTitle: {
        paddingLeft: 20,
        marginBottom: 20
    },
    drawerButton: {
        width: 64,
        height: 64
    },
    drawerButtonIcon: {
        width: 48,
        height: 48,
        fontFamily: 'oneplaceIcons',
        fontSize: 42,
        color: theme.palette.secondary.main
    }
});

export interface INewItemFABProps extends WithStyles<typeof styles>, RouteComponentProps<any> {
    defaultAssignee?: IAssignment;
    currentUser: User;
    accessNotes: boolean;
    onNewChecklist?: (() => void);
    onNewIncident?: (() => void);
}

export interface INewItemFABState {
    drawerOpen: boolean;
}

export const NewItemFAB = withStyles(styles)(withRouter(
    class extends React.Component<INewItemFABProps, INewItemFABState> {

    constructor(props: any) {
        super(props);

        this.state = {
            drawerOpen: false
        };
    }

    drawerOpen(open: boolean) {
        this.setState({
            drawerOpen: open
        });
    }

    onNewChecklistClick = () => {
        if(this.props.onNewChecklist){
            this.props.onNewChecklist()
        }
        let url = '/checklists/new';
        if (this.props.defaultAssignee) {
            url += '?' + createQueryString(this.props.defaultAssignee);
        }
        this.props.history.push(url);
    }

    onNewTicketClick = () => {
        if (this.props.defaultAssignee) {
            const siteOnlyRole = this.props.currentUser.capabilities.retailOrganisation;
            if (this.props.defaultAssignee.assignee == 'franchisee' && siteOnlyRole){
                // allow site only organisation select site for new ticket
                this.props.history.push('/tickets/new');
            }else{
                let url = '/tickets/edit';
                if (this.props.defaultAssignee) {
                    url += '?' + createQueryString(this.props.defaultAssignee);
                }
                this.props.history.push(url);
            }
        }
        else {
            this.props.history.push('/tickets/new');
        }
    }

    onNewNoteClick = () => {
        let url = '/notes/new';
        if (this.props.defaultAssignee) {
            url += '?' + createQueryString(this.props.defaultAssignee);
        }
        this.props.history.push(url);
    }

    onNewHazardClick = () => {
        let url = '/hazards/new';
        if (this.props.defaultAssignee) {
            url += '?' + createQueryString(this.props.defaultAssignee);
        }
        this.props.history.push(url);
    }


    onNewIncidentClick = () => {
        if(this.props.onNewIncident){
            this.props.onNewIncident()
        }
        let url = '/incidents/new';
        if (this.props.defaultAssignee && this.props.defaultAssignee.assignee == 'site') {
            url = '/incidents/edit';
            url += '?' + createQueryString({ siteId: this.props.defaultAssignee.assigneeId });
        }
        this.props.history.push(url);
    }

    render() {
        const t = i18n.t;
        const capabs = this.props.currentUser.capabilities;
        return (<>
            <div className={this.props.classes.fabBar}>
                <div className={this.props.classes.fabContainer}>
                    <Fab color="secondary" aria-label="add" className={this.props.classes.fab}
                        onClick={() => this.drawerOpen(true)}>
                        <AddIcon />
                    </Fab>
                </div>
            </div>
            <Drawer
                anchor="bottom"
                open={this.state.drawerOpen}
                onClose={() => this.drawerOpen(false)}
                >
                <div
                    tabIndex={0}
                    role="button"
                    className={this.props.classes.drawerContainer}
                >
                    <Typography variant="h6" className={this.props.classes.drawerTitle}>New</Typography>
                    <Grid container justifyContent="space-around" spacing={0}>
                        {capabs.checklists &&
                            <Grid item xs={3} className={this.props.classes.drawerButtonContainer}
                                onClick={this.onNewChecklistClick}>
                                <IconButton className={this.props.classes.drawerButton}>
                                    <div className={this.props.classes.drawerButtonIcon}>
                                        <span className="icon-1place-checklist" />
                                    </div>
                                </IconButton>
                                <Typography variant="subtitle1">
                                    {t('customLabel_checklist')}
                                </Typography>
                            </Grid>}
                        {capabs.tickets &&
                            <Grid item xs={3} className={this.props.classes.drawerButtonContainer}
                                onClick={this.onNewTicketClick}>
                                <IconButton className={this.props.classes.drawerButton}>
                                    <div className={this.props.classes.drawerButtonIcon}>
                                        <span className="icon-1place-tickets" />
                                    </div>
                                </IconButton>
                                <Typography variant="subtitle1">
                                    {t('customLabel_ticket')}
                                </Typography>
                            </Grid>}
                        {capabs.customButtonType === 'note' && this.props.accessNotes &&
                            <Grid item xs={3} className={this.props.classes.drawerButtonContainer}
                                onClick={this.onNewNoteClick}>
                                <IconButton className={this.props.classes.drawerButton}>
                                    <div className={this.props.classes.drawerButtonIcon}>
                                        <span className="icon-1place-notes" />
                                    </div>
                                </IconButton>
                                <Typography variant="subtitle1">
                                    Note
                                </Typography>
                            </Grid>
                        }
                        {capabs.customButtonType === 'hazard' && 
                            (capabs.hsFranchiseeMenu || capabs.hsMainMenu) &&
                            capabs.updateHazard &&
                                <Grid item xs={3} className={this.props.classes.drawerButtonContainer}
                                    onClick={this.onNewHazardClick}>
                                    <IconButton className={this.props.classes.drawerButton}>
                                        <div className={this.props.classes.drawerButtonIcon}>
                                            <span className="icon-1place-hazard" />
                                        </div>
                                    </IconButton>
                                    <Typography variant="subtitle1">
                                    {t('customLabel_hazard')}
                                    </Typography>
                                </Grid>
                        }
                        {(capabs.hsFranchiseeMenu || capabs.hsMainMenu) &&
                            <Grid item xs={3} className={this.props.classes.drawerButtonContainer}
                                onClick={this.onNewIncidentClick}>
                                <IconButton className={this.props.classes.drawerButton}>
                                    <div className={this.props.classes.drawerButtonIcon}>
                                        <span className="icon-1place-health-and-safety" />
                                    </div>
                                </IconButton>
                                <Typography variant="subtitle1">
                                    {t('customLabel_incident')}
                                </Typography>
                            </Grid>
                        }
                        <Grid item xs={3} />
                        <Grid item xs={3} />
                    </Grid>
                </div>
            </Drawer>
        </>);
    }

}));
