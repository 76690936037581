import * as React from 'react';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Avatar from '@material-ui/core/Avatar';
import { Theme, createStyles, WithStyles, withStyles } from '@material-ui/core/styles';
import { formatDate } from '../../utils/dates';
import { User } from '../../models/User';
import { i18n } from '../../i18n';
import { PageLoader } from 'oneplace-components';
import { IDashboardNote} from '../../models/Dashboard';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import { OnePlaceIcon } from '../common/OnePlaceIcon';

const styles = (_theme: Theme) => createStyles({
    badgeRoot: {
        paddingLeft: 40
    },
    badgeStyle: {
        top: 0,
        left: 0
    },
    listItemTextRoot: {
        padding: '0 16px'
    }
});

export interface INotesListProps extends WithStyles<typeof styles> {
    title: string;
    currentUser: User;
    notes: IDashboardNote[];
    openNote?: (note: IDashboardNote) => void;
    onExpand?: () => void;
    onShowMore?: () => void;
    onClickTab?: () => void;
    loading?: boolean;
    loadingMore?: boolean;
    hasMore?: boolean;
}

export const NotesList = withStyles(styles)((props: INotesListProps) => {
    const t = i18n.t;

    const noteSiteInfoRender = (site?: {id: number; name: string}) => {
        return (site && t('customLabel_site') + ':' + site.name);
    };
    return (
        <Card>
            <CardContent style={{ padding: 8 }}>
                {props.loading == true &&
                    <PageLoader loading={true} paddingTop={0} />}
                {props.loading != true && props.notes && (props.notes.length == 0) &&
                    <Typography style={{ textAlign: 'center' }}>{t('no_notes')}</Typography>
                }
                {props.loading != true && props.notes && props.notes.length > 0 &&
                    <List style={{ width: '100%'}} disablePadding={true}>
                        {props.notes.map((note, idx) => {
                            return (
                                <ListItem key={idx}>
                                    <Avatar>
                                        <OnePlaceIcon icon="notes" />
                                    </Avatar>
                                    <ListItemText classes={{ root: props.classes.listItemTextRoot }}
                                        primary={'Subject: ' + note.subject}
                                        secondary={<span>
                                            {noteSiteInfoRender(note.site)} <br />
                                            Id: {note.id} <br />
                                            Author: {note.author} <br />
                                            Date: {formatDate('display_date', note.date, props.currentUser.capabilities.dateFormat)} <br />
                                            Content: {note.text} <br />
                                        </span>}
                                    />
                                    <div>
                                        {!note.readOnly &&
                                        <Button variant="contained" color="default"
                                            onClick={() => props.openNote!(note)}>
                                            {t('edit')}
                                        </Button>
                                        }
                                    </div>
                                </ListItem>
                            );
                        })}
                        {props.notes && <div style={{ textAlign: 'center' }}>
                            {!props.loadingMore && props.hasMore &&
                                <Button
                                    variant="contained"
                                    onClick={props.onShowMore}
                                >{t('show_more')}</Button>}
                             {props.loadingMore &&
                                <Button
                                    variant="contained"
                                    disabled={true}
                                >{t('loading')}</Button>}
                        </div>}
                    </List>
                }
            </CardContent>
        </Card>
    );
});
