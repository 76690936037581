
import { IStoredChecklist, IStoredChecklistMeta, ISites, ISite, IPerson, IPersonType } from 'oneplace-components';
import { ISummaryData } from '../../../models/Dashboard';
import Dexie, { IndexableType } from 'dexie';
import { exportDB, ExportOptions, importInto } from 'dexie-export-import';
import { getDefaultSettings } from '../../../settings/getDefaultSettings';
import { ISettings } from '../../../settings/settings';
import { ISyncMetadata, ISyncSettings } from '../../sync/SyncManager';
import { IStoredTicket, IStoredTicketMeta } from '../../../models/Tickets';
import { IStoredIncident, IStoredIncidentMeta } from '../../../models/Incident';
import { getDefaultSummary } from '../../../components/dashboard/getDefaultSummary';
import { IStockedPreviousPage, PreviousPageID } from '../../PreviousPage';
import { DatabaseError}  from '../../../errors/DatabaseError';
import { IAppDataDB } from '..';
import { exportLocalPhotos, exportTicketLocalPhotos, exportAttachmentLocalPhotos } from '../../../components/checklists/utils/photos';

class DexieAppDB extends Dexie {
    checklists!: Dexie.Table<IStoredChecklist, number>;
    draft_tickets!: Dexie.Table<IStoredTicket, number>;
    draft_incidents!: Dexie.Table<IStoredIncident, number>;
    draft_previous_pages!: Dexie.Table<IStockedPreviousPage, string>;
    constructor(dbName: string) {
        super(dbName);
        this.version(2).stores({
            messages: '', franchise: '', franchisees: '', sites: '', tags: '',
            checklist_template_list: '', checklist_templates: '',
            checklists: '++localId, templateId, state, franchiseeId, siteId',
        });
        this.version(3).stores({
            messages: '', franchise: '', franchisees: '', sites: '', tags: '',
            checklist_template_list: '', checklist_templates: '',
            checklists: '++localId, templateId, checklistId, franchiseeId, siteId',
            overview_dashboard: '', overview_schedule: '', franchisee_dashboards: '',
            franchisee_schedules: '', site_dashboards: '', site_schedules: '',
        });
        this.version(4).stores({
            messages: '', franchise: '', franchisees: '', sites: '', tags: '',
            checklist_template_list: '', checklist_templates: '',
            checklists: '++localId, templateId, checklistId, franchiseeId, siteId',
            overview_dashboard: '', overview_schedule: '', franchisee_dashboards: '',
            franchisee_schedules: '', site_dashboards: '', site_schedules: '',
            ticket_types: '', ticket_categories: '', tickets: ''
        });
        this.version(5).stores({
            messages: '', franchise: '', franchisees: '', sites: '', tags: '',
            checklist_template_list: '', checklist_templates: '',
            checklists: '++localId, templateId, checklistId, franchiseeId, siteId',
            tickets: '', // this is not used, and Dexie cannot change the primary key
            overview_dashboard: '', overview_schedule: '', franchisee_dashboards: '',
            franchisee_schedules: '', site_dashboards: '', site_schedules: '',
            ticket_types: '', ticket_categories: '', settings: ''
        });
        this.version(6).stores({
            messages: '', franchise: '', franchisees: '', sites: '', tags: '',
            checklist_template_list: '', checklist_templates: '',
            checklists: '++localId, templateId, checklistId, franchiseeId, siteId',
            draft_tickets: '++localId, ticketId, franchiseeId, siteId',
            overview_dashboard: '', overview_schedule: '', franchisee_dashboards: '',
            franchisee_schedules: '', site_dashboards: '', site_schedules: '',
            ticket_types: '', ticket_categories: '', settings: ''
        });
        this.version(6).stores({
            messages: '', franchise: '', franchisees: '', sites: '', tags: '',
            checklist_template_list: '', checklist_templates: '',
            checklists: '++localId, templateId, checklistId, franchiseeId, siteId',
            overview_dashboard: '', overview_schedule: '', franchisee_dashboards: '',
            franchisee_schedules: '', site_dashboards: '', site_schedules: '',
            ticket_types: '', ticket_categories: '', tickets: '', settings: '',
            asset_cache: ''
        });
        this.version(7).stores({
            messages: '', franchise: '', franchisees: '', sites: '', tags: '',
            checklist_template_list: '', checklist_templates: '', incident_template: '',
            checklists: '++localId, templateId, checklistId, franchiseeId, siteId',
            draft_tickets: '++localId, ticketId, franchiseeId, siteId',
            overview_dashboard: '', overview_schedule: '', franchisee_dashboards: '',
            franchisee_schedules: '', site_dashboards: '', site_schedules: '',
            ticket_types: '', ticket_categories: '', tickets: '', settings: '',
            asset_cache: '', draft_incidents: '++localId, siteId, incidentId'
        });
        this.version(8).stores({
            messages: '', franchise: '', franchisees: '', sites: '', tags: '',
            checklist_template_list: '', checklist_templates: '', incident_template: '',
            checklists: '++localId, templateId, checklistId, franchiseeId, siteId',
            draft_tickets: '++localId, ticketId, franchiseeId, siteId',
            overview_dashboard: '', overview_schedule: '', franchisee_dashboards: '',
            franchisee_schedules: '', site_dashboards: '', site_schedules: '',
            ticket_types: '', ticket_categories: '', tickets: '', settings: '',
            asset_cache: '', draft_incidents: '++localId, siteId, incidentId', summary: ''
        });
        this.version(9).stores({
            messages: '', franchise: '', franchisees: '', sites: '', tags: '',
            checklist_template_list: '', checklist_templates: '', incident_template: '',
            checklists: '++localId, templateId, checklistId, franchiseeId, siteId',
            draft_tickets: '++localId, ticketId, franchiseeId, siteId',
            overview_dashboard: '', overview_schedule: '', franchisee_dashboards: '',
            franchisee_schedules: '', site_dashboards: '', site_schedules: '',
            ticket_types: '', ticket_categories: '', tickets: '', settings: '',
            asset_cache: '', draft_incidents: '++localId, siteId, incidentId', summary: '',
            draft_previous_pages: 'pageId,targetPage'
        });
        this.version(10).stores({
            messages: '', franchise: '', franchisees: '', sites: '', tags: '',
            checklist_template_list: '', checklist_templates: '', incident_template: '',
            checklists: '++localId, templateId, checklistId, franchiseeId, siteId',
            draft_tickets: '++localId, ticketId, franchiseeId, siteId',
            overview_dashboard: '', overview_schedule: '', franchisee_dashboards: '',
            franchisee_schedules: '', site_dashboards: '', site_schedules: '',
            ticket_types: '', ticket_categories: '', tickets: '', settings: '',
            asset_cache: '', draft_incidents: '++localId, siteId, incidentId', summary: '',
            draft_previous_pages: 'pageId,targetPage',
            checklist_template_list_parent: '', incident_types: ''
        });
        this.version(11).stores({
            messages: '', franchise: '', franchisees: '', sites: '', tags: '',
            checklist_template_list: '', checklist_templates: '', incident_template: '',
            checklists: '++localId, versionId, checklistId, franchiseeId, siteId',
            draft_tickets: '++localId, ticketId, franchiseeId, siteId',
            overview_dashboard: '', overview_schedule: '', franchisee_dashboards: '',
            franchisee_schedules: '', site_dashboards: '', site_schedules: '',
            ticket_types: '', ticket_categories: '', tickets: '', settings: '',
            asset_cache: '', draft_incidents: '++localId, siteId, incidentId', summary: '',
            draft_previous_pages: 'pageId,targetPage',
            checklist_template_list_parent: '', incident_types: '', people: 'id', person_types: 'id, *checklistTemplateIds, *incidentTypeIds, *ticketCategoryIds'
        }).upgrade ((trans) => {
            trans.db.table('checklists').toCollection().modify ((checklist: any) => {
                checklist.versionId = checklist.templateId;
                if (checklist.templateId) delete checklist.templateId;
            });
            // update index column
            const templateIdIndex = trans.db.table('checklists').schema.indexes.find(i => i.name='templateId');
            if (templateIdIndex) {
                templateIdIndex.name='versionId';
                templateIdIndex.keyPath='versionId';
                templateIdIndex.src='versionId';
            }
        });
        this.version(12).stores({
            messages: '', franchise: '', franchisees: '', sites: '', tags: '',
            checklist_template_list: '', checklist_templates: '', incident_template: '',
            checklists: '++localId, versionId, checklistId, franchiseeId, siteId',
            draft_tickets: '++localId, ticketId, franchiseeId, siteId',
            overview_dashboard: '', overview_schedule: '', franchisee_dashboards: '',
            franchisee_schedules: '', site_dashboards: '', site_schedules: '',
            ticket_types: '', ticket_categories: '', tickets: '', settings: '',
            asset_cache: '', draft_incidents: '++localId, siteId, incidentId', summary: '',
            draft_previous_pages: 'pageId,targetPage',
            checklist_template_list_parent: '', incident_types: '', people: 'id', person_types: 'id, *checklistTemplateIds, *incidentTypeIds, *ticketCategoryIds',
            owner: ''
        });
        this.version(13).stores({
            messages: '', franchise: '', franchisees: '', sites: '', tags: '',
            checklist_template_list: '', checklist_templates: '', incident_template: '',
            checklists: '++localId, versionId, checklistId, franchiseeId, siteId',
            draft_tickets: '++localId, ticketId, franchiseeId, siteId',
            overview_dashboard: '', overview_schedule: '', franchisee_dashboards: '',
            franchisee_schedules: '', site_dashboards: '', site_schedules: '',
            ticket_types: '', ticket_categories: '', tickets: '', settings: '',
            asset_cache: '', draft_incidents: '++localId, siteId, incidentId', summary: '',
            draft_previous_pages: 'pageId,targetPage',
            checklist_template_list_parent: '', incident_types: '', people: 'id', person_types: 'id, *checklistTemplateIds, *incidentTypeIds, *ticketCategoryIds',
            owner: '', site: 'id', franchisee: 'id'
        });
        this.version(14).stores({
            franchisee_sites: '', checklist_dashboard_filter: ''
        })
    }
}

export class AppDataDB implements IAppDataDB {
    db!: DexieAppDB;
    dbName!: string;
    async initialise(db_name: string, id: string ): Promise<boolean> {
        this.db = new DexieAppDB(db_name);
        return this.getById<string>('owner', '1').then((owner) => {
            if(owner) {
                if (owner !== id) {
                    throw new DatabaseError('Database exists')
                }
                this.dbName = db_name;
                return true
            } else{
                // your database
                this.dbName = db_name;
                void this.setById('owner', '1',id)
                return true
            }
        }).catch((_e) =>{
            return false;
        }
        );
    }

    getDB(): DexieAppDB {
        if (!this.db) {
            this.db = new DexieAppDB(this.dbName);
        }

        const idb = this.db.backendDB();

        if (idb) {
            try {
                // Check if if connection to idb did not close in the meantime
                idb.transaction('messages').abort();
            } catch (e) {
                this.db.close();
                this.db = new DexieAppDB(this.dbName);
            }
        }
        return this.db;
    }

    async getById<T>(entityName: string, entityId: string): Promise<T> {
        const result: T = await this.getDB().table(entityName).get(entityId);
        return result;
    }

    async setById(entityName: string, entityId: string, data: any): Promise<void> {
        await this.getDB().table(entityName).put(data, entityId);
    }

    async deleteById(entityName: string, entityId: string | number): Promise<void> {
        await this.getDB().table(entityName).delete(entityId);
    }

    async getByIds(entityName: string, entityIds: number[], indexName?: string): Promise<any[]> {
        const result = await this.getDB().table(entityName)
                                    .where(indexName?indexName:'id') // default id column is "id"
                                    .anyOf(entityIds)
                                    .toArray();
        return result;
    }

    async getAll(entityName: string): Promise<any>{
        const result = await this.getDB().table(entityName)
            .toArray();
        return result;
    }

    async clearEntityCache<T>(entityName: string): Promise<void> {
        await this.getDB().table(entityName).clear();
    }

    async storeMessages(ns: string, messages: { [key: string]: string }): Promise<void> {
        await this.setById('messages', ns, messages);
    }

    async retrieveMessages(ns: string): Promise<any> {
        return await this.getById('messages', ns) as any;
    }

    async saveChecklist(checklist: IStoredChecklist): Promise<number> {
        if (checklist.localId) {
            return Number(await this.getDB().checklists.put(checklist));
        }
        else {
            delete checklist.localId;  // make sure we dont save 0 or null
            return Number(await this.getDB().checklists.add(checklist));
        }
    }

    async saveIncident(incident: IStoredIncident): Promise<number> {
        if (incident.localId) {
            return Number(await this.getDB().draft_incidents.put(incident));
        }
        else {
            delete incident.localId;  // make sure we dont save 0 or null
            return Number(await this.getDB().draft_incidents.add(incident));
        }
    }

    async loadIncident(localId: number): Promise<IStoredIncident | undefined> {
        return await this.getDB().draft_incidents.get(localId);
    }

    async loadTicket(localId: number): Promise<IStoredTicket | undefined> {
        return await this.getDB().draft_tickets.get(localId);
    }

    async saveTicket(ticket: IStoredTicket): Promise<number> {
        if (ticket.localId) {
            return Number(await this.getDB().draft_tickets.put(ticket));
        }
        else {
            delete ticket.localId;  // make sure we dont save 0 or null
            return Number(await this.getDB().draft_tickets.add(ticket));
        }
    }

    async loadPreviousPage(pageId: string): Promise<any | undefined> {
        return await this.getDB().draft_previous_pages.get(pageId);
    }
    async loadPreviousPageByTargetPage(targetPage: string): Promise<any | undefined> {
        return await this.getDB().draft_previous_pages.where('targetPage')
            .equals(targetPage)
            .first();
    }
    async savePreviousPage(previousPage: IStockedPreviousPage): Promise<string> {
        if (previousPage.pageId) {
            const existingDoc = await this.getDB().draft_previous_pages.get(previousPage.pageId);
            if (existingDoc) {
                return await this.getDB().draft_previous_pages.put(previousPage);
            }else{
                return await this.getDB().draft_previous_pages.add(previousPage);
            }
        }
        return '-1';
    }

    async removePreviousPage(pageId: PreviousPageID): Promise<void> {
        return await this.getDB().draft_previous_pages.delete(pageId);
    }

    async emptyPreviousPage(): Promise<void> {
        const count =  await this.getDB().draft_previous_pages.count();
        if (count > 0) {
            return await this.getDB().draft_previous_pages.clear();
        }
    }

    async loadChecklist(localId: number): Promise<IStoredChecklist | undefined> {
        return await this.getDB().checklists.get(localId);
    }

    async removeLocalChecklist(localId: number): Promise<void> {
        return await this.getDB().checklists.delete(localId);
    }

    async removeLocalTicket(localId: number): Promise<void> {
        return await this.getDB().draft_tickets.delete(localId);
    }

    async removeLocalIncident(localId: number): Promise<void> {
        return await this.getDB().draft_incidents.delete(localId);
    }

    async searchDraftChecklistByAssignee(
        versionId: number, franchiseeId: number, siteId?: number
    ): Promise<number | null> {
        const criteria: Partial<IStoredChecklist> = {
            versionId,
            franchiseeId,
            siteId: 0
        };
        if (siteId) {
            criteria.siteId = siteId;
        }
        const match = await this.getDB().checklists
            .where(criteria as {[key: string]: IndexableType})
            .first();
        if (match) {
            return match.localId!;
        }
        return null;
    }

    async searchDraftNumbersByChecklistIdAssignee(
        checklistId: number, franchiseeId: number, siteId: number
    ): Promise<number> {
        const criteria: Partial<IStoredChecklist> = {
            checklistId,
            franchiseeId,
            siteId: 0
        };
        if (siteId > 0) {
            criteria.siteId = siteId;
        }
        const count = await this.getDB().checklists
            .where(criteria as {[key: string]: IndexableType})
            .count();
        return count;
    }

    async searchDraftByChecklistIdAssignee(
        checklistId: number, franchiseeId: number, siteId: number
    ): Promise<number> {
        const criteria: Partial<IStoredChecklist> = {
            checklistId,
            franchiseeId,
            siteId: 0
        };
        if (siteId > 0) {
            criteria.siteId = siteId;
        }
        const match = await this.getDB().checklists
            .where(criteria as {[key: string]: IndexableType})
            .first();
        return match!.localId!;
    }
    async searchDraftNumbersByIncidentIdAssignee(
        incidentId: number, siteId: number
    ): Promise<number> {
        const criteria: Partial<IStoredIncident> = {
            incidentId,
            siteId
        };
        const count = await this.getDB().draft_incidents
            .where(criteria as {[key: string]: IndexableType})
            .count();
        return count;
    }

    async searchDraftByIncidentIdAssignee(
        incidentId: number, siteId: number
    ): Promise<number> {
        const criteria: Partial<IStoredIncident> = {
            incidentId,
            siteId
        };
        const match = await this.getDB().draft_incidents
            .where(criteria as {[key: string]: IndexableType})
            .first();
        return match!.localId!;
    }
    async searchDraftIncidentBySite(siteId: number): Promise<number | null> {
        const criteria: Partial<IStoredChecklist> = {
            siteId: 0
        };
        if (siteId) {
            criteria.siteId = siteId;
        }
        const match = await this.getDB().draft_incidents
            .where(criteria as {[key: string]: IndexableType})
            .first();
        if (match) {
            return match.localId!;
        }
        return null;
    }
    // fix duplicate checklist drafts
    async removeDraftChecklistByChecklistId(checklistId: number): Promise<void>  {
        const draftIds: number[] = [];
        await this.getDB().checklists.filter((draft)=>draft.checklistId==checklistId).each((draft) =>{
            if (draft.localId)
                draftIds.push(draft.localId);
        });
        return await this.getDB().checklists.bulkDelete(draftIds);
    }

    // fix duplicate incident drafts
    async removeDraftIncidentByIncidentId(incidentId: number): Promise<void>  {
        const draftIds: number[] = [];
        await this.getDB().draft_incidents.filter((draft)=>draft.incidentId==incidentId).each((draft) =>{
            // negative localId shouldn't happen
            if (draft.localId) {
                draftIds.push(draft.localId);
            }
        });
        return await this.getDB().draft_incidents.bulkDelete(draftIds);
    }

    async getDraftChecklistList(): Promise<IStoredChecklistMeta[]> {
        const res: IStoredChecklistMeta[] = [];
        await this.getDB().checklists.each((storedChecklist) => {
            // negative localId shouldn't happen
            res.push({
                localId: storedChecklist.localId? storedChecklist.localId : -1,
                templateId: storedChecklist.versionId,
                checklistId: storedChecklist.checklistId,
                franchiseeId: storedChecklist.franchiseeId,
                siteId: storedChecklist.siteId,
                date: storedChecklist.date,
                checklistName: storedChecklist.checklist.name,
            });
        });
        return res;
    }

    async getDraftTicketsList(): Promise<IStoredTicketMeta[]> {
        const res: IStoredTicketMeta[] = [];
        await this.getDB().draft_tickets.each((storedTicket) => {
            // console.log('draft', storedTicket);
            // negative localId shouldn't happen
            res.push({
                localId: storedTicket.localId? storedTicket.localId : -1,
                ticketId: storedTicket.ticketId,
                franchiseeId: storedTicket.franchiseeId,
                siteId: storedTicket.siteId,
                ticketNo: storedTicket.ticket.ticketNo,
                subject: storedTicket.ticket.subject,
                categoryId: storedTicket.ticket.category && storedTicket.ticket.category.id || 0
            });
        });
        return res;
    }

    async getDraftIncidentsList(): Promise<IStoredIncidentMeta[]> {
        const res: IStoredIncidentMeta[] = [];
        await this.getDB().draft_incidents.each((storedIncident) => {
            // console.log('draft', storedIncident);
             // negative localId shouldn't happen
             res.push({
                localId: storedIncident.localId? storedIncident.localId: -1,
                incidentId: storedIncident.incidentId,
                siteId: storedIncident.siteId,
                incidentNo: storedIncident.incident.incidentNo || 0,
                incidentName: storedIncident.incident.name,
                date: storedIncident.date
            });
        });
        return res;
    }

    async isDraftExist(): Promise<boolean> {
        const [checklistsCount, ticketsCount, incidentsCount]  = await Promise.all([this.getDraftChecklistsCount(),
        this.getDraftTicketsCount(),
        this.getDraftIncidentsCount()]);
        if ((checklistsCount > 0) || (ticketsCount > 0) || (incidentsCount > 0))
            return true;
        return false;
    }
    async getDraftsTotal(): Promise<number> {
        const [checklistsCount, ticketsCount, incidentsCount]  = await Promise.all([this.getDraftChecklistsCount(),
        this.getDraftTicketsCount(),
        this.getDraftIncidentsCount()]);
        return checklistsCount + ticketsCount + incidentsCount;
    }

    async getDraftChecklistsCount(): Promise<number> {
        return this.getDB().checklists.count();
    }
    async getDraftTicketsCount(): Promise<number> {
        return this.getDB().draft_tickets.count();
    }
    async getDraftIncidentsCount(): Promise<number> {
        return this.getDB().draft_incidents.count();
    }
    async getSettings(): Promise<ISettings> {
        const settings = getDefaultSettings();
        const storedSettings = await this.getById<ISettings>('settings', 'settings');
        if (storedSettings) {
            Object.assign(settings, storedSettings);
        }
        return settings;
    }

    async saveSettings(settings: ISettings): Promise<void> {
        await this.setById('settings', 'settings', settings);
    }

    async getSummary(): Promise<ISummaryData> {
        const summary = getDefaultSummary();
        const storedSummary = await this.getById<ISummaryData>('summary', 'summary');
        if (storedSummary) {
            Object.assign(summary, storedSummary);
        }
        return summary;
    }

    async saveSummary(summary: ISummaryData): Promise<void> {
        await this.setById('summary', 'summary', summary);
    }

    async getSyncStatus(): Promise<ISyncMetadata> {
        const status = await this.getById<ISyncMetadata>('settings', 'syncStatus');
        if (!status) {
            return {
                lastSuccessfulSync: '',
                lastSync: {}
            };
        }
        return status;
    }

    async setSyncStatus(status: ISyncMetadata): Promise<void> {
        await this.setById('settings', 'syncStatus', status);
    }

    async getSyncSettings(): Promise<ISyncSettings> {
        const status = await this.getById<ISyncSettings>('settings', 'syncSettings');
        if (!status) {
            return {
                fetchLimit: 100,
                 peopleFailedCount: 0
            };
        }
        return status;
    }

    async setSyncSettings(status: ISyncSettings): Promise<void> {
        await this.setById('settings', 'syncSettings', status);
    }

    async export(option: ExportOptions ): Promise<Blob> {
        try {
            // @ts-ignore https://github.com/dfahlander/Dexie.js/issues/1262
            return await exportDB(this.getDB(),option);
        } catch (e) {
            Dexie.currentTransaction.abort();
            throw e
        }
    }

    async import(exportedData: Blob): Promise<void> {
        const data = await (new Response(exportedData)).text();
        const json = JSON.parse(data);
        if (json.data?.databaseName !=='OnePlace_images' && json.data?.databaseVersion == this.getDB().verno) {
            const filter = (table: string, value: any, _key?: any) => {
                if (table =='checklists' || table == 'draft_tickets' || table ==  'draft_incidents') {
                    console.log(value);
                    return true;
                } else {
                    return false;
                }
            };
            // @ts-ignore https://github.com/dfahlander/Dexie.js/issues/1262
            return importInto(this.getDB(), exportedData, {overwriteValues: true, noTransaction: true, filter});

        } else {
            throw new Error('invalid database file');
        }
    }

    async getLastSyncTimeForPeople(): Promise<string | null> {
        const status = await this.getById<ISyncMetadata>('settings', 'syncStatus');
        if (status && status.lastSync && status.lastSync.people_list) {
            return status.lastSync.people_list;
        }
        return null;
    }

    async getLastSyncTimeForPersonTypes(): Promise<string | null> {
        const status = await this.getById<ISyncMetadata>('settings', 'syncStatus');
        if (status && status.lastSync && status.lastSync.person_types) {
            return status.lastSync.person_types;
        }
        return null;
    }

    // used on checklist/incident/ticket details page
    async getPeopleListForPeopleControl(franchiseId: number, franchiseeId: number, siteId: number, ticketCategoryId: number, incidentTypeId: number, checklistTemplateId: number): Promise<IPerson[]> {

        const peopleIds = await this.getPeopleIdByPersonTypes(incidentTypeId, ticketCategoryId, checklistTemplateId);

        // TODO: when person types is empty, we should return super users list

        const rawPeople: IPerson[] = [];
        let filteredPeople: IPerson[] = [];

        const peopleListFromPersonTypes: IPerson[] = await this.getByIds('people',peopleIds);

        // find distinct people and store in rawPeople
        peopleListFromPersonTypes.forEach((p: IPerson) =>{
            if(!rawPeople.includes(p)){
                rawPeople.push(p);
            }
        });
        if (siteId > 0) {
            filteredPeople = rawPeople.filter((p) => p.siteIds!.includes(siteId));
        }else if (franchiseeId > 0){
            // all sites
            const sitesList: ISites = await this.getDB().table('sites')
                .get(''+franchiseId);
            const filteredSitesList: ISite[] = [];

            // sites for selected franchisee
            sitesList.sites.filter((s: ISite) => s.franchiseeId == franchiseeId)
                .forEach((s1: any) => {
                    if(!filteredSitesList.includes(s1)){
                        filteredSitesList.push(s1);
                    }
                });
            const franchiseeSitesIds = filteredSitesList.map((s) => s.id);

            filteredPeople = rawPeople.filter((p) =>
                p.siteIds!.some((s: number) => franchiseeSitesIds.includes(s))
            );
        }
        return filteredPeople;
    }


    async getPeopleIdByPersonTypes(incidentTypeId: number | null,
        ticketCategoryId: number | null, checklistTemplateId: number | null): Promise<number[]> {

        const peopleIds: number[] = [];

        let idAndType: [number, string];
        if(incidentTypeId && incidentTypeId > 0) {
            idAndType = [Number(incidentTypeId), 'incidentTypeIds']
        } else if (ticketCategoryId && ticketCategoryId > 0) {
            idAndType = [Number(ticketCategoryId), 'ticketCategoryIds']
        } else {
            idAndType = [Number(checklistTemplateId), 'checklistTemplateIds']
        }

        const personTypes: IPersonType[] = await this.getDB().table<IPersonType>('person_types')
            .where(idAndType[1])
            .equals(idAndType[0])
            .toArray();
        personTypes.forEach(pt => peopleIds.push(...pt.peopleIds!))

        return peopleIds;
    }

    async isTableEmpty(tableName: string): Promise<boolean> {
        const  rowCount = await this.getDB().table(tableName).count();
        return rowCount <1;
    }

    async setPersonTypes(entityId: string, data: any): Promise<void> {
        await this.getDB().table('person_types').put(data, entityId);
    }

    async deletePersonTypes(entityId: string | number): Promise<void> {
        await this.getDB().table('person_types').delete(entityId);
    }

    async getDraftImagesIds(): Promise<number[]> {
        let ids: number[]=[];
        const storedChecklists = await this.getDraftChecklistList();
        for (const storedChecklist of storedChecklists) {
            const draftChecklist = await this.loadChecklist(storedChecklist.localId);
            if (draftChecklist?.checklist) {
                ids = ids.concat(exportLocalPhotos(draftChecklist?.checklist));
            }
        }

        const storedTickets = await this.getDraftTicketsList();
        for (const storedTicket of storedTickets) {
            const draftTicket = await this.loadTicket(storedTicket.localId);
            if (draftTicket?.ticket) {
                ids = ids.concat(exportTicketLocalPhotos(draftTicket?.ticket));
                if(draftTicket.ticket.checklist){
                    ids = ids.concat(exportLocalPhotos(draftTicket.ticket.checklist));
                }
            }
        }

        const storedIncidents = await this.getDraftIncidentsList();
        for (const storedIncident of storedIncidents) {
            const draftIncident = await this.loadIncident(storedIncident.localId);
            if (draftIncident?.incident) {
                ids = ids.concat(exportAttachmentLocalPhotos(draftIncident?.attachments));
            }
        }
        return ids
    }

    async getAssetCacheList(): Promise<string[]> {
        const assetList: string[] = [];
        await this.getDB().table('asset_cache').toCollection().each((value) =>{
            assetList.push(value);
        });
        return assetList;
    }
}
