import { ENV } from "../../environment";

let pushNotification: any;

// we should run initPushNotification before calling notification functions
export const  initPushNotification = () => {
    if(pushNotification){
        console.log("push notification instance already init");
        return;
    }

    pushNotification = PushNotification.init({
        ios: {
            alert: "false",
            badge: "true",
            sound: "true",
            fcmSandbox: ENV.target!='prod' ? "true":"false"
        },
        android: {}
    });

    pushNotification.on('error', (err: any) => {
        console.log('push notification error: '+ err);
    });

}

//app notifications for IOS and Android
export const addNotificationListeners = (onNotification: (data: any) => void,
    registerForApp : (token: string) => Promise<void>) => {

    if (!pushNotification) {
        initPushNotification();
    }

    pushNotification.on('registration', (data: any) => {
        console.log('going to get registrationid');
        console.log(data.registrationId);
        console.log(data.registrationType);
        registerForApp(data.registrationId);
    });

    pushNotification.on('notification', (data: any) => {
        console.log("received new notification");
        console.log(data);
        onNotification(data);
    });
    pushNotification.on('error', (err: any) => {
        console.log(err)
    });
}

export const setBadgeNumber = (badgeNumber : number) => {
    if((ENV.platform == 'cordova' && ENV.os == 'browser') ||  ENV.platform === 'web'){
        return;
    }

    if(!pushNotification){
        initPushNotification();
    }

    pushNotification.setApplicationIconBadgeNumber(
        () => {
          console.log('set badge success:' + badgeNumber);
        },
        () => {
          console.log('set badge error:' + badgeNumber);
        },
        badgeNumber
    );
}
