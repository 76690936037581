
import * as React from 'react';
import {  Route } from 'react-router-dom';
import Loadable from 'react-loadable';
import { OverviewDashboard } from './dashboard/OverviewDashboard';
import { FranchiseeSearchPage } from './franchisees/FranchiseeSearch';
import { FranchiseeDashboard } from './franchisees/FranchiseeDashboard';
import { SiteSearchPage } from './sites/SiteSearch';
import { SiteDashboard } from './sites/SiteDashboard';
import { SettingsPage } from './settings/SettingsPage';
import LoadingComponent from './LoadingComponent';
import { withAppContext, IAppContextProp } from './AppContext';
import Typography from '@material-ui/core/Typography'

const NewChecklist = Loadable({
    loader: () => import('./checklists/NewChecklistPage'),
    loading: LoadingComponent,
});

const EditChecklist = Loadable({
    loader: () => import('./checklists/EditChecklistPage'),
    loading: LoadingComponent
});

const ChecklistSearch = Loadable({
    loader: () => import('./checklists/ChecklistSearch'),
    loading: LoadingComponent
});

const NewTicket = Loadable({
    loader: () => import('./tickets/NewTicketPage'),
    loading: LoadingComponent
});

const EditTicket = Loadable({
    loader: () => import('./tickets/EditTicketPage'),
    loading: LoadingComponent
});

const TicketSearch = Loadable({
    loader: () => import('./tickets/TicketSearch'),
    loading: LoadingComponent
});

const Drafts = Loadable({
    loader: () => import('./drafts/DraftsPage'),
    loading: LoadingComponent
});

const Favourites = Loadable({
    loader: () => import('./checklists/Favourites'),
    loading: LoadingComponent
});

const IncidentSearch = Loadable({
    loader: () => import('./health_safety/incidents/IncidentSearch'),
    loading: LoadingComponent
});

const NewIncident = Loadable({
    loader: () => import('./health_safety/incidents/NewIncidentPage'),
    loading: LoadingComponent
});

const EditIncident = Loadable({
    loader: () => import('./health_safety/incidents/EditIncidentPage'),
    loading: LoadingComponent
});

const HazardSearch = Loadable({
    loader: () => import('./health_safety/hazards/HazardSearch'),
    loading: LoadingComponent
});

const NewHazard = Loadable({
    loader: () => import('./health_safety/hazards/NewHazardPage'),
    loading: LoadingComponent
});

const EditHazard = Loadable({
    loader: () => import('./health_safety/hazards/EditHazardPage'),
    loading: LoadingComponent
});

const editNote = Loadable({
    loader: () => import('./notes/EditNotePage'),
    loading: LoadingComponent
});
const newNote = Loadable({
    loader: () => import('./notes/NewNotePage'),
    loading: LoadingComponent
});

const PeopleSearch = Loadable({
    loader: () => import('./people/PeopleSearch'),
    loading: LoadingComponent
});

const PersonDetails = Loadable({
    loader: () => import('./people/PersonDetails'),
    loading: LoadingComponent
});

const PermissionError = () => {
    return <Typography align='center'>You do not have access to this Component</Typography>
}

type IRoutesProp = IAppContextProp
export const Routes = withAppContext((props: IRoutesProp) => {

    const capabs = props.ctx.auth.user.capabilities;

    return (
        <div>
            <Route exact path="/overview" component={OverviewDashboard} />
            <Route exact path="/checklists/new" component={NewChecklist} />
            <Route exact path="/checklists/edit" component={EditChecklist} />
            <Route exact path="/checklists" component={ChecklistSearch} />
            <Route exact path="/tickets/edit" component={EditTicket} />
            <Route exact path="/tickets/new" component={NewTicket} />
            <Route exact path="/tickets" component={TicketSearch} />
            <Route exact path="/drafts" component={Drafts} />
            <Route exact path="/Favourites" component={Favourites} />
            <Route exact path="/franchisees" component={FranchiseeSearchPage} />
            <Route exact path="/franchisees/:franchiseeId" component={FranchiseeDashboard} />
            <Route exact path="/sites" component={SiteSearchPage} />
            <Route exact path="/sites/:siteId" component={SiteDashboard} />
            <Route exact path="/incidents" component={IncidentSearch} />
            <Route exact path="/incidents/new" component={NewIncident} />
            <Route exact path="/incidents/edit" component={EditIncident} />
            <Route exact path="/hazards" component={HazardSearch} />
            <Route exact path="/hazards/new" component={NewHazard} />
            <Route exact path="/hazards/edit" component={EditHazard} />
            <Route exact path="/settings" component={SettingsPage} />
            <Route exact path="/notes/edit" component={editNote} />
            <Route exact path="/notes/new" component={newNote} />
            <Route
                exact
                path="/people"
                render={() => capabs.usePeople && !capabs.hidePeopleManager ?
                        <PeopleSearch /> : <PermissionError />
                }
            />
            <Route
                exact
                path="/people/details"
                render={() => capabs.usePeople && !capabs.hidePeopleManager?
                        <PersonDetails /> : <PermissionError />
                }
            />
        </div>
    )
});
