
import { IAppDataDB } from './database';
import { IApi } from './api';
import { DataWithCache, IDataWithCache } from './cache';
import { User } from '../models/User';
import { IIncidentResponse, IIncidentTypes, IncidentExcludableField} from '../models/Incident';


export interface IIncidentData {
    getIncidentTemplate(franchiseId: number, franchiseeId: number, params?: {incidentTypeId?: number, exclude?: IncidentExcludableField[]}): IDataWithCache<IIncidentResponse>;
    getIncidentTypes(franchiseId: number): DataWithCache<IIncidentTypes>
}

export class IncidentData implements IIncidentData {

    constructor(
        public _db: IAppDataDB,
        public _api: IApi,
        public _user: User
    ) {}

    getIncidentTemplate(franchiseId: number, franchiseeId: number, params?: {incidentTypeId?: number, exclude?: IncidentExcludableField[]}): DataWithCache<IIncidentResponse> {
        return new DataWithCache<IIncidentResponse>({
            strategy: 'api_first',
            cache: this._db,
            entityName: 'incident_template',
            entityId: String(franchiseId),
            updateCacheOlderThan: 300000,  // 5 minutes
            getApiDataFn: () => this._api.getIncidentTemplate(franchiseId, franchiseeId, params),
            disableCacheUpdateFromApi: params && params.exclude && params.exclude.length > 0 //we only update cache when returning full template
        });

    }

    getIncidentTypes(franchiseId: number): DataWithCache<IIncidentTypes> {
        return new DataWithCache<IIncidentTypes>({
            strategy: 'api_first',
            cache: this._db,
            entityName: 'incident_types',
            entityId: String(franchiseId),
            updateCacheOlderThan: 300000,  // 5 minutes
            getApiDataFn: () => this._api.getIncidentTypes(franchiseId)
        });
    }

}
