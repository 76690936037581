
import { IImageStorage} from './';
import { STORED_IMAGE_PREFIX, ImageStorageType, TEMP_IMAGE_PREFIX, getImageStorageType } from 'oneplace-components'
import Dexie from 'dexie';
import {exportDB, ExportOptions, importInto } from 'dexie-export-import'
import { isDefined } from '../../utils';

interface IStoredImage {
    imageId?: number;
    timestamp: number;
    filename: string;
    data: Blob;
}

class DexieImageDB extends Dexie {
    images!: Dexie.Table<IStoredImage, number>;

    constructor() {
        super('OnePlace_images');
        this.version(1).stores({
            images: '++imageId'
        });
        this.version(2).stores({
            images: '++imageId'
        }).upgrade ((trans) => {
            trans.db.table('images').toCollection().modify ((image: any) => {
                if (image.filename == 'fileName') {
                    image.filename = `image${image.imageId}.jpg`;
                }
            });
        });
    }
}

export class CordovaFSImageStorage implements IImageStorage {
    db: DexieImageDB;
    objectUrls: {
        [objectUrl: string]: Blob;
    } = {};
    storedImageObjectUrls: {
        [imageId: number]: string;
    } = {};
    constructor() {
        this.db = new DexieImageDB();
    }

    getDB() {
        if (!this.db) {
            this.db = new DexieImageDB();
        }

        const idb = this.db.backendDB();

        if (idb) {
            try {
                // Check if if connection to idb did not close in the meantime
                idb.transaction('images').abort();
            } catch (e) {
                this.db.close();
                this.db = new DexieImageDB();
            }
        }
        return this.db;
    }

    _writeFile(fileName: string, data: Blob) {
        return new Promise<void>((resolve, reject) => {
            window.resolveLocalFileSystemURL(cordova.file.dataDirectory, (fs: any) => {
                // console.log('file system open: ');
                fs.getFile(fileName, { create: true }, (fileEntry: any) => {
                    console.log('fileEntry is file?', fileEntry.isFile.toString());
                    console.log('path:', fileEntry.fullPath);
                    fileEntry.createWriter((fileWriter: any) => {
                        fileWriter.onwriteend = () => {
                            console.log('Successful file write!');
                            resolve();
                        };
                        fileWriter.onerror = (e: any) => {
                            console.error('Failed file write: ', e);
                            reject(e);
                        };
                        fileWriter.write(data);
                    });
                }, (err: any) => {
                    reject(err);
                });
            }, (err: any) => {
                reject(err);
            });
        });
    }

    _readFile(fileName: string) {
        return new Promise<Blob>((resolve, reject) => {
            window.resolveLocalFileSystemURL(cordova.file.dataDirectory, (fs: any) => {
                // console.log('file system open: ');
                fs.getFile(fileName, {}, (fileEntry: any) => {
                    console.log('fileEntry is file?', fileEntry.isFile.toString());
                    console.log('path:', fileEntry.fullPath);
                    fileEntry.file((file: any) => {
                        const reader = new FileReader();
                        reader.onloadend = () => {
                            const data = new Blob([new Uint8Array(reader.result as any)], { type: 'image/jpg' });
                            resolve(data);
                        };
                        reader.onerror = (e: any) => {
                            reject(e);
                        };
                        reader.readAsArrayBuffer(file);
                    });
                }, (err: any) => {
                    reject(err);
                });
            }, (err: any) => {
                reject(err);
            });
        });
    }

    _removeFile(fileName: string) {
        return new Promise<Blob>((resolve, reject) => {
            window.resolveLocalFileSystemURL(cordova.file.dataDirectory, (fs: any) => {
                // console.log('file system open: ');
                fs.getFile(fileName, {}, (fileEntry: any) => {
                    console.log('fileEntry is file?', fileEntry.isFile.toString());
                    console.log('remove file path:', fileEntry.fullPath);
                    fileEntry.remove(resolve, reject, reject);
                }, reject);
            });
        });
    }

    async storeImage(imageData: Blob, storageType: ImageStorageType): Promise<string> {
        console.log('storing image: ' + storageType);
        if (storageType == 'temporary') {
            // just create objectURL and keep a reference to the blob in memory
            const objectURL = window.URL.createObjectURL(imageData);
            this.objectUrls[objectURL] = imageData;
            return TEMP_IMAGE_PREFIX + objectURL;
        }
        else {
            // Store image on filesystem and keep a reference in indexedDB
            const storedImage: IStoredImage = {
                timestamp: Date.now(),
                filename: '',
                data: imageData
            };
            const imageId = await this.getDB().images.add(storedImage);
            const fileName = `image${imageId}.jpg`;
            await this._writeFile(fileName, imageData);
            storedImage.filename = fileName;
            try {
                // changed to update to handle windows issue
                // const putid = await this.getDB().images.put(storedImage);
                const putid = await this.getDB().images.update(imageId, {filename: fileName});
                console.log('put update iamge: ' + putid);
            } catch (e) {
                // windows fails to put
                console.error(e);
            }
            return STORED_IMAGE_PREFIX + imageId;
        }
    }

    async getImageObjectUrl(localImageUrl: string, reloadImageUrl?: boolean): Promise<string> {
        const [storageType, imageId] = getImageStorageType(localImageUrl);
        if (storageType == 'temporary') {
            return imageId;
        }
        else {
            // For windows: One or more blob URLs were revoked by closing the blob for which they were created.
            // These URLs will no longer resolve as the data backing the URL has been freed.
            // so let just reload the blob
            const reloadImageUrlFromDB = typeof reloadImageUrl == 'undefined'? false: reloadImageUrl;
            if (reloadImageUrlFromDB == false && imageId in this.storedImageObjectUrls) {
                return this.storedImageObjectUrls[Number(imageId)];
            }
            else {
                const imageData = await this.getImageData(localImageUrl);
                const objectURL = window.URL.createObjectURL(imageData);
                this.objectUrls[objectURL] = imageData;
                this.storedImageObjectUrls[Number(imageId)] = objectURL;
                return objectURL;
            }
        }
    }

    async getImageData(localImageUrl: string): Promise<Blob> {
        const [storageType, imageId] = getImageStorageType(localImageUrl);
        if (storageType == 'temporary') {
            return this.objectUrls[imageId];
        }
        else {
            const image = await this.getDB().images.get(Number(imageId));
            if (isDefined(image)) {
                try {
                    const data = await this._readFile(image!.filename);
                    return data;
                }  catch (e) {
                    console.log(e)
                    if (image!.data) {
                        this._writeFile(image!.filename, image!.data)
                        return image!.data;
                    } else{
                        throw e
                    }
                }
            } else {
                throw new Error('missing image')
            }
        }
    }

    async makePermenant(tempImageUrl: string): Promise<string> {
        const [storageType, imageId] = getImageStorageType(tempImageUrl);
        if (storageType != 'temporary') {
            throw new Error('makePermenant() called on a non-temporary image');
        }
        const imageData = this.objectUrls[imageId];
        const storedUrl = await this.storeImage(imageData, 'permanent');
        const storedImageId = getImageStorageType(storedUrl)[1];
        this.storedImageObjectUrls[Number(storedImageId)] = imageId;
        return storedUrl;
    }

    async removeImage(localImageUrl: string): Promise<void> {
        console.log('removing image', localImageUrl);
        const [storageType, imageId] = getImageStorageType(localImageUrl);
        if (storageType == 'temporary') {
            window.URL.revokeObjectURL(imageId);
            delete this.objectUrls[imageId];
        }
        else {
            // Revoke ObjectURL
            const objectUrl = this.storedImageObjectUrls[Number(imageId)];
            if (objectUrl) {
                window.URL.revokeObjectURL(objectUrl);
            }
            // Get filename from DB and delete it
            const image = await this.getDB().images.get(Number(imageId));
            if (isDefined(image)) {
                await this._removeFile(image!.filename);
            }
            // Delete database record
            await this.getDB().images.delete(Number(imageId));
        }
    }

    revokeObjectUrls(): void {
        const objectUrls = Object.keys(this.objectUrls);
        console.log(`revoking ${objectUrls.length} object URLs`);
        for (const objectUrl of objectUrls) {
            window.URL.revokeObjectURL(objectUrl);
        }
        this.storedImageObjectUrls = {};
        this.objectUrls = {};
    }

    async export(option: ExportOptions ): Promise<Blob> {
        // @ts-ignore https://github.com/dfahlander/Dexie.js/issues/1262
        return exportDB(this.getDB(), option);
    }

    async import(exportedData: Blob): Promise<void> {
        const data = await (new Response(exportedData)).text();
        const json = JSON.parse(data);
        if (json.data?.databaseName =='OnePlace_images' && json.data?.databaseVersion == this.getDB().verno) {
            // @ts-ignore https://github.com/dfahlander/Dexie.js/issues/1262
            await importInto(this.getDB(), exportedData, {overwriteValues: true,  noTransaction:true});
            await this.getDB().images.toCollection().each(async (image) =>{
                if (isDefined(image)) {
                    await this._removeFile(image.filename);
                }
            });
        } else {
            throw new Error('invalid database file');
        }
    }

    removeFiles(assetList: string[]) {
        const promises: any[] = [];
        assetList.forEach(cacheKey => {
            promises.push(this.removeImage(cacheKey));
        })
        return Promise.all(promises);
    }

}
