
import { CONFIG } from '../../config';
import { IFranchise, ISites, ITags, IFranchisees, IChecklistTemplates, IChecklist, IChecklistEmailResponse,
     IFavouriteChecklist, IChecklistSearchParams, ICompletedChecklist, IChecklistTemplateList, IEmailFields, IMyNotificationsWrapper, IAttachment, IPerson, IPersonType, IFranchisee, ISite, IApi as OneplaceComponentsIApi, Api as OneplaceComponentsApi, IApiClientConfig, IIDAndName } from 'oneplace-components';
import { UserFacingError } from '../../errors/UserFacingError';
import { IDashboardData, IScheduleData, IDashboardTicket, IDashboardNotes, ISummaryData, IChecklistDashboardFilter} from '../../models/Dashboard';
import { ITicketTypes, ITicketCategories, ITicket, INewTicketComment, ITicketSearchParams } from '../../models/Tickets';
import { postData, putData } from './postData';
import { IIncidentEmailResponse, IIncidentTypes, IIncidentResponse, IIncidentsListEntryWrapper, IIncidentSearchParams, IIncidentCategories, IIncidentTicketsCount, IncidentExcludableField } from '../../models/Incident';
import { UnauthorisedError } from '../../errors/UnauthorisedError';
import { createQueryString } from '../../utils';
import { IOnePlaceAuth } from '../../auth/OnePlaceAuth';
import { INetworkStatus } from '../../network/NetworkStatus';
import { INoteWrapper } from '../../models/Note';
import { UnauthenticatedError } from '../../errors/UnauthenticatedError';
import { IUserCapabilities} from '../../models/User';
import { BadRequestError } from '../../errors/BadRequestError';
import { NotFoundError } from '../../errors/NotFoundError';
import { ForbiddenError } from '../../errors/ForbiddenError';
import { IHazardSearchParams, IHazardsListEntryWrapper, IHazardResponse, IHazardComment, IHazardTicketsCount, IHsComment } from '../../models/Hazard';
import { TimeoutError } from '../../errors/TimeoutError';
import { logError } from '../../logging';
import { IChecklistResponse } from '../../components/checklists/utils/submitChecklist';
import { ENV } from '../../environment';
import { ISearchPerson } from '../../models/Person';


export type ScheduleType = 'completed' | 'week' | 'overdue' | 'today';

export interface IApi  extends OneplaceComponentsIApi{

    getAuthenticatedPhotoUrl(url: string): string;
    validateAuth(): Promise<void>;
    getMessages(): Promise<{ [key: string]: string }>;
    getFranchise(): Promise<IFranchise>;
    getFranchisee(franchiseId: number, franchiseeId: number): Promise<IFranchisee>;
    updateFranchisee(franchiseId: number, franchisee: IFranchisee): Promise<number>;
    getFranchiseeAssignments(franchiseId: number): Promise<IFranchisees>;
    getSiteAssignments(franchiseId: number, from?: number, howMany?: number): Promise<ISites>;
    getSites(franchiseId: number, from?: number, howMany?: number): Promise<ISites>;
    getFranchiseeSites(franchiseId: number, franchiseeId: number): Promise<ISites>
    getSitesArchived(franchiseId: number): Promise<ISites>;
    getSite(franchiseId: number, franchiseeId: number, siteId: number): Promise<ISite>;
    updateSite(franchiseId: number, franchiseeId: number, site: ISite): Promise<number>;
    getTags(franchiseId: number): Promise<ITags>;
    // return the templateChecklistVersion entity
    getScheduleOnlyChecklistTemplates(franchiseId: number): Promise<IChecklistTemplates>;
    // return the templateChecklistVersion entity
    getChecklistTemplates(franchiseId: number): Promise<IChecklistTemplates>;
    // return the templateChecklist entity
    getChecklistTemplateList(franchiseId: number): Promise<IChecklistTemplateList>;
    getChecklistTemplate(franchiseId: number, franchiseeId: number, checklistId: number, request_type?: 'ticket' | 'checklist'): Promise<IChecklist>;
    getChecklist(franchiseId: number, franchiseeId: number, checklistId: number): Promise<IChecklist>;
    getChecklistTicketsList(franchiseId: number, franchiseeId: number, siteId: number): Promise<ITicket[]>;
    getChecklistTicketsCount(franchiseId: number, franchiseeId: number, siteId: number): Promise<number>;
    searchCompletedChecklists(
        franchiseId: number, franchiseeId: number,
        params: IChecklistSearchParams, fromId?: number): Promise<ICompletedChecklist[]>;
    submitChecklist(
        franchiseId: number, franchiseeId: number,
        checklistId: number, checklistData: FormData, timeout: number): Promise<IChecklistResponse>;
    getChecklistEmail(franchiseId: number, franchiseeId: number, checklistId: number): Promise<IChecklistEmailResponse>;
    sendChecklistEmail(
        franchiseId: number, franchiseeId: number,
        checklistId: number, message: IEmailFields): Promise<void>;
    getOverviewDashboardData(franchiseId: number, checklistDashboardFilter: IChecklistDashboardFilter | null): Promise<IDashboardData>;
    getFranchiseeDashboardData(franchiseId: number, franchiseeId: number, checklistDashboardFilter: IChecklistDashboardFilter | null): Promise<IDashboardData>;
    getSiteDashboardData(franchiseId: number, franchiseeId: number, siteId: number, checklistDashboardFilter: IChecklistDashboardFilter | null): Promise<IDashboardData>;
    getOverviewSchedule(franchiseId: number, checklistDashboardFilter: IChecklistDashboardFilter | null,
        type: ScheduleType, howMany?: number, fromId?: number): Promise<IScheduleData>;
    getFranchiseeSchedule(
        franchiseId: number, franchiseeId: number,
        checklistDashboardFilter: IChecklistDashboardFilter | null,
        type: ScheduleType, howMany?: number, fromId?: number): Promise<IScheduleData>;
    getSiteSchedule(
        franchiseId: number, franchiseeId: number, siteId: number,
        checklistDashboardFilter: IChecklistDashboardFilter | null,
        type: ScheduleType, howMany?: number, fromId?: number): Promise<IScheduleData>;
    getTicketTypes(franchiseId: number): Promise<ITicketTypes>;
    getTicketCategories(franchiseId: number, excludeChecklist?: boolean): Promise<ITicketCategories>;
    getTicket(franchiseId: number, ticketId: number, excludeChecklist?: boolean): Promise<ITicket>;
    getTicketFranchisee(franchiseId: number, ticketId: number): Promise<IFranchisee>;
    getTicketSite(franchiseId: number, ticketId: number): Promise<ISite>;
    newTicket(
        franchiseId: number, franchiseeId: number, ticketData: FormData, timeout: number): Promise<number>;
    updateTicket(
        franchiseId: number, franchiseeId: number, ticketId: number, ticketData: FormData, timeout: number): Promise<number>;
    addTicketComment(
        franchiseId: number, franchiseeId: number, ticketId: number, comment: INewTicketComment, timeout: number): Promise<void>;
    searchTickets(
        franchiseId: number, franchiseeId: number, params: ITicketSearchParams, fromId?: number): Promise<ITicket[]>;
    getOverviewMyTickets(franchiseId: number, fromId?: number): Promise<IDashboardTicket[]>;
    getFranchiseeMyTickets(franchiseId: number, franchiseeId: number, fromId?: number): Promise<IDashboardTicket[]>;
    getSiteMyTickets(
        franchiseId: number, franchiseeId: number, siteId: number, fromId?: number): Promise<IDashboardTicket[]>;
    getIncidentTemplate(franchiseId: number, franchiseeId: number, params?: {incidentTypeId?: number, exclude?: IncidentExcludableField[]}): Promise<IIncidentResponse>;
    getIncidentCategories(franchiseId: number, incidentId?: number): Promise<IIncidentCategories>;
    getIncidentTypes(franchiseId: number): Promise<IIncidentTypes>;
    getIncident(franchiseId: number, franchiseeId: number, incidentId: number, exclude?: IncidentExcludableField[]): Promise<IIncidentResponse>;
    newIncident(
        franchiseId: number, franchiseeId: number, incidentData: FormData, timeout: number): Promise<number>;
    updateIncident(franchiseId: number, franchiseeId: number, incidentId: number, incidentData: FormData, timeout: number): Promise<number>;
    getIncidentEmail(franchiseId: number, franchiseeId: number, incidentId: number): Promise<IIncidentEmailResponse>;
    sendIncidentEmail(
            franchiseId: number, franchiseeId: number,
            checklistId: number, message: IEmailFields): Promise<void>;
    getHazard(franchiseId: number, hazardId: number, excludeSitesList?: boolean): Promise<IHazardResponse>;
    getActiveHazards(franchiseId: number): Promise<IIDAndName[]>
    newHazard(
        franchiseId: number, hazardData: FormData): Promise<number>;
    updateHazard(
        franchiseId: number, hazardId: number, hazardData: FormData): Promise<number>;
    addHsComment(
        franchiseId: number, franchiseeId: number | null, hazardId: number | null, incidentId: number | null, comment: IHsComment, timeout: number): Promise<IHazardComment>;

    getFavouriteChecklists(franchiseId: number): Promise<IFavouriteChecklist[]>;
    removeFavouriteChecklist(franchiseId: number, checklistId: number): Promise<void>;
    searchIncidents(franchiseId: number, franchiseeId: number, fromId: number, params: IIncidentSearchParams): Promise<IIncidentsListEntryWrapper[]>;
    searchHazards(franchiseId: number, fromId: number, params: IHazardSearchParams): Promise<IHazardsListEntryWrapper[]>;
    getFranchiseeNotes(franchiseId: number, franchiseeId: number, fromId?: number): Promise<IDashboardNotes>;
    getSiteNotes(franchiseId: number, franchiseeId: number, siteId: number, fromId: number): Promise<IDashboardNotes>;
    getNote(franchiseId: number, franchiseeId: number, noteId: number): Promise<INoteWrapper>;
    newNote(franchiseId: number, franchiseeId: number, noteData: FormData, timeout: number): Promise<number>;
    updateNote(franchiseId: number, franchiseeId: number, noteId: number, noteData: FormData, timeout: number): Promise<number>;
    getSummary(franchiseId: number): Promise<ISummaryData>;

    getPeopleForIncident(franchiseId: number, franchiseeId: number, siteId: number, incidentTypeId: number): Promise<IPerson[]>;
    getPeopleEmailsForIncident(franchiseId: number, personIds: number[]) : Promise<string[]>;
    getPeopleForTicket(franchiseId: number, franchiseeId: number, siteId: number, ticketCategoryId: number): Promise<IPerson[]>;
    getPeopleForChecklist(franchiseId: number, franchiseeId: number, siteId: number, checklistTemplateId: number): Promise<IPerson[]>;
    getPeopleForAutocomplete(franchiseId: number, franchiseeId: number, siteId: number, q: string, ticketCategoryId: number, incidentTypeId: number, checklistTemplateId: number): Promise<IPerson[]>;

    getPeople(franchiseId: number): Promise<IPerson[]>;  // used for the people filter on search pages, online only
    syncPeople(franchiseId: number, howMany: number, from: number,lastSyncTime: string | null): Promise<IPerson[]>;  // used in sync function for local DB
    syncPersonTypes(franchiseId: number, lastSyncTime: string | null): Promise<IPersonType[]>;  // used in sync function for local DB
    getPersonTypes(franchiseId: number): Promise<IPersonType[]>;
    searchPeople(franchiseId: number, from: number, siteId?: number, personTypesIds?: number[], name?: string): Promise<IPerson[]>; // used on people page
    searchPeopleByName(name: string, franchiseId: number, from: number, howMany: number): Promise<ISearchPerson[]>;
    searchPeopleByIds(franchiseId: number, ids: (string | number)[]): Promise<ISearchPerson[]>;
    getPerson(franchiseId: number, personId: number): Promise<IPerson>;
    updatePerson(franchiseId: number, person: IPerson): Promise<number>;
    register(platform: string, token: string): Promise<boolean>;
    unregister(address: string): Promise<number>;
    getMyNotifications(franchiseId: number, batchSize: number, newOffset: number): Promise<IMyNotificationsWrapper>;
    markNotificationsAsRead(franchiseId: number, notificationId?: number): Promise<boolean>;
    getUnreadNotificationsCount(franchiseId: number): Promise<number>;
    syncFranchiseeSites(franchiseId: number): Promise<Map<string, IIDAndName[]>>;
    getHazardTickets(franchiseId: number, hazardId: number, fromId: number): Promise<ITicket[]>;
    getHazardTicketsCount(franchiseId: number, hazardId: number): Promise<IHazardTicketsCount>;
    getIncidentTickets(franchiseId: number, incidentId: number, fromId: number): Promise<ITicket[]>;
    getIncidentTicketsCount(franchiseId: number, incidentId: number): Promise<IIncidentTicketsCount>;
}

export class Api implements IApi {

    constructor(
        private net: INetworkStatus,
        private auth: IOnePlaceAuth
    ) { }

    private getChecklistTemplateQueryParams(checklistDashboardFilter: IChecklistDashboardFilter): string{
        const templates = checklistDashboardFilter && checklistDashboardFilter.templates ?
            checklistDashboardFilter.templates.map(t => t.id).join(',') : ''
        return templates.length > 0 ?  `&checklistTemplateIds=${templates}` : ''
    }

    // same as AssetCacheManager.validateAuth
    async validateAuth(): Promise<void> {
        if (this.net.isOffline) {
            console.log('navigator.connection.type: ')
            console.log('navigator.onLine: ' + navigator.onLine)
            throw new Error('Network is offline ' );
        }
        else if (this.auth.cachedUser) {
            throw new Error('Currently working offline');
        }
        else {
            try {
                const authenticated = await this.auth.checkAuth();
                if (!authenticated) {
                    throw new UnauthenticatedError('User is not authenticated');
                }
            } catch (e) {
                logError(e);
                throw new UnauthenticatedError('Error User is not authenticated');
            }
        }
    }

    // same as AssetCacheManager.getAuthHeaders
    getAuthHeaders(): HeadersInit {
        if (this.auth.tokens) {
            return {
                'Authorization': 'Bearer ' + this.auth.tokens.token,
                'X-App-Version': CONFIG.build
            };
        }
        else {
            return {};
        }
    }

    getAuthenticatedPhotoUrl(url: string): string {
        // Adds access token to URL if it is on the API
        let photoUrl = url;
        if (!photoUrl.startsWith('blob:') && this.auth.tokens!.token) {
            photoUrl += '?access_token=' + this.auth.tokens!.token;
        }
        return photoUrl;
    }

    validateResponseStatus(status: number, errorMsg: string): void{
        switch (status){
            case 401 : throw new UnauthorisedError(errorMsg);
            case 0   : throw new UnauthorisedError(errorMsg);
            case 400 : throw new BadRequestError(errorMsg);
            case 403 : throw new ForbiddenError(errorMsg);
            case 404 : throw new NotFoundError(errorMsg);
            case 408 : throw new TimeoutError(errorMsg);
            case 460 : throw new TimeoutError(errorMsg);
            case 500 : throw new TimeoutError(errorMsg);
            case 501 : throw new TimeoutError(errorMsg);
            case 502 : throw new TimeoutError(errorMsg);
            case 503 : throw new TimeoutError(errorMsg);
            case 504 : throw new TimeoutError(errorMsg);
        }
    }
    async getUserCapabilities(): Promise<IUserCapabilities> {
        await this.validateAuth();
        let url = CONFIG.apiUrl + '/capabilities'
        if (ENV.os == 'browser' || ENV.os =='android' || ENV.os =='ios' || ENV.platform === 'web') {
            if (ENV.platform == 'cordova') {
                url += '?platform=' + ENV.os
            } else{
                url += '?platform=browser'
            }
        }
        const res = await fetch(url, {
            headers: this.getAuthHeaders()
        });
        this.validateResponseStatus(res.status, 'getUserCapabilities()');
        const data = await res.json();
        if (!data.capabilities) {
            throw new Error('Capabilities endpoint did not return capabilities key');
        }
        return data.capabilities as IUserCapabilities;
    }

    async getMessages(): Promise<{ [key: string]: string; }> {
        const REMOVE_PREFIX = 'com.oneplaceonline.business.customfields.entity.customLabel.';
        const REPLACE_PREFIX = 'customLabel_';

        await this.validateAuth();
        const res = await fetch(CONFIG.apiUrl + '/messages', {
            headers: this.getAuthHeaders()
        });
        this.validateResponseStatus(res.status, 'getMessages()');
        const data = await res.text();
        const dataCleansed = data.split(REMOVE_PREFIX).join(REPLACE_PREFIX);
        const messages: { [key: string]: string } = {};
        dataCleansed.split('\n').forEach((line) => {
            const [key, value] = line.split('=');
            if (key) {
                messages[key] = value;
            }
        });
        return messages;
    }

    async getFranchise(): Promise<IFranchise> {
        await this.validateAuth();
        const res = await fetch(CONFIG.apiUrl + '/franchise', {
            headers: this.getAuthHeaders()
        });
        this.validateResponseStatus(res.status, 'getFranchise()');
        const data = await res.json();
        if (!data.franchise) {
            throw new Error('Franchise endpoint did not return franchise key');
        }
        return data.franchise as IFranchise;
    }

    async getFranchiseeAssignments(franchiseId: number): Promise<IFranchisees> {
        await this.validateAuth();
        const res = await fetch(`${CONFIG.apiUrl}/franchise/${franchiseId}/franchisee/assignments?howmany=-1`, {
            headers: this.getAuthHeaders()
        });
        this.validateResponseStatus(res.status, 'getFranchiseeAssignments()');
        const data = await res.json();
        if (!data.assignments || !data.assignments.franchisees) {
            throw new Error('Franchisee assignments endpoint did not return franchisees key');
        }
        return {
            franchisees: data.assignments.franchisees
        };
    }

    async getSiteAssignments(franchiseId: number, from?: number, howMany?: number): Promise<ISites> {
        await this.validateAuth();

        const params = []
        if(from !== undefined && from !== null){
            params.push(`from=${from}`)
        }
        if(howMany){
            params.push(`howmany=${howMany}`)
        }
        const paramValues = params.length > 0 ? `?${params.join('&')}` : ''

        const res = await fetch(`${CONFIG.apiUrl}/franchise/${franchiseId}/sites/assignments${paramValues}`,
            {headers: this.getAuthHeaders()}
        );

        this.validateResponseStatus(res.status, 'getSiteAssignments()');
        const data = await res.json();
        if (!data.assignments || !data.assignments.sites) {
            throw new Error('Site assignments endpoint did not return sites key');
        }
        return {
            sites: data.assignments.sites
        };
    }

    async getSites(franchiseId: number, from?: number, howMany?: number): Promise<ISites> {
        await this.validateAuth();

        const params = []
        if(from !== undefined && from !== null){
            params.push(`from=${from}`)
        }
        if(howMany){
            params.push(`howmany=${howMany}`)
        }

        const paramValues = params.length > 0 ? `?${params.join('&')}` : ''

        const res = await fetch(`${CONFIG.apiUrl}/franchise/${franchiseId}/sites${paramValues}`, {
            headers: this.getAuthHeaders()
        });

        this.validateResponseStatus(res.status, 'getSites()');
        const data = await res.json();
        if (!data.sites) {
            throw new Error('Franchise Sites endpoint did not return sites key');
        }
        return data as ISites;
    }

    async getFranchiseeSites(franchiseId: number, franchiseeId: number ): Promise<ISites> {
        await this.validateAuth();
        const res = await fetch(`${CONFIG.apiUrl}/franchise/${franchiseId}/franchisee/${franchiseeId}/sites`, {
            headers: this.getAuthHeaders()
        });
        this.validateResponseStatus(res.status, 'getFranchiseeSites()');
        const data = await res.json();
        if (!data.sites) {
            throw new Error('Franchisee Sites endpoint did not return sites key');
        }
        return data as ISites;
    }

    async getSitesArchived(franchiseId: number): Promise<ISites> {
        await this.validateAuth();
        const res = await fetch(`${CONFIG.apiUrl}/franchise/${franchiseId}/sites?rec_status=archived`, {
            headers: this.getAuthHeaders()
        });
        this.validateResponseStatus(res.status, 'getSitesArchived()');
        const data = await res.json();
        if (!data.sites) {
            throw new Error('Franchise Archived Sites endpoint did not return sites key');
        }
        return data as ISites;
    }

    async getTags(franchiseId: number): Promise<ITags> {
        await this.validateAuth();
        const res = await fetch(`${CONFIG.apiUrl}/franchise/${franchiseId}/tags`, {
            headers: this.getAuthHeaders()
        });
        this.validateResponseStatus(res.status, 'getTags()');
        const data = await res.json();
        if (!data.tags) {
            throw new Error('Franchise Tags endpoint did not return tags key');
        }
        return data as ITags;
    }

    async getPeople(franchiseId: number): Promise<IPerson[]> {
        await this.validateAuth();
        const res = await fetch(`${CONFIG.apiUrl}/franchise/${franchiseId}/people?option=all`, {
            headers: this.getAuthHeaders()
        });
        this.validateResponseStatus(res.status, 'getPeople()');
        const data = await res.json();
        if (!data) {
            throw new Error('People endpoint did not return people data');
        }
        return data;
    }

    async syncPeople(franchiseId: number, howMany: number, from: number, lastSyncTime: string | null): Promise<IPerson[]> {
        await this.validateAuth();
        let url = `${CONFIG.apiUrl}/franchise/${franchiseId}/people/sync?howMany=${howMany}&from=${from}`;

        if (lastSyncTime) {
            url += '&lastSyncTime=' + lastSyncTime;
        }
        const res = await fetch(url, {
            headers: this.getAuthHeaders()
        });
        this.validateResponseStatus(res.status, 'syncPeople()');
        const data = await res.json();
        if (!data) {
            throw new Error('People endpoint did not return people data');
        }
        return data;
    }

    async syncPersonTypes(franchiseId: number, lastSyncTime: string | null): Promise<IPersonType[]> {
        await this.validateAuth();
        let url = `${CONFIG.apiUrl}/franchise/${franchiseId}/persontypes/sync`;

        if (lastSyncTime) {
            url += '?lastSyncTime=' + lastSyncTime;
        }
        const res = await fetch(url, {
            headers: this.getAuthHeaders()
        });
        this.validateResponseStatus(res.status, 'syncPersonTypes()');
        const data = await res.json();
        if (!data) {
            throw new Error('Person Sync endpoint did not return person type data');
        }
        return data;
    }

    async getScheduleOnlyChecklistTemplates(franchiseId: number): Promise<IChecklistTemplates> {
        await this.validateAuth();
        const res = await fetch(
            `${CONFIG.apiUrl}/franchise/${franchiseId}/franchisee/-1/checklistTemplates?type=scheduled`,
            {
                headers: this.getAuthHeaders()
            });
        this.validateResponseStatus(res.status, 'getChecklistTemplates()');
        const data = await res.json();
        if (!(data instanceof Array)) {
            throw new Error('Checklist Templates endpoint did not return an array of templates');
        }
        const templates: IChecklistTemplates = {
            templates: data
        };
        return templates;
    }

    async getChecklistTemplates(franchiseId: number): Promise<IChecklistTemplates> {
        await this.validateAuth();
        const res = await fetch(
            `${CONFIG.apiUrl}/franchise/${franchiseId}/franchisee/-1/checklistTemplates`,
            {
                headers: this.getAuthHeaders()
            });
        this.validateResponseStatus(res.status, 'getChecklistTemplates()');
        const data = await res.json();
        if (!(data instanceof Array)) {
            throw new Error('Checklist Templates endpoint did not return an array of templates');
        }
        const templates: IChecklistTemplates = {
            templates: data
        };
        return templates;
    }

    async getChecklistTemplateList(franchiseId: number): Promise<IChecklistTemplateList> {
        await this.validateAuth();
        const res = await fetch(
            `${CONFIG.apiUrl}/franchise/${franchiseId}/franchisee/checklistTemplates`,
            {
                headers: this.getAuthHeaders()
            });
        this.validateResponseStatus(res.status, 'getChecklistTemplateList()');
        const data = await res.json();
        if (!(data.templates instanceof Array)) {
            throw new Error('Checklist Templates endpoint did not return an array of templates');
        }
        const templates: IChecklistTemplateList = {
            templates: data.templates
        };
        return templates;
    }

    async getChecklistTemplate(franchiseId: number, franchiseeId: number, checklistId: number , request_type ='checklist' ): Promise<IChecklist> {
        await this.validateAuth();
        const res = await fetch(
            `${CONFIG.apiUrl}/franchise/${franchiseId}/franchisee/${franchiseeId}/checklist/${checklistId}?request_type=${request_type}`,
            {
                headers: this.getAuthHeaders()
            });
        this.validateResponseStatus(res.status, 'getChecklistTemplate()');
        const data = await res.json();
        if (!data.checklist) {
            throw new Error('Checklist template endpoint did not return a checklist');
        }
        return data.checklist as IChecklist;
    }

    async getChecklist(franchiseId: number, franchiseeId: number, checklistId: number): Promise<IChecklist> {
        await this.validateAuth();
        const res = await fetch(
            `${CONFIG.apiUrl}/franchise/${franchiseId}/franchisee/${franchiseeId}/checklist/${checklistId}`,
            {
                headers: this.getAuthHeaders()
            });
        this.validateResponseStatus(res.status, 'getChecklist()');
        const data = await res.json();
        if (!data.checklist) {
            throw new Error('Checklist endpoint did not return a checklist');
        }
        return data.checklist as IChecklist;
    }

    async getChecklistTicketsList(
        franchiseId: number, franchiseeId: number, siteId?: number
    ): Promise<ITicket[]> {
        await this.validateAuth();
        // tslint:disable-next-line:max-line-length
        let url = `${CONFIG.apiUrl}/franchise/${franchiseId}/franchisee/${franchiseeId}/ticket/checklistTickets`;

        if (siteId) {
            url += '?siteId=' + siteId;
        }
        const res = await fetch(url, {
            headers: this.getAuthHeaders()
        });
        this.validateResponseStatus(res.status, 'searchChecklistTickets()');
        const data = await res.json();
        if (!data.tickets) {
            throw new Error('Search did not return any tickets data.');
        }
        return data.tickets as ITicket[];
    }
    async getChecklistTicketsCount(franchiseId: number, franchiseeId: number, siteId?: number): Promise<number> {
        await this.validateAuth();
        let url = `${CONFIG.apiUrl}/franchise/${franchiseId}/franchisee/${franchiseeId}/ticket/checklistTicketsCount`;

        if (siteId) {
            url += '?siteId=' + siteId;
        }
        const res = await fetch(url, {
            headers: this.getAuthHeaders()
        });
        this.validateResponseStatus(res.status, 'searchChecklistTicketsCount()');
        const data = await res.json();
        if (!DataTransferItem) {
            throw new Error('Search did not return any tickets data.');
        }
        return data;
    }
    async searchCompletedChecklists(
        franchiseId: number, franchiseeId: number, params: IChecklistSearchParams, fromId?: number
    ): Promise<ICompletedChecklist[]> {
        await this.validateAuth();
        let url = `${CONFIG.apiUrl}/franchise/${franchiseId}/checklists?howmany=10`;
        if (franchiseeId > -1) {
            url += '&' + encodeURIComponent('franchiseeId') + '=' + encodeURIComponent(franchiseeId);
        }
        if (Object.keys(params).length > 0) {
            url += '&' + createQueryString(params);
        }
        if (fromId) {
            url += '&from=' + fromId;
        }
        const res = await fetch(url, {
            headers: this.getAuthHeaders()
        });
        this.validateResponseStatus(res.status, 'searchCompletedChecklists()');
        const data = await res.json();
        if (!data.checklists) {
            throw new Error('Completed checklist search endpoint did not return expected data.');
        }
        return data.checklists as ICompletedChecklist[];
    }

    async submitChecklist(franchiseId: number, franchiseeId: number, checklistId: number, checklistData: FormData, timeout: number): Promise<IChecklistResponse> {
        await this.validateAuth();
        const [status, response] = await postData(
            `${CONFIG.apiUrl}/franchise/${franchiseId}/franchisee/${franchiseeId}/checklist/${checklistId}`,
            this.getAuthHeaders(),
            checklistData,
            timeout
        );
        if ('success' in response && !response.success  && typeof response.data == 'string') {
            throw new UserFacingError(response.data);
        }
        if (!('data' in response) ||'data' in response &&  (!('Location' in response.data))) {
            logError(`${status} - ${response}`)
            throw new Error('Submit checklist endpoint did not return the correct response. Please try again');
        }
        // this should run after the specified error checks in each function
        this.validateResponseStatus(status, 'submitChecklist()');
        const checklistResponse = {
            checklistId : Number(response.data.Location.split('/').slice(-1)[0]),
            score: response.data.score,
            unansweredQuestions: response.data.unansweredQuestions
        };
        return checklistResponse as IChecklistResponse;
    }

    async getChecklistEmail(franchiseId: number, franchiseeId: number, checklistId: number): Promise<IChecklistEmailResponse> {
        await this.validateAuth();
        // const timestamp = Date.now();
        const res = await fetch(
            `${CONFIG.apiUrl}/franchise/${franchiseId}/franchisee/${franchiseeId}/checklist/${checklistId}/email`,
            {
                headers: this.getAuthHeaders(),
            });
        this.validateResponseStatus(res.status, 'getChecklistEmail()');
        const response: IChecklistEmailResponse = await res.json();
        if (!response.message) {
            throw new Error('Checklist email endpoint did not return a message.');
        }
        return response;
    }

    async sendChecklistEmail(franchiseId: number, franchiseeId: number, checklistId: number, message: IEmailFields): Promise<void> {
        await this.validateAuth();
        const [status, response] = await postData(
            `${CONFIG.apiUrl}/franchise/${franchiseId}/franchisee/${franchiseeId}/checklist/${checklistId}/email`,
            {
                ...this.getAuthHeaders(),
                'Content-Type': 'application/json'
            },
            JSON.stringify({ message })
        );
        try {
            this.validateResponseStatus(status, response.data);
        } catch (e) {
            throw new UserFacingError(!response.data ? 'Checklist email send failed. Response: ' + JSON.stringify(response) : response.data);
        }
        if (!response.success) {
            throw new UserFacingError(!response.data ? 'Checklist email send failed. Response: ' + JSON.stringify(response) : response.data);
        }
        return response;
    }

    async getOverviewDashboardData(franchiseId: number, checklistDashboardFilter: IChecklistDashboardFilter | null): Promise<IDashboardData> {
        await this.validateAuth();

        let url = `${CONFIG.apiUrl}/franchise/${franchiseId}/dashboard?status=myTickets&howmany=10`
        if(checklistDashboardFilter){
            url += this.getChecklistTemplateQueryParams(checklistDashboardFilter)
        }

        const res = await fetch(url, {
            headers: this.getAuthHeaders()
        });
        this.validateResponseStatus(res.status, 'getOverviewDashboardData()');
        const data = await res.json();
        if (!data.dashboard) {
            throw new Error('Overview Dashboard endpoint did not return dashboard data.');
        }
        return data as IDashboardData;
    }

    async getFranchiseeDashboardData(franchiseId: number, franchiseeId: number, checklistDashboardFilter: IChecklistDashboardFilter | null): Promise<IDashboardData> {
        await this.validateAuth();

        let url = `${CONFIG.apiUrl}/franchise/${franchiseId}/franchisee/${franchiseeId}/overview?status=myTickets&howmany=10`
        if(checklistDashboardFilter){
            url += this.getChecklistTemplateQueryParams(checklistDashboardFilter)
        }
        const res = await fetch(url, {
            headers: this.getAuthHeaders()
        });
        this.validateResponseStatus(res.status, 'getFranchiseeDashboardData()');
        const data = await res.json();
        if (!data.dashboard) {
            throw new Error('Franchisee Dashboard endpoint did not return dashboard data.');
        }
        return data as IDashboardData;
    }

    async getSiteDashboardData(franchiseId: number, franchiseeId: number, siteId: number, checklistDashboardFilter: IChecklistDashboardFilter | null): Promise<IDashboardData> {
        await this.validateAuth();

        let url = `${CONFIG.apiUrl}/franchise/${franchiseId}/franchisee/${franchiseeId}/site/${siteId}/dashboard?status=myTickets&howmany=10`
        if(checklistDashboardFilter){
            url += this.getChecklistTemplateQueryParams(checklistDashboardFilter)
        }

        const res = await fetch(url, {
            headers: this.getAuthHeaders()
        });
        this.validateResponseStatus(res.status, 'getSiteDashboardData()');
        const data = await res.json();
        if (!data.dashboard) {
            throw new Error('Site Dashboard endpoint did not return dashboard data.');
        }
        return data as IDashboardData;
    }

    async getOverviewSchedule(franchiseId: number, checklistDashboardFilter: IChecklistDashboardFilter | null, type: ScheduleType, howMany = 10, fromId?: number): Promise<IScheduleData> {
        await this.validateAuth();
        let url = `${CONFIG.apiUrl}/franchise/${franchiseId}/schedule?status=${type}&howmany=${howMany}`;
        if (fromId) {
            url += '&from=' + fromId;
        }
        if(checklistDashboardFilter){
            url += this.getChecklistTemplateQueryParams(checklistDashboardFilter)
        }
        const res = await fetch(url, {
            headers: this.getAuthHeaders()
        });
        this.validateResponseStatus(res.status, 'getOverviewSchedule()');
        const data = await res.json();
        if (!data.schedule || !data.schedule.checklists) {
            throw new Error('Overview Schedule endpoint did not return checklist data.');
        }
        return data.schedule as IScheduleData;
    }

    async getFranchiseeSchedule(franchiseId: number, franchiseeId: number, checklistDashboardFilter: IChecklistDashboardFilter | null, type: ScheduleType, howMany = 10, fromId?: number): Promise<IScheduleData> {
        await this.validateAuth();
        let url = `${CONFIG.apiUrl}/franchise/${franchiseId}/franchisee/${franchiseeId}/schedule?status=${type}&howmany=${howMany}`;
        if (fromId) {
            url += '&from=' + fromId;
        }
        if(checklistDashboardFilter){
            url += this.getChecklistTemplateQueryParams(checklistDashboardFilter)
        }
        const res = await fetch(url, {
            headers: this.getAuthHeaders()
        });
        this.validateResponseStatus(res.status, 'getFranchiseeSchedule()');
        const data = await res.json();
        if (!data.schedule || !data.schedule.checklists) {
            throw new Error('Franchisee Schedule endpoint did not return checklist data.');
        }
        return data.schedule as IScheduleData;
    }

    async getSiteSchedule(
            franchiseId: number, franchiseeId: number, siteId: number,
            checklistDashboardFilter: IChecklistDashboardFilter | null,
            type: ScheduleType, howMany = 10, fromId?: number): Promise<IScheduleData> {
        await this.validateAuth();
        let url = `${CONFIG.apiUrl}/franchise/${franchiseId}/franchisee/${franchiseeId}/site/${siteId}/schedule?status=${type}&howmany=${howMany}`;
        if (fromId) {
            url += '&from=' + fromId;
        }
        if(checklistDashboardFilter){
            url += this.getChecklistTemplateQueryParams(checklistDashboardFilter)
        }
        const res = await fetch(url, {
            headers: this.getAuthHeaders()
        });
        this.validateResponseStatus(res.status, 'getSiteSchedule()');
        const data = await res.json();
        if (!data.schedule || !data.schedule.checklists) {
            throw new Error('Site Schedule endpoint did not return checklist data.');
        }
        return data.schedule as IScheduleData;
    }

    async getTicketTypes(franchiseId: number): Promise<ITicketTypes> {
        await this.validateAuth();
        const res = await fetch(`${CONFIG.apiUrl}/franchise/${franchiseId}/ticket/types`, {
            headers: this.getAuthHeaders()
        });
        this.validateResponseStatus(res.status, 'getTicketTypes()');
        const data = await res.json();
        if (!data.ticketTypes) {
            throw new Error('Ticket Types endpoint did not return ticketTypes data.');
        }
        return data as ITicketTypes;
    }

    async getTicketCategories(franchiseId: number, excludeChecklist?: boolean): Promise<ITicketCategories> {
        await this.validateAuth();
        let url = `${CONFIG.apiUrl}/franchise/${franchiseId}/ticket/categories`
        if(excludeChecklist) url += '?exclude=checklist'

        const res = await fetch(url, { headers: this.getAuthHeaders()});
        this.validateResponseStatus(res.status, 'getTicketCategories()');
        const data = await res.json();
        if (!data.categories) {
            throw new Error('Ticket Categories endpoint did not return categories data.');
        }
        return data as ITicketCategories;
    }

    async getTicket(franchiseId: number, ticketId: number, excludeChecklist?: boolean): Promise<ITicket> {
        await this.validateAuth();
        let url = `${CONFIG.apiUrl}/franchise/${franchiseId}/ticket/${ticketId}`;
        if (excludeChecklist) {
            url += '?excludeChecklist=' + excludeChecklist;
        }
        const res = await fetch(url, {
            headers: this.getAuthHeaders()
        });
        this.validateResponseStatus(res.status, 'getTicket()');
        const data = await res.json();
        if (!data.ticket) {
            throw new Error('Ticket endpoint did not return ticket data.');
        }
        return data.ticket as ITicket;
    }

    async getTicketFranchisee(franchiseId: number, ticketId: number): Promise<IFranchisee> {
        await this.validateAuth();
        const url = `${CONFIG.apiUrl}/franchise/${franchiseId}/ticket/${ticketId}/franchisee`;
        const res = await fetch(url, {
            headers: this.getAuthHeaders()
        });
        this.validateResponseStatus(res.status, 'getTicketFranchisee()');
        const data = await res.json();
        if (!data) {
            throw new Error('getTicketFranchisee endpoint did not return data');
        }
        return data;
    }

    async getTicketSite(franchiseId: number, ticketId: number): Promise<ISite> {
        await this.validateAuth();
        const url = `${CONFIG.apiUrl}/franchise/${franchiseId}/ticket/${ticketId}/site`;
        const res = await fetch(url, {
            headers: this.getAuthHeaders()
        });
        this.validateResponseStatus(res.status, 'getTicketSite()');
        const data = await res.json();
        if (!data) {
            throw new Error('getTicketSite endpoint did not return data');
        }
        return data;
    }

    async newTicket(franchiseId: number, franchiseeId: number, ticketData: FormData, timeout: number): Promise<number> {
        await this.validateAuth();
        const [status, response] = await postData(
            `${CONFIG.apiUrl}/franchise/${franchiseId}/franchisee/${franchiseeId}/ticket`,
            this.getAuthHeaders(),
            ticketData,
            timeout
        );

        if (!response.success && typeof response.data == 'string') {
            throw new UserFacingError(response.data);
        }
        if (!response.data || !response.data.Location) {
            throw new Error('New ticket endpoint did not return new ticket location.');
        }

        // this should run after the specified error checks in each function
        this.validateResponseStatus(status, 'submitChecklist()');

        const newTicketId = Number(response.data.Location.split('/').slice(-1)[0]);
        return newTicketId;
    }

    async updateTicket(franchiseId: number, franchiseeId: number, ticketId: number, ticketData: FormData, timeout: number): Promise<number> {
        await this.validateAuth();
        const [status, response] = await postData(
            `${CONFIG.apiUrl}/franchise/${franchiseId}/franchisee/${franchiseeId}/ticket/${ticketId}`,
            this.getAuthHeaders(),
            ticketData,
            timeout
        );

        if (status != 204) {
            if (response && !response.success && typeof response.data == 'string') {
                throw new UserFacingError(response.data);
            }
            else {
                this.validateResponseStatus(status, 'updateTicket()');
            }
        }
        return ticketId;
    }

    async addTicketComment(franchiseId: number, franchiseeId: number, ticketId: number, comment: INewTicketComment, timeout: number): Promise<void> {
        await this.validateAuth();
        const [status, response] = await postData(
            `${CONFIG.apiUrl}/franchise/${franchiseId}/franchisee/${franchiseeId}/ticket/${ticketId}/conversation`,
            { 'Content-Type': 'application/json', ...this.getAuthHeaders() },
            JSON.stringify(comment),
            timeout
        );

        if (status != 204) {
            if (response && !response.success && typeof response.data == 'string') {
                throw new UserFacingError(response.data);
            }
            else {
                this.validateResponseStatus(status, 'addTicketComment()');
            }
        }
    }

    async addHsComment(franchiseId: number, franchiseeId: number | null, hazardId: number | null, incidentId: number | null, comment: IHsComment, timeout: number): Promise<IHazardComment> {
        await this.validateAuth();
        let uri = '';

        if (incidentId && incidentId > 0) {
            uri = `${CONFIG.apiUrl}/franchise/${franchiseId}/franchisee/${franchiseeId}/incident/${incidentId}/conversation`;
        } else {
            uri = `${CONFIG.apiUrl}/franchise/${franchiseId}/hazard/${hazardId}/conversation`;
        }
        const [status, response] = await postData(
            uri,
            { 'Content-Type': 'application/json', ...this.getAuthHeaders() },
            JSON.stringify(comment),
            timeout
        );

        if (status != 201) {
            if (response && !response.success && typeof response.data == 'string') {
                throw new UserFacingError(response.data);
            }
            else {
                this.validateResponseStatus(status, 'add comment');
            }
        }
        const newHSComment = response.conversation;

        if (!newHSComment) {
            throw new Error(`The api did not return new comment`);
        }
        return newHSComment as IHazardComment;
    }

    async newHazard(franchiseId: number, hazardData: FormData): Promise<number> {
        await this.validateAuth();
        const [status, response] = await postData(
            `${CONFIG.apiUrl}/franchise/${franchiseId}/hazard`,
            this.getAuthHeaders(),
            hazardData
        );

        if (!response.success && typeof response.data == 'string') {
            throw new UserFacingError(response.data);
        }
        if (!response.data || !response.data.Location) {
            throw new Error('New hazard endpoint did not return new hazard location.');
        }

        // this should run after the specified error checks in each function
        this.validateResponseStatus(status, 'newHazard()');

        const newHazardId = Number(response.data.Location.split('/').slice(-1)[0]);
        return newHazardId;
    }

    async searchTickets(franchiseId: number, franchiseeId: number, params: ITicketSearchParams, fromId?: number): Promise<ITicket[]> {
        await this.validateAuth();
        let url = `${CONFIG.apiUrl}/franchise/${franchiseId}/franchisee/${franchiseeId}/tickets?howmany=10`;
        if (Object.keys(params).length > 0) {
            url += '&' + createQueryString(params);
        }
        if (fromId) {
            url += '&from=' + fromId;
        }
        const res = await fetch(url, {
            headers: this.getAuthHeaders()
        });
        this.validateResponseStatus(res.status, 'searchTickets()');
        const data = await res.json();
        if (!data.tickets) {
            throw new Error('Ticket search endpoint did not return ticket data.');
        }
        return data.tickets as ITicket[];
    }

    async getOverviewMyTickets(franchiseId: number, fromId?: number): Promise<IDashboardTicket[]> {
        await this.validateAuth();
        // tslint:disable-next-line:max-line-length
        let url = `${CONFIG.apiUrl}/franchise/${franchiseId}/ticket/overview-mytickets?howmany=10`;
        if (fromId) {
            url += '&from=' + fromId;
        }
        const res = await fetch(url, {
            headers: this.getAuthHeaders()
        });
        this.validateResponseStatus(res.status, 'getOverviewMyTickets()');
        const data = await res.json();
        if (!data.schedule || !data.schedule.tickets) {
            throw new Error('Overview MyTickets endpoint did not return ticket data.');
        }
        return data.schedule.tickets as IDashboardTicket[];
    }

    async getFranchiseeMyTickets(franchiseId: number, franchiseeId: number, fromId?: number): Promise<IDashboardTicket[]> {
        await this.validateAuth();
        // tslint:disable-next-line:max-line-length
        let url = `${CONFIG.apiUrl}/franchise/${franchiseId}/franchisee/${franchiseeId}/ticket/mytickets?status=myTickets&howmany=10`;
        if (fromId) {
            url += '&from=' + fromId;
        }
        const res = await fetch(url, {
            headers: this.getAuthHeaders()
        });
        this.validateResponseStatus(res.status, 'getFranchiseeMyTickets()');
        const data = await res.json();
        if (!data.schedule || !data.schedule.tickets) {
            throw new Error('Franchisee MyTickets endpoint did not return ticket data.');
        }
        return data.schedule.tickets as IDashboardTicket[];
    }

    async getSiteMyTickets(franchiseId: number, franchiseeId: number, siteId: number, fromId?: number): Promise<IDashboardTicket[]> {
        await this.validateAuth();
        // tslint:disable-next-line:max-line-length
        let url = `${CONFIG.apiUrl}/franchise/${franchiseId}/franchisee/${franchiseeId}/site/${siteId}/ticket/mytickets?status=myTickets&howmany=10`;
        if (fromId) {
            url += '&from=' + fromId;
        }
        const res = await fetch(url, {
            headers: this.getAuthHeaders()
        });
        this.validateResponseStatus(res.status, 'getSiteMyTickets()');
        const data = await res.json();
        if (!data.schedule || !data.schedule.tickets) {
            throw new Error('Franchisee MyTickets endpoint did not return ticket data.');
        }
        return data.schedule.tickets as IDashboardTicket[];
    }

    async getIncidentTemplate(franchiseId: number, franchiseeId: number, params?: {incidentTypeId?: number, exclude?: IncidentExcludableField[]}): Promise<IIncidentResponse> {
        await this.validateAuth();
        const qParams = []
        if(params){
            const {exclude, incidentTypeId} = params
            if(exclude && exclude.length > 0){
                qParams.push(`exclude=[${exclude.join()}]`)
            }
            if(incidentTypeId){
                qParams.push(`incidentTypeId=${incidentTypeId}`)
            }
        }

        const url = `${CONFIG.apiUrl}/franchise/${franchiseId}/franchisee/${franchiseeId}/incident/-1`
        const res = await fetch(
            `${url}${qParams.length > 0 ? '?' + qParams.join('&') : ''}`,
            {
                headers: {
                    ...this.getAuthHeaders(),
                    'Content-Type': 'application/vnd.1placeapp.v3.1+json;charset=utf-8',
                },
            });
        this.validateResponseStatus(res.status, 'getIncidentTemplate()');
        const data = await res.json();
        if (!data.incident) {
            throw new Error('Incident template endpoint did not return an incident template');
        }
        return data as IIncidentResponse;
    }

    async getIncidentCategories(franchiseId: number, incidentId?: number): Promise<IIncidentCategories> {
        await this.validateAuth();
        const categoriesUrl = incidentId ? `incident/${incidentId}/categories` : 'incident/categories';
        const res = await fetch(
            `${CONFIG.apiUrl}/franchise/${franchiseId}/${categoriesUrl}`,
            {
                headers: this.getAuthHeaders()
            });
        this.validateResponseStatus(res.status, 'getIncidentCategories()');
        const data = await res.json();
        if (!data.categories) {
            throw new Error('Incident category endpoint did not return categories');
        }
        return data.categories as IIncidentCategories;
    }

    async getIncidentTypes(franchiseId: number): Promise<IIncidentTypes> {
        await this.validateAuth();

        const res = await fetch(
            `${CONFIG.apiUrl}/franchise/${franchiseId}/incident/types`,
            {
                headers: this.getAuthHeaders()
            });
        this.validateResponseStatus(res.status, 'getIncidentTypes()');
        const data = await res.json();
        if (!data.incidentTypes) {
            throw new Error('Incident category endpoint did not return categories');
        }
        return data as IIncidentTypes;
    }

    async getIncident(franchiseId: number, franchiseeId: number, incidentId: number,  exclude?: IncidentExcludableField[]): Promise<IIncidentResponse> {
        await this.validateAuth();
        const excludeParam = exclude && exclude.length > 0? `exclude=[${exclude.join()}]` : ''
        const params = excludeParam
        const res = await fetch(
            `${CONFIG.apiUrl}/franchise/${franchiseId}/franchisee/${franchiseeId}/incident/${incidentId}${params ? '?' + params : ''}`,
            {
                headers: this.getAuthHeaders()
            });
        this.validateResponseStatus(res.status, 'getIncident()');
        const data = await res.json();
        if (!data.incident) {
            throw new Error('Incident endpoint did not return incident data');
        }
        return data as IIncidentResponse;
    }

    async newIncident(franchiseId: number, franchiseeId: number, incidebntData: FormData, timeout: number): Promise<number> {
        await this.validateAuth();
        const [status, response] = await postData(
            `${CONFIG.apiUrl}/franchise/${franchiseId}/franchisee/${franchiseeId}/incident`,
            this.getAuthHeaders(),
            incidebntData,
            timeout
        );

        if (!response.success && typeof response.data == 'string') {
            throw new UserFacingError(response.data);
        }
        if (!response.data || !response.data.Location) {
            throw new Error('New incident endpoint did not return new incident location.');
        }

        // this should run after the specified error checks in each function
        this.validateResponseStatus(status, 'newIncident()');

        const newIncidentId = Number(response.data.Location.split('/').slice(-1)[0]);
        return newIncidentId;
    }

    async updateIncident(franchiseId: number, franchiseeId: number, incidentId: number, incidentData: FormData, timeout: number): Promise<number> {
        await this.validateAuth();
        const [status, response] = await postData(
            `${CONFIG.apiUrl}/franchise/${franchiseId}/franchisee/${franchiseeId}/incident/${incidentId}`,
            this.getAuthHeaders(),
            incidentData,
            timeout
        );

        if (status != 204) {
            if (response && !response.success && typeof response.data == 'string') {
                throw new UserFacingError(response.data);
            }
            else {
                this.validateResponseStatus(status, 'updateIncident()');
            }
        }
        return incidentId;
    }

    async getIncidentEmail(franchiseId: number, franchiseeId: number, incidentId: number): Promise<IIncidentEmailResponse> {
        await this.validateAuth();
        const res = await fetch(
            `${CONFIG.apiUrl}/franchise/${franchiseId}/franchisee/${franchiseeId}/incident/${incidentId}/email`,
            {
                headers: this.getAuthHeaders(),
            });
        this.validateResponseStatus(res.status, 'getIncidentEmail()');
        const response: IIncidentEmailResponse = await res.json();
        if (!response.message) {
            throw new Error('Incident email endpoint did not return a message.');
        }
        return response;
    }

    async sendIncidentEmail(franchiseId: number, franchiseeId: number, incidentId: number, message: IEmailFields): Promise<void> {
        await this.validateAuth();
        const [status, response] = await postData(
            `${CONFIG.apiUrl}/franchise/${franchiseId}/franchisee/${franchiseeId}/incident/${incidentId}/email`,
            {
                ...this.getAuthHeaders(),
                'Content-Type': 'application/json'
            },
            JSON.stringify({ message })
        );
        try {
            this.validateResponseStatus(status, response.data);
        } catch (e) {
            throw new UserFacingError(!response.data ? 'Incident email send failed. Response: ' + JSON.stringify(response) : response.data);
        }
        if (!response.success) {
            throw new UserFacingError(!response.data ? 'Incident email send failed. Response: ' + JSON.stringify(response) : response.data);
        }
    }

    async getHazard(franchiseId: number, hazardId: number, excludeSitesList? :boolean): Promise<IHazardResponse> {
        await this.validateAuth();
        let url = `${CONFIG.apiUrl}/franchise/${franchiseId}/hazard/${hazardId}`
        if(excludeSitesList) url += '?exclude=[sitesList]'
        const res = await fetch(
            url,
            {
                headers: this.getAuthHeaders(),
            });
        this.validateResponseStatus(res.status, 'getHazard()');
        const data = await res.json();
        if (!data.hazard) {
            throw new Error('Hazard template endpoint did not return an hazard template');
        }
        return data as IHazardResponse;
    }

    async getActiveHazards(franchiseId: number): Promise<IIDAndName[]> {
        await this.validateAuth()
        const res = await fetch(
            `${CONFIG.apiUrl}/franchise/${franchiseId}/hazards/active`,
            {headers: this.getAuthHeaders()}
        )
        const data = await res.json();
        if (!data.hazards) {
            throw new Error('Active Hazards endpoint did not return hazards');
        }
        return data.hazards as IIDAndName[];
    }

    async getFavouriteChecklists(franchiseId: number): Promise<IFavouriteChecklist[]> {
        await this.validateAuth();
        const res = await fetch(
            `${CONFIG.apiUrl}/franchise/${franchiseId}/favouriteChecklists`,
            {
                headers: this.getAuthHeaders(),
            });
        this.validateResponseStatus(res.status, 'getFavouriteChecklists()');
        const data = await res.json();
        if (!data.favouriteLists || !data.favouriteLists.favouriteChecklists) {
            throw new Error('Favourite checklists endpoint did not return an record');
        }
        return data.favouriteLists.favouriteChecklists as IFavouriteChecklist[];
    }

    async removeFavouriteChecklist(franchiseId: number, checklistId: number): Promise<void> {
        await this.validateAuth();
        const [status] = await putData(
            `${CONFIG.apiUrl}/franchise/${franchiseId}/removeFavouriteChecklist/${checklistId}`,
            { ...this.getAuthHeaders() },
            ''
        );
        this.validateResponseStatus(status, 'removeFavouriteChecklist()');
        return;
    }

    async searchIncidents(franchiseId: number, franchiseeId: number, fromId: number, params: IIncidentSearchParams): Promise<IIncidentsListEntryWrapper[]> {
        await this.validateAuth();
        let url = `${CONFIG.apiUrl}/franchise/${franchiseId}/incidents?howmany=10`;
        if (fromId) {
            url += '&from=' + fromId;
        }
        if (Object.keys(params).length > 0) {
            url += '&' + createQueryString(params);
        }
        const res = await fetch(url, {
            headers: this.getAuthHeaders(),
        });
        this.validateResponseStatus(res.status, 'searchIncidents()');
        const data = await res.json();
        return data.incidents as IIncidentsListEntryWrapper[];
    }

    async searchHazards(franchiseId: number, fromId: number, params: IHazardSearchParams): Promise<IHazardsListEntryWrapper[]> {
        await this.validateAuth();
        let url = `${CONFIG.apiUrl}/franchise/${franchiseId}/hazards?howmany=10`;
        if (fromId) {
            url += '&from=' + fromId;
        }
        if (Object.keys(params).length > 0) {
            url += '&' + createQueryString(params);
        }
        const res = await fetch(url, {
            headers: this.getAuthHeaders(),
        });
        this.validateResponseStatus(res.status, 'searchHazards()');
        const data = await res.json();
        return data.hazards as IHazardsListEntryWrapper[];
    }

    async getFranchiseeNotes(franchiseId: number, franchiseeId: number, fromId?: number): Promise<IDashboardNotes> {
        await this.validateAuth();
        let url = `${CONFIG.apiUrl}/franchise/${franchiseId}/franchisee/${franchiseeId}/dashboardNotes?howmany=10`;
        if (fromId) {
            url += '&from=' + fromId;
        }
        const res = await fetch(url, {
            headers: this.getAuthHeaders()
        });
        this.validateResponseStatus(res.status, 'getFranchiseeNotes()');
        const data = await res.json();
        return data as IDashboardNotes;
    }

    async getSiteNotes(franchiseId: number, franchiseeId: number, siteId: number, fromId: number): Promise<IDashboardNotes> {
        await this.validateAuth();
        let url = `${CONFIG.apiUrl}/franchise/${franchiseId}/franchisee/${franchiseeId}/dashboardNotes?howmany=10`;
        if (siteId) {
            url += '&siteId=' + siteId;
        }else{
            throw new UnauthorisedError('getSiteNotes()');
        }
        if (fromId) {
            url += '&from=' + fromId;
        }
        const res = await fetch(url, {
            headers: this.getAuthHeaders()
        });
        this.validateResponseStatus(res.status, 'getSiteNotes()');
        const data = await res.json();
        return data as IDashboardNotes;
    }

    async getNote(franchiseId: number, franchiseeId: number, noteId: number): Promise<INoteWrapper> {
        await this.validateAuth();
        const url = `${CONFIG.apiUrl}/franchise/${franchiseId}/franchisee/${franchiseeId}/note/${noteId}`;
        const res = await fetch(url, {
            headers: this.getAuthHeaders()
        });
        this.validateResponseStatus(res.status, 'getNote()');
        const data = await res.json();
        return data as INoteWrapper;
    }

    async newNote(franchiseId: number, franchiseeId: number, noteData: FormData, timeout: number): Promise<number> {
        await this.validateAuth();
        const [status, response] = await postData(
            `${CONFIG.apiUrl}/franchise/${franchiseId}/franchisee/${franchiseeId}/note`,
            this.getAuthHeaders(),
            noteData,
            timeout
        );
        if (!response.success && typeof response.data == 'string') {
            throw new UserFacingError(response.data);
        }

        if (!response.data || !response.data.Location) {
            throw new Error('New note endpoint did not return new note location.');
        }
        this.validateResponseStatus(status, 'newNote()');
        const newNoteId = Number(response.data.Location.split('/').slice(-1)[0]);
        return newNoteId;
    }

    async updateNote(franchiseId: number, franchiseeId: number, noteId: number, noteData: FormData, _timeout: number): Promise<number> {
        await this.validateAuth();
        const [status, response] = await putData(
            `${CONFIG.apiUrl}/franchise/${franchiseId}/franchisee/${franchiseeId}/note/${noteId}`,
            this.getAuthHeaders(),
            noteData
        );
        this.validateResponseStatus(status, 'updateNote()');
        if (status != 204) {
            if (response && !response.success && typeof response.data == 'string') {
                throw new UserFacingError(response.data);
            }
            else {
                this.validateResponseStatus(status, 'updateNote()');
            }
        }
        return noteId;
    }

    async updateHazard(franchiseId: number, hazardId: number, hazardData: FormData): Promise<number> {
        await this.validateAuth();
        const [status, response] = await postData(
            `${CONFIG.apiUrl}/franchise/${franchiseId}/hazard/${hazardId}`,
            this.getAuthHeaders(),
            hazardData
        );

        if (status != 204) {
            if (response && !response.success && typeof response.data == 'string') {
                throw new UserFacingError(response.data);
            }
            else {
                this.validateResponseStatus(status, 'updateHazard()');
            }
        }
        return hazardId;
    }

    async getSummary(franchiseId: number): Promise<ISummaryData> {
        await this.validateAuth();
        const url = `${CONFIG.apiUrl}/franchise/${franchiseId}/summary`;
        const res = await fetch(url, {
            headers: this.getAuthHeaders()
        });
        this.validateResponseStatus(res.status, 'getSummary()');
        const data = await res.json();
        return data as ISummaryData;
    }

    async saveAttachment(attachment: IAttachment): Promise<{ UUID: any; url: any; }> {
        let path
        if(attachment.forEntity === 'checklist'){
            path = 'checklist/checklistAttachment'
        } else if(attachment.forEntity === 'incident'){
            path = 'incident/attachment'
        } else if(attachment.forEntity === 'hazard'){
            path = 'hazard/attachment'
        }
        const franchiseId = this.auth.user.capabilities.franchiseId
        const config: IApiClientConfig = {
            url: `${CONFIG.apiUrl}/franchise/${franchiseId}/${path}`,
            header: this.getAuthHeaders(),
            timeout: 600000
        }
        return new OneplaceComponentsApi(config).saveAttachment(attachment)//calls base method from oneplace-components
    }


    async deleteAttachment(attachment: IAttachment): Promise<any> {
        let path
        if(attachment.forEntity === 'checklist'){
           path = 'checklist/checklistAttachment'
        } else if(attachment.forEntity === 'incident'){
            path = 'incident/attachment'
        } else if(attachment.forEntity === 'hazard'){
            path = 'hazard/attachment'
        }
        const franchiseId = this.auth.user.capabilities.franchiseId
        const config: IApiClientConfig = {
            url: `${CONFIG.apiUrl}/franchise/${franchiseId}/${path}`,
            header: this.getAuthHeaders(),
            timeout: 60000
        }
        return new OneplaceComponentsApi(config).deleteAttachment(attachment)
    }

    viewAttachment(attachment: IAttachment): void {
        if(!attachment.url) return;
        let url = this.getAuthenticatedPhotoUrl(attachment.url);
        if(attachment.id) {
            url += '&attachment=true';
        }
        if (ENV.platform == 'cordova') {
            cordova.InAppBrowser.open(url, '_system');
        }
        else {
            window.open(url, '_blank')!.opener = null;
        }
    }

    async getPeopleForIncident(franchiseId: number, franchiseeId: number, siteId: number, incidentTypeId: number): Promise<IPerson[]>{
        await this.validateAuth();
        const url = `${CONFIG.apiUrl}/franchise/${franchiseId}/franchisee/${franchiseeId}/people?siteId=${siteId}&incidentTypeId=${incidentTypeId}`
        const res = await fetch(url, {headers: this.getAuthHeaders()});
        this.validateResponseStatus(res.status, 'getPeopleForIncident()');
        const data = await res.json();
        if (!data) {
            throw new Error('People endpoint did not return people data');
        }
        return data;
    }

    async getPeopleEmailsForIncident(franchiseId: number, personIds: number[]) : Promise<string[]>{
        await this.validateAuth();
        const url = `${CONFIG.apiUrl}/franchise/${franchiseId}/incident/emails?peopleIds=${personIds.join(',')}`
        const response = await fetch(url, {headers: this.getAuthHeaders()});

        this.validateResponseStatus(response.status, 'getPeopleEmailsForIncident()');

        if (response.status == 200) {
            const data = await response.json()
            return data.emails
        }

        throw new Error('Error retrieving people emails');
    }

    async getPeopleForTicket(franchiseId: number, franchiseeId: number, siteId: number, ticketCategoryId: number): Promise<IPerson[]>{

        await this.validateAuth();

        let url = `${CONFIG.apiUrl}/franchise/${franchiseId}/franchisee/${franchiseeId}/people?`

        url += 'ticketCategoryId=' + ticketCategoryId;

        if(siteId){
            url += '&siteId=' + siteId;
        }

        const res = await fetch(url, {headers: this.getAuthHeaders()});

        this.validateResponseStatus(res.status, 'getPeopleForTicket()');

        const data = await res.json();
        if (!data) {
            throw new Error('People endpoint did not return people data');
        }
        return data;
    }

    async getPeopleForChecklist(franchiseId: number, franchiseeId: number, siteId: number, checklistTemplateId: number): Promise<IPerson[]>{

        await this.validateAuth();

        let url = `${CONFIG.apiUrl}/franchise/${franchiseId}/franchisee/${franchiseeId}/people?`

        url += 'checklistTemplateId=' + checklistTemplateId;

        if(siteId){
            url += '&siteId=' + siteId;
        }

        url +='&howMany=100';

        const res = await fetch(url, {headers: this.getAuthHeaders()});

        this.validateResponseStatus(res.status, 'getPeopleForChecklist()');

        const data = await res.json();
        if (!data) {
            throw new Error('People endpoint did not return people data');
        }
        return data;
    }

    async getPeopleForAutocomplete(franchiseId: number, franchiseeId: number, siteId: number, q: string, ticketCategoryId: number, incidentTypeId: number, checklistTemplateId: number): Promise<IPerson[]>{

        await this.validateAuth();

        let url = `${CONFIG.apiUrl}/franchise/${franchiseId}/franchisee/${franchiseeId}/people?`

        if (checklistTemplateId > 0) {
            url += 'checklistTemplateId=' + checklistTemplateId;
        } else if (ticketCategoryId > 0) {
            url += 'ticketCategoryId=' + ticketCategoryId;
        } else if (incidentTypeId > 0) {
            url += 'incidentTypeId=' + incidentTypeId;
        }

        if(siteId){
            url += '&siteId=' + siteId;
        }

        if (q) {
            url +='&q=' + q;
        }
        // set limit to 50 to reduce the list size
        url +='&howMany=50';


        const res = await fetch(url, {headers: this.getAuthHeaders()});

        this.validateResponseStatus(res.status, 'getPeople()');

        const data = await res.json();
        if (!data) {
            throw new Error('People endpoint did not return people data');
        }
        return data;
    }

    async getPersonTypes(franchiseId: number): Promise<IPersonType[]>{
        await this.validateAuth();
        const url = `${CONFIG.apiUrl}/franchise/${franchiseId}/persontypes`
        const res = await fetch(url, {headers: this.getAuthHeaders()});
        this.validateResponseStatus(res.status, 'getPersonType()');
        const data = await res.json();
        if (!data) {
            throw new Error('Person Type endpoint did not return any data');
        }
        return data;
    }

    async searchPeople(franchiseId: number, from: number, siteId?: number, personTypesIds?: number[], name?: string): Promise<IPerson[]>{
        await this.validateAuth();

        let url = `${CONFIG.apiUrl}/franchise/${franchiseId}/people?option=search&howMany=20&from=${from}`;
        if(siteId && siteId > 0){
            url += `&siteId=${siteId}`
        }
        if(personTypesIds){
            personTypesIds.forEach((id: any) => {
                url += `&personTypesIds=${id}`
            });
        }
        if(name){
            url += `&name=${name}`
        }

        const res = await fetch(url, {headers: this.getAuthHeaders()});
        this.validateResponseStatus(res.status, 'search()');
        const data = await res.json();
        if (!data) {
            throw new Error(' endpoint did not return people data');
        }
        return data;
    }

    async searchPeopleByName(name: string, franchiseId: number, from: number, howMany: number): Promise<ISearchPerson[]>{
        await this.validateAuth()
        const url = `${CONFIG.apiUrl}/franchise/${franchiseId}/people/search?pagination=true&howMany=${howMany}&from=${from}`
        + (name ? `&key=${name} ` : '')
        const res = await fetch(url, {headers: this.getAuthHeaders()})
        this.validateResponseStatus(res.status, 'search()')
        const data = await res.json()
        if (!data) {
            throw new Error(' endpoint did not return people data')
        }
        return data.people
    }

    async searchPeopleByIds(franchiseId: number, ids: (number | string)[]): Promise<ISearchPerson[]>{
        await this.validateAuth()
        const url = `${CONFIG.apiUrl}/franchise/${franchiseId}/people/search?personIds=${ids.join()}`
        const res = await fetch(url, {headers: this.getAuthHeaders()})
        this.validateResponseStatus(res.status, 'search()')
        const data = await res.json() as ISearchPerson[]
        if (!data) {
            throw new Error(' endpoint did not return people data')
        }
        return data
    }

    async getPerson(franchiseId: number, personId: number): Promise<IPerson>{
        await this.validateAuth();

        const url = `${CONFIG.apiUrl}/franchise/${franchiseId}/people/${personId}`;
        const res = await fetch(url, {headers: this.getAuthHeaders()});
        this.validateResponseStatus(res.status, 'getPerson()');
        const data = await res.json();
        if (!data) {
            throw new Error('Person endpoint did not return data');
        }
        return data;
    }

    async updatePerson(franchiseId: number, person: IPerson): Promise<number> {
        await this.validateAuth();
        const [status, response] = await putData(
            `${CONFIG.apiUrl}/franchise/${franchiseId}/people/${person.id}`,
            {...this.getAuthHeaders(), 'Content-Type': 'application/json'},
            JSON.stringify(person)
        );
        if (status != 204) {
            if (response && !response.success && typeof response.data == 'string') {
                throw new UserFacingError(response.data);
            }
            else {
                this.validateResponseStatus(status, 'updatePerson()');
            }
        }
        return person.id;
    }

    async getFranchisee(franchiseId: number, franchiseeId: number): Promise<IFranchisee>{
        await this.validateAuth();

        const url = `${CONFIG.apiUrl}/franchise/${franchiseId}/franchisee/${franchiseeId}`;
        const res = await fetch(url, {headers: this.getAuthHeaders()});
        this.validateResponseStatus(res.status, 'getFranchisee()');
        const data = await res.json();
        if (!data) {
            throw new Error('Franchisee endpoint did not return data');
        }
        return data;
    }

    async updateFranchisee(franchiseId: number, franchisee: IFranchisee): Promise<number> {
        await this.validateAuth();
        const [status, response] = await putData(
            `${CONFIG.apiUrl}/franchise/${franchiseId}/franchisee/${franchisee.id}`,
            {...this.getAuthHeaders(), 'Content-Type': 'application/json'},
            JSON.stringify(franchisee)
        );
        if (status != 204) {
            if (response && !response.success && typeof response.data == 'string') {
                throw new UserFacingError(response.data);
            }
            else {
                this.validateResponseStatus(status, 'updateFranchisee()');
            }
        }
        return franchisee.id;
    }

    async getSite(franchiseId: number, franchiseeId: number, siteId: number): Promise<ISite>{
        await this.validateAuth();

        const url = `${CONFIG.apiUrl}/franchise/${franchiseId}/franchisee/${franchiseeId}/site/${siteId}`;
        const res = await fetch(url, {headers: this.getAuthHeaders()});
        this.validateResponseStatus(res.status, 'getSite()');
        const data = await res.json();
        if (!data) {
            throw new Error('Site endpoint did not return data');
        }
        return data;
    }

    async updateSite(franchiseId: number, franchiseeId: number, site: ISite): Promise<number> {
        await this.validateAuth();
        const [status, response] = await putData(
            `${CONFIG.apiUrl}/franchise/${franchiseId}/franchisee/${franchiseeId}/site/${site.id}`,
            {...this.getAuthHeaders(), 'Content-Type': 'application/json'},
            JSON.stringify(site)
        );
        if (status != 204) {
            if (response && !response.success && typeof response.data == 'string') {
                throw new UserFacingError(response.data);
            }
            else {
                this.validateResponseStatus(status, 'updateSite()');
            }
        }
        return site.id;
    }

    async register(platform: string,  token: string): Promise<boolean> {
        if(/^(browser|android|ios|amazon)$/.test(platform)){
            await this.validateAuth();
            const [status, response] = await postData(
                `${CONFIG.apiUrl}/bindings`,
                {
                    ...this.getAuthHeaders(),
                    'Content-Type': 'application/json'
                },
                JSON.stringify({
                    binding: {
                        token,
                        platform
                    },
                })
            );
            this.validateResponseStatus(status, 'register()');
            if ( status != 201) {
                if (response && !response.success) {
                    throw new Error('register binding failed.' + response.data);
                }
            }
            return true;
        } else {
            throw new Error('register binding failed for token ' + token);
        }
    }


    async unregister( token: string): Promise<number> {
        await this.validateAuth();
        const url = `${CONFIG.apiUrl}/bindings?token=${token}`;
        const res = await fetch(url, { method: 'DELETE', headers: this.getAuthHeaders()});
        return res.status;
    }

    async getMyNotifications(franchiseId: number, batchSize: number, newOffset: number): Promise<IMyNotificationsWrapper> {
        await this.validateAuth();
        const res = await fetch(
            `${CONFIG.apiUrl}/franchise/${franchiseId}/notifications/user?limit=${batchSize}&offset=${newOffset}`,
            {
                headers: this.getAuthHeaders(),
            });
        this.validateResponseStatus(res.status, 'fetchMyNotifications()');
        const data = await res.json();
        if (!data.notifications) {
            throw new Error('Notification endpoint did not return an notifications list');
        }
        return data as IMyNotificationsWrapper;
    }

    async markNotificationsAsRead(franchiseId: number, notificationId?: number): Promise<boolean>{
        await this.validateAuth();
        let url = `${CONFIG.apiUrl}/franchise/${franchiseId}/notifications/mark-as-read`;
        if (notificationId) {
            url += `?notificationId=${notificationId}`;
        }
        const res = await fetch(url,
            { credentials: 'same-origin',
              method: 'PUT',
              headers: this.getAuthHeaders() },
            );
        this.validateResponseStatus(res.status, 'markNotificationsAsRead()');
        return 204===res.status;
    }

    async getUnreadNotificationsCount(franchiseId: number): Promise<number> {
        await this.validateAuth();
        const res = await fetch(
            `${CONFIG.apiUrl}/franchise/${franchiseId}/notifications/user/count?status=unread`,
            {
                headers: this.getAuthHeaders(),
            });
        this.validateResponseStatus(res.status, 'fetchMyNotifications()');
        const data = await res.json();
        if (!data.count && data.count!==0) {
            throw new Error('getUnreadNotificationsCount endpoint did not return number');
        }
        return data.count as number;
    }

    async syncFranchiseeSites(franchiseId: number): Promise<Map<string, IIDAndName[]>> {
        await this.validateAuth();
        const res = await fetch(`${CONFIG.apiUrl}/franchise/${franchiseId}/sites/sync-franchisee-sites`, {
            headers: this.getAuthHeaders()
        });
        this.validateResponseStatus(res.status, 'getFranchiseeSites()');
        const data = await res.json();
        return data;
    }
    async getHazardTickets(
        franchiseId: number, hazardId: number, fromId: number
    ): Promise<ITicket[]> {
        await this.validateAuth();
        // tslint:disable-next-line:max-line-length
        let url = `${CONFIG.apiUrl}/franchise/${franchiseId}/hazard/${hazardId}/tickets?limit=10`;

        if (fromId) {
            url += '&from=' + fromId;
        }

        const res = await fetch(url, {
            headers: this.getAuthHeaders()
        });
        this.validateResponseStatus(res.status, 'getHazardTickets()');
        const data = await res.json();
        if (!data.tickets) {
            throw new Error('Search did not return any tickets data.');
        }
        return data.tickets as ITicket[];
    }
    async getHazardTicketsCount(franchiseId: number, hazardId: number): Promise<IHazardTicketsCount> {
        await this.validateAuth();
        const url = `${CONFIG.apiUrl}/franchise/${franchiseId}/hazard/${hazardId}/ticketcount`;

        const res = await fetch(url, {
            headers: this.getAuthHeaders()
        });
        this.validateResponseStatus(res.status, 'getHazardTicketsCount()');
        const data = await res.json();
        if (!DataTransferItem) {
            throw new Error('Search did not return any tickets data.');
        }
        return data as IHazardTicketsCount;
    }
    async getIncidentTickets(
        franchiseId: number, incidentId: number, fromId: number
    ): Promise<ITicket[]> {
        await this.validateAuth();
        // tslint:disable-next-line:max-line-length
        let url = `${CONFIG.apiUrl}/franchise/${franchiseId}/incident/${incidentId}/tickets?limit=10`;

        if (fromId) {
            url += '&from=' + fromId;
        }

        const res = await fetch(url, {
            headers: this.getAuthHeaders()
        });
        this.validateResponseStatus(res.status, 'getIncidentTickets()');
        const data = await res.json();
        if (!data.tickets) {
            throw new Error('Search did not return any tickets data.');
        }
        return data.tickets as ITicket[];
    }
    async getIncidentTicketsCount(franchiseId: number, incidentId: number): Promise<IIncidentTicketsCount> {
        await this.validateAuth();
        const url = `${CONFIG.apiUrl}/franchise/${franchiseId}/incident/${incidentId}/ticketcount`;

        const res = await fetch(url, {
            headers: this.getAuthHeaders()
        });
        this.validateResponseStatus(res.status, 'getIncidentTicketsCount()');
        const data = await res.json();
        if (!DataTransferItem) {
            throw new Error('Search did not return any tickets data.');
        }
        return data as IIncidentTicketsCount;
    }
}
