
import * as React from 'react';
import { withStyles, Theme, createStyles, WithStyles } from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import Avatar from '@material-ui/core/Avatar';
import FranchiseeIcon from '@material-ui/icons/Business';
import ListItemText from '@material-ui/core/ListItemText';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import { i18n } from '../../i18n';
import { FranchiseData, IFranchiseData } from '../../data_sources/FranchiseData';
import { withAppContext, IAppContextProp } from '../AppContext';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { withNavigationContext, INavigationContextProp } from '../navigation/Navigation';
import { PageLoader, IFranchisee } from 'oneplace-components';
import Input from '@material-ui/core/Input';
import { debounce } from 'debounce';
import { CONFIG } from '../../config';
import Typography from '@material-ui/core/Typography';
import VisibilitySensor from 'react-visibility-sensor';
import { clearPreviousPages } from '../../data_sources/PreviousPage';

const styles = (_theme: Theme) => createStyles({
    root: {
    },
    searchCard: {
        marginBottom: 20,
        overflow: 'visible'
    },
    cardContent: {
        paddingTop: 0
    },
    listItemTextRoot: {
        padding: '0 16px'
    }
});

export interface IFranchiseeSearchProps extends
        WithStyles<typeof styles>,
        IAppContextProp,
        INavigationContextProp,
        RouteComponentProps<any> {
    mock_franchiseData?: IFranchiseData;
}

export interface IFranchiseeSearchState {
    loadState: 'loading' | 'loaded';
    allFranchisees: IFranchisee[];
    filteredFranchisees: IFranchisee[];
    numberShown: number;
    lastSearch: string;
    search: string;
    loadingMore: boolean;
}

export const FranchiseeSearchPage = withStyles(styles)(withRouter(withAppContext(withNavigationContext(
    class extends React.Component<IFranchiseeSearchProps, IFranchiseeSearchState> {
        franchiseData: IFranchiseData;
        searchDebounced: () => void = null as any;

        constructor(props: any) {
            super(props);
            this.onSearchChanged = this.onSearchChanged.bind(this);
            this.search = this.search.bind(this);
            this.onShowMore = this.onShowMore.bind(this);
            this.state = {
                loadState: 'loading',
                allFranchisees: [],
                filteredFranchisees: [],
                numberShown: CONFIG.franchiseeSearchResults,
                lastSearch: '',
                search: '',
                loadingMore: false
            };
            this.franchiseData = this.props.mock_franchiseData
                || new FranchiseData(this.props.ctx.db, this.props.ctx.api, this.props.ctx.auth.user);

            this.props.nav.updateNavigation({
                title: i18n.t('customLabel_franchisees')
            });

            this.loadData();
            this.searchDebounced = debounce(this.search, CONFIG.franchiseeSearchDelay);
        }

        async loadData() {
            const user = this.props.ctx.auth.user;
            const [ franchisees ] = await Promise.all([
                this.franchiseData.getFranchisees(user.capabilities.franchiseId).getData(),
            ]);
            this.setState({
                allFranchisees: franchisees.franchisees,
                filteredFranchisees: franchisees.franchisees,
                numberShown: CONFIG.franchiseeSearchResults,
                lastSearch: '',
                search: '',
                loadState: 'loaded'
            });
        }

        onShowMore(showMore: boolean) {
            if (showMore && !this.state.loadingMore
                    && this.state.numberShown < this.state.filteredFranchisees.length) {
                this.setState({
                    loadingMore: true
                });
                // we don't need to wait?
                setTimeout(() => {
                    this.setState((state) => ({
                        numberShown: state.numberShown + CONFIG.franchiseeSearchResults,
                        loadingMore: false
                    }));
                }, 200);
            }
        }

        onSearchChanged(event: any) {
            const search = event.target.value;
            this.setState({ search });
            this.searchDebounced();
        }

        search() {
            this.setState((state) => ({
                lastSearch: state.search,
                filteredFranchisees: state.allFranchisees
                    .filter((franchisee) =>
                        franchisee.name.toLowerCase().includes(
                            state.search.trim().toLowerCase())),
                numberShown: CONFIG.franchiseeSearchResults
            }));
        }

        goToDashboard(franchiseeId: number) {
            // reset previous page states
            clearPreviousPages(this.props.ctx.db);
            this.props.history.push(
                '/franchisees/' + franchiseeId
            );
        }

        render() {
            const t = i18n.t;

            if (this.state.loadState == 'loaded') {
                const franchisees = this.state.filteredFranchisees.slice(0, this.state.numberShown);

                return (
                    <div className={this.props.classes.root}>
                        <Card className={this.props.classes.searchCard}>
                            <CardHeader
                                title={t('customLabel_franchisees')}
                                style={{ paddingBottom: 8 }}
                            />
                            <CardContent className={this.props.classes.cardContent}>
                                <Input
                                    fullWidth
                                    id="search"
                                    value={this.state.search}
                                    onChange={this.onSearchChanged}
                                    type="search"
                                    placeholder={t('search')}
                                />
                            </CardContent>
                        </Card>
                        {franchisees.length > 0 &&
                            <Card>
                                <CardContent style={{ padding: 16 }}>
                                    <List disablePadding={true}>
                                        {franchisees.map((franchisee) => (
                                            <ListItem button style={{ padding: '8px'}} key={franchisee.id}
                                                onClick={this.goToDashboard.bind(this, franchisee.id)}>
                                                <Avatar>
                                                    <FranchiseeIcon />
                                                </Avatar>
                                                <ListItemText classes={{ root: this.props.classes.listItemTextRoot }}
                                                    primary={franchisee.name}
                                                />
                                            </ListItem>
                                        ))}
                                    </List>
                                </CardContent>
                                <VisibilitySensor onChange={this.onShowMore} partial={true}>
                                    <div style={{ minHeight: 8 }}>
                                        {this.state.loadingMore && <PageLoader loading={true} paddingTop={0} />}
                                    </div>
                                </VisibilitySensor>
                            </Card>
                        }
                        {franchisees.length == 0 &&
                            <Typography variant="subtitle1" style={{ textAlign: 'center' }}>
                                No matches for &quot;{this.state.lastSearch}&quot;
                            </Typography>
                        }
                    </div>
                );
            }
            else if (this.state.loadState == 'loading') {
                return (
                    <PageLoader loading={true} />
                );
            }else{
                return (
                    <div>
                        <PageLoader loading={false} status={t('data_load_error')} />
                    </div>
                );
            }
        }
    }
))));
