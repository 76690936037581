
import { IDashboardChecklist } from '../../../models/Dashboard';
import { AssigneeType } from 'oneplace-components';
import { formatDate } from '../../../utils/dates';
import { createQueryString } from '../../../utils';

export function getChecklistUrl(checklist: IDashboardChecklist) {
    const assignee: AssigneeType =
        checklist.site ? 'site' : 'franchisee';
    const assigneeId: number =
        checklist.site ? checklist.site.id : checklist.franchisee!.id;
    const date = formatDate('iso_dateonly', checklist.dueDate);

    if (checklist.localId) {
        return '/checklists/edit?' + createQueryString({
            localId: checklist.localId
        });
    }
    else if (checklist.status == 'not_done') {
        return '/checklists/edit?' + createQueryString({
            versionId: checklist.templateId,
            assignee,
            assigneeId,
            date
        });
    }
    else {
        return '/checklists/edit?' + createQueryString({
            checklistId: checklist.templateId,
            franchiseeId: checklist.franchisee!.id
        });
    }

}
