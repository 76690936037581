
import * as React from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogActions from '@material-ui/core/DialogActions';
import Button from '@material-ui/core/Button';

export interface IPromptAction {
    label: string;
    onClick: () => void;
}

export interface IPromptParams {
    title: string;
    message: string;
    closeButtonLabel: string;
    actions?: IPromptAction[];
}

export interface IPromptsContext {
    showPrompt(params: IPromptParams): void;
}

export interface IPromptsState extends IPromptParams {
    promptOpen: boolean;
}

export const PromptsContext = React.createContext<IPromptsContext>(null as any);

// eslint-disable-next-line @typescript-eslint/ban-types
export class PromptsProvider extends React.Component<{}, IPromptsState> {

    constructor(props: any) {
        super(props);
        this.showPrompt = this.showPrompt.bind(this);
        this.hidePrompt = this.hidePrompt.bind(this);
        this.state = {
            promptOpen: false,
            title: '',
            message: '',
            closeButtonLabel: ''
        };
    }

    showPrompt(params: IPromptParams) {
        console.log('prompt called', params);
        this.setState({...params, promptOpen: true});
    }

    hidePrompt() {
        this.setState({ promptOpen: false });
    }

    doAction(actionIdx: number) {
        this.state.actions![actionIdx].onClick();
        this.setState({ promptOpen: false});
    }

    render() {
        return (
            <PromptsContext.Provider value={{ showPrompt: this.showPrompt }}>
                {this.props.children}
                <Dialog
                    open={this.state.promptOpen}
                    onClose={this.hidePrompt}
                >
                    <DialogTitle>{this.state.title}</DialogTitle>
                    <DialogContent>
                        <DialogContentText>
                            {this.state.message}
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={this.hidePrompt} color="primary">
                            {this.state.closeButtonLabel}
                        </Button>
                        {this.state.actions && this.state.actions.map((action, actionIdx) => (
                            <Button key={actionIdx} onClick={this.doAction.bind(this, actionIdx)} color="primary">
                                {action.label}
                            </Button>))}
                    </DialogActions>
                </Dialog>
            </PromptsContext.Provider>
        );
    }
}

export interface IPromptsProp {
    prompts: IPromptsContext;
}

export function withPrompts<TComponentProps extends IPromptsProp>(Component: React.ComponentType<TComponentProps>) {
    return function AppWithPromptsComponent(props: Pick<TComponentProps, Exclude<keyof TComponentProps, keyof IPromptsProp>>) {
        return (
            <PromptsContext.Consumer>{(prompts) => (
                <Component {...props as TComponentProps} prompts={prompts} />
            )}</PromptsContext.Consumer>
        );
    };
}
