import * as React from 'react';
import { useEffect, useState } from 'react'
import {Button, Grid} from '@material-ui/core';
import { withAppContext, IAppContextProp } from '../AppContext';
import { withNavigationContext, INavigationContextProp } from '../navigation/Navigation';
import {  DropdownAutoComplete, IChecklistTemplate} from 'oneplace-components';
import { i18n } from '../../i18n';
import { IChecklistDashboardFilter } from '../../models/Dashboard';
import { ChecklistData } from '../../data_sources/ChecklistData';


interface IChecklistDashboardFilterProps extends
IAppContextProp,
INavigationContextProp{
    filter: IChecklistDashboardFilter | null
    onApply: (appliedFilter: IChecklistDashboardFilter) => void
}

const ChecklistFilterDashboard = 
    (props: IChecklistDashboardFilterProps) => {

    const t = i18n.t;
    const franchiseId = props.ctx.auth.user.capabilities.franchiseId
    const checklistData = new ChecklistData(props.ctx.db, props.ctx.api, props.ctx.auth.user)

    const [filter, setFilter] = useState<IChecklistDashboardFilter>({templates: []} as IChecklistDashboardFilter)
    const [templates, setTemplates] = useState<IChecklistTemplate[] | null>(null)  

    useEffect(() => {
        async function loadTemplates() {
            try{
                const checklistTemplates = await checklistData.getChecklistTemplateList(franchiseId).getData()
                setTemplates(checklistTemplates.templates)
            } catch(e){
                setTemplates([])
            }
        }
        if(!templates){
            void loadTemplates()
        }
    },[])

    useEffect(() => {
        //when initializing filter from parent component
        if(props.filter){
            setFilter(props.filter)
        }        
    },[])

    return (
        <>
            {filter && 
                <Grid container spacing={1}>
                    <Grid item xs={12}>
                        <DropdownAutoComplete
                            fieldLabel={t('checklist_template_data')}
                            itemKey='id'
                            itemLabel='name'
                            items={templates|| []}
                            onChange={(_event: React.ChangeEvent<any>, newValue: IChecklistTemplate[]) => {
                                setFilter({...filter, templates: [...newValue]})
                            }}
                            selectedItems={filter.templates || []}
                        />        
                    </Grid>    
                    <Grid item container justifyContent='flex-end' xs={12} style={{columnGap: '5px'}}>
                        <Button
                            variant='outlined'
                            color='primary'
                            onClick={() =>{
                                props.onApply(filter)
                            }}
                        >
                            {t('apply')}
                        </Button>
                        <Button
                            variant='outlined'
                            color='primary'
                            onClick={async () =>{          
                                props.onApply(filter)                      
                                if(filter.templates.length > 0){
                                    await checklistData.setChecklistDashboardFilter(franchiseId, filter)
                                } else {
                                    //for now, we only have templates filter, so, if no templates are selected, then we can remove the filter from DB
                                    await checklistData.clearChecklistDashboardFilter(franchiseId)
                                }                                
                            }}
                        >
                            {t('save_and_apply')}
                        </Button>
                    </Grid>
                </Grid>
            }
        </>
    )
}

export default withAppContext(
    withNavigationContext(ChecklistFilterDashboard)
)
