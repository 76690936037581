
import { SnackbarProvider } from 'notistack';
import * as React from 'react';
import { IAppProviderContext } from '../AppContext';
import NotificationCenterHome from './NotificationCenterHome';


export interface INotificationCenterProps {
    ctx: IAppProviderContext;
}

function NotificationCener(props: INotificationCenterProps) {
    return(
        <SnackbarProvider maxSnack={3} autoHideDuration={10000} dense={true} anchorOrigin={{ vertical: 'top', horizontal: 'right' }}>
            <NotificationCenterHome ctx={props.ctx}/>
        </SnackbarProvider>
    );
}
export default NotificationCener;
