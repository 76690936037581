
import * as React from 'react';
import { ENV } from '../../environment';

export interface IALinkProps {
    url: string;
    className?: string;
    style?: any;
}

export const ALink: React.SFC<IALinkProps> = (props) => {
    if (ENV.platform == 'cordova') {
        return (
            <a
                href=""
                style={props.style}
                className={props.className}
                onClick={(e) => {
                    e.preventDefault();
                    cordova.InAppBrowser.open(props.url, '_system');
                }}
            >{props.children}
            </a>
        );
    }
    else {
        return (
            <a
                href={props.url}
                style={props.style}
                className={props.className}
                target="_blank"
                rel="noopener noreferrer"
            >{props.children}
            </a>
        );
    }
};
