/* eslint-disable @typescript-eslint/no-misused-promises */
/* eslint-disable @typescript-eslint/require-await */
import { IEnvironment } from '../environment';

export type NetworkStateChangeListener = () => void;

export interface INetworkStatus {
    initialise: () => Promise<void>;
    isOffline: boolean;
    addListener: (listener: NetworkStateChangeListener) => void;
    removeListener: (listener: NetworkStateChangeListener) => void;
}

export class NetworkStatus implements INetworkStatus {
    isOffline = true;
    private listeners: NetworkStateChangeListener[];

    constructor(
        private env: IEnvironment
    ) {
        this.listeners = [];
    }

    async initialise() {
        let reportedOffline: boolean;
        if (this.env.platform == 'cordova') {
            reportedOffline = navigator.connection.type == Connection.NONE;
        }
        else {
            reportedOffline = !navigator.onLine;
        }
        if (reportedOffline) {
            await this.onNetworkReportedOffline();
        }
        else {
            await this.onNetworkReportedOnline();
        }
        // Cordova and web share the same online / offline events
        window.addEventListener('online', this.onNetworkReportedOnline);
        window.addEventListener('offline', this.onNetworkReportedOffline);
    }

    // Alternatively we could add a periodic network ping instead of relying on the
    // network status change events. This would use more data though...

    onNetworkReportedOnline = async () => {
        console.log('NetworkStatus: Connection reported online.');
        console.log('navigator.onLine: ' + navigator.onLine)
        this.isOffline = false;
        this.notifyListeners();
    }

    onNetworkReportedOffline = async () => {
        console.log('NetworkStatus: Connection reported offline.');
        console.log('navigator.onLine: ' + navigator.onLine)
        this.isOffline = true;
        this.notifyListeners();
    }

    addListener(listener: NetworkStateChangeListener) {
        this.listeners.push(listener);
    }

    removeListener(listener: NetworkStateChangeListener) {
        const idx = this.listeners.indexOf(listener);
        if (idx > -1) {
            this.listeners.splice(idx, 1);
        }
    }

    notifyListeners() {
        this.listeners.forEach((listener) => listener());
    }

}
