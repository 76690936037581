
import * as i18next from 'i18next';

export interface Ii18nHelper {
    initialise(): Promise<void>;
    t(key: string, values?: any): i18next.TFunctionResult;
    changeLang(lang: string): Promise<void>;
    addTranslations(ns: string, messages: any): void;
    onUpdate: () => void;
}

export class I18nHelper implements Ii18nHelper {
    _instance: i18next.i18n = null as any;
    _t: i18next.TFunction = null as any;
    onUpdate: () => void = null as any;

    initialise() : Promise<void> {
        return new Promise<void>((resolve, reject) => {
            if (this._instance) {
                throw new Error('i18n already initialised!');
            }
            this._instance = i18next.createInstance();
            void this._instance.init({
                lng: 'en',
                debug: false,
                fallbackLng: 'en',
                ns: ['common'],
                defaultNS: 'common',
                interpolation: {
                    escapeValue: false, // not needed for react
                },
                resources: {
                    en: {
                        common: {
                            home: 'Home',
                            dashboard: 'Dashboard',
                            settings: 'Settings',
                            log_in: 'Log In',
                            log_out: 'Log Out',
                            new_checklist: 'New $t(customLabel_checklist)',
                            edit_checklist: 'Edit $t(customLabel_checklist)',
                            select: 'Select',
                            select_a_checklist_template: 'Select a $t(customLabel_checklist) Template',
                            checklist: '$t(customLabel_checklist)',
                            checklist_name: '$t(customLabel_checklist) Name',
                            checklist_version: '$t(customLabel_checklist) Version',
                            version: 'Version',
                            created_by: 'Created By',
                            details: 'Details',
                            tags: 'Tags',
                            checklist_date: '$t(customLabel_checklist) Date',
                            checklist_from_date: 'From Date',
                            checklist_to_date: 'To Date',
                            search: 'Search',
                            comment: 'Comment',
                            photos: 'Photos',
                            new_attachment: 'New Photo Attachment',
                            edit_attachment: 'Edit Photo Attachment',
                            view_attachment: 'Photo Attachment',
                            photo_comment: 'Photo Comment',
                            initial_photo_comment: 'Photo Comment',
                            new_photo_comment: 'Add New Photo Comment',
                            gallery: 'Gallery Photos',
                            add_gallery_photo: 'Add Gallery Photo',
                            apply: 'Apply',
                            save_and_apply: 'Save and Apply',
                            ok: 'OK',
                            save: 'Save',
                            save_and_take_another_photo: 'Save & Photo',
                            delete: 'Delete',
                            cancel: 'Cancel',
                            clear: 'Clear',
                            validation_errors: 'Validation Errors',
                            please_correct_the_following: 'Please correct the following:',
                            error_must_enter_value_for: 'You must enter a value for "{{field}}" under "{{tab}}"',
                            error_must_enter_value_for_people: 'You must enter a value for $t(customLabel_people)',
                            error_must_enter_comment_for: 'You must enter a comment for "{{field}}" under "{{tab}}"',
                            error_must_attach_photo_for: 'You must attach a photo for "{{field}}" under "{{tab}}"',
                            error_must_attach_file_for: 'You must attach a file for "{{field}}" under "{{tab}}"',
                            error_must_enter_grid_value_for:
                                'Value missing for "{{gridField}}" in grid "{{field}}", under "{{tab}}"',
                            error_must_enter_grid_comment_for:
                                'Comment missing for "{{gridField}}" in grid "{{field}}", under "{{tab}}"',
                            error_must_attach_grid_photo_for:
                                'Photo missing for "{{gridField}}" in grid "{{field}}", under "{{tab}}"',
                            error_must_attach_grid_file_for:'File missing for "{{gridField}}" in grid "{{field}}", under "{{tab}}"',
                            error_must_enter_number_for: 'You must enter a number for "{{field}}"',
                            submit: 'Submit',
                            submitting: 'Submitting...',
                            submit_checklist: 'Submit $t(customLabel_checklist)',
                            submit_checklist_intro: 'Submit this $t(customLabel_checklist) to 1Place?',
                            submit_success: '$t(customLabel_checklist) Submitted Successfully',
                            submit_failed: 'Submitting the $t(customLabel_checklist) failed due to the following problem(s)',
                            operation_timeout: 'Unfortunately the operation timed out.'
                                + ' Please check your network connection and try again.',
                            submit_error: 'There was an error sending the $t(customLabel_checklist). Please check your network connection.'
                                + ' Your $t(customLabel_checklist) is still saved as a Draft.',
                            network_error: 'We are unable to determine if your submit was successful.'
                                + ' If you click Retry Submission you might end up with duplicate $t(customLabel_checklists).'
                                + ' You can check $t(customLabel_checklists) Completed Today to confirm submission',
                            retry_submission: 'Retry Submission',
                            data_load_error: 'Unfortunately we were unable to load required data.'
                                + ' Please try again shortly.',
                            rendering_error: 'There was a problem displaying this page. Please try again and'
                                + ' if the problem persists, contact support.',
                            forbidden_error: 'You do not have access to the requested resource.',
                            missing_entity_error: 'You do not have access to this {{entity}}\'s {{siteOrFranchisee}}',
                            take_a_photo: 'Take a Photo',
                            select_from_gallery: 'Select from Gallery',
                            select_multiple_from_gallery: 'Select Multiple from Gallery',
                            add_signature: 'Add Signature',
                            signature: 'Signature',
                            start_timer: 'Start Timer',
                            start_break: 'Start Break',
                            stop_break: 'Finish Break',
                            stop_timer: 'Stop Timer',
                            ticked: 'Ticked',
                            not_ticked: 'Not Ticked',
                            previous: 'Previous',
                            next: 'Next',
                            row: 'Row',
                            add_row: 'Add Row',
                            saving: 'Saving...',
                            saved: 'All Changes Saved.',
                            save_error: 'SAVE ERROR!',
                            draft_checklist_exists_assignee: `You have a draft $t(customLabel_checklist)
                            for the selected {{assigneeType}}. Do you want to open the draft or start a new $t(customLabel_checklist)?`,
                            draft_checklist_exists_single_draft: `You have a draft on your device with unsubmitted changes for this $t(customLabel_checklist).
                            Open the draft to continue with your changes, or delete the draft to work on the current version from the server.`,
                            draft_checklist_exists_multiple_drafts: `You have multiple draft $t(customLabel_checklists) for the selected.
                            Do you want to open the draft list or open the submitted $t(customLabel_checklist)?
                            (Please note: Opening the submitted $t(customLabel_checklist) will delete all drafts for the selected)`,
                            open_draft_list: 'Open Draft List',
                            open_draft: 'Open Draft',
                            view_drafts: 'Drafts',
                            no_drafts: 'You do not have any drafts.',
                            draft_checklists: 'Draft Unsubmitted $t(customLabel_checklists)',
                            draft_submitted_checklists: 'Draft Updated $t(customLabel_checklists)',
                            draft_tickets: 'Draft Unsubmitted $t(customLabel_tickets)',
                            draft_submitted_tickets: 'Draft Updated $t(customLabel_tickets)',
                            draft_incidents: 'Draft Unsubmitted $t(customLabel_incidents)',
                            draft_submitted_incidents: 'Draft Updated $t(customLabel_incidents)',
                            incident_template: '$t(customLabel_incident) template',
                            draft_incident_exists_single_draft: `You have a draft on your device with unsubmitted changes for this $t(customLabel_incident).
                            Open the draft to continue with your changes, or delete the draft to work on the current version from the server.`,
                            draft_incident_exists_multiple_drafts: `You have multiple draft $t(customLabel_incidents) for the selected.
                            Do you want to open the draft list or open the submitted $t(customLabel_incident)?
                            (Please note: Opening the submitted incident will delete all drafts for the selected)`,
                            drafts: 'Drafts',
                            date: 'Date',
                            delete_draft: 'Delete Draft',
                            reassign: 'Reassign',
                            reassign_checklist: 'Reassign $t(customLabel_checklists)',
                            checklist_action_no_option: 'No options available',
                            checklist_toemail: 'Send $t(customLabel_checklist) via email?',
                            checklist_editemail: 'Edit email before sending?',
                            email_checklist: 'Email $t(customLabel_checklist)',
                            checklist_score: 'Score',
                            unanswered_questions: 'Unanswered Questions',
                            email_to: 'To:',
                            email_cc: 'CC:',
                            email_subject: 'Subject:',
                            email_message: 'Message:',
                            send_email: 'Send Email',
                            email_process_error:
                                'Unfortunately there was a problem creating or sending the email.',
                            email_sent_successfully: 'Email sent successfully',
                            overview: 'Overview',
                            address: 'Address',
                            contact: 'Contact',
                            cl_status_not_done: 'To Do',
                            cl_status_draft: 'Draft',
                            filter_checklists: 'Filter',
                            filter_checklists_applied: 'Filter Applied',
                            completed_checklists: 'Completed',
                            checklists_due_today: '$t(customLabel_checklists) To Do',
                            checklists_completed_today: '$t(customLabel_checklists) Completed Today',
                            checklists_due_this_week: '$t(customLabel_checklists) Due This Week',
                            checklists_overdue: 'Overdue $t(customLabel_checklists)',
                            no_checklists: 'No $t(customLabel_checklists)',
                            start: 'Start',
                            edit_draft: 'Edit Draft',
                            edit: 'Edit',
                            continue_editing: 'Continue Editing',
                            exit: 'Exit',
                            go_to_dashboard: 'Go to Dashboard',
                            ticket_dueDate: 'Due Date',
                            ticket_dueDate_readonly: 'Due Date will be set by priority',
                            ticket_categoryType: '$t(customLabel_ticketType)',
                            ticket_category: '$t(customLabel_ticketCategory)',
                            ticket_status: 'Status',
                            ticket_priority: 'Priority',
                            ticket_subject: 'Subject',
                            ticket_subject_template:
                                'Q: {{question}}',
                            ticket_content: 'Notes',
                            ticket_content_template:
                                'Question: {{question}}\nAnswer: {{answer}}\n$t(customLabel_checklist): {{checklist}}\n$t(customLabel_checklist) Date: {{checklistDate}}',
                            ticket_sendEmail: 'Additional Email(s)',
                            ticket_emailTo: 'Additional Email(s)',
                            priority_high: 'High',
                            priority_medium: 'Medium',
                            priority_low: 'Low',
                            ticket_error_must_enter_note: 'Please complete the notes field',
                            ticket_error_must_enter_subject: 'Please complete the subject field',
                            ticket_error_must_enter_category: 'Please select a category',
                            ticket_error_must_not_contain_checklist: 'You can not select a category that has a $t(customLabel_checklist) attached,  please select a different one',
                            ticket_submit_successful: '$t(customLabel_ticket) submitted successfully',
                            ticket_submit_successful_continue: '$t(customLabel_ticket) submitted successfully, click ok to continue editing',
                            checklist_tickets_error: 'An error occured while searchinging the $t(customLabel_tickets). Please check your network connection and try again.',
                            checklist_tickets_empty_list: 'There is no pending $t(customLabel_ticket).',
                            ticket_submit_problem: 'There was a problem submitting the $t(customLabel_ticket). Your $t(customLabel_ticket) is still saved as a Draft.',
                            ticket_network_error: 'We are unable to determine if your submit was successful.'
                                + ' Your $t(customLabel_ticket) is saved as a draft'
                                + ' You can check the $t(customLabel_tickets) pages to confirm submission or try again shortly.',
                            ticket_submit_error:
                                'An error occured while submitting the $t(customLabel_ticket). Please try again shortly. Your $t(customLabel_ticket) is still saved as a Draft.',
                            my_tickets: 'My $t(customLabel_tickets)',
                            no_tickets: 'No $t(customLabel_tickets)',
                            ticket_details: '$t(customLabel_ticket) Details',
                            ticket_comments: 'Comments',
                            ticket_email_creator: 'Email $t(customLabel_statusCreator)',
                            ticket_email_opener: 'Email $t(customLabel_statusOpener)',
                            ticket_email_resolver: 'Email $t(customLabel_statusResolver)',
                            ticket_email_closer: 'Email $t(customLabel_statusCloser)',
                            ticket_wf_open: '$t(customLabel_statusOpen) $t(customLabel_ticket)',
                            ticket_wf_delegate: '$t(customLabel_statusDelegate) $t(customLabel_ticket)',
                            ticket_wf_reopen: 'Re-open $t(customLabel_ticket)',
                            ticket_wf_resolve: '$t(customLabel_statusResolve) $t(customLabel_ticket)',
                            ticket_wf_close: '$t(customLabel_statusClose) $t(customLabel_ticket)',
                            ticket_wf_back_to_resolved: 'Set Back to $t(customLabel_ticketStatusResolved)',
                            ticket_wf_reassign_delegator: 'Reassign to $t(customLabel_statusDelegator)',
                            ticket_wf_reassign_others: 'Reassign to Others',
                            ticket_wf_cancel: 'Cancel $t(customLabel_ticket)',
                            ticket_actions: 'Actions...',
                            ticket_submit: 'Submit',
                            incident_types: 'Incident Types',
                            incident_type: 'Type',
                            incident_from_date: 'From Date',
                            incident_to_date: 'To Date',
                            incident_submit_successful: '$t(customLabel_incident) submitted successfully',
                            incident_submit_problem: 'There was a problem submitting the $t(customLabel_incident)',
                            incident_network_error: 'We are unable to determine if your submit was successful.'
                                + ' Your $t(customLabel_incident) is saved as a draft'
                                + ' You can check the $t(customLabel_incidents) page to confirm submission or try again shortly.',

                            incident_submit_error:
                                'There was an error sending the $t(customLabel_incident). Please check your network connection.'
                                + ' Your $t(customLabel_incident) is still saved as a Draft.',
                            incident_submit_failed: 'Submitting the $t(customLabel_incident) failed due to the following problem(s)',
                            incident_wf_open: '$t(customLabel_statusOpen) $t(customLabel_incident)',
                            incident_wf_resolve: '$t(customLabel_statusResolve) $t(customLabel_incident)',
                            incident_wf_reopen: 'Re-$t(customLabel_statusOpen) $t(customLabel_incident)',
                            tab_incident_details: '$t(customLabel_incident) Details',
                            tab_incident_investigation: 'Investigation',
                            new_hazard: 'New $t(customLabel_hazard)',
                            hazard_details: '$t(customLabel_hazard) Details',
                            hazard_comments: 'Comments',
                            hazard_submit: 'Submit',
                            hazard_submit_successful: '$t(customLabel_hazard) submitted successfully',
                            hazard_network_error: 'We are unable to determine if your submit was successful.'
                                + ' Please check your network connection'
                                + ' and try again shortly.',
                            hazard_submit_problem: 'There was a problem submitting your $t(customLabel_hazard)',
                            notes: 'Notes',
                            note: 'Note',
                            note_author: 'Author',
                            note_type: 'Type',
                            no_notes: 'No Notes',
                            new_note: 'New Note',
                            note_subject: 'Subject',
                            note_content: 'Content',
                            note_submit: 'Submit',
                            note_submit_successful: 'Note submitted successfully',
                            note_network_problem: 'We are unable to determine if your submit was successful.'
                                + ' Please check your network connection and try again shortly.',
                            note_submit_problem: 'There was a problem submitting your note',
                            other: 'Other',
                            incidents: '$t(customLabel_incidents)',
                            hazards: '$t(customLabel_hazards)',
                            new: 'New',
                            comments: 'Comments',
                            add_comment: 'Add Comment',
                            comment_submit_successful: 'Comment added successfully',
                            comment_submit_problem: 'There was a problem adding your comment',
                            comment_network_error: 'We are unable to determine if your submit was successful.'
                                + ' Please check your network connection and try again shortly.',
                            comment_submit_error:
                                'An error occured while adding the comment. Please try again shortly.',
                            search_no_matches: 'Your search did not match any records.',
                            favourites: 'Favourites',
                            favourite_checklists: 'Favourite $t(customLabel_checklists)',
                            no_favourites: 'No Favourite $t(customLabel_checklists) Found',
                            delete_favourite: 'Remove Favourite',
                            download_favourite: 'Download PDF',
                            keep_in_favourites: 'Keep in Favourites?',
                            user: 'User',
                            show_more: 'Show More',
                            loading: 'Loading...',
                            setting_addPhotosToGallery: 'Add newly-taken photos to device gallery',
                            setting_disableBackgroundSync: 'Don\'t automatically synchronise data in the background',
                            add: 'Add',
                            create: 'Create',
                            close: 'Close',
                            connection_status: 'Connection Status',
                            network_status: 'Device Network Status',
                            api_status: '1Place Server Connection',
                            offline: 'Offline',
                            cached_user: 'Cached Credentials',
                            connecting: 'Connecting...',
                            online: 'Online',
                            none: 'None',
                            no_connection: 'No Network Connection',
                            login_required: 'Login Required',
                            session_expired: 'It looks like your session has expired and you need to log in again.',
                            connected_with_drafts: 'You are back online and have unsubmitted drafts.',
                            offline_data: 'Offline Data',
                            last_synced: 'Last Synced',
                            sync_now: 'Sync Now',
                            never: 'Never',
                            synchronising: 'Synchronising',
                            data: 'Data',
                            user_capabilities: 'User Profile',
                            dashboard_data: 'Dashboard Data',
                            checklist_template_data: '$t(customLabel_checklist) Templates',
                            checklist_template: '$t(customLabel_checklist) Template',
                            help_images: 'Help Images',
                            ticket_type_data: '$t(customLabel_ticket) Type Data',
                            ticket_category_data: '$t(customLabel_ticket) Category Data',
                            offline_and_old_data: 'Your device has not been able to synchronise with 1Place servers for'
                                + ' more than 24 hours.\n\nPlease connect your device to the internet to download the latest data.',
                            offline_data_not_available_for_entity: 'Offline data is not available for this {{entityName}}.',
                            checklist_back_confirmation: 'Have you finished with this $t(customLabel_checklist)?',
                            ticket_back_confirmation: 'Have you finished with this $t(customLabel_ticket)?',
                            incident_back_confirmation: 'Have you finished with this $t(customLabel_incident)?',
                            no: 'No',
                            yes_go_back: 'Yes, Go Back',
                            custom_fields: 'Custom Fields',
                            submit_incident: 'Submit $t(customLabel_incident)',
                            submit_incident_intro: 'Submit this $t(customLabel_incident) to 1Place?',
                            incident_email_include: 'Attachments to include ',
                            incident_email_include_tip: '(choose at least one option): ',
                            incident_toemail: 'Send $t(customLabel_incident) via email?',
                            incident_email_include_link: 'Link to add details',
                            incident_email_include_pdf: 'PDF Report of the $t(customLabel_incident)',
                            incident_toemail_invalidate: 'Replace last link sent?',
                            email_incident: 'Email $t(customLabel_incident) ',
                            new_my_ticket: 'You have 1 new $t(customLabel_ticket)',
                            new_my_tickets: 'You have {{number}} new $t(customLabel_ticket)',
                            dynamic_datagrid_delete_label : 'Delete',
                            dynamic_datagridlist_delete_label : 'Delete List',
                            dynamic_datagridlist_add_label : 'Add List',
                            new_update_available: 'A new update is available and will be used when all tabs for this page are closed',
                            question_help: 'Help',
                            question_help_image: 'Help Image',
                            question_photo: 'Add Photo',
                            question_attachment: 'Upload File',
                            question_comment: 'Add Comment',
                            show: 'Show',
                            check_connection: 'Check Connection',
                            export_database: 'Export',
                            export_image_database: 'Export Images',
                            import_image_database: 'Import Images',
                            import_database: 'Import',
                            setting_exportDatabase: 'Export Database in the Background',
                            setting_exportFrequency: 'Export Database every (minutes)',
                            person_submit_successful: '$t(customLabel_person) submitted successfully',
                            person_submit_problem: 'There was a problem submitting the $t(customLabel_person)',
                            person_network_error: 'We are unable to determine if your submit was successful.',
                            person_submit_error: 'An error occured while submitting the $t(customLabel_person). Please try again shortly.',

                            franchisee_submit_successful: '$t(customLabel_franchisee) submitted successfully',
                            franchisee_submit_problem: 'There was a problem submitting the $t(customLabel_franchisee)',
                            franchisee_network_error: 'We are unable to determine if your submit was successful.',
                            franchisee_submit_error: 'An error occured while submitting the $t(customLabel_franchisee). Please try again shortly.',

                            site_submit_successful: '$t(customLabel_site) submitted successfully',
                            site_submit_problem: 'There was a problem submitting the $t(customLabel_site)',
                            site_network_error: 'We are unable to determine if your submit was successful.',
                            site_submit_error: 'An error occured while submitting the $t(customLabel_site). Please try again shortly.',

                            show_all_cf: 'Show All Custom Fields',

                            logout_completely: 'Logout of 1Place completely',
                            logout_cancel: 'Cancel logout',
                            logout_dialog_message: 'Looks like you have tried to logout of 1Place and your SSO account.',
                            logout_dialog_title: 'Logging out of 1Place?',
                            // default values
                            customLabel_franchisee: 'franchisee',
                            customLabel_franchisees: 'franchisees',
                            customLabel_site: 'site',
                            customLabel_sites: 'sites',
                            customLabel_person: 'person',
                            customLabel_people: 'people',
                            customLabel_personType: 'person Type',
                            customLabel_personTypes: 'person Types',
                            customLabel_ticket: 'ticket',
                            customLabel_tickets: 'tickets',
                            customLabel_incident: 'incident',
                            customLabel_incidents: 'incidents',
                            customLabel_incidentName: 'Name Of Affected Person',
                            customLabel_incidentTypeInjury: 'Type of Injury',
                            customLabel_incidentCategory: 'incident Category',
                            customLabel_incidentCategories: 'incident Categories',
                            customLabel_incidentDetailCategoryOtherLabel: 'To Be Determined',
                            customLabel_hazard: 'Hazard',
                            customLabel_hazards: 'hazards',
                            customLabel_hazardAction: 'Action',
                            customLabel_hazardActionMinimise: 'minimise',
                            customLabel_hazardActionEliminated: 'eliminated',
                            customLabel_ticketCategory: 'ticket Category',
                            customLabel_ticketCategories: 'ticket Categories',
                            customLabel_checklist: 'checklist',
                            customLabel_checklists: 'checklists',
                            customLabel_incidentStatusOpen: 'Open',
                            customLabel_incidentStatusPending: 'Pending',
                            customLabel_incidentStatusResolved: 'Resolved',
                            customLabel_ticketType: 'Category Type',
                            customLabel_ticketTypes: 'Category Types',
                            customLabel_ticketStatusOpen: 'Open',
                            customLabel_ticketStatusPending: 'Pending',
                            customLabel_ticketStatusResolved:'Resolved',
                            customLabel_ticketStatusClosed:'Closed',
                            customLabel_statusCreator: 'Creator',
                            customLabel_statusOpener: 'Opener',
                            customLabel_statusResolver: 'Resolver',
                            customLabel_statusCloser: 'Closer',
                            customLabel_statusDelegator: 'Delegator',
                            customLabel_statusCreate: 'Create',
                            customLabel_statusOpen: 'Open',
                            customLabel_statusResolve: 'Resolve',
                            customLabel_statusClose: 'Close',
                            customLabel_statusDelegate: 'Delegate',

                            hazard_action_minimise:'minimise'
                        }
                    },
                    en_US: {
                        common: {
                            setting_disableBackgroundSync: 'Don\'t automatically synchronize data in the background',
                            synchronising: 'Synchronizing',
                            offline_and_old_data: 'Your device has not been able to synchronize with 1Place servers for'
                                + ' more than 24 hours.\n\nPlease connect your device to the internet to download the latest data.',
                             favourites: 'Favorites',
                            favourite_checklists: 'Favorite Checklists',
                            no_favourites: 'No Favorite Checklists Found',
                            delete_favourite: 'Remove Favorite',
                            keep_in_favourites: 'Keep in Favorites?',
                            hazard_action_minimise:'minimize'
                        }
                    },
                    fr: {
                        common: {
                            dashboard: 'Le Dashboard',
                            settings: 'Le Settings',
                            log_out: 'Le Log Out'
                        }
                    }
                }}, (err, t) => {
                    if (err) {
                        reject(err);
                    }
                    else {
                        this._t = t;
                        resolve();
                    }
                });
        });
    }

    t = (key: string, values?: any): string => {
        return this._t(key, values);
    }

    async changeLang(lang: string): Promise<void> {
        return new Promise<void>((resolve, reject) => {
            void this._instance.changeLanguage(lang, (err, _t) => {
                if (err) {
                    reject(err);
                }
                else {
                    if (this.onUpdate) {
                        this.onUpdate();
                    }
                    resolve();
                }
            });
        });
    }

    addTranslations(ns: string, messages: any): void {
        this._instance.addResourceBundle(this._instance.language, ns, messages);
        this.onUpdate();
    }

    mockT(): void {
        this._t = (key: string | string[], values?: any) => {
            return values ? key + ' ' + JSON.stringify(values) : key;
        };
    }
}

export const i18n = new I18nHelper();
