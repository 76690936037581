import { IChecklistTemplate, IFranchisee, ISite } from 'oneplace-components';

export type ChecklistScheduleStatus =
    // API States
    'not_done' | 'inprogress' | 'completed' |
    // Local States
    'local_draft';

export interface IDashboardChecklist {
    status: ChecklistScheduleStatus;
    checklist: string;
    checklistFor: string;
    dueDate: string;
    dueByDate: string;
    templateId: number;  // either a templateId or checklistId (depending on status)
    checklistTemplateId: number; //the actual checklist template id
    franchisee?: IFranchisee;
    site?: ISite;
    id: number;  // Schedule ID
    localId?: number;
}

export interface IDashboardTicket {
    id: number;
    ticketNo: number;
    date: string;
    dueDate: string;
    status: number;
    priority: number;
    statusLabel: string;
    statusProgress: any;
    priorityLabel: string;
    subject: string;
    content: string | null;
    trackingField: boolean;
    cancelTicket?: boolean;
    forFranchisee?: {
        id: number;
        name: string;
    };
    site?: {
        id: number;
        name: string;
    };
    emailSentDate: string | null;
    category: {
        id: number;
        name: string;
        checklist: any;
        dueDateReadOnly?: boolean;
    };
    checklist: any;
    incidentNo: number | null;
    entityName: string;
    requiredAction: string;
}

export interface IncidentSummaryData {
    unresolved: number; // open and pending (signed + unsigned)
    signed: number; //unresolved signed incidents
    unsigned: number;//unresolved unsigned incidents
}

export interface IDashboardData {
    dashboard: {
        incidentSummary?: IncidentSummaryData;
        ticketSummary?: ITicketSummaryData;
        checklistSummary: {
            dueToday: number;
            completedToday: number;
            overDue: number;
        };
        myTickets?: IDashboardTicket[];
        favouriteChecklistNumber: number;
    };
}

export interface IChecklistsWithCount {
    checklists: IDashboardChecklist[];
    totalCount: number;
}

export interface IScheduleData {
    checklists: IDashboardChecklist[];
}

export interface IDashboardNotes {
    notes: IDashboardNote[];
}
export interface IDashboardNote {
    id: number;
    author: string;
    date: string;
    type: string;
    subject: string;
    text: string;
    readOnly: boolean;
    site?: {
        id: number;
        name: string;
    };
}
export interface ISummaryData {
    summary: {
        ticketSummary: ITicketSummaryData;
    };
}
export interface ITicketSummaryData {
    toAction: number;
    overdue: number;
    highPriority: number;
    dueToday: number;
    dueThisWeek: number;
    overDue: number;
    myTickets: number;
}


export interface IChecklistDashboardFilter{
    templates: IChecklistTemplate[]
}

/*Helper function to avoid this long assertion throughout the codebase, and also, 
in the future, we might have other fields to be added to the filter/assertion*/
export function hasFilterApplied(filter: IChecklistDashboardFilter | null): boolean{
    return !!filter && filter.templates && filter.templates.length > 0
} 