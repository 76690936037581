import { IFranchise, ISites, ITags, IFranchisees, IPerson, IIDAndName, ISite} from 'oneplace-components';
import { IAppDataDB } from './database';
import { IApi } from './api';
import { DataWithCache, IDataWithCache } from './cache';
import { User } from '../models/User';
import { logError } from '../logging';

export interface IFranchiseData {
    getFranchise(): IDataWithCache<IFranchise>;
    getFranchisees(franchiseId: number): IDataWithCache<IFranchisees>;
    getSites(franchiseId: number): IDataWithCache<ISites>;
    getSitesArchived(franchiseId: number): IDataWithCache<ISites>;
    getTags(franchiseId: number): IDataWithCache<ITags>;
    getPeople(franchiseId: number): IDataWithCache<IPerson[]>;
    syncPeople(franchiseId: number, howMany: number, from: number, lastSyncTime: string | null): IDataWithCache<IPerson[]>;
    syncFranchiseeSites(franchiseId: number): IDataWithCache<Map<string, IIDAndName[]>>;
    getFranchiseeSites(franchiseId: number, franchiseeId: number, isOffline: boolean): Promise<IIDAndName[]>
}

const SITE_PAGE_SIZE = 100

export class FranchiseData implements IFranchiseData {

    constructor(
        public _db: IAppDataDB,
        public _api: IApi,
        public _user: User
    ) {}

    getFranchise(): DataWithCache<IFranchise> {
        const data = new DataWithCache<IFranchise>({
            strategy: 'db_first',
            cache: this._db,
            entityName: 'franchise',
            entityId: '1',
            updateCacheOlderThan: 300000,  // 5 minutes
            getApiDataFn: async () => {
                // We store franchisees seperately, to be compatible with useAutocomplete option
                const franchise = await this._api.getFranchise();
                if (!this._user.capabilities.useAutocomplete) {
                    try {
                        await this._db.setById('franchisees', String(franchise.id), {
                            franchisees: franchise.franchisees,
                            __cache_timestamp: Date.now()
                        });
                    }
                    catch (e) {
                        logError(e, 'Could not store franchisees');
                    }
                }
                delete franchise.franchisees;
                return franchise;
            }
        });
        return data;
    }

    getFranchisees(franchiseId: number): DataWithCache<IFranchisees> {
        const data = new DataWithCache<IFranchisees>({
            strategy: 'db_first',
            cache: this._db,
            entityName: 'franchisees',
            entityId: String(franchiseId),
            updateCacheOlderThan: 300000,  // 5 minutes
            getApiDataFn: async () => {
                if (this._user.capabilities.useAutocomplete) {
                    return this._api.getFranchiseeAssignments(franchiseId);
                }
                else {
                    const franchise = await this._api.getFranchise();
                    return {
                        franchisees: franchise.franchisees
                    };
                }
            }
        });
        return data;
    }

    getSites(franchiseId: number): DataWithCache<ISites> {
        const data = new DataWithCache<ISites>({
            strategy: 'db_first',
            cache: this._db,
            entityName: 'sites',
            entityId: String(franchiseId),
            updateCacheOlderThan: 300000,  // 5 minutes
            getApiDataFn: async () => { 
                const allSites = [] as ISite[]
                let fetchedAll = false
                let page = 0
                if (this._user.capabilities.useAutocomplete) {                    
                    do {
                        const sites = await this._api.getSiteAssignments(franchiseId, (page * SITE_PAGE_SIZE) , SITE_PAGE_SIZE);
                        allSites.push(...sites.sites)
                        fetchedAll = sites.sites.length < SITE_PAGE_SIZE
                        page++
                    } while(!fetchedAll)
                }
                else {
                    do {
                        const sites = await this._api.getSites(franchiseId, (page * SITE_PAGE_SIZE) , SITE_PAGE_SIZE);
                        allSites.push(...sites.sites)
                        fetchedAll = sites.sites.length < SITE_PAGE_SIZE
                        page++
                    } while(!fetchedAll)                    
                }
                return {sites: allSites} as ISites
            }
        });
        return data;
    }

    getSitesArchived(franchiseId: number): DataWithCache<ISites> {
        const data = new DataWithCache<ISites>({
            strategy: 'db_first',
            cache: this._db,
            entityName: 'sites',
            entityId: String(franchiseId) + 'R',
            updateCacheOlderThan: 300000,  // 5 minutes
            getApiDataFn: async () => {
                return this._api.getSitesArchived(franchiseId);
            }
        });
        return data;
    }

    getTags(franchiseId: number): DataWithCache<ITags> {
        const data = new DataWithCache<ITags>({
            strategy: 'db_first',
            cache: this._db,
            entityName: 'tags',
            entityId: String(franchiseId),
            updateCacheOlderThan: 300000,  // 5 minutes
            getApiDataFn: () => this._api.getTags(franchiseId)
        });
        return data;
    }

    // used on search pages
    getPeople(franchiseId: number): DataWithCache<IPerson[]> {
        const data = new DataWithCache<IPerson[]>({
            strategy: 'api_first',
            cache: this._db,
            entityName: 'people',
            entityId: String(franchiseId),
            updateCacheOlderThan: 300000,  // 5 minutes
            getApiDataFn: () => this._api.getPeople(franchiseId)
        });
        return data;
    }

    // used in sync function
    syncPeople(franchiseId: number, howMany: number, from: number, lastSyncTime: string | null): DataWithCache<IPerson[]> {
        const data = new DataWithCache<IPerson[]>({
            strategy: 'api_first',
            cache: this._db,
            entityName: 'people',
            entityId: String(franchiseId),
            updateCacheOlderThan: 300000,  // 5 minutes
            getApiDataFn: () => this._api.syncPeople(franchiseId, howMany, from, lastSyncTime)
        });
        return data;
    }

    // used in sync function
    syncPersonTypes(franchiseId: number, lastSyncTime: string | null): DataWithCache<IPerson[]> {
        const data = new DataWithCache<IPerson[]>({
            strategy: 'api_first',
            cache: this._db,
            entityName: 'person_types',
            entityId: String(franchiseId),
            updateCacheOlderThan: 300000,  // 5 minutes
            getApiDataFn: () => this._api.syncPersonTypes(franchiseId, lastSyncTime)
        });
        return data;
    }

    syncFranchiseeSites(franchiseId: number): DataWithCache<Map<string, IIDAndName[]>> {
        const data = new DataWithCache<Map<string, IIDAndName[]>>({
            strategy: 'api_first',
            cache: this._db,
            entityName: 'franchisee_sites',
            entityId: String(franchiseId),
            updateCacheOlderThan: 900000,  // 15 minutes
            getApiDataFn: async () => this._api.syncFranchiseeSites(franchiseId)
        });
        return data;
    }

    async getFranchiseeSites(franchiseId: number, franchiseeId: number, isOffline: boolean): Promise<IIDAndName[]> {        
        let result: IIDAndName[];
        if(!isOffline){
            const response: ISites = await this._api.getFranchiseeSites(franchiseId, franchiseeId)
            result = response.sites.map(site => {
                const s = {} as IIDAndName
                s.id = site.id
                s.name = site.name
                return s
            })
        } else {
            //for some reason, converting to Map<string, IIDAndName[]> does not work
            const localResult = await this._db.getById('franchisee_sites', String(franchiseId));            
            // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-explicit-any
            result = (localResult as any)[`${franchiseeId}`]
        }

        return result || []
    }
}
