import * as React from 'react';
import { ENV } from '../../environment';
import IconButton from '@material-ui/core/IconButton';
import PDFIcon from '@material-ui/icons/PictureAsPdf';

export const PDFButton = (props: { url: string }) => {
    if (ENV.platform == 'cordova') {
        return (
            <IconButton onClick={(e) => {
                e.stopPropagation();
                cordova.InAppBrowser.open(props.url, '_system');
            }}>
                <PDFIcon />
            </IconButton>
        );
    }
    else {
        return (
            <IconButton onClick={(e) => {
                e.stopPropagation();
                window.open(props.url, '_blank')!.opener = null;
            }}>
                <PDFIcon />
            </IconButton>
        );
    }
};
