/* eslint-disable no-control-regex */
import { SelectOptions, IAddress } from 'oneplace-components';
import { IIDAndName, IIDAndLabel } from '../models/entity';
import { ENV } from '../environment';

export function withTimeout<T>(timeout: number, promise: Promise<T>, error?: Error): Promise<T> {
    return Promise.race([
        promise,
        new Promise<T>((resolve, reject) => {
            setTimeout(() => {
                reject(error || new Error(`timed out after ${timeout}ms`));
            }, timeout);
        })
    ]);
}

export function deepCopy<T>(obj: T): T {
    return JSON.parse(JSON.stringify(obj));
}

export function roundDecimal(num: number, dp: number) {
    // fixed JS' floating point innacuracies problem,
    // 1.005.toFixed(2) gives result as 1.00 which should be 1.01;
    return Number(Math.round(Number(num + 'e' + dp)) + 'e-' + dp);
}

export function createQueryString(obj: any) {
    const parts: string[] = [];
    for (const key in obj) {
        if (Object.prototype.hasOwnProperty.call(obj,key) && obj[key] !== null && obj[key] !== undefined) {
            parts.push(encodeURIComponent(key) + '=' + encodeURIComponent(obj[key]));
        }
    }
    return parts.join('&');
}

export function isDefined(val: any) {
    return (typeof val != 'undefined');
}

// escapeBlob and unescapeBlob are workarounds for a websql bug:
// https://code.google.com/p/chromium/issues/detail?id=422690
// https://bugs.webkit.org/show_bug.cgi?id=137637
// The goal is to never actually insert the \u0000 character
// in the database.
export function escapeBlob(str: string) {
    return str
      .replace(/\u0002/g, '\u0002\u0002')
      .replace(/\u0001/g, '\u0001\u0002')
      .replace(/\u0000/g, '\u0001\u0001');
}

export function unescapeBlob(str: string) {
    return str
      .replace(/\u0001\u0001/g, '\u0000')
      .replace(/\u0001\u0002/g, '\u0001')
      .replace(/\u0002\u0002/g, '\u0002');
}



export function mapStringOptions(options: string[]): SelectOptions  {
    const newOptions: SelectOptions = [];
    options.forEach((option) => {
        newOptions.push({value : option, label : option});
    });
    return newOptions;
}

export function mapIDAndNameOptions(options: IIDAndName[]): SelectOptions {
    const newOptions: SelectOptions = [];
    options.forEach((option) => {
        newOptions.push({value: String(option.id), label: option.name});
    });
    return newOptions;
}

export function mapIDAndLabelOptions(options: IIDAndLabel[]): SelectOptions {
    const newOptions: SelectOptions = [];
    options.forEach((option) => {
        newOptions.push({value: String(option.id), label: option.label});
    });
    return newOptions;
}

export function getMapsURL(address: IAddress) {
    const urlProperties: string[] = []

    if(address.unit_no)
        urlProperties.push(address.unit_no)
    if(address.street)
        urlProperties.push(address.street)
    if(address.suburb)
        urlProperties.push(address.suburb)
    if(address.postcode)
        urlProperties.push(address.postcode)
    if(address.city)
        urlProperties.push(address.city)
    if(address.state)
        urlProperties.push(address.state)
    if(address.country)
        urlProperties.push(address.country)

    const urlParameters = urlProperties.join('+')

    const  iOS =
    [
      'iPad Simulator',
      'iPhone Simulator',
      'iPod Simulator',
      'iPad',
      'iPhone',
      'iPod'
    ].includes(navigator.platform)
    // iPad on iOS 13 detection
    || (navigator.userAgent.includes('Mac') && 'ontouchend' in document)

    const prefix = ENV.os === 'ios' || iOS ? 'maps' : 'https'
    return `${prefix}://maps.google.com/maps?q=${urlParameters}`
}
