import * as React from "react";
import { AppLoader } from "./AppLoader";
import { logError } from "../logging";
import { IApi, Api } from "../data_sources/api";
import {
    IAppDataDB,
    AppDataDB,
    ITokenDB,
    TokenDB,
    TokenSqliteDB,
    AppDataSqliteDB,
} from "../data_sources/database";
import { Ii18nHelper, i18n } from "../i18n";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import { IImageStorage } from "../data_sources/imagestorage";
import { ENV, isUseSqliteDatabase } from "../environment";
import { CordovaFSImageStorage } from "../data_sources/imagestorage/cordovafs";
import { DexieImageStorage } from "../data_sources/imagestorage/dexie";
import { NetworkStatus, INetworkStatus } from "../network/NetworkStatus";
import { OnePlaceAuth, IOnePlaceAuth } from "../auth/OnePlaceAuth";
import { ISyncManager, SyncManager } from "../data_sources/sync/SyncManager";
import { AssetCacheManager } from "../data_sources/assets/AssetCacheManager";
import * as OneplaceComponents from "oneplace-components";
import {
    IOneplaceComponents,
    OneplaceComponentsManager,
} from "../components/oneplace_components";
import { BackupManager } from "../data_sources/backup/BackupManager";
import { DatabaseError } from "../errors/DatabaseError";
import { CordovaSqliteFSImageStorage } from "../data_sources/imagestorage/cordovaSqliteFs";
import * as randomstring from "randomstring";
import { Deploy } from "cordova-plugin-ionic";
import { CONFIG } from "../config";
import { IDeployConfig } from "cordova-plugin-ionic/dist/IonicCordova";
import { TimeoutError } from "../errors/TimeoutError";
import { NetworkError } from "../errors/NetworkError";
import Keycloak from 'keycloak-js';

export interface IAppProviderProps {
    mock_token_db?: ITokenDB;
    mock_app_db?: IAppDataDB;
    mock_keycloak?: Keycloak;
    mock_api?: IApi;
    mock_i18n?: Ii18nHelper;
    kc_timeout?: number;
}

export type AppStatus =
    | "initialising"
    | "login_required"
    | "active"
    | "error"
    | "reload_required"
    | "logout";

export interface IAppProviderContext {
    message?: string | null;
    appStatus: AppStatus;
    net: INetworkStatus;
    auth: IOnePlaceAuth;
    db: IAppDataDB;
    api: IApi;
    sync: ISyncManager;
    lang: string;
    imageStorage: IImageStorage;
    assetCache: AssetCacheManager;
    backupManager: BackupManager;
    goOnline(): void;
    logOut(): void;
    forceLogout(): void;
    stayLoggedIn():void
    oneplaceComponents: IOneplaceComponents;
}

export const AppContext = React.createContext<IAppProviderContext>(null as any);

export class AppContextProvider extends React.Component<
    IAppProviderProps,
    IAppProviderContext
> {
    _tokenDB: ITokenDB;
    _i18n: Ii18nHelper;

    constructor(props: IAppProviderProps) {
        super(props);
        this._tokenDB =
            props.mock_token_db || isUseSqliteDatabase()
                ? new TokenSqliteDB()
                : new TokenDB();
        this._i18n = props.mock_i18n || i18n;
        this.login = this.login.bind(this);
        this.logout = this.logout.bind(this);
        this.forceLogout = this.forceLogout.bind(this);
        this.stayLoggedIn = this.stayLoggedIn.bind(this);
        this.reload = this.reload.bind(this);
        this.initialiseAuth = this.initialiseAuth.bind(this);

        const imageStorage: IImageStorage =
            ENV.platform == "cordova" && ENV.os != "browser"
                ? isUseSqliteDatabase()
                    ? new CordovaSqliteFSImageStorage()
                    : new CordovaFSImageStorage()
                : new DexieImageStorage();

        const db =
            props.mock_app_db || isUseSqliteDatabase()
                ? new AppDataSqliteDB()
                : new AppDataDB();
        const net = new NetworkStatus(ENV);
        const auth = new OnePlaceAuth(net, this._tokenDB);
        const api = props.mock_api || new Api(net, auth);
        const assetCache = new AssetCacheManager(net, auth, db, imageStorage);
        const sync = new SyncManager(
            net,
            auth,
            api,
            this._tokenDB,
            db,
            assetCache
        );
        const oneplaceComponents = new OneplaceComponentsManager(
            ENV,
            db,
            api,
            assetCache
        );
        const backupManager = new BackupManager(db, imageStorage);

        this.state = {
            message: null,
            appStatus: "initialising",
            net,
            auth,
            db,
            api,
            sync,
            imageStorage,
            assetCache,
            lang: "en",
            goOnline: this.initialiseAuth,
            logOut: this.logout,
            forceLogout: this.forceLogout,
            stayLoggedIn: this.stayLoggedIn,
            oneplaceComponents,
            backupManager,
        };
    }

    componentDidMount = async () => {
        try {
            await this.initialiseApp();
        } catch (err) {
            console.log(err);
        }
    };

    async initialiseApp() {
        // Pre-authentication actions
        try {
            await this._i18n.initialise();
            this._i18n.onUpdate = () => {
                this.setState({
                    lang: i18n._instance.language,
                });
            };
            await this.state.net.initialise();
            await this.initialiseTokensDB();
            await this.initialiseAuth();
        } catch (e) {
            logError(e);
            this.setState({
                appStatus: "error",
            });
        }
    }

    async initialiseUserData() {
        // Post-authentication actions
        try {
            this.setState({
                appStatus: "initialising",
            });
            await this.initialiseAppDataDB();
            this.initialiseMessages();
            this.registerIdentifiedUser();
            await this.state.sync.initialise();
            await this.state.oneplaceComponents.initialise();
            await this.state.backupManager.initialise();
            await this.state.db.emptyPreviousPage()//clear any possible stored state
            this.setState({
                appStatus: "active",
            });
        } catch (e) {
            logError(e);
            this.setState({
                appStatus: "error",
            });
        }
    }

    async initialiseTokensDB() {
        await this._tokenDB.initialise();
    }

    async initialiseAppDataDB() {
        const dbName = `OnePlace_${this.state.auth.user.database}`;
        const result = await this.state.db.initialise(
            dbName,
            this.state.auth.user.id
        );
        if (result === false) {
            // 2 users with the same first 15 characters in the email address
            // we need to create a different databse name or see if they already have one
            const dbNameSuffix = `${this.state.auth.user.email.substring(
                0,
                8
            )}${randomstring.generate(9)}`;
            const newDbName = "OnePlace_" + dbNameSuffix;
            this.state.auth.updateUserDatabase(dbNameSuffix);
            const nextResult = await this.state.db.initialise(
                newDbName,
                this.state.auth.user.id
            );
            // console.log(this.state.auth.user)
            if (nextResult === false) {
                throw new DatabaseError(
                    "db error: more than 2 users with a simalar email"
                );
            }
        }
    }

    async registerIdentifiedUser() {
        if (this.state.auth.status == "online") {
            if (
                (ENV.platform == "cordova" && ENV.os == "browser") ||
                ENV.platform === "web"
            ) {
                window.Intercom("boot", {
                    app_id: process.env.INTERCOM_APP_ID,
                    email: this.state.auth.user.email,
                    name: this.state.auth.user.fullName,
                    user_id: this.state.auth.user.id,
                    created_at: this.state.auth.user.capabilities.createdAt,
                    user_hash: this.state.auth.user.capabilities.userHash,
                    profile: this.state.auth.user.capabilities.profile,
                    company: {
                        company_id:
                            this.state.auth.user.capabilities.advisorCode,
                        name: this.state.auth.user.capabilities.companyName,
                        industry: this.state.auth.user.capabilities.industry,
                        plan: this.state.auth.user.capabilities.planType,
                        partner: this.state.auth.user.capabilities.partner ? this.state.auth.user.capabilities.presetName : '',
                        'Partner Instance': this.state.auth.user.capabilities.partner,
                        'Promo Code': this.state.auth.user.capabilities.presetName,
                        created_at: this.state.auth.user.capabilities.createdAt,
                    },
                });
            } else if (ENV.platform == "cordova" && ENV.os != "windows") {
                try {
                    await Deploy.configure({
                        channel: CONFIG.appFlowChannelName,
                    } as IDeployConfig);
                } catch (e) {
                    logError(e, "Ionic deploy configure error");
                }
                const intercom = {
                    userId: this.state.auth.user.id,
                    email: this.state.auth.user.email,
                    name: this.state.auth.user.fullName,
                    created_at: this.state.auth.user.capabilities.createdAt,
                    profile: this.state.auth.user.capabilities.profile,
                    company: {
                        company_id:
                            this.state.auth.user.capabilities.advisorCode,
                        name: this.state.auth.user.capabilities.companyName,
                        industry: this.state.auth.user.capabilities.industry,
                        plan: this.state.auth.user.capabilities.planType,
                        partner: this.state.auth.user.capabilities.partner ? this.state.auth.user.capabilities.presetName : '',
                        'Partner Instance': this.state.auth.user.capabilities.partner,
                        'Promo Code': this.state.auth.user.capabilities.presetName,
                        created_at: this.state.auth.user.capabilities.createdAt,
                    },
                };

                cordova.plugins.intercom.setUserHash(
                    this.state.auth.user.capabilities.userHash
                );
                cordova.plugins.intercom.registerIdentifiedUser(
                    intercom,
                    () => {
                        cordova.plugins.intercom.updateUser(
                            {
                                name: this.state.auth.user.fullName,
                                created_at:
                                    this.state.auth.user.capabilities.createdAt,
                                custom_attributes: {
                                    profile:
                                        this.state.auth.user.capabilities
                                            .profile,
                                },
                                companies: [
                                    {
                                        company_id:
                                            this.state.auth.user.capabilities
                                                .advisorCode,
                                        name: this.state.auth.user.capabilities
                                            .companyName,
                                        plan: this.state.auth.user.capabilities
                                            .planType,
                                        industry:
                                            this.state.auth.user.capabilities
                                                .industry,
                                        created_at:
                                            this.state.auth.user.capabilities
                                                .createdAt,
                                    },
                                ],
                            },
                            () => {
                                console.log("updated intercom");
                            },
                            (err: any) => {
                                console.log("error with intercom" + err);
                            }
                        );
                    }
                );
            }
        }
    }

    async initialiseAuth() {
        const authenticated = await this.state.auth.authenticateUser();
        if (authenticated) {
            await this.initialiseUserData();
        } else {
            this.setState({
                appStatus: "login_required",
            });
        }
    }

    async initialiseMessages() {
        let messages: any;
        if (this.state.auth.cachedUser) {
            messages = await this.state.db.retrieveMessages("common");
        } else {
            messages = await this.state.api.getMessages();
            try {
                await this.state.db.storeMessages("common", messages);
            } catch (e) {
                logError(e, "Error while storing messages");
            }
        }
        this._i18n.changeLang(this.state.auth.user.capabilities.locale);
        this._i18n.addTranslations("common", messages);
    }

    async login() {
        try {
            const loggedIn = await this.state.auth.login();
            if (loggedIn) {
               await this.initialiseUserData();
            }
        } catch (e) {
            if (e instanceof TimeoutError) {
                // timeout connecting to keycloak
                this.setState({
                    message: "Failed to authenicate, Please try again later",
                });
            } else if (e instanceof NetworkError) {
                // not network connection
                this.setState({
                    appStatus: "reload_required",
                    message:
                        "Failed to authenicate, Please check your internet connection",
                });
            }
        }
    }

    reload() {
        location.reload();
    }

    async logout() {
        const isLoggedout = await this.state.auth.logout();
        console.log("logout:" + isLoggedout )
        if (isLoggedout) {
            if (ENV.platform == "cordova" && ENV.os !== "browser") {
                cordova.plugins.intercom.logout();
            }
            this.setState({ appStatus: "login_required" });
        } else {
            // onlys show this for sso users
            if (this.state.auth.tokens && this.state.auth.tokens.identityProvider) {
                this.setState({ appStatus: "logout" });
            }
        }
    }
    forceLogout() {
        // SSO and keycloak don't do a proper openid logout as we don't get a redirect
        // So we show a dialog and ask the user to logout again
        this.state.auth.forceLogout();
        if (ENV.platform == "cordova" && ENV.os !== "browser") {
            cordova.plugins.intercom.logout();
        }
        this.setState({ appStatus: "login_required" });
    }

    stayLoggedIn() {
        this.setState({
            appStatus: "active",
        });
    }

    render() {
        if (this.state.appStatus == "initialising") {
            return <AppLoader loading={true} />;
        } else if (this.state.appStatus == "login_required") {
            return (
                <AppLoader loading={false}>
                    {this.state.message && (
                        <Typography variant="subtitle1" color="inherit">
                            {this.state.message}
                        </Typography>
                    )}

                    <Typography variant="subtitle1" color="inherit">
                        You need to log in.
                    </Typography>
                    <Button
                        variant="contained"
                        style={{ background: "#FFF", marginTop: 30 }}
                        size="large"
                        onClick={this.login}
                    >
                        Log In
                    </Button>
                </AppLoader>
            );
        } else if (this.state.appStatus == "reload_required") {
            return (
                <AppLoader loading={false}>
                    {this.state.message && (
                        <Typography variant="subtitle1" color="inherit">
                            {this.state.message}
                        </Typography>
                    )}
                    <Typography variant="subtitle1" color="inherit">
                        You need to restart 1Place app.
                    </Typography>
                    <Button
                        variant="contained"
                        style={{ background: "#FFF", marginTop: 30 }}
                        size="large"
                        onClick={this.reload}
                    >
                        Restart App
                    </Button>
                </AppLoader>
            );
        } else if (this.state.appStatus === "error") {
            return (
                <AppLoader
                    loading={false}
                    status="There was a problem starting up. Please try again shortly."
                />
            );
        } else {
            return (
                <AppContext.Provider value={this.state}>
                    {this.props.children}
                </AppContext.Provider>
            );
        }
    }
}

export interface IAppContextProp {
    ctx: IAppProviderContext;
}

export function withAppContext<TComponentProps extends IAppContextProp>(
    Component: React.ComponentType<TComponentProps>
) {
    return function AppcontextComponent(
        props: Pick<
            TComponentProps,
            Exclude<keyof TComponentProps, keyof IAppContextProp>
        >
    ) {
        return (
            <AppContext.Consumer>
                {(ctx) => (
                    <OneplaceComponents.OneplaceLibraryContextProvider
                        env={ctx.oneplaceComponents.getEnv()}
                        i18n={i18n._instance}
                        client={ctx.oneplaceComponents}
                        imageStorage={ctx.imageStorage}
                    >
                        <Component {...(props as TComponentProps)} ctx={ctx} />
                    </OneplaceComponents.OneplaceLibraryContextProvider>
                )}
            </AppContext.Consumer>
        );
    };
}
