import * as React from 'react';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Avatar from '@material-ui/core/Avatar';
import TicketIcon from '@material-ui/icons/Tune';
import { Theme, createStyles, WithStyles, withStyles } from '@material-ui/core/styles';
import { formatDate } from '../../utils/dates';
import { User } from '../../models/User';
import { i18n } from '../../i18n';
import { PageLoader } from 'oneplace-components';
import { IDashboardTicket } from '../../models/Dashboard';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import SearchIcon from '@material-ui/icons/Search';
import { PullToRefresh } from '../common/PullToRefresh';
import { ITicket } from '../../models/Tickets';
import { utcToZonedTime } from 'date-fns-tz';

const styles = (_theme: Theme) => createStyles({
    badgeRoot: {
        paddingLeft: 40
    },
    badgeStyle: {
        top: 0,
        left: 0
    },
    listItemTextRoot: {
        padding: '0 16px'
    },
    otherTicketDetails: {
        display: 'flex',
        flexDirection: 'row',
        flexWrap: 'wrap',
        [_theme.breakpoints.down('sm')]: {
            width: 200,
        },
        [_theme.breakpoints.up('sm')]: {
            width: 500,
        }
    },
    otherTicketDetails__section: {
        [_theme.breakpoints.down('sm')]: {
            width: '100%',
        },
        [_theme.breakpoints.up('sm')]: {
            width: 200,
            paddingRight: 10
        }
    }
});

export interface ITicketListProps extends WithStyles<typeof styles> {
    title: string;
    totalCount: number;
    tickets: IDashboardTicket[];
    currentUser: User;
    openTicket: (ticket: IDashboardTicket) => void;
    onAllTicketsTapped?: () => void;
    onExpand?: () => void;
    onShowMore?: () => void;
    onRefresh: () => void;
    loading?: boolean;
    loadingMore?: boolean;
}

export const TicketList = withStyles(styles)((props: ITicketListProps) => {
    const t = i18n.t;
    console.log('myTickets props', props);

    return (
        <PullToRefresh className="" onRefresh={props.onRefresh}>
            <Card>
                <CardContent style={{ padding: 8 }}>
                    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                        <Typography variant="subtitle1" style={{ margin: 8 }}>{props.title}</Typography>
                        <Button color="primary" onClick={props.onAllTicketsTapped}>
                            <SearchIcon style={{ marginRight: 8 }} />
                            All {t('customLabel_tickets')}
                        </Button>
                    </div>
                    {props.loading == true &&
                        <PageLoader loading={true} paddingTop={0} />}
                    {props.loading != true && props.tickets.length == 0 &&
                        <Typography style={{ textAlign: 'center' }}>{t('no_tickets')}</Typography>
                    }
                    {props.loading != true && props.tickets.length > 0 &&
                        <List style={{ width: '100%'}} disablePadding={true}>
                            {props.tickets.map((ticket, idx) => {
                                return (
                                    <ListItem key={idx}>
                                        <Avatar>
                                            <TicketIcon />
                                        </Avatar>
                                        <ListItemText classes={{ root: props.classes.listItemTextRoot }}
                                            primary={`${ticket.ticketNo}: ${ticket.subject}`}
                                            secondary={
                                                <OtherTicketDetails
                                                    ticket={ticket}
                                                    currentUser={props.currentUser}
                                                />
                                            }
                                        />
                                        <div>
                                            <Button variant="contained" color="default"
                                                onClick={() => props.openTicket(ticket)}>
                                                {t('edit')}
                                            </Button>
                                        </div>
                                    </ListItem>
                                );
                            })}
                            {props.tickets.length < props.totalCount && <div style={{ textAlign: 'center' }}>
                                {!props.loadingMore &&
                                    <Button
                                        variant="contained"
                                        onClick={props.onShowMore}
                                    >{t('show_more')}</Button>}
                                {props.loadingMore &&
                                    <Button
                                        variant="contained"
                                        disabled={true}
                                    >{t('loading')}</Button>}
                            </div>}
                        </List>
                    }
                </CardContent>
            </Card>
        </PullToRefresh>
    );
});

export interface IOtherTicketDetailsProps extends WithStyles<typeof styles> {
    ticket: IDashboardTicket | ITicket;
    currentUser: User;
}

export const OtherTicketDetails = withStyles(styles)((props: IOtherTicketDetailsProps) => {
    const {
        classes,
        ticket: { entityName, date, dueDate, statusLabel, category },
        currentUser
    } = props;
    const t = i18n.t;
    return (
        <div className={classes.otherTicketDetails}>
            <span className={classes.otherTicketDetails__section}>
                Created: {formatDate('display_date', utcToZonedTime(date, currentUser.capabilities.timeZone), currentUser.capabilities.dateFormat)}
            </span>
            <span className={classes.otherTicketDetails__section}>
                Due: {formatDate('display_date', dueDate, currentUser.capabilities.dateFormat)}
            </span>
            <span className={classes.otherTicketDetails__section}>For: {entityName}</span>
            <span className={classes.otherTicketDetails__section}>Status: {t('customLabel_ticketStatus' + statusLabel)}</span>
            <span className={classes.otherTicketDetails__section}>Category: {category?.name || ''}</span>
        </div>
    )
})
