import { ISettings } from './settings';

export function getDefaultSettings(): ISettings {
    return {
        addPhotosToGallery: false,
        disableBackgroundSync: false,
        exportDatabase: false,
        exportFrequency: 10
    };
}
