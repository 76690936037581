import * as React from 'react';
import  {useState, useEffect} from 'react'
import Collapse from '@material-ui/core/Collapse';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import { Typography } from '@material-ui/core';

interface ICollapseProps{
    onSwitch: (expanded: boolean) => void;
    expanded: boolean;
    children: React.ReactNode;
}

export default(props: ICollapseProps) => {

    const [expanded, setExpanded] = useState<boolean>(props.expanded || false);

    const handleChange = () => {
        setExpanded((prev) => !prev);
    };

    // This is triggered in the initial render.
    // We should change it to trigger only on user action.
    useEffect(() => {
        props.onSwitch(expanded)
    },[expanded])

    const actionStyle = {
        display: 'flex',
        cursor: 'pointer',
        color: '#757575'
    }

    return (
        <>
            {!expanded &&
                <div style={actionStyle} onClick={handleChange}>
                    <Typography>Show More</Typography>
                    <ExpandMoreIcon />
                </div>
            }
            <Collapse in={expanded}>
                {props.children}
            </Collapse>
            {expanded &&
                <div style={actionStyle} onClick={handleChange}>
                    <Typography>Show Less</Typography>
                    <ExpandLessIcon />
                </div>
            }
        </>
    );
}
