
import * as React from 'react';
/* eslint-disable @typescript-eslint/unbound-method */ // since we're using bind to handle 'this', we can ignore this warning

import { withStyles, Theme, createStyles, WithStyles } from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import Avatar from '@material-ui/core/Avatar';
import SiteIcon from '@material-ui/icons/Place';
import ListItemText from '@material-ui/core/ListItemText';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import { i18n } from '../../i18n';
import { FranchiseData, IFranchiseData } from '../../data_sources/FranchiseData';
import { withAppContext, IAppContextProp } from '../AppContext';
import { IIDAndName, PageLoader} from 'oneplace-components';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { withNavigationContext, INavigationContextProp } from '../navigation/Navigation';
import Input from '@material-ui/core/Input';
import { debounce } from 'debounce';
import { CONFIG } from '../../config';
import Typography from '@material-ui/core/Typography';
import VisibilitySensor from 'react-visibility-sensor';
import { clearPreviousPages } from '../../data_sources/PreviousPage';

const styles = (_theme: Theme) => createStyles({
    root: {
    },
    searchCard: {
        marginBottom: 20,
        overflow: 'visible'
    },
    cardContent: {
        paddingTop: 0
    },
    listItemTextRoot: {
        padding: '0 16px'
    }
});

export interface ISiteSearchProps extends
        WithStyles<typeof styles>,
        IAppContextProp,
        INavigationContextProp,
        RouteComponentProps<any> {
    mock_franchiseData?: IFranchiseData;
}

export interface ISiteSearchState {
    loadState: 'loading' | 'loaded';
    allSites: IIDAndName[];
    filteredSites: IIDAndName[];
    numberShown: number;
    lastSearch: string;
    search: string;
    loadingMore: boolean;
}
export const SiteSearchPage = withStyles(styles)(withRouter(withAppContext(withNavigationContext(
    class extends React.Component<ISiteSearchProps, ISiteSearchState> {
        franchiseData: IFranchiseData;
        searchDebounced: () => void;

        constructor(props: ISiteSearchProps) {
            super(props);
            this.onSearchChanged = this.onSearchChanged.bind(this);
            this.search = this.search.bind(this);
            this.onShowMore = this.onShowMore.bind(this);
            this.state = {
                loadState: 'loading',
                allSites: [],
                filteredSites: [],
                numberShown: CONFIG.franchiseeSearchResults,
                lastSearch: '',
                search: '',
                loadingMore: false
            };
            this.franchiseData = this.props.mock_franchiseData
                || new FranchiseData(this.props.ctx.db, this.props.ctx.api, this.props.ctx.auth.user);

            this.props.nav.updateNavigation({
                title: i18n.t('customLabel_sites')
            });

            void this.loadData();
            this.searchDebounced = debounce(this.search, CONFIG.franchiseeSearchDelay);
        }
        
        async componentDidUpdate(prevProps: ISiteSearchProps) {
            const previousParam = new URLSearchParams(prevProps.location.search).get('franchiseeId')
            const currentParam = new URLSearchParams(this.props.location.search).get('franchiseeId')
            if(previousParam && !currentParam){
                await this.loadData()
            }
         }

        async loadData() {
            const capabilities = this.props.ctx.auth.user.capabilities
            const franchiseeId = new URLSearchParams(this.props.location.search).get('franchiseeId');
            let sites: IIDAndName[]
            
            if(franchiseeId){//filtering only sites for current franchisee
                sites = await this.franchiseData.getFranchiseeSites(
                    capabilities.franchiseId, 
                    Number(franchiseeId), 
                    this.props.ctx.net.isOffline
                )

                //always show franchisee name + site name
                const franchiseeName = new URLSearchParams(this.props.location.search).get('franchiseeName');
                if(franchiseeName && franchiseeName.trim()){
                    sites = sites.map(site => {
                        site.name = `${franchiseeName} -- ${site.name}`
                        return site
                    })
                }
            } else {//all instance sites
                const [ franchiseSites ] = await Promise.all([
                    this.franchiseData.getSites(capabilities.franchiseId).getData(),
                ]);

                //always show franchisee name + site name
                //when using autoComplete, site name contains the franchisee name already
                sites = capabilities.useAutocomplete ? 
                    franchiseSites.sites : 
                    franchiseSites.sites.map(site =>{
                        site.name = `${site.franchiseeName} -- ${site.name}`
                        return site
                    })
            }            

            this.setState({
                allSites: sites,
                filteredSites: sites,
                numberShown: CONFIG.franchiseeSearchResults,
                lastSearch: '',
                search: '',
                loadState: 'loaded'
            });
        }

        onShowMore(showMore: boolean) {
            if (showMore && !this.state.loadingMore
                    && this.state.numberShown < this.state.filteredSites.length) {
                this.setState({
                    loadingMore: true
                });
                setTimeout(() => {
                    this.setState((state) => ({
                        numberShown: state.numberShown + CONFIG.franchiseeSearchResults,
                        loadingMore: false
                    }));
                }, 200);
            }
        }

        onSearchChanged(event: React.ChangeEvent<HTMLInputElement>) {
            const search = event.target.value;
            this.setState({ search });
            this.searchDebounced();
        }

        search() {
            this.setState((state) => ({
                lastSearch: state.search,
                filteredSites: state.allSites
                    .filter((site) =>
                        site.name.toLowerCase().includes(
                            state.search.trim().toLowerCase())),
                numberShown: CONFIG.franchiseeSearchResults
            }));
        }

        goToDashboard(siteId: number) {
            // reset previous page states
            void clearPreviousPages(this.props.ctx.db);

            this.props.history.push(
                '/sites/' + siteId
            );
        }

        render() {
            const t = i18n.t;

            if (this.state.loadState == 'loaded') {
                const sites = this.state.filteredSites.slice(0, this.state.numberShown);

                return (
                    <div className={this.props.classes.root}>
                        <Card className={this.props.classes.searchCard}>
                            <CardHeader
                                title={t('customLabel_sites')}
                                style={{ paddingBottom: 8 }}
                            />
                            <CardContent className={this.props.classes.cardContent}>
                                <Input
                                    fullWidth
                                    id="search"
                                    value={this.state.search}
                                    onChange={this.onSearchChanged}
                                    type="search"
                                    placeholder={t('search')}
                                />
                            </CardContent>
                        </Card>
                        {sites.length > 0 &&
                            <Card>
                                <CardContent style={{ padding: 16 }}>
                                    <List disablePadding={true}>
                                        {sites.map((site) => (
                                            <ListItem button style={{ padding: '8px'}} key={site.id}
                                                onClick={this.goToDashboard.bind(this, site.id)}>
                                                <Avatar>
                                                    <SiteIcon />
                                                </Avatar>
                                                <ListItemText classes={{ root: this.props.classes.listItemTextRoot }}
                                                    primary={site.name}
                                                />
                                            </ListItem>
                                        ))}
                                    </List>
                                </CardContent>
                                <VisibilitySensor onChange={this.onShowMore} partial={true}>
                                    <div style={{ minHeight: 8 }}>
                                        {this.state.loadingMore && <PageLoader loading={true} paddingTop={0} />}
                                    </div>
                                </VisibilitySensor>
                            </Card>
                        }
                        {sites.length == 0 &&
                            <Typography variant="subtitle1" style={{ textAlign: 'center' }}>
                                No matches for &quot;{this.state.lastSearch}&quot;
                            </Typography>
                        }
                    </div>
                );
            }
            else if (this.state.loadState == 'loading'){
                return (
                    <PageLoader loading={true} />
                );
            }else{
                return (
                    <div>
                        <PageLoader loading={false} status={t('data_load_error')} />
                    </div>
                );
            }
        }
    }
))));
