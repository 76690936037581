
import { User } from '../../../models/User';

export function getMainDashboardUrl(currentUser: User) {
    const capabs = currentUser.capabilities;
    if (capabs.franchiseDashboard == false
        && capabs.retailOrganisation == true
        && capabs.franchiseeId) {
            return '/franchisees/' + capabs.franchiseeId;
    }
    return '/overview';
}
