import { IDashboardNote } from './Dashboard';
import { createQueryString } from '../utils';
import { AssigneeType, IPhoto } from 'oneplace-components';
import { IIDAndName } from './entity';

export interface INoteWrapper {
    note: INote;
}
export interface INote {
    id: number | null;
    date: string;
    author: string;
    subject: string;
    text: string;
    type: string;
    forFranchisee?: IIDAndName;
    site?: IIDAndName;
    franchiseeId: number;
    attachments: IPhoto[];
}

export const NoteTypes = [
    { value : 'memo', label : 'Memo'},
    { value : 'discussion', label : 'Discussion'},
    { value : 'email_private', label : 'Email (Private)'}
];

export function getNoteUrlFromDashboardPage(note: IDashboardNote, assigneeParam: AssigneeType, assigneeIdParam: number) {
    return '/notes/edit?' + createQueryString({
        noteId: note.id,
        assignee: assigneeParam,
        assigneeId: assigneeIdParam,
    });
}
