import * as React from 'react';
import { useEffect, useState, useRef } from 'react'
import { createStyles, WithStyles, withStyles } from '@material-ui/core/styles'
import {Card, CardContent, Grid, Typography, Theme, Button} from '@material-ui/core';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { withAppContext, IAppContextProp } from '../AppContext';
import { withNavigationContext, INavigationContextProp } from '../navigation/Navigation';
import { TextControl, PageLoader, IFranchisee, ICustomField} from 'oneplace-components';
import { FormikField } from '../form_controls/FormikField';
import { i18n } from '../../i18n';
import { Formik, Form } from 'formik';
import ContactPhone from '@material-ui/icons/ContactPhone';
import Room from '@material-ui/icons/Room';
import ViewList from '@material-ui/icons/ViewList';
import { CustomFieldsGroup } from '../custom_fields/CustomFieldsGroup';
import { LoadStatus } from '../common/types';
import { ForbiddenError } from '../../errors/ForbiddenError';
import { SubmitStatusDialog, SubmitDialogStage } from '../common/SubmitStatusDialog';
import { UserFacingError } from '../../errors/UserFacingError';
import { NetworkError } from '../../errors/NetworkError';
import Collapse from '../common/Collapse'
import { getMapsURL } from '../../utils'
import { ALink } from '../common/ALink';
import { convertDDMMYYYYToLocalDate } from '../../utils/dates';
import { CONFIG } from '../../config';

const styles = (_theme: Theme) => createStyles({
    root:{},
    entityCard: {
        marginBottom: 20,
        overflow: 'visible'
    },
    entitySelector: {
        marginTop: 10
    },
    cardContent: {
        paddingTop: 0
    }
})

interface IFranchiseeDetailsProps extends
WithStyles<typeof styles>,
IAppContextProp,
INavigationContextProp,
RouteComponentProps<any> {
    franchiseeId: number;
}

interface IFranchiseeDetailsState {
    loadState: LoadStatus;
    submitDialogOpen: boolean;
    submitDialogStage: SubmitDialogStage | null;
    submitDialogDismissDisabled: boolean;
    submitDialogMessage: string;
    submitDialogSubMessage: string | React.ReactElement<any>;
    loadFranchisee: boolean;
    contactDetailsExpanded: boolean;
    addressDetailsExpanded: boolean;
    editingMode: boolean;
}

export default withStyles(styles)(
    withRouter(
        withAppContext(
            withNavigationContext(
                (props: IFranchiseeDetailsProps) => {

                    const t = i18n.t;
                    const api = props.ctx.api;
                    const capabs = props.ctx.auth.user.capabilities;
                    const franchiseeRef = useRef<IFranchisee | null>();

                    // state
                    const [franchisee, setFranchisee] = useState<IFranchisee | null>(null)

                    // state
                    const [state, setState] = useState<IFranchiseeDetailsState>({
                        loadState: 'loading',
                        submitDialogOpen: false,
                        submitDialogStage: null,
                        submitDialogMessage: '',
                        submitDialogSubMessage: '',
                        submitDialogDismissDisabled: false,
                        loadFranchisee: true,
                        contactDetailsExpanded: false,
                        addressDetailsExpanded: false,
                        editingMode: false
                    })


                    useEffect(() => {
                        async function getFranchisee(franchiseeId: number) {
                            const franch = await api.getFranchisee(capabs.franchiseId, franchiseeId);
                            if(franch.customFields){
                                franch.customFields.forEach((customField) => {
                                    if (customField.type === 'DATE') {
                                        // date format is currently  "dd-MM-yyyy" ie. local date
                                        if (customField.value && customField.value != '') {
                                            customField.value = convertDDMMYYYYToLocalDate(customField.value);
                                        }
                                        customField.dateFormat = props.ctx.auth.user.capabilities.dateFormat;
                                    } else if (customField.type === 'DATETIME') {
                                        customField.dateTimeFormat = props.ctx.auth.user.capabilities.dateTimeFormat;
                                    } else if (customField.type === 'TIME') {
                                        customField.timeFormat = CONFIG.displayTimeFormat;
                                    }
                                    if(isReadOnly()){
                                        customField.readonly = true
                                    }
                                });
                            }
                            franchiseeRef.current = franch;
                            setFranchisee({...franchisee, ...franch})
                            setState((prevState)  => ({...prevState,
                                loadState: 'loaded',
                                loadFranchisee: false
                            }));
                            franchiseeRef.current = franch
                        }

                        if(state.loadFranchisee){
                            getFranchisee(props.franchiseeId).catch(e => setState({...state,
                                loadState: (e instanceof ForbiddenError ? 'load_error_forbidden' : 'load_error')})
                            );
                        }
                    },[state.loadFranchisee])


                     useEffect(() => {
                        franchiseeRef.current = franchisee
                    },[franchisee])


                    function setFranchiseeFieldValues(values: IFranchisee){
                        setFranchisee({...franchisee, ...values})
                    }

                    function onCustomFieldChanged(updatedField: ICustomField){
                        // early return to avoid adding exclamation mark
                        if(!franchisee?.customFields){
                            return
                        }
                        const fieldIdx = franchisee.customFields.findIndex((field) =>
                            field.customFieldId === updatedField.customFieldId);
                        if (fieldIdx == -1) {
                            throw new Error(`Updated custom field id ${updatedField.customFieldId} did not match ${t('customLabel_franchisee')}!`);
                        }
                        const newCustomFields = [...franchisee.customFields]
                        newCustomFields[fieldIdx] = updatedField
                        setFranchisee({...franchisee, customFields: newCustomFields})
                    }

                    function isReadOnly() {return capabs.franchiseeReadOnly || !state.editingMode}

                    function closeSubmitDialog(){
                        if(state.submitDialogStage === 'submit_error'){
                            setState(prevState => ({
                                ...prevState,
                                submitDialogOpen: false
                            }))
                        } else {
                            // reload franchisee details
                            setState(prevState => ({
                                ...prevState,
                                loadState: 'loading',
                                submitDialogOpen: false,
                                loadFranchisee: true
                            }))
                        }
                    }

                    async function save(){
                        try {
                            setState(prevState => ({
                                ...prevState,
                                submitDialogOpen: true,
                                submitDialogStage: 'submitting',
                                submitDialogMessage: '',
                                submitDialogSubMessage: ''
                            }))

                            await props.ctx.api.updateFranchisee(capabs.franchiseId, franchiseeRef.current!)

                            setState(prevState => ({
                                ...prevState,
                                submitDialogStage: 'submit_successful',
                                submitDialogMessage: t('franchisee_submit_successful'),
                                submitDialogDismissDisabled: true,
                                editingMode: false
                            }))
                        } catch(e) {
                            let submitDialogMessage = ''
                            let submitDialogSubMessage = ''

                            if (e instanceof UserFacingError) {
                                submitDialogMessage = t('franchisee_submit_problem')
                                submitDialogSubMessage = e.message
                            }
                            else if (e instanceof NetworkError) {
                                submitDialogMessage = t('franchisee_network_error')
                            }
                            else {
                                submitDialogMessage = t('franchisee_submit_error')
                            }
                            setState(prevState => ({
                                ...prevState,
                                submitDialogStage: 'submit_error',
                                submitDialogMessage,
                                submitDialogSubMessage,
                                submitDialogDismissDisabled: false
                            }))
                        }
                    }

                    function activateEditingMode(){
                        setState({...state, editingMode: true})
                        if(franchisee?.customFields){
                            const customFields = [...franchisee.customFields]
                            customFields.forEach((customField) => {
                                    customField.readonly = !!customField.integrationFieldMapping
                            });
                            setFranchisee({...franchisee, customFields: [...customFields]})
                        }
                    }

                    const headerStyle = {
                        display: 'flex',
                        alignItems: 'center',
                        flexWrap: 'wrap',
                        marginTop: '20px',
                        color: '#223d79'
                    } as React.CSSProperties

                    const iconHeaderStyle = {
                        color: '#223d79',
                        fontSize : '30px'
                    }

                    const textHeaderStyle = {
                        marginLeft: '5px',
                        fontSize: '20px',
                        color: '#223d79'
                    } as React.CSSProperties

                    return(
                        <>
                            {state.loadState ==='loaded' && franchisee &&
                                <Card className={props.classes.entityCard}>
                                    <CardContent className={props.classes.cardContent}>
                                        <Formik
                                            initialValues={franchisee}
                                            onSubmit={(_values, _actions) => {
                                return;
                            }}
                                            validate={setFranchiseeFieldValues}
                                            render={() => (
                                                <Form>
                                                    <Grid container spacing={2} style={{ marginTop: 8 }}>
                                                    {franchisee.primaryContact &&
                                                        <>
                                                            <Grid item xs={12} sm={12}>
                                                                <div style={headerStyle}>
                                                                    <ContactPhone style={iconHeaderStyle}/>
                                                                    <Typography variant='subtitle1' style={textHeaderStyle}>
                                                                        Main Contact
                                                                    </Typography>
                                                                    <div style={{flex: 'auto'}}></div>
                                                                    {!capabs.franchiseeReadOnly &&
                                                                        <Button variant="contained" color="secondary"
                                                                            onClick={() => state.editingMode ?
                                                                                save() : activateEditingMode()}
                                                                        >
                                                                            {state.editingMode ? t('save') : t('edit')}
                                                                        </Button>
                                                                    }
                                                                </div>
                                                            </Grid>

                                                            <FormikField
                                                                disabled={isReadOnly()}
                                                                name={'primaryContact.first_name'}
                                                                label='First Name'
                                                                gridProps={{ xs: 12, sm: 6 }}
                                                                component={TextControl}
                                                            />
                                                            <FormikField
                                                                disabled={isReadOnly()}
                                                                name={'primaryContact.last_name'}
                                                                label='Last Name'
                                                                gridProps={{ xs: 12, sm: 6 }}
                                                                component={TextControl}
                                                            />
                                                            <FormikField
                                                                disabled={isReadOnly() || franchisee.disabledFields?.email}
                                                                name={'primaryContact.email'}
                                                                label='Email'
                                                                gridProps={{ xs: 12, sm: 12 }}
                                                                component={TextControl}
                                                            />
                                                            <FormikField
                                                                disabled={isReadOnly() || franchisee.disabledFields?.telephone}
                                                                name={'primaryContact.work_phone'}
                                                                label='Work Phone'
                                                                gridProps={{ xs: 12, sm: 6 }}
                                                                component={TextControl}
                                                            />
                                                            <FormikField
                                                                disabled={isReadOnly() || franchisee.disabledFields?.mobile}
                                                                name={'primaryContact.mobile'}
                                                                label='Mobile Phone'
                                                                gridProps={{ xs: 12, sm: 6 }}
                                                                component={TextControl}
                                                            />
                                                            <Grid item xs={12} sm={12}>
                                                                <Collapse
                                                                    expanded={state.contactDetailsExpanded}
                                                                    onSwitch={(expanded) => setState({...state, contactDetailsExpanded: expanded})}
                                                                >
                                                                    <Grid container spacing={2} style={{ marginBottom: 10 }}>
                                                                        <FormikField
                                                                            disabled={isReadOnly()}
                                                                            name={'primaryContact.home_phone'}
                                                                            label='Home Phone'
                                                                            gridProps={{ xs: 12, sm: 6 }}
                                                                            component={TextControl}
                                                                        />
                                                                        <FormikField
                                                                            disabled={isReadOnly()}
                                                                            name={'primaryContact.fax'}
                                                                            label='Fax Number'
                                                                            gridProps={{ xs: 12, sm: 6 }}
                                                                            component={TextControl}
                                                                        />
                                                                        <FormikField
                                                                            disabled={isReadOnly()}
                                                                            name={'primaryContact.emailCC'}
                                                                            label='Email CC'
                                                                            gridProps={{ xs: 12, sm: 12 }}
                                                                            component={TextControl}
                                                                        />
                                                                        <FormikField
                                                                            disabled={isReadOnly()}
                                                                            name={'primaryContact.position'}
                                                                            label='Title'
                                                                            gridProps={{ xs: 12, sm: 12 }}
                                                                            component={TextControl}
                                                                        />
                                                                    </Grid>
                                                                </Collapse>
                                                            </Grid>
                                                        </>
                                                    }
                                                    {franchisee.primaryAddress &&
                                                        <>
                                                            <Grid item xs={12} sm={12}>
                                                                <div style={headerStyle}>
                                                                    <Room style={iconHeaderStyle}/>
                                                                    <Typography variant='subtitle1' style={textHeaderStyle}>
                                                                        Primary Address [<ALink style={{color: '#223d79'}} url={getMapsURL(franchisee.primaryAddress)}>view map</ALink>]
                                                                    </Typography>

                                                                </div>
                                                            </Grid>

                                                            <FormikField
                                                                disabled={isReadOnly() || franchisee.disabledFields?.address}
                                                                name={'primaryAddress.unit_no'}
                                                                label='Unit No'
                                                                gridProps={{ xs: 12, sm: 12 }}
                                                                component={TextControl}
                                                            />
                                                            <FormikField
                                                                disabled={isReadOnly() || franchisee.disabledFields?.address}
                                                                name={'primaryAddress.street'}
                                                                label='Street'
                                                                gridProps={{ xs: 12, sm: 12 }}
                                                                component={TextControl}
                                                            />
                                                            <FormikField
                                                                disabled={isReadOnly() || franchisee.disabledFields?.address}
                                                                name={'primaryAddress.city'}
                                                                label='City'
                                                                gridProps={{ xs: 12, sm: 12 }}
                                                                component={TextControl}
                                                            />

                                                            <Grid item xs={12} sm={12}>
                                                                <Collapse
                                                                    expanded={state.addressDetailsExpanded}
                                                                    onSwitch={(expanded) => setState({...state, addressDetailsExpanded: expanded})}
                                                                >
                                                                    <Grid container spacing={2} style={{ marginBottom: 10 }}>

                                                                    <FormikField
                                                                        disabled={isReadOnly() || franchisee.disabledFields?.address}
                                                                        name={'primaryAddress.postcode'}
                                                                        label='Postal Code'
                                                                        gridProps={{ xs: 12, sm: 12 }}
                                                                        component={TextControl}
                                                                    />
                                                                    {franchisee.primaryAddress.suburb != null &&
                                                                        franchisee.primaryAddress.suburb != undefined &&
                                                                            <FormikField
                                                                                disabled={isReadOnly() || franchisee.disabledFields?.address}
                                                                                name={'primaryAddress.suburb'}
                                                                                label='Suburb'
                                                                                gridProps={{ xs: 12, sm: 12 }}
                                                                                component={TextControl}
                                                                            />
                                                                    }

                                                                    {franchisee.primaryAddress.state != null &&
                                                                        franchisee.primaryAddress.state != undefined &&
                                                                            <FormikField
                                                                                disabled={isReadOnly() || franchisee.disabledFields?.address}
                                                                                name={'primaryAddress.state'}
                                                                                label='State'
                                                                                gridProps={{ xs: 12, sm: 12 }}
                                                                                component={TextControl}
                                                                            />
                                                                    }
                                                                    <FormikField
                                                                        disabled={isReadOnly() || franchisee.disabledFields?.address}
                                                                        name={'primaryAddress.country'}
                                                                        label='Country'
                                                                        gridProps={{ xs: 12, sm: 12 }}
                                                                        component={TextControl}
                                                                    />
                                                                    </Grid>
                                                                </Collapse>
                                                            </Grid>



                                                        </>
                                                    }
                                                    </Grid>
                                                </Form>
                                        )}/>
                                        {franchisee.customFields &&
                                            <div style={{marginTop: '30px'}}>

                                                    <div style={headerStyle}>
                                                        <ViewList style={iconHeaderStyle}/>
                                                        <Typography variant='subtitle1' style={textHeaderStyle}>
                                                            Custom Fields
                                                        </Typography>
                                                    </div>
                                                    <div style={{marginTop: '15px'}}>
                                                        <CustomFieldsGroup
                                                            label={t('custom_fields')}
                                                            fields={franchisee.customFields}
                                                            helpInfos={[]}
                                                            onFieldChanged={onCustomFieldChanged}
                                                        />
                                                    </div>
                                            </div>
                                        }
                                    </CardContent>
                                </Card>
                            }
                            {state.loadState ==='loading' &&
                                <PageLoader loading={true} paddingTop={16} />
                            }
                            {state.loadState === 'load_error_forbidden' &&
                                <PageLoader loading={false} status={t('forbidden_error')} />
                            }
                            {state.loadState === 'load_error' &&
                                <PageLoader loading={false} status={t('data_load_error')} />
                            }
                            <SubmitStatusDialog
                                isOpen={state.submitDialogOpen}
                                dismissDisabled={state.submitDialogDismissDisabled}
                                title={`Submit ${t('customLabel_franchisee')} Details`}
                                stage={state.submitDialogStage!}
                                message={state.submitDialogMessage}
                                subMessage={state.submitDialogSubMessage}
                                onClose={closeSubmitDialog}
                                showContinueButtons={false}
                            />
                        </>
                    )
                }
            )
        )
    )
)
