import { IAppDataDB } from '../database';
import { IImageStorage } from '../imagestorage';
import downloadjs from 'downloadjs';
import { exportTicketLocalPhotos, exportAttachmentLocalPhotos, exportLocalPhotos } from '../../components/checklists/utils/photos';
import { formatDate } from '../../utils/dates';
import { ENV } from '../../environment';
import { parse } from 'date-fns';
import { logError } from '../../logging';
import { ISettings } from '../../settings/settings';

export type BackupStatus = 'disabled' | 'enabled' | 'in_progress';

export class BackupManager {


    private listeners: (() => void)[] = [];
    private settings: ISettings =  null as any;
    private backupIntervalTimer: any = null;
    backupStatus: BackupStatus = 'disabled';



    constructor(
          private db: IAppDataDB,
        private imageStorage: IImageStorage
    ) { }

    async initialise() {
        try {
            this.settings = await this.db.getSettings();
            if (ENV.platform == 'cordova' && ENV.os != 'browser' && this.settings.exportDatabase) {
                this.backupStatus = 'enabled';
                this.startSyncCheck()
            }
            this.notifyListeners();
        }
        catch (e) {
            logError(e, 'Error initialising Backup Manager.');
        }

    }


    private startSyncCheck() {
        this.backupStatus = 'enabled';
        // eslint-disable-next-line @typescript-eslint/no-misused-promises
        this.backupIntervalTimer = setInterval(this.exportDatabase, this.settings.exportFrequency? this.settings.exportFrequency  * 60000: 5 * 60000);
        console.log('backup enabled.');
    }


    private stopBackup() {
        this.backupStatus = 'disabled';
        clearInterval(this.backupIntervalTimer);
        console.log('backup disabled.');
    }

    updateBackup(){
        this.stopBackup();
        this.initialise();
    }
// https://github.com/apache/cordova-plugin-file/pull/461/files
// https://stackoverflow.com/questions/18736255/using-phonegap-filewriter-write-for-big-files

    _writeLargeFile(location: string, fileName: string, data: Blob) {
        return new Promise<void>((resolve, reject) => {
            window.requestFileSystem(window.LocalFileSystem.PERSISTENT, 0, (_fsd: any) => {
                window.resolveLocalFileSystemURL(location , (fs: any) => {
                    console.log('_writeFile  file system open: ');
                    fs.getFile(fileName, { create: true }, (fileEntry: any) => {
                        console.log('path:', fileEntry.fullPath);
                        fileEntry.createWriter((fileWriter: any) => {
                            const writeFinish = () => {
                                function success(file: File) {
                                    console.log('Wrote file with size: ' + file.size);
                                    resolve();
                                }
                                function fail(error: Error) {
                                    console.error('Failed file write: ', error);
                                    reject(error);
                                }
                                fileEntry.file(success, fail);
                            }



                            fileWriter.onerror = (e: any) => {
                                console.error('Failed file write: ', e);
                                reject(e);
                            };
                            let written = 0;
                            const BLOCK_SIZE = 1*1024*1024; // write 1M every time of write
                            const writeNext = (cbFinish: any) => {
                                fileWriter.onwrite = (_evt: any) => {
                                    if (written < data.size)
                                        writeNext(cbFinish);
                                    else
                                        cbFinish();
                                };
                                if (written) fileWriter.seek(fileWriter.length);
                                fileWriter.write(data.slice(written, written + Math.min(BLOCK_SIZE, data.size - written)));
                                written += Math.min(BLOCK_SIZE, data.size - written);
                            }
                            writeNext(writeFinish);
                        });
                    }, (err: any) => {
                        reject(err);
                    });
                }, (err: any) => {
                    reject(err);
                });
            }, (err: any) => {
                reject(err);
            });
        });
    }

    _writeFile(location: string, fileName: string, data: Blob) {
        return new Promise<void>((resolve, reject) => {
            window.requestFileSystem(window.LocalFileSystem.PERSISTENT, 0, (_fsd: any) => {
                window.resolveLocalFileSystemURL(location , (fs: any) => {
                    console.log('_writeFile  file system open: ');
                    fs.getFile(fileName, { create: true }, (fileEntry: any) => {
                        console.log('path:', fileEntry.fullPath);
                        fileEntry.createWriter((fileWriter: any) => {
                            fileWriter.onwriteend = () => {
                                console.log('Successful file write!');
                                resolve();
                            };
                            fileWriter.onerror = (e: any) => {
                                console.error('Failed file write: ', e);
                                reject(e);
                            };
                            fileWriter.write(data);
                        });
                    }, (err: any) => {
                        reject(err);
                    });
                }, (err: any) => {
                    reject(err);
                });
            }, (err: any) => {
                reject(err);
            });
        });
    }

    _readDir(location: string) {
        return new Promise<void>((resolve, reject) => {
            window.resolveLocalFileSystemURL(location, (fs: any) => {
                    console.log('file system open: ');
                    fs.createReader().readEntries((reader: any) => {
                        reader.forEach((file: any) => {
                            console.log(file);
                        })
                        resolve();
                    },
                    (err: any) => {
                            reject(err);
                    });
            },
            (err: any) => {
                 reject(err);
            });

        });
    }

    /*
     This delete the files locally
    */
    _writeToCloud(location: string, fileName: string) {
        return new Promise<void>((resolve, reject) => {
            window.resolveLocalFileSystemURL(location, (fs: any) => {
                console.log('writeToCloud file system open: ');
                fs.getFile(fileName, {}, (fileEntry: any) => {
                    console.log('path:', fileEntry.fullPath);
                    if (fileEntry.isFile) {
                        window.iCloudDocStorage.syncToCloud(fileEntry.toURL(),  () => {
                            console.log('synced to icloud');
                            resolve();
                        },
                        (err: any) => {
                            console.log(err)
                            reject(err);
                        });
                    }
                }, (err: any) => {
                    reject(err);
                });
            }, (err: any) => {
                reject(err);
            });
        });
    }

    _readFile(location: string, fileName: string, fileType: string) {
        return new Promise<Blob>((resolve, reject) => {
            window.resolveLocalFileSystemURL(location, (fs: any) => {
                console.log('file system open: ');
                fs.getFile(fileName, {}, (fileEntry: any) => {
                    console.log('fileEntry is file?', fileEntry.isFile.toString());
                    console.log('path:', fileEntry.fullPath);
                    fileEntry.file((file: any) => {
                        const reader = new FileReader();
                        reader.onloadend = () => {
                            const data = new Blob([new Uint8Array(reader.result as any)], { type: fileType });
                            resolve(data);
                        };
                        reader.onerror = (e: any) => {
                            reject(e);
                        };
                        reader.readAsArrayBuffer(file);
                    });
                }, (err: any) => {
                    reject(err);
                });
            }, (err: any) => {
                reject(err);
            });
        });
    }

    _copyFile(location: string, fileName: string, targetLocation: string, targetFileName: string) {
        return new Promise<void>((resolve, reject) => {
            window.resolveLocalFileSystemURL(location, (fs: any) => {
                console.log('_copyFile file system open: ');
                fs.getFile(fileName, {}, (fileEntry: any) => {
                    console.log('path:', fileEntry.fullPath);
                    if (fileEntry.isFile) {
                        window.resolveLocalFileSystemURL(targetLocation, (targetFS: any) => {
                            fileEntry.copyTo(targetFS, targetFileName,  () => {
                                console.log('copiedfile completed');
                                resolve();
                            },
                            (err: any) => {
                                console.log(err)
                                reject(err);
                            })
                        }
                        ,(err: any) => {
                            reject(err);
                            }
                        )
                    } else {
                        resolve();
                    }
                }, (err: any) => {
                    reject(err);
                })
            }, (err: any) => {
                reject(err);
            });
        });
    }

    _writeDirectoryToCloud(location: string) {
        return new Promise<void>((resolve, reject) => {
            window.iCloudDocStorage.initUbiquitousContainer('1place', () => {

                window.resolveLocalFileSystemURL(location, (fs: any) => {
                    console.log('file system open: ');
                    fs.createReader().readEntries((reader: any) => {
                        reader.forEach((file: any) => {
                            console.log(file);
                            if (file.isFile) {
                                window.iCloudDocStorage.syncToCloud(file.toURL(),  () => {
                                    console.log(file);
                                },
                                (err: any) => {
                                    console.log(err)
                                });
                            }
                        })
                        resolve();
                    },
                    (err: any) => {
                            reject(err);
                    });
                    }, (err: any) => {
                        reject(err);
                    });
            },
            (err: any) => {
                 reject(err);
            });

        });
    }

    removeOldBackups(location: string) {
        return new Promise<void>((resolve, reject) => {
            window.resolveLocalFileSystemURL(location, (fs: any) => {
                console.log('_removeOldBackups file system open: ');
                fs.createReader().readEntries((reader: any) => {
                    reader.sort( (fileA: any, fileB: any) => {
                        let fileADate = new Date()
                        let fileBDate = new Date();
                        let matches = fileA.name.match(/^(draft-export-|image-export-)(.+)(\.json)/);
                        if (matches) {
                            fileADate = parse(matches[2],'yyyy-MM-dd\'T\'HHmmss', new Date())
                        }
                        matches = fileB.name.match(/^(draft-export-|image-export-)(.+)(\.json)/);
                        if (matches) {
                            fileBDate = parse(matches[2],'yyyy-MM-dd\'T\'HHmmss', new Date())
                        }
                        return fileADate.getTime() - fileBDate.getTime();
                    });
                    const toDelete: any[] = [];

                    reader.forEach((file: any) => {
                        if(file.name.startsWith('draft-export-') || file.name.startsWith('image-export-') ) {
                            // console.log(file.name);
                            toDelete.push (file);
                        }
                    });
                    if(toDelete.length > 4) {
                        const itemToDelete = toDelete.length - 4
                        const arrDeletedItems = toDelete.splice(0,itemToDelete)
                        arrDeletedItems.forEach((file: any) => {
                            console.log('file to remove ' + file.name)
                            file.remove(() => {
                                console.log('file removed')
                            },
                            (err: any) => {
                                console.log('error on delte');
                                    console.log(err)
                            });
                        })

                        if  (ENV.os =='ios') {
                            window.iCloudDocStorage.initUbiquitousContainer('1place', () => {
                                arrDeletedItems.forEach((file: any) => {
                                        window.iCloudDocStorage.removeFile(file.name,  () => {
                                            console.log('removed from icloud');
                                            console.log(file);
                                    },
                                    (err: any) => {
                                        console.log('error removing from icloud');
                                        console.log(err)
                                    });
                                })
                            },
                            (err: any) => {
                                reject(err);
                            });
                        } else if (ENV.os == 'windows') {
                            arrDeletedItems.forEach((file: any) => {
                                window.documentsLibrary.deleteFileFromDocuments(file.name,  () => {
                                    console.log('removed from documents');
                                    console.log(file);
                            },
                            (err: any) => {
                                console.log('error removing from documents folder');
                                console.log(err)
                            });
                           })
                        }
                        resolve();
                    }
                },
                (err: any) => {
                    reject(err);
                });
            }, (err: any) => {
                reject(err);
            });
        });
    }

    async backupFile(location: string, fileName: string, data: Blob) {
        await this._writeLargeFile(location, fileName, data);
        if  (ENV.os =='ios') {
            await this._copyFile(location, fileName, location + '/NoCloud', fileName);
            try {
                await this._writeToCloud(location  + '/NoCloud', fileName);
            } catch (e) {
                logError(e)
            }
        } else if (ENV.os == 'windows') {
            const blob = await this._readFile(location, fileName, 'application/json');
            window.documentsLibrary.saveFileToDocuments(fileName, blob, () => {console.log('saved to documents saved')}, (error: any) =>{console.log(error)} );
        }
    }

    exportDatabase= async (option: 'both'|'data'|'images') => {
        // window.location.href.search).includes('localId') == false
        // if (String(this.props.location.search).includes('localId') == false) {
        this.backupStatus = 'in_progress';
        this.notifyListeners()
        let ids: number[]=[];
        let downloadFile = false;
        let location  =  '';
        const fileSuffix = '.json';
        if ((ENV.platform == 'cordova' && ENV.os == 'browser') ||  ENV.platform === 'web') {
            downloadFile = true;
        } else {
            downloadFile = false;
            location  =  cordova.file.dataDirectory;
            if (ENV.os =='android') {
                location = cordova.file.externalApplicationStorageDirectory;
                // adndroid doesn't support accept .sql  fileSuffix = '.sql'
            } else if  (ENV.os =='ios') {
                location = cordova.file.documentsDirectory;
                // ios doesn't support accept .sql  fileSuffix = '.sql'
            } else if  (ENV.os =='windows') {
                location = cordova.file.dataDirectory;
            }
        }
        const date = formatDate('display_datetime', new Date(),'yyyy-MM-dd\'T\'HHmmss');
        const dataFileName = 'draft-export-' + date + fileSuffix;
        const imageFileName = 'image-export-' + date + fileSuffix;
        try {
            if (option ==='both' || option==='data') {
                const filter = (table: string, value: any, _key?: any) => {
                    if (table =='checklists') {
                        ids = ids.concat(exportLocalPhotos(value.checklist));
                        return true;

                    } else if (table == 'draft_tickets') {
                        ids = ids.concat(exportTicketLocalPhotos(value.ticket));
                        if(value.ticket.checklist){
                            ids = ids.concat(exportLocalPhotos(value.ticket.checklist));
                        }
                        return true;

                    } else if (table ==  'draft_incidents') {
                        ids = ids.concat(exportAttachmentLocalPhotos(value.attachments));
                        return true;
                    } else {
                        return false;
                    }
                };

                const blob = await this.db.export({prettyJson: false, filter});




                if (downloadFile) {
                        downloadjs(blob, dataFileName, 'application/json');
                } else {
                    await this.backupFile(location,dataFileName , blob);
                }
            }
            if (option ==='both' || option==='images') {
                console.log('images to save');
                console.log(ids);
                const imageFilter = (table: string, value: any, _key?: any) => {
                    if (table =='images' ) {
                        return ids.findIndex(element => element === value.imageId) > -1;
                    }
                    return false;
                };
                let photoIds: number[]=[];
                if (ENV.os =='ios') {
                    photoIds = await  this.db.getDraftImagesIds();
                    console.log('images to save');
                    console.log(photoIds);
                }

                const imageBlob = await this.imageStorage.export({prettyJson: false, noTransaction:true, filter: imageFilter}, photoIds);
                if (downloadFile) {
                    downloadjs(imageBlob, imageFileName, 'application/json');
                } else {
                    await this.backupFile(location,imageFileName , imageBlob);
                    this.removeOldBackups(location);
                    if  (ENV.os =='ios') {
                        this.removeOldBackups(location  + '/NoCloud');
                    }
                }
            }
            this.backupStatus = 'enabled';
            this.notifyListeners()
        } catch (e) {
            if (!downloadFile) {
                this.removeOldBackups(location);
                if  (ENV.os =='ios') {
                    this.removeOldBackups(location  + '/NoCloud');
                }
            }
            this.backupStatus = 'enabled';
            this.notifyListeners()
            let msg;
            if (typeof e === 'string') {
                msg = e
            } else{
                msg = e.message
            }

            throw new Error(msg);
        }
    }

    addListener(listener: () => void) {
        this.listeners.push(listener);
    }
    removeListener(listener: () => void) {
        const idx = this.listeners.indexOf(listener);
        if (idx > -1) {
            this.listeners.splice(idx, 1);
        }
    }
    notifyListeners() {
        this.listeners.forEach((listener) => listener());
    }
}
