import * as React from "react";
import { withRouter, RouteComponentProps } from "react-router";
import { isUseSqliteDatabase } from "../../environment";

// eslint-disable-next-line @typescript-eslint/ban-types
export const AppUrlListener = withRouter(
    class extends React.Component<RouteComponentProps<any>, any> {
        constructor(props: any) {
            super(props);
        }

        componentDidMount() {
            if (isUseSqliteDatabase()) {
                universalLinks.subscribe("route", this.handleUniversalLinks);
            }
        }

        handleUniversalLinks(eventData: any) {
            console.log("Universal Links received:", eventData);
            //alert("Did launch application from the link: " + eventData.url);
            const slug = eventData.url.split("#").pop();
            console.log("Slug: " + slug);
            if (slug) {
                this.props.history.push(slug);
            }
        }

        render() {
            return null;
        }
    }
);
