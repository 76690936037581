import * as React from 'react';
import * as Loadable from 'react-loadable';

export default class LoadingComponent extends React.Component<Loadable.LoadingComponentProps>
{
    render()
    {
        if (this.props.error || this.props.timedOut) {
            return (<div>
                    Unexpected error while loading your content! Please try again later.<br/>
                    {this.props.error}
                    </div>);
        } else if (this.props.pastDelay) {
            return (<div>Loading</div>);
        } else {
            return null;
        }
    }
}
