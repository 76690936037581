import * as React from 'react';
import * as ReactDOM from 'react-dom';
import { App } from './components/App';
import { CONFIG } from './config';
import { waitForEnv, ENV } from './environment';
import { initStoragePersistence } from './utils/storage';

function boot() {
    ReactDOM.render(<App />, document.getElementById('app'));
    document.body.style.backgroundColor = '#eee';
    document.body.style.backgroundImage = 'none';
}

waitForEnv()
    .then( async () => {
        console.log('ENV', ENV);
        // This should probably be moved somewhere nearer the Auth code
        if (ENV.platform == 'cordova') {
            // Initialise Sentry
            const Sentry = cordova.require('sentry-cordova.Sentry');
            Sentry.init({ dsn: CONFIG.sentryEndpoint,
                          release: CONFIG.sentryReleaseVersion,
                          debug: CONFIG.sentryEnvironment == 'Test',
                          environment: CONFIG.sentryEnvironment});

            CONFIG.authClientId = 'franchise-manager-app';
        }
        else {
            CONFIG.authClientId = 'franchise-manager-web';
        }
        await initStoragePersistence()
        boot();
    });
