import * as React from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import Button from '@material-ui/core/Button';
import { i18n } from '../../i18n';

export interface IPhotoDialogProps {
    title?: string;
    content: React.ReactElement<any>;
    isOpen: boolean;
    onClose: () => void;
}

export const BasicDialog = (props: IPhotoDialogProps) => {
    const t = i18n.t;
    return (
        <Dialog
            open={props.isOpen}
            onClose={props.onClose}
        >
            {props.title && <DialogTitle>{props.title}</DialogTitle>}
            <DialogContent>
                {props.content}
            </DialogContent>
            <DialogActions>
                <Button color="primary" onClick={props.onClose}>
                    {t('close')}
                </Button>
            </DialogActions>
        </Dialog>
    );
};
