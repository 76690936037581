import * as React from "react";
import CssBaseline from "@material-ui/core/CssBaseline";
import { MuiThemeProvider, createTheme } from "@material-ui/core/styles";
import { Navigation } from "./navigation/Navigation";
import { AppContextProvider } from "./AppContext";
import { HashRouter } from "react-router-dom";
import { Routes } from "./Routes";
import { PromptsProvider } from "./common/PromptProvider";
import { ErrorBoundary } from "./ErrorRoundary";
import DateFnsUtils from "@date-io/date-fns";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import { AppUrlListener } from "./navigation/AppUrlListener";
import { isUseSqliteDatabase } from "../environment";

const theme = createTheme({
    palette: {
        primary: {
            main: "#223d79",
            light: "#5567a8",
            dark: "#00184c",
            contrastText: "#fff",
        },
        secondary: {
            main: "#f50057",
            light: "#ff5983",
            dark: "#bb002f",
            contrastText: "#fff",
        },
    },
    // fix up margin for keyboardDatePicker picker
    overrides: {
        MuiFormControl: {
            root: {
                marginTop: "16px",
            },
        },
    },
});

export function App() {
    const appUrlListener = isUseSqliteDatabase();

    return (
        <MuiThemeProvider theme={theme}>
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <CssBaseline />
                <PromptsProvider>
                    <AppContextProvider>
                        <HashRouter>
                            {appUrlListener && (
                                <AppUrlListener></AppUrlListener>
                            )}
                            <Navigation>
                                <ErrorBoundary>
                                    <Routes />
                                </ErrorBoundary>
                            </Navigation>
                        </HashRouter>
                    </AppContextProvider>
                </PromptsProvider>
            </MuiPickersUtilsProvider>
        </MuiThemeProvider>
    );
}
