
import { IAppDataDB } from './database';
import { IApi } from './api';
import { DataWithCache, IDataWithCache } from './cache';
import { User } from '../models/User';
import { ITicketTypes, ITicketCategories } from '../models/Tickets';
import { IChecklist, IFranchisee, ISite } from 'oneplace-components';
import { logError } from '../logging';

export interface ITicketData {
    getTypes(franchiseId: number): IDataWithCache<ITicketTypes>;
    getCategories(franchiseId: number): IDataWithCache<ITicketCategories>;
    getTicketFranchisee(franchiseId: number, ticketId: number,  franchiseeId: number): IDataWithCache<IFranchisee>;
    getTicketSite(franchiseId: number, ticketId: number,  siteId: number): IDataWithCache<ISite>;
}

export class TicketData implements ITicketData {

    constructor(
        public _db: IAppDataDB,
        public _api: IApi,
        public _user: User
    ) {}

    getTypes(franchiseId: number): IDataWithCache<ITicketTypes> {
        return new DataWithCache<ITicketTypes>({
            strategy: 'db_first',
            cache: this._db,
            entityName: 'ticket_types',
            entityId: '1',
            updateCacheOlderThan: 300000,  // 5 minutes
            getApiDataFn: () => this._api.getTicketTypes(franchiseId)
        });
    }

    getCategories(franchiseId: number): IDataWithCache<ITicketCategories> {
        return new DataWithCache<ITicketCategories>({
            strategy: 'db_first',
            cache: this._db,
            entityName: 'ticket_categories',
            entityId: '1',
            updateCacheOlderThan: 300000,  // 5 minutes
            getApiDataFn: async () => {
                const categories = await this._api.getTicketCategories(franchiseId, true)
                //check if checklists exist in local DB
                for(const category of categories.categories){
                    if(category.templateChecklistId){//template version ID
                        let template = await this._db.getById<IChecklist>('checklist_templates', String(category.templateChecklistId))
                        if(!template){
                            try {
                                template = await this._api.getChecklistTemplate(franchiseId, -1 , category.templateChecklistId, 'ticket')
                                if(template){
                                    await this._db.setById('checklist_templates', String(category.templateChecklistId), template)
                                }
                            } catch (e) {
                                logError(e, 'Error getting getChecklistTemplate');
                            }
                        }
                    }
                }
                return categories
            }
        });
    }
    getTicketFranchisee(franchiseId: number, ticketId: number,  franchiseeId: number): IDataWithCache<IFranchisee> {
        return new DataWithCache<IFranchisee>({
            strategy: 'api_first',
            cache: this._db,
            entityName: 'franchisee',
            entityId: franchiseeId.toString(),
            updateCacheOlderThan: 300000,  // 5 minutes
            getApiDataFn: () => this._api.getTicketFranchisee(franchiseId, ticketId)
        });
    }
    getTicketSite(franchiseId: number, ticketId: number,  siteId: number): IDataWithCache<ISite> {
        return new DataWithCache<IFranchisee>({
            strategy: 'api_first',
            cache: this._db,
            entityName: 'site',
            entityId: siteId.toString(),
            updateCacheOlderThan: 300000,  // 5 minutes
            getApiDataFn: () => this._api.getTicketSite(franchiseId, ticketId)
        });
    }
}
