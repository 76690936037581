import * as React from 'react';
import {Button, Accordion, AccordionSummary, AccordionDetails, Box} from '@material-ui/core';
import { withAppContext, IAppContextProp } from '../AppContext';
import { withNavigationContext, INavigationContextProp } from '../navigation/Navigation';
import { i18n } from '../../i18n';
import SearchIcon from '@material-ui/icons/Search';
import FilterIcon from '@material-ui/icons/FilterList';
import { useState } from 'react';
import ChecklistDashboardFilter from './ChecklistDashboardFilter';
import { hasFilterApplied, IChecklistDashboardFilter } from '../../models/Dashboard';

interface IChecklistDashboardHeaderPanelProps extends
IAppContextProp,
INavigationContextProp{
    filter: IChecklistDashboardFilter | null
    onClickSearchChecklist: () => void
    onApplyChecklistFilter: (appliedFilter: IChecklistDashboardFilter) => void
}

const ChecklistDashboardHeaderPanel = 
    (props: IChecklistDashboardHeaderPanelProps) => {

    const t = i18n.t;
    const [expanded, setExpanded] = useState<boolean>(false)
    const filterActive = hasFilterApplied(props.filter)

    return (
        <>
            <Accordion
                defaultExpanded={true}
                expanded={expanded}
                onChange={() => setExpanded(!expanded)}
            >
                <Box style={{display: 'flex', justifyContent: 'space-between'}}>
                    <AccordionSummary >
                        <Button color={filterActive ? 'secondary' : 'primary'}>
                            <FilterIcon style={{ marginRight: 8 }} />
                            {filterActive ? t('filter_checklists_applied') : t('filter_checklists')}
                        </Button>
                    </AccordionSummary>
                    <Button color="primary" onClick={props.onClickSearchChecklist}>
                        <SearchIcon style={{ marginRight: 8 }} />
                        {t('completed_checklists')}
                    </Button>
                </Box>
                <AccordionDetails style={{paddingTop: 0}}>
                    <ChecklistDashboardFilter filter={props.filter} onApply={props.onApplyChecklistFilter} />
                </AccordionDetails>
            </Accordion>
        </>
    )
}

export default withAppContext(
    withNavigationContext(ChecklistDashboardHeaderPanel)
)