import * as React from 'react';

export interface IOnePlaceIconProps {
    icon: string;
}

export const OnePlaceIcon = (props: IOnePlaceIconProps) => (
    <div style={{
        fontFamily: 'oneplaceIcons', fontSize: 20, marginRight: 8
    }}>
        <span className={'icon-1place-' + props.icon} />
    </div>
);
