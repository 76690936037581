import { ISummaryData } from '../../models/Dashboard';

export function getDefaultSummary(): ISummaryData {
    return {
        summary: {
            ticketSummary: {
                toAction: 0,
                overdue: 0,
                highPriority: 0,
                dueToday: 0,
                dueThisWeek: 0,
                overDue: 0,
                myTickets: 0
            }
        }
    };
}
