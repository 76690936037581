import * as React from 'react';
import { WithStyles, withStyles, Theme, createStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';
import Typography from '@material-ui/core/Typography';
import { ALink } from './common/ALink';

const styles = (theme: Theme) => createStyles({
    root: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-around',
        alignItems: 'center',
        height: '100vh',
        color: '#fff',
        backgroundColor: theme.palette.primary.dark,
        // backgroundImage: 'url(\'img/login_bg.jpg\')',
        // backgroundSize: 'cover'
    },
    loginLogo: {
        width: '80%',
        maxWidth: 500
    },
    progressWrapper: {
        textAlign: 'center'
    },
    progressSpinner: {
        marginBottom: 16
    },
    termsWrapper: {
        width: '80%',
        textAlign: 'center'
    },
    termsLink: {
        color: 'white',
        whiteSpace: 'nowrap'
    }
});

export interface IAppLoaderProps extends WithStyles<typeof styles> {
    loading: boolean;
    status?: string;
    children?: any;
}

export const AppLoader = withStyles(styles)((props: IAppLoaderProps) => {
    return (
        <div className={props.classes.root}>
            <img className={props.classes.loginLogo} src="img/login_logo.png" />
            <div className={props.classes.progressWrapper}>
                {props.loading &&
                    <CircularProgress color="inherit" size={24}
                        className={props.classes.progressSpinner} />}
                {props.children &&
                    <div>{props.children}</div>
                }
                {!props.children &&
                    <Typography variant="subtitle1" color="inherit">
                        {props.status || 'Loading...'}
                    </Typography>}
                {}
            </div>
            <div className={props.classes.termsWrapper}>
                <Typography variant="subtitle1" color="inherit">
                    By signing in you hereby agree to our  <ALink
                    url="https://www.1placeonline.com/terms-of-use/"
                    className={props.classes.termsLink}
                    >Terms of Use</ALink>  and our <ALink
                    url="https://www.1placeonline.com/privacy-policy/" className={props.classes.termsLink}>Privacy Policy</ALink>.
                </Typography>
            </div>
        </div>
    );
});
