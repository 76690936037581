
import * as React from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import Button from '@material-ui/core/Button';
import { i18n } from '../../i18n';
import { Theme, createStyles, withStyles, WithStyles } from '@material-ui/core/styles';
import DialogContentText from '@material-ui/core/DialogContentText';
import Typography from '@material-ui/core/Typography';
import CircularProgress from '@material-ui/core/CircularProgress';

const styles = (_theme: Theme) => createStyles({
    root: {}
});

export type SubmitDialogStage = 'submitting' | 'submit_successful' | 'submit_error';

export interface ISubmitStatusDialogProps extends
        WithStyles<typeof styles> {
    isOpen: boolean;
    title: string;
    stage: SubmitDialogStage;
    message?: string;
    subMessage?: string | React.ReactElement<any>;
    showContinueButtons?: boolean;
    onClose: () => void;
    onContinueEditing?: () => void;
    onGoToDashboard?: () => void;
    dismissDisabled?: boolean;
    onSubmit?: () => void;
    customButtonAction?: () => void;
    customButtonActionLabel?: string;
}

export const SubmitStatusDialog = withStyles(styles)((props: ISubmitStatusDialogProps) => {
    const t = i18n.t;
    const dismissDisabled = props.stage == 'submitting' || !!props.dismissDisabled;

    return (
        <Dialog
            open={props.isOpen}
            onClose={props.onClose}
            disableBackdropClick={dismissDisabled}
            disableEscapeKeyDown={dismissDisabled}
        >
            <DialogTitle>{props.title}</DialogTitle>
            <DialogContent>
                {props.stage == 'submitting' && <div
                        style={{ display: 'flex', marginTop: 10 }}>
                    <CircularProgress size={32} />
                    <Typography
                        style={{ fontSize: 16, marginTop: 6, marginLeft: 16 }}
                    >
                        {t('submitting')}
                    </Typography>
                </div>}
                {props.stage != 'submitting' && <div>
                    <DialogContentText>
                        {props.message}
                    </DialogContentText>
                    {props.subMessage &&
                        <Typography style={{ marginTop: 16 }} component="div">
                            {props.subMessage}
                        </Typography>
                    }
                </div>}
            </DialogContent>
            <DialogActions>
                {props.stage == 'submit_error' && (
                    <>
                        <Button onClick={props.onSubmit} color="primary">
                            {t('retry_submission')}
                        </Button>
                        <Button onClick={props.onClose} color="primary">
                            {t('continue_editing')}
                        </Button>
                        {props.customButtonAction ? (
                            <Button onClick={props.customButtonAction} color="primary">
                                {props.customButtonActionLabel || 'Custom action button label'}
                            </Button>
                        ) : (
                            <Button onClick={props.onGoToDashboard} color="primary">
                                {t('go_to_dashboard')}
                            </Button>
                        )}
                    </>
                )}
                {(props.stage == 'submit_successful' && !props.showContinueButtons) &&
                    <Button onClick={props.onClose} color="primary">
                        {t('ok')}
                    </Button>
                }
                {props.stage == 'submit_successful' && props.showContinueButtons && <>
                    <Button onClick={props.onContinueEditing} color="primary">
                        {t('continue_editing')}
                    </Button>
                    <Button onClick={props.onGoToDashboard} color="primary">
                        {t('exit')}
                    </Button>
                </>}
            </DialogActions>
        </Dialog>
    );
});
