import { IDashboardChecklist } from '../../../models/Dashboard';
import { IStoredChecklistMeta } from 'oneplace-components';
import { formatDate } from '../../../utils/dates';

// <template_id
export interface IDraftIndex {
    [key: string]: number;
}

export function addLocalIdsToCLList(clList: IDashboardChecklist[], drafts: IStoredChecklistMeta[]) {
    // Augment dashboard data with locally stored checklists
    clList.forEach((checklist) => {
        const match = drafts.find((draft) => {
            if (checklist.status == 'not_done') {
                // templateId from API == templateId in local DB
                const date = formatDate('iso_dateonly', checklist.dueDate);
                return Boolean(
                    draft.templateId == checklist.templateId
                    && draft.date == date
                    && draft.franchiseeId == checklist.franchisee!.id
                    && (
                        (!checklist.site && draft.siteId == 0)
                        || (checklist.site && draft.siteId == checklist.site.id)
                    )
                );
            }
            else {
                // templateId from API == checklistId is local DB
                return (draft.checklistId > 0 && draft.checklistId == checklist.templateId);
            }
        });
        if (match) {
            checklist.localId = match.localId;
        }
    });
}
