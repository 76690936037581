
import { IImageStorage} from '.';
import { STORED_IMAGE_PREFIX, ImageStorageType, TEMP_IMAGE_PREFIX, getImageStorageType } from 'oneplace-components'
import {ExportOptions } from 'dexie-export-import'
import { isDefined, unescapeBlob } from '../../utils';
import SqliteDB, { ISqliteDBConfig } from '../database/sqlite';
import { DatabaseError } from '../../errors/DatabaseError';
import { logError } from '../../logging';
import Dexie from 'dexie';
import { CordovaFSImageStorage } from './cordovafs';
import { binaryStringToBlob, blobToBase64String, base64StringToBlob } from 'blob-util'



interface IStoredImage {
    imageId?: number;
    timestamp: number;
    filename: string;
    data: Blob;
    mimetype: string;
}



export class CordovaSqliteFSImageStorage implements IImageStorage {
    db: SqliteDB;
    objectUrls: {
        [objectUrl: string]: Blob;
    } = {};
    storedImageObjectUrls: {
        [imageId: number]: string;
    } = {};

    config: ISqliteDBConfig = {
        version: 2, // current schema/database version
        onVersionUpdate: (prevVersion: number) => { // migrate versions
            return new Promise((resolve, reject) => {
                switch(prevVersion) {
                    case 0:
                        // initialise
                        this.db.sqlBatch(
                            [
                                'CREATE TABLE IF NOT EXISTS "_version" (id TEXT PRIMARY KEY UNIQUE, version INTEGER)',
                                [ 'INSERT INTO _version (id, version) VALUES (?1,?2)', ['version', 0] ],
                                'CREATE TABLE IF NOT EXISTS "images" (id INTEGER PRIMARY KEY, data TEXT, body BLOB)'
                            ],
                            () =>{
                                console.log('version 1 migration completed.');
                                resolve(1);
                            },
                            (error: any) =>{
                                console.log('version 1 migration error: ' + error.message);
                                reject()
                        });
                        break;
                    case 1:
                        // convert body field to BASE64
                        this.db.executeSql('select id , body FROM images', [])
                        .then((result: any) => {
                            const convertImage = (id: any, image: Blob) => {
                                return blobToBase64String(image)
                                .then( (base64String) => {
                                    return this.db.executeSql('update images set body = BLOBFROMBASE64(?) where id = ? ', [base64String, id])
                                }).then((result_1) => {
                                    console.log('migrated  : ' + id);
                                    console.log('add rowsAffected: ' + result_1.rowsAffected);
                                    return
                                }).catch((err) => {
                                    console.error(err)
                                    throw err
                                });
                            }
                            const promises: any[] = [];
                            for (let i = 0; i < result.rows.length; i++){
                                promises.push(convertImage(result.rows.item(i).id ,new Blob([binaryStringToBlob(unescapeBlob(result.rows.item(i).body), 'images/jpg')], {type: 'images/jpg'})))
                            }
                            return Promise.all(promises);
                        }).then(() => {
                            console.log('version 2 migration completed.');
                            resolve(2);
                        }).catch((err: Error) => {
                            console.log('version 2 migration error: ' + err.message);
                            reject()
                        })
                    break;
                }
            });
        }
    }
    constructor() {
        this.db = new SqliteDB('OnePlace_images', this.config);
        this.db.upgradeDatabase()
        .then((successful: boolean) => {
            if(successful) {
                console.log('OnePlace_images database create/upgraded')
                return this.db.executeSql('SELECT version FROM _version WHERE id = ?', ['dexie'])

            } else {
                throw new DatabaseError('Database exists')
            }
        }).then((dbVersionResult: any) => {
            if (dbVersionResult.rows.length === 0) {
                    return true
            } else{
                console.log('OnePlace_images no migration necessary')
                return false
            }
        }).then((checkDb: boolean) => {
            if (checkDb) {
                return  new Promise<void>((resolve, _reject) => {
                    Dexie.getDatabaseNames()
                    .then((databases: any) => {
                        // console.log('DEXIE');
                        // console.log(databases)
                        if(databases.includes('OnePlace_images')) {
                            const oldDb = new CordovaFSImageStorage();
                            oldDb.db.table('images').toCollection().eachPrimaryKey((primaryKey: any) => {
                                return new Promise<void>((checklistResolve, _checklistReject) => {
                                    // console.log('DEXIE ' + primaryKey);
                                    oldDb.db.images.get(Number(primaryKey))
                                    .then((imageStore: any) => {
                                        // console.log('DEXIE imageStore' + imageStore.filename);
                                        return this._putImages(primaryKey, imageStore)
                                    })
                                    .then(()=>{
                                        checklistResolve();
                                    });
                                })
                            }).then(() => {
                                this.db.executeSql('INSERT INTO _version (id, version) VALUES (?1,?2)', ['dexie',1])
                                .then((_result_1) => {
                                    console.log(this.db.dbName + ' migration completed');
                                    resolve()
                                }).catch((err) => {
                                    logError(err);
                                    // resolve so user can conitinue working
                                    resolve()
                                });

                            })
                        } else{
                            this.db.executeSql('INSERT INTO _version (id, version) VALUES (?1,?2)', ['dexie',1])
                            .then((_result_1) => {
                                console.log(this.db.dbName + ' no migration neccessary');
                                resolve();
                            }).catch((err) => {
                                logError(err);
                                // resolve so user can conitinue working
                                resolve()
                            });
                        }
                    })
                })
            }
        }).catch((e) =>{
            logError(e)
            throw new DatabaseError('Database error')
        }
        );
    }


    _writeFile(fileName: string, data: Blob) {
        return new Promise<void>((resolve, reject) => {
            window.resolveLocalFileSystemURL(cordova.file.dataDirectory, (fs: any) => {
                // console.log('file system open: ');
                fs.getFile(fileName, { create: true }, (fileEntry: any) => {
                    // console.log('fileEntry is file?', fileEntry.isFile.toString());
                    // console.log('path:', fileEntry.fullPath);
                    fileEntry.createWriter((fileWriter: any) => {
                        fileWriter.onwriteend = () => {
                            // console.log('Successful file write!');
                            resolve();
                        };
                        fileWriter.onerror = (e: any) => {
                            console.error('Failed file write: ', e);
                            reject(e);
                        };
                        fileWriter.write(data);
                    });
                }, (err: any) => {
                    reject(err);
                });
            }, (err: any) => {
                reject(err);
            });
        });
    }

    _readFile(fileName: string) {
        return new Promise<Blob>((resolve, reject) => {
            window.resolveLocalFileSystemURL(cordova.file.dataDirectory, (fs: any) => {
                // console.log('file system open: ');
                fs.getFile(fileName, {}, (fileEntry: any) => {
                    // console.log('fileEntry is file?', fileEntry.isFile.toString());
                    // console.log('path:', fileEntry.fullPath);
                    fileEntry.file((file: any) => {
                        const reader = new FileReader();
                        reader.onloadend = () => {
                            const data = new Blob([new Uint8Array(reader.result as any)], { type: 'image/jpg' });
                            resolve(data);
                        };
                        reader.onerror = (e: any) => {
                            console.error('Failed file write: ', e);
                            reject(e);
                        };
                        reader.readAsArrayBuffer(file);
                    });
                }, (err: any) => {
                    reject(err);
                });
            }, (err: any) => {
                reject(err);
            });
        });
    }

    _removeFile(fileName: string) {
        return new Promise<Blob>((resolve, reject) => {
            window.resolveLocalFileSystemURL(cordova.file.dataDirectory, (fs: any) => {
                // console.log('file system open: ');
                fs.getFile(fileName, {}, (fileEntry: any) => {
                    console.log('fileEntry is file?', fileEntry.isFile.toString());
                    console.log('path:', fileEntry.fullPath);
                    fileEntry.remove(resolve, reject, reject);
                }, reject);
            });
        });
    }

    async storeImage(imageData: Blob, storageType: ImageStorageType): Promise<string> {
        // console.log('storing image: ' + storageType);
        // console.log('mime type: ' + imageData.type);
        if (storageType == 'temporary') {
            // just create objectURL and keep a reference to the blob in memory
            const objectURL = window.URL.createObjectURL(imageData);
            this.objectUrls[objectURL] = imageData;
            return TEMP_IMAGE_PREFIX + objectURL;
        }
        else {
            // Store image on filesystem and keep a reference in sqlite db
            const storedImage: IStoredImage = {
                timestamp: Date.now(),
                filename: '',
                data: imageData,
                mimetype: imageData.type
            };
            const imageId = await this._addImages(storedImage);
            const fileName = `image${imageId}.jpg`;
            await this._writeFile(fileName, imageData);
            storedImage.filename = fileName;
            try {
                const putid = await this.db.update('images', Number(imageId), {filename: fileName});
                console.log('put update iamge: ' + putid);
            } catch (e) {
                // windows fails to put
                console.error(e);
            }
            return STORED_IMAGE_PREFIX + imageId;
        }
    }
    //
    async _addImages( storedImage: IStoredImage): Promise<string|number> {
        return blobToBase64String(storedImage.data)
        .then((base64String: string) => {
            return this.db.executeSql('INSERT INTO images (data, body) VALUES (?, BLOBFROMBASE64(?))', [JSON.stringify(storedImage), base64String])
        }).then((result_1) => {
            console.log('add : ' + result_1.insertId);
            console.log('add rowsAffected: ' + result_1.rowsAffected);
            return result_1.insertId
        }).catch((err) => {
            logError(err)
            throw Error(err)
        });
    }

    // used for migration when we know the primary key
    async _putImages( id: number, storedImage: IStoredImage): Promise<string|number> {
        return blobToBase64String(storedImage.data)
        .then((base64String: string) => {
            return this.db.executeSql('INSERT INTO images (id, data, body) VALUES (?, ?, BLOBFROMBASE64(?))', [id, JSON.stringify(storedImage), base64String])
        }).then((result_1) => {
            console.log('add : ' + result_1.insertId);
            console.log('add rowsAffected: ' + result_1.rowsAffected);
            return result_1.insertId
        }).catch((err) => {
            logError(err)
            throw Error(err)
        });
    }

    async _getImage(key: string) {
        // for cordova-sqlite-storage use the following
        const json  = await this.db.executeSql('select data , BASE64(body) as base64Body FROM images where id = ?', [key])
        .then((result: any) => {
            if (result.rows.length === 1) {
                const imageData = result.rows.item(0).data
                if (imageData == null) {
                    return undefined;
                } else {
                    const image = JSON.parse(imageData);
                    image.data =  base64StringToBlob(result.rows.item(0).base64Body);
                    return image
                }
            } else{
                return undefined
            }
        }).catch((err: Error) => {
            console.log(err)
            return undefined
        })
        return json;
    }

    async getImageObjectUrl(localImageUrl: string, reloadImageUrl?: boolean): Promise<string> {
        const [storageType, imageId] = getImageStorageType(localImageUrl);
        if (storageType == 'temporary') {
            return imageId;
        }
        else {
            const reloadImageUrlFromDB = typeof reloadImageUrl == 'undefined'? false: reloadImageUrl;
            if (reloadImageUrlFromDB == false && imageId in this.storedImageObjectUrls) {
                return this.storedImageObjectUrls[Number(imageId)];
            }
            else {
                const imageData = await this.getImageData(localImageUrl);
                const objectURL = window.URL.createObjectURL(imageData);
                this.objectUrls[objectURL] = imageData;
                this.storedImageObjectUrls[Number(imageId)] = objectURL;
                return objectURL;
            }
        }
    }

    async getImageData(localImageUrl: string): Promise<Blob> {
        const [storageType, imageId] = getImageStorageType(localImageUrl);
        if (storageType == 'temporary') {
            return this.objectUrls[imageId];
        }
        else {
            const image = await this._getImage(imageId);
            if (isDefined(image)) {
                try {
                    const data = await this._readFile(image.filename);
                    return data;
                }  catch (e) {
                    // console.log(e)
                    console.log('write from db: ' + localImageUrl  )
                    if (image.data) {
                        this._writeFile(image.filename, image.data)
                        return image.data;
                    } else{
                        throw e
                    }
                }
            } else {
                throw new Error('missing image')
            }
        }
    }

    async makePermenant(tempImageUrl: string): Promise<string> {
        const [storageType, imageId] = getImageStorageType(tempImageUrl);
        if (storageType != 'temporary') {
            throw new Error('makePermenant() called on a non-temporary image');
        }
        const imageData = this.objectUrls[imageId];
        const storedUrl = await this.storeImage(imageData, 'permanent');
        const storedImageId = getImageStorageType(storedUrl)[1];
        this.storedImageObjectUrls[Number(storedImageId)] = imageId;
        return storedUrl;
    }

    async removeImage(localImageUrl: string): Promise<void> {
        console.log('removing image', localImageUrl);
        const [storageType, imageId] = getImageStorageType(localImageUrl);
        if (storageType == 'temporary') {
            window.URL.revokeObjectURL(imageId);
            delete this.objectUrls[imageId];
        }
        else {
            // Revoke ObjectURL
            const objectUrl = this.storedImageObjectUrls[Number(imageId)];
            if (objectUrl) {
                window.URL.revokeObjectURL(objectUrl);
            }
            // Get filename from DB and delete it
            const image = await this.db.getByIndex<IStoredImage>('images', 'id', Number(imageId));
            if (isDefined(image)) {
                await this._removeFile(image!.filename);
            }
            // Delete database record
            await this.db.deleteByIndex('images', 'id', Number(imageId));
        }
    }

    revokeObjectUrls(): void {
        const objectUrls = Object.keys(this.objectUrls);
        console.log(`revoking ${objectUrls.length} object URLs`);
        for (const objectUrl of objectUrls) {
            window.URL.revokeObjectURL(objectUrl);
        }
        this.storedImageObjectUrls = {};
        this.objectUrls = {};
    }

    binaryStringToBlobOrBuffer (binString: string, _type: string) {
        return binaryStringToBlob(binString);
    }

    readAsBinaryString(blob: Blob) {
        return new Promise<string>((resolve, reject) => {
            const reader = new FileReader();
            reader.onloadend = () => {
                const result = reader.result as any || '';
                resolve(result);
            };
            reader.onerror = (e: any) => {
                reject(e);
            };
            reader.readAsBinaryString(blob);
        })
    }

    async export(_option: ExportOptions, ids: number[] ): Promise<Blob> {
        return new Promise((resolve) => {
            cordova.plugins.sqlitePorter.exportDbToSql(this.db.sqlite, {
                successFn(sql: any, _count: any){
                    resolve(new Blob([sql],{type: 'text/plain'}));
                },
                tableClauses:{
                    'images': {
                        column: 'id',
                        value:ids
                    }
                },
                separator: ';\n'
            })
        })
    }

    async importSql(data: any) {
        return new Promise<void>((resolve, reject) => {
            cordova.plugins.sqlitePorter.importSqlToDb(this.db.sqlite, data, {
                successFn(count: any){
                    console.log('Imported '+count+' JSON statements');
                    resolve()
                },
                errorFn(error: Error){
                    logError(error)
                    reject('failed to import')
                },
                progressFn(current: any, total: any){
                    console.log('Imported '+current+'/'+total);
                },
                separator: ';\n'
            });
        })
    }

    async import(exportedData: Blob): Promise<void> {
        const data = await (new Response(exportedData)).text();
        await this.importSql(data)
        this.removeAllFiles();
    }

    removeAllFiles() {
       this.db.executeSql('select id, data , BASE64(body) as base64Body FROM images', [])
        .then((result: any) => {
            const promises: any[] = [];
            for (let i = 0; i < result.rows.length; i++){
                const imageData = result.rows.item(i).data
                if (imageData != null) {
                    const image = JSON.parse(imageData);
                    image.data =  base64StringToBlob(result.rows.item(0).base64Body);
                    if(image.data!)
                        promises.push(this._removeFile(image.filename))
                }
            }
            return Promise.all(promises);
        }).catch((err: Error) => {
            console.log('error ' + err.message);
            throw err
        })
    }

    removeFiles(assetList: string[]) {
        const promises: any[] = [];
        assetList.forEach(cacheKey => {
            promises.push(this.removeImage(cacheKey));
        })
        return Promise.all(promises);
    }
}
