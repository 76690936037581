import { IStoredChecklist, IStoredChecklistMeta, ISites, ISite, IPerson, IPersonType, i18n } from 'oneplace-components';
import { ISummaryData } from '../../../models/Dashboard';
import { ExportOptions} from 'dexie-export-import';
import { getDefaultSettings } from '../../../settings/getDefaultSettings';
import { ISettings } from '../../../settings/settings';
import { ISyncMetadata, ISyncSettings } from '../../sync/SyncManager';
import { IStoredTicket, IStoredTicketMeta } from '../../../models/Tickets';
import { IStoredIncident, IStoredIncidentMeta } from '../../../models/Incident';
import { getDefaultSummary } from '../../../components/dashboard/getDefaultSummary';
import { IStockedPreviousPage, PreviousPageID } from '../../PreviousPage';
import { DatabaseError}  from '../../../errors/DatabaseError';
import { IAppDataDB, AppDataDB } from '..';
import SqliteDB, { ISqliteDBConfig } from '../sqlite';
import { logError } from '../../../logging';
import { exportLocalPhotos, exportTicketLocalPhotos, exportAttachmentLocalPhotos } from '../../../components/checklists/utils/photos';
import Dexie from 'dexie';

export class AppDataSqliteDB implements IAppDataDB {
    db!: SqliteDB;
    config: ISqliteDBConfig = {
        version: 3, // current schema/database version. update this when upgrading db
        onVersionUpdate: (prevVersion: number) => { // migrate versions
            return new Promise((resolve, reject) => {
                switch(prevVersion) {
                    case 0:
                        // initialise
                        this.db.sqlBatch(
                            [
                                'CREATE TABLE IF NOT EXISTS "_version" (id TEXT PRIMARY KEY UNIQUE, version INTEGER)',
                                [ 'INSERT INTO _version (id, version) VALUES (?1,?2)', ['version', 0] ],
                                'CREATE TABLE IF NOT EXISTS "owner" (id TEXT PRIMARY KEY UNIQUE, data TEXT)',
                                'CREATE TABLE IF NOT EXISTS "messages" (id TEXT PRIMARY KEY UNIQUE, data TEXT)',
                                'CREATE TABLE IF NOT EXISTS "franchise" (id TEXT PRIMARY KEY UNIQUE, data TEXT)',
                                'CREATE TABLE IF NOT EXISTS "franchisees" (id TEXT PRIMARY KEY UNIQUE, data TEXT)',
                                'CREATE TABLE IF NOT EXISTS "sites" (id TEXT PRIMARY KEY UNIQUE, data TEXT)',
                                'CREATE TABLE IF NOT EXISTS "tags" (id TEXT PRIMARY KEY UNIQUE, data TEXT)',
                                'CREATE TABLE IF NOT EXISTS "checklist_template_list" (id TEXT PRIMARY KEY UNIQUE, data TEXT)',
                                'CREATE TABLE IF NOT EXISTS "checklist_templates" (id TEXT PRIMARY KEY UNIQUE, data TEXT)',
                                'CREATE TABLE IF NOT EXISTS "incident_template" (id TEXT PRIMARY KEY UNIQUE, data TEXT)',
                                'CREATE TABLE IF NOT EXISTS "overview_dashboard" (id TEXT PRIMARY KEY UNIQUE, data TEXT)',
                                'CREATE TABLE IF NOT EXISTS "overview_schedule" (id TEXT PRIMARY KEY UNIQUE, data TEXT)',
                                'CREATE TABLE IF NOT EXISTS "franchisee_dashboards" (id TEXT PRIMARY KEY UNIQUE, data TEXT)',
                                'CREATE TABLE IF NOT EXISTS "franchisee_schedules" (id TEXT PRIMARY KEY UNIQUE, data TEXT)',
                                'CREATE TABLE IF NOT EXISTS "site_dashboards" (id TEXT PRIMARY KEY UNIQUE, data TEXT)',
                                'CREATE TABLE IF NOT EXISTS "site_schedules" (id TEXT PRIMARY KEY UNIQUE, data TEXT)',
                                'CREATE TABLE IF NOT EXISTS "ticket_types" (id TEXT PRIMARY KEY UNIQUE, data TEXT)',
                                'CREATE TABLE IF NOT EXISTS "ticket_categories" (id TEXT PRIMARY KEY UNIQUE, data TEXT)',
                                'CREATE TABLE IF NOT EXISTS "tickets" (id TEXT PRIMARY KEY UNIQUE, data TEXT)',
                                'CREATE TABLE IF NOT EXISTS "settings" (id TEXT PRIMARY KEY UNIQUE, data TEXT)',
                                'CREATE TABLE IF NOT EXISTS "asset_cache" (id TEXT PRIMARY KEY UNIQUE, data TEXT)',
                                'CREATE TABLE IF NOT EXISTS "summary" (id TEXT PRIMARY KEY UNIQUE, data TEXT)',
                                'CREATE TABLE IF NOT EXISTS "checklist_template_list_parent" (id TEXT PRIMARY KEY UNIQUE, data TEXT)',
                                'CREATE TABLE IF NOT EXISTS "incident_types" (id TEXT PRIMARY KEY UNIQUE, data TEXT)',
                                'CREATE TABLE IF NOT EXISTS "checklists" (localId INTEGER PRIMARY KEY, versionId INTEGER, checklistId INTEGER, franchiseeId INTEGER, siteId INTEGER, data TEXT)',
                                'CREATE INDEX checklistsVersionIndex on "checklists" (versionId)',
                                'CREATE INDEX checklistChecklistIndex on "checklists" (checklistId)',
                                'CREATE INDEX checklistsFranchiseeIndex on "checklists" (franchiseeId)',
                                'CREATE INDEX checklistsSiteIndex on "checklists" (siteId)',
                                'CREATE TABLE IF NOT EXISTS "draft_tickets" (localId INTEGER PRIMARY KEY, ticketId INTEGER, franchiseeId INTEGER, siteId INTEGER, data TEXT)',
                                'CREATE INDEX draft_ticketsTicketIndex on "draft_tickets" (ticketId)',
                                'CREATE INDEX draft_ticketsFranchiseeIndex on "draft_tickets" (franchiseeId)',
                                'CREATE INDEX draft_ticketsSiteIndex on "draft_tickets" (siteId)',
                                'CREATE TABLE IF NOT EXISTS "draft_incidents" (localId INTEGER PRIMARY KEY, incidentId INTEGER, siteId INTEGER, data TEXT)',
                                'CREATE INDEX draft_incidentsIncidentIndex on "draft_incidents" (incidentId)',
                                'CREATE INDEX draft_incidentsSiteIndex on "draft_incidents" (siteId)',
                                'CREATE TABLE IF NOT EXISTS "people" (id INTEGER PRIMARY KEY UNIQUE, data TEXT)',
                                'CREATE TABLE IF NOT EXISTS "person_types" (id INTEGER PRIMARY KEY UNIQUE, data TEXT)',
                                'CREATE TABLE IF NOT EXISTS "person_types_checklist_template" (id INTEGER , personTypeId INTEGER, FOREIGN KEY(personTypeId) REFERENCES person_types(id))',
                                'CREATE TABLE IF NOT EXISTS "person_types_incident_type" (id INTEGER, personTypeId INTEGER, FOREIGN KEY(personTypeId) REFERENCES person_types(id))',
                                'CREATE TABLE IF NOT EXISTS "person_types_ticket_category" (id INTEGER, personTypeId INTEGER, FOREIGN KEY(personTypeId) REFERENCES person_types(id))',
                                'CREATE TABLE IF NOT EXISTS "draft_previous_pages" (pageId TEXT PRIMARY KEY UNIQUE, targetPage TEXT KEY, data TEXT)',
                            ],
                            () =>{
                                console.log('version 1 migration completed.');
                                resolve(1);
                            },
                            (error: any) =>{
                                console.error(`version 1 migration error: ${error.name} : ${error.message}`);
                                reject(error)
                        });
                        break;
                    case 1:
                        // initialise
                        this.db.sqlBatch(
                            [
                                'CREATE TABLE IF NOT EXISTS "franchisee" (id INTEGER PRIMARY KEY, data TEXT)',
                                'CREATE TABLE IF NOT EXISTS "site" (id INTEGER PRIMARY KEY, data TEXT)'
                            ],
                            () =>{
                                console.log('version 2 migration completed.');
                                resolve(2); // return the current version.  This is the config.version.
                            },
                            (error: any) =>{
                                console.error(`version 2 migration error: ${error.name} : ${error.message}`);
                                reject(error)
                        });
                        break;

                    case 2:
                        // initialise
                        this.db.sqlBatch(
                            [
                                'CREATE TABLE IF NOT EXISTS "franchisee_sites" (id TEXT PRIMARY KEY UNIQUE, data TEXT)',
                                'CREATE TABLE IF NOT EXISTS "checklist_dashboard_filter" (id TEXT PRIMARY KEY UNIQUE, data TEXT)',
                            ],
                            () =>{
                                console.log('version 3 migration completed.');
                                resolve(3); // return the current version.  This is the config.version.
                            },
                            (error: any) =>{
                                console.error(`version 3 migration error: ${error.name} : ${error.message}`);
                                reject(error)
                        });
                        break;
                }
            });
        }
    }
    async initialise(db_name: string, id: string ): Promise<boolean> {
        this.db = new SqliteDB(db_name, this.config);
        return this.db.upgradeDatabase()
        .then((successful: boolean) => {
            return new Promise<boolean>( (resolve, _reject) => {
                if(successful) {
                    console.log(db_name + 'database create/upgraded')
                    this.getById('owner', '1')
                    .then((owner: any) => {
                        if(owner) {
                            if (owner !== id) {
                                logError('database owners don\'t match')
                                return false
                            }
                            // resolve(true)
                            return true
                        } else{
                            // your database
                            void this.setById('owner', '1',id)
                            // resolve(true)
                            return true
                        }
                    }).then((dbCreatedOK: boolean) => {
                        if (dbCreatedOK) {
                            void this.db.executeSql('SELECT version FROM _version WHERE id = ?', ['dexie'])
                            .then((dbVersionResult: any) => {
                                if (dbVersionResult.rows.length === 0) {
                                    return true
                                } else{
                                    console.log(db_name + 'no migration necessary')
                                    return false
                                }
                            }).then((checkDb: boolean) => {
                                if (checkDb) {
                                    void Dexie.getDatabaseNames()
                                    .then((databases: string[]) => {
                                        if(databases.includes(db_name)) {
                                            const oldDb = new AppDataDB();
                                            void oldDb.initialise(db_name, id)
                                            .then((oldDBOK: boolean) => {
                                                if (oldDBOK) {
                                                    void oldDb.getDraftChecklistList()

                                                    .then((draftChecklists: IStoredChecklistMeta[]) =>{
                                                        const getChecklist = (localId: number) => {
                                                            return new Promise<void>((checklistResolve, _checklistReject) => {
                                                                void oldDb.loadChecklist(localId)
                                                                .then((storedChecklist: any) => {
                                                                    if (storedChecklist)
                                                                        return this.migrateChecklist(storedChecklist)
                                                                    else
                                                                        return ;
                                                                })
                                                                .then((_result: any) => {
                                                                    checklistResolve()
                                                            });
                                                            })
                                                        }
                                                        const promises: any[] = [];
                                                        draftChecklists.forEach((checklist) => {
                                                            promises.push(getChecklist(checklist.localId))
                                                        });
                                                        return Promise.all(promises)
                                                    }).then(() => {
                                                        return oldDb.getDraftTicketsList()
                                                    }).then((draftTickets: IStoredTicketMeta[]) =>{
                                                        const getTickets = (localId: number) => {
                                                            return new Promise<void>((ticketResolve, _ticketreject) => {
                                                                void oldDb.loadTicket(localId)
                                                                .then((storedTicket: any) => {
                                                                    if (storedTicket)
                                                                        return this.migrateTicket(storedTicket)
                                                                    else
                                                                        return ;
                                                                })
                                                                .then((_result: any) => {
                                                                    ticketResolve()
                                                            });
                                                            })
                                                        }
                                                        const promises: any[] = [];
                                                        draftTickets.forEach((ticket) => {
                                                            promises.push(getTickets(ticket.localId))
                                                        });
                                                        return Promise.all(promises)
                                                    }).then(() => {
                                                        return oldDb.getDraftIncidentsList()
                                                    }).then((draftIncident: IStoredIncidentMeta[]) =>{
                                                        const getIncident = (localId: number) => {
                                                            return new Promise<void>((incidentResolve, _incidentReject) => {
                                                                void oldDb.loadIncident(localId)
                                                                .then((storedIncident: any) => {
                                                                    if (storedIncident)
                                                                        return this.migrateIncident(storedIncident)
                                                                    else
                                                                        return ;
                                                                })
                                                                .then((_result: any) => {
                                                                    incidentResolve()
                                                            });
                                                            })
                                                        }
                                                        const promises: any[] = [];
                                                        draftIncident.forEach((incident) => {
                                                            promises.push(getIncident(incident.localId))
                                                        });
                                                        return Promise.all(promises)
                                                     }).then(() => {
                                                        return oldDb.getSettings()
                                                    }).then((settings: ISettings) =>{
                                                        settings.exportDatabase = false;
                                                        return this.saveSettings(settings)
                                                    }).then(() => {
                                                        this.updateDexieVersion()
                                                        .then((_result_1) => {
                                                            console.log(this.db.dbName + ' migration completed');
                                                            resolve(true)
                                                        }).catch((err) => {
                                                            logError(err);
                                                            // resolve so user can conitinue working
                                                            resolve(true)
                                                        });
                                                    })
                                                } else {
                                                    logError('Database is corrupted');
                                                    this.updateDexieVersion()
                                                    .then((_result_1) => {
                                                        console.log(this.db.dbName + ' migration completed');
                                                        resolve(true)
                                                    }).catch((err) => {
                                                        logError(err);
                                                        // resolve so user can conitinue working
                                                        resolve(true)
                                                    });
                                                }
                                            })
                                        } else{
                                            this.updateDexieVersion()
                                            .then((_result_1) => {
                                                console.log(this.db.dbName + ' no migration neccessary');
                                                resolve(true);
                                            }).catch((err) => {
                                                logError(err);
                                                // resolve so user can conitinue working
                                                resolve(true)
                                            });
                                        }
                                    })
                                } else {
                                    resolve(true)
                                }
                            })
                        } else{
                            logError('database owners don\'t match')
                            resolve(false)
                        }

                    }).catch((e: any) =>{
                        logError(e)
                        throw new DatabaseError('Database error')
                    })
                } else {
                    throw new DatabaseError('Database exists')
                }
            })
        }).catch((e) =>{
            logError(e)
            throw new DatabaseError('Database error')
        })
    }

    updateDexieVersion(): Promise<any> {
        return this.db.executeSql('INSERT INTO _version (id, version) VALUES (?1,?2)', ['dexie',1])
    }

    async getById<T>(entityName: string, entityId: string): Promise<T> {
        const result: T = await this.db.get(entityName,entityId);
        return result;
    }

    async setById(entityName: string, entityId: string, data: any): Promise<void> {
        await this.db.put(entityName, data, entityId);
    }

    async deleteById(entityName: string, entityId: string | number): Promise<void> {
        await this.db.delete(entityName, entityId);
    }


    async getByIds(entityName: string, entityIds: number[], indexName?: string): Promise<any[] | undefined> {
        const index = indexName? indexName: 'id'
        if (entityIds.length > 0 ) {
            let parameters =''
            entityIds.forEach(_item => parameters += '?,')
            const ids  =  await this.db.executeSql('select id FROM ' + entityName + ' where ' + index + 'in  ( ' + parameters.slice(0, -1) + ' )', entityIds)
            .then((result: any) => {
                console.log(`get:  ${result.insertId}`);
                console.log(`get rowsAffected:  ${result.rowsAffected}`);
                const data = [];
                for (let i = 0; i < result.rows.length; i++){
                    data.push(result.rows.item(i).id);
                }
                return data
            }).catch((err: Error) => {
                console.log(err)
                return undefined
            })
            return ids;
        } else{
            return []
        }
    }

    async getEntityByIds(entityName: string, entityIds: number[], indexName?: string): Promise<any[]> {
        const index = indexName? indexName: 'id'
        if (entityIds.length > 0 ) {
            let parameters =''
            entityIds.forEach(_item => parameters += '?,')
            const entities  =  await this.db.executeSql('select data FROM ' + entityName + ' where ' + index + ' in  ( ' + parameters.slice(0, -1) + ' )', entityIds)
            .then((result: any) => {
                console.log(`get: ${result.insertId}`);
                console.log(`get rowsAffected: ${result.rowsAffected}`);
                const data = [];
                for (let i = 0; i < result.rows.length; i++){
                    data.push(JSON.parse(result.rows.item(i).data));
                }
                return data
            }).catch((err: Error) => {
                console.log(err)
                throw err
            })
            return entities;
        } else{
            return []
        }
    }

    async getAll(entityName: string): Promise<any[]>{
        const result = await this.db.executeSql('select data FROM ' + entityName , [])
        .then((resultSet: any) => {
            const data = [];
            console.log(`get: ${resultSet.insertId}`);
            console.log(`get rowsAffected: ${resultSet.rowsAffected}`);
            for (let i = 0; i < resultSet.rows.length; i++){
                data.push(JSON.parse(resultSet.rows.item(i).data));
            }
            return data;
        }).catch((err: Error) => {
            console.log(err)
            throw err
        })
        return result;
    }

    async clearEntityCache<T>(_entityName: string): Promise<void> {
        await this.db.executeSql('Delete FROM ' + _entityName , [])
        .then((resultSet: any) => {
            console.log('deleted table: ' + _entityName);
            console.log(`deleted table rowsAffected: ${resultSet.rowsAffected}`);
        }).catch((err: Error) => {
            console.log(err)
            throw err
        })
    }

    async storeMessages(ns: string, messages: { [key: string]: string }): Promise<void> {
        await this.setById('messages', ns, messages);
    }

    async retrieveMessages(ns: string): Promise<any> {
        return await this.db.get('messages', ns) as any;
    }

    async migrateChecklist(checklist: IStoredChecklist): Promise<number> {
        return this.db.executeSql('INSERT into checklists (localId, versionId, checklistId, franchiseeId, siteId, data) values (?,?,?,?,?,?) ',
            [checklist.localId, checklist.versionId, checklist.checklistId, checklist.franchiseeId, checklist.siteId, JSON.stringify(checklist)])
        .then((result) => {
            console.log(`insertId ${result.insertId} local id ${checklist.localId}`)
            return Number (checklist.localId)
        }).catch((err) => {
            logError(err)
            throw new Error('Unable to save checklist')
        })
    }

    async saveChecklist(checklist: IStoredChecklist): Promise<number> {
        if (checklist.localId) {
            return this.db.executeSql('UPDATE checklists SET versionId =?, checklistId=?, franchiseeId =?, siteId=?, data=? WHERE localId = ?',
                [checklist.versionId, checklist.checklistId, checklist.franchiseeId, checklist.siteId, JSON.stringify(checklist), checklist.localId])
            .then((_result) => {
                return Number (checklist.localId)
            }).catch((err) => {
                logError(err)
                throw new Error('Unable to save checklist')
            })
        }
        else {
            delete checklist.localId;  // make sure we dont save 0 or null
            return this.db.executeSql('INSERT into checklists (versionId, checklistId, franchiseeId, siteId, data) values (?,?,?,?,?) ',
                [checklist.versionId, checklist.checklistId, checklist.franchiseeId, checklist.siteId, JSON.stringify(checklist)])
            .then(async (result) => {
                console.log(`insertId ${result.insertId}`)
                checklist.localId = Number (result.insertId)
                return this.db.executeSql('UPDATE checklists SET  data=? WHERE localId = ?',
                    [ JSON.stringify(checklist), checklist.localId])
            }).then((_result) => {
                return Number (checklist.localId)
            }).catch((err) => {
                logError(err)
                throw new Error('Unable to save checklist')
            })
        }
    }

    async migrateIncident(incident: IStoredIncident): Promise<number> {
        return  this.db.executeSql('INSERT into draft_incidents (localId, incidentId, siteId, data) values (?, ?,?,?) ',
        [incident.localId, incident.incidentId, incident.siteId, JSON.stringify(incident)])
        .then((result) => {
            console.log(`insertId ${result.insertId} local id ${incident.localId}`)
            return Number (incident.localId)
        }).catch((err) => {
            logError(err)
            throw new Error('Unable to save ' + i18n.t('customLabel_incident'))
        })

    }


    async saveIncident(incident: IStoredIncident): Promise<number> {
        if (incident.localId) {
            return this.db.executeSql('UPDATE draft_incidents SET incidentId =?, siteId=?, data=? WHERE localId = ?',
            [incident.incidentId, incident.siteId, JSON.stringify(incident), incident.localId])
            .then((_result) => {
                return Number (incident.localId)
            }).catch((err) => {
                logError(err)
                throw new Error('Unable to save ' + i18n.t('customLabel_incident'))
            })
        }
        else {
            delete incident.localId;  // make sure we dont save 0 or null
            return this.db.executeSql('INSERT into draft_incidents (incidentId, siteId, data) values (?,?,?) ',
            [incident.incidentId, incident.siteId, JSON.stringify(incident)])
            .then(async (result) => {
                console.log(`insertId ${result.insertId}`)
                incident.localId = Number (result.insertId)
                return this.db.executeSql('UPDATE draft_incidents SET  data=? WHERE localId = ?',
                    [ JSON.stringify(incident), incident.localId])
            })
            .then((_result) => {
                return Number (incident.localId)
            }).catch((err) => {
                logError(err)
                throw new Error('Unable to save ' + i18n.t('customLabel_incident'))
            })
        }
    }

    async loadIncident(localId: number): Promise<IStoredIncident | undefined> {
        return await this.db.getByIndex('draft_incidents', 'localId', localId);
    }

    async loadTicket(localId: number): Promise<IStoredTicket | undefined> {
        return await this.db.getByIndex('draft_tickets', 'localId', localId);
    }

    async migrateTicket(ticket: IStoredTicket): Promise<number> {
        return await this.db.executeSql('INSERT into draft_tickets (localId, ticketId, franchiseeId, siteId, data) values (?,?,?,?,?) ',
            [ticket.localId, ticket.ticketId, ticket.franchiseeId, ticket.siteId, JSON.stringify(ticket)])
            .then((result) => {
                console.log(`insertId ${result.insertId} localid ${ticket.localId}` )
                return Number (ticket.localId)
            }).catch((err) => {
                logError(err)
                throw new Error('Unable to save ' + i18n.t('customLabel_ticket'))
            })
    }

    async saveTicket(ticket: IStoredTicket): Promise<number> {
        console.log(`save tikcet localID ${ticket.localId}`)
        if (ticket.localId) {
            return await this.db.executeSql('UPDATE draft_tickets SET ticketId =?, franchiseeId = ? , siteId=?, data=? WHERE localId = ?',
                [ticket.ticketId, ticket.franchiseeId, ticket.siteId, JSON.stringify(ticket), ticket.localId])
            .then((_result) => {
                return Number (ticket.localId)
            }).catch((err) => {
                logError(err)
                throw new Error('Unable to save ' + i18n.t('customLabel_ticket'))
            })
        }
        else {
            delete ticket.localId;  // make sure we dont save 0 or null
            return await this.db.executeSql('INSERT into draft_tickets (ticketId, franchiseeId, siteId, data) values (?,?,?,?) ',
            [ticket.ticketId, ticket.franchiseeId, ticket.siteId, JSON.stringify(ticket)])
            .then(async (result) => {
                console.log(`insertId ${result.insertId}`)
                ticket.localId = Number (result.insertId)
                return await this.db.executeSql('UPDATE draft_tickets SET  data=? WHERE localId = ?',
                    [ JSON.stringify(ticket), ticket.localId])
            })
            .then((_result) => {
                return Number (ticket.localId)
            }).catch((err) => {
                logError(err)
                throw new Error('Unable to save ' + i18n.t('customLabel_ticket'))
            })
        }
    }

    async loadPreviousPage(pageId: string): Promise<any | undefined> {
        return await this.db.getByIndex('draft_previous_pages', 'pageId', pageId);
    }
    async loadPreviousPageByTargetPage(targetPage: string): Promise<any | undefined> {
        const results =  await this.db.getAllByIndex('draft_previous_pages', 'targetPage', targetPage)
        if (results && results.length > 0) {
            return results[0]
        } else{
            return results
        }
    }
    async savePreviousPage(previousPage: IStockedPreviousPage): Promise<string> {
        if (previousPage.pageId) {
            const existingDoc = await this.loadPreviousPage(previousPage.pageId);
            if (existingDoc) {
                return await this.db.executeSql('UPDATE draft_previous_pages SET targetPage =?, data=? WHERE pageId = ?',
                    [previousPage.targetPage, JSON.stringify(previousPage), previousPage.pageId])
                    .then((_result) => {
                        return previousPage.pageId
                    }).catch((err) => {
                       throw Error(err)
                    })
            }else{
                return await this.db.executeSql('INSERT into draft_previous_pages (pageId, targetPage, data) values (?,?,?) ',
                    [previousPage.pageId, previousPage.targetPage, JSON.stringify(previousPage)])
                .then((_result) => {
                    return previousPage.pageId
                }).catch((err) => {
                    console.log(err)
                    throw Error(err)
                })
            }
        }
        return '-1';
    }

    async removePreviousPage(pageId: PreviousPageID): Promise<any> {
        return await this.db.deleteByIndex('draft_previous_pages' , 'pageId', pageId);
    }

    async emptyPreviousPage(): Promise<void> {
       await this.db.clear('draft_previous_pages');
    }

    async loadChecklist(localId: number): Promise<IStoredChecklist | undefined> {
        return await this.db.getByIndex('checklists', 'localId', localId);
    }

    async removeLocalChecklist(localId: number): Promise<any> {
        return await this.db.deleteByIndex('checklists','localId', localId);
    }

    async removeLocalTicket(localId: number): Promise<any> {
        return await this.db.deleteByIndex('draft_tickets','localId', localId);
    }

    async removeLocalIncident(localId: number): Promise<any> {
        return await this.db.deleteByIndex('draft_incidents','localId', localId);
    }

    async searchDraftChecklistByAssignee(
        versionId: number, franchiseeId: number, siteId?: number
    ): Promise<number | null> {
        if (siteId) {
            const jsonArray  =  await this.db.executeSql('select localId FROM checklists where versionId= ? and franchiseeId = ? and siteId = ?',
                [versionId, franchiseeId, siteId])
                .then((result: any) => {
                    const data = [];
                    for (let i = 0; i < result.rows.length; i++){
                        data.push(result.rows.item(i).localId);
                    }
                    return data;
            }).catch((err: Error) => {
                console.log(err)
                return undefined
            })
            if (jsonArray && jsonArray.length > 0) {
                return jsonArray[0];
            } else{
                return null;
            }
        } else{
            const jsonArray  =  await this.db.executeSql('select localId FROM checklists where versionId= ? and franchiseeId = ? and siteId is null',
                [versionId, franchiseeId])
                .then((result: any) => {
                    const data = [];
                    for (let i = 0; i < result.rows.length; i++){
                        data.push(result.rows.item(i).localId);
                    }
                    return data;
            }).catch((err: Error) => {
                console.log(err)
                return undefined
            })
            if (jsonArray && jsonArray.length > 0) {
                return jsonArray[0];
            } else{
                return null;
            }
        }
    }

    async searchDraftNumbersByChecklistIdAssignee(
        checklistId: number, franchiseeId: number, siteId: number
    ): Promise<number> {
        if (siteId > 0) {
            const count  =  await this.db.count('select count(localId) as count FROM checklists where checklistId= ? and franchiseeId = ? and siteId = ?',
                [checklistId, franchiseeId, siteId])
            return count
        } else{
            const count  =  await this.db.count('select count(localId) as count FROM checklists where checklistId= ? and franchiseeId = ? and siteId is null',
               [checklistId, franchiseeId])
            return count
        }
    }

    async searchDraftByChecklistIdAssignee(
        checklistId: number, franchiseeId: number, siteId: number
    ): Promise<number> {

        if (siteId) {
            const jsonArray  =  await this.db.executeSql('select localId FROM checklists where checklistId= ? and franchiseeId = ? and siteId = ?',
                [checklistId, franchiseeId, siteId])
                .then((result: any) => {
                    const data = [];
                    for (let i = 0; i < result.rows.length; i++){
                        data.push(result.rows.item(i).localId);
                    }
                    return data;
            }).catch((err: Error) => {
                console.log(err)
                return undefined
            })
            if (jsonArray && jsonArray.length > 0) {
                return jsonArray[0];
            } else{
                return 0;
            }
        } else{
            const jsonArray  =  await this.db.executeSql('select localId FROM checklists where checklistId= ? and franchiseeId = ? and siteId is null',
                [checklistId, franchiseeId, siteId])
                .then((result: any) => {
                    const data = [];
                    for (let i = 0; i < result.rows.length; i++){
                        data.push(result.rows.item(i).localId);
                    }
                    return data;
            }).catch((err: Error) => {
                console.log(err)
                return undefined
            })
            if (jsonArray && jsonArray.length > 0) {
                return jsonArray[0];
            } else{
                return 0;
            }
        }
    }

    async searchDraftNumbersByIncidentIdAssignee(
        incidentId: number, siteId: number
    ): Promise<number> {
        const count  =  await this.db.count('select count(localId) as count FROM draft_incidents where incidentId= ? and siteId = ?',
               [incidentId, siteId])
        return count;
    }

    async searchDraftByIncidentIdAssignee(
        incidentId: number, siteId: number
    ): Promise<number> {
        const jsonArray  =  await this.db.executeSql('select localId FROM draft_incidents where incidentId= ? and siteId = ?',
            [incidentId, siteId])
            .then((result: any) => {
                const data = [];
                for (let i = 0; i < result.rows.length; i++){
                    data.push(result.rows.item(i).localId);
                }
                return data;
        }).catch((err: Error) => {
            console.log(err)
            return undefined
        })
        if (jsonArray && jsonArray.length > 0) {
            return jsonArray[0];
        } else{
            return 0;
        }
    }

    async searchDraftIncidentBySite(siteId: number): Promise<number | null> {
        const jsonArray  =  await this.db.executeSql('select localId FROM draft_incidents where siteId = ?',
            [siteId])
        .then((result: any) => {
            const data = [];
            for (let i = 0; i < result.rows.length; i++){
                data.push(result.rows.item(i).localId);
            }
            return data;
        }).catch((err: Error) => {
            console.log(err)
            return undefined
        })
        if (jsonArray && jsonArray.length > 0) {
            return jsonArray[0];
        } else{
            return 0;
        }
    }
    // fix duplicate checklist drafts
    async removeDraftChecklistByChecklistId(checklistId: number): Promise<void>  {
        return await this.db.deleteByIndex('checklists','checklistId',checklistId)
    }

    // fix duplicate incident drafts
    async removeDraftIncidentByIncidentId(incidentId: number): Promise<void>  {
        return await this.db.deleteByIndex('draft_incidents','incidentId',incidentId)
    }

    async getDraftChecklistList(): Promise<IStoredChecklistMeta[]> {
        const res = await this.db.executeSql('select data FROM checklists', [])
        .then((resultSet: any) => {
            const data: IStoredChecklistMeta[] = [];
            for (let i = 0; i < resultSet.rows.length; i++){
                const storedChecklist = JSON.parse(resultSet.rows.item(i).data);
                data.push({
                    localId: storedChecklist.localId,
                    templateId: storedChecklist.versionId,
                    checklistId: storedChecklist.checklistId,
                    franchiseeId: storedChecklist.franchiseeId,
                    siteId: storedChecklist.siteId,
                    date: storedChecklist.date,
                    checklistName: storedChecklist.checklist.name,
                });
            }
            return data;
        }).catch((err: Error) => {
            console.log(err)
            throw err
        })
        return res;
    }

    async getDraftTicketsList(): Promise<IStoredTicketMeta[]> {
        const res = await this.db.executeSql('select data FROM draft_tickets', [])
        .then((resultSet: any) => {
            const data: IStoredTicketMeta[] = [];
            for (let i = 0; i < resultSet.rows.length; i++){
                const storedTicket = JSON.parse(resultSet.rows.item(i).data);
                data.push({
                    localId: storedTicket.localId,
                    ticketId: storedTicket.ticketId,
                    franchiseeId: storedTicket.franchiseeId,
                    siteId: storedTicket.siteId,
                    ticketNo: storedTicket.ticket.ticketNo,
                    subject: storedTicket.ticket.subject,
                    categoryId: storedTicket.ticket.category && storedTicket.ticket.category.id || 0
                });
            }
            return data;
        }).catch((err: Error) => {
            console.log(err)
            throw err
        })
        return res
    }

    async getDraftIncidentsList(): Promise<IStoredIncidentMeta[]> {
        const res = await this.db.executeSql('select data FROM draft_incidents', [])
        .then((resultSet: any) => {
            const data: IStoredIncidentMeta[] = [];
            for (let i = 0; i < resultSet.rows.length; i++){
                const storedIncident = JSON.parse(resultSet.rows.item(i).data);
                data.push({
                    localId: storedIncident.localId,
                    incidentId: storedIncident.incidentId,
                    siteId: storedIncident.siteId,
                    incidentNo: storedIncident.incident.incidentNo || 0,
                    incidentName: storedIncident.incident.name,
                    date: storedIncident.date
                });
            }
            return data;
        }).catch((err: Error) => {
            console.log(err)
            throw err
        })
        return res
    }

    async isDraftExist(): Promise<boolean> {
        const [checklistsCount, ticketsCount, incidentsCount]  = await Promise.all([this.getDraftChecklistsCount(),
        this.getDraftTicketsCount(),
        this.getDraftIncidentsCount()]);
        if ((checklistsCount > 0) || (ticketsCount > 0) || (incidentsCount > 0))
            return true;
        return false;
    }
    async getDraftsTotal(): Promise<number> {
        const [checklistsCount, ticketsCount, incidentsCount]  = await Promise.all([this.getDraftChecklistsCount(),
        this.getDraftTicketsCount(),
        this.getDraftIncidentsCount()]);
        return checklistsCount + ticketsCount + incidentsCount;
    }

    async getDraftChecklistsCount(): Promise<number> {
        return this.db.count('select count(localId) as count FROM checklists');
    }
    async getDraftTicketsCount(): Promise<number> {
        return this.db.count('select count(localId) as count FROM draft_tickets');
    }
    async getDraftIncidentsCount(): Promise<number> {
        return this.db.count('select count(localId) as count FROM draft_incidents');
    }
    async getSettings(): Promise<ISettings> {
        const settings = getDefaultSettings();
        const storedSettings = await this.getById<ISettings>('settings', 'settings');
        if (storedSettings) {
            Object.assign(settings, storedSettings);
        }
        return settings;
    }

    async saveSettings(settings: ISettings): Promise<void>  {
        await this.setById('settings', 'settings', settings);
    }

    async getSummary(): Promise<ISummaryData> {
        const summary = getDefaultSummary();
        const storedSummary = await this.getById<ISummaryData>('summary', 'summary');
        if (storedSummary) {
            Object.assign(summary, storedSummary);
        }
        return summary;
    }

    async saveSummary(summary: ISummaryData): Promise<void> {
        await this.setById('summary', 'summary', summary);
    }

    async getSyncStatus(): Promise<ISyncMetadata> {
        const status = await this.getById<ISyncMetadata>('settings', 'syncStatus');
        if (!status) {
            return {
                lastSuccessfulSync: '',
                lastSync: {}
            };
        }
        return status;
    }

    async setSyncStatus(status: ISyncMetadata): Promise<void> {
        await this.setById('settings', 'syncStatus', status);
    }

    async getSyncSettings(): Promise<ISyncSettings> {
        const status = await this.getById<ISyncSettings>('settings', 'syncSettings');
        if (!status) {
            return {
                fetchLimit: 100,
                peopleFailedCount: 0
            };
        }
        return status;
    }

    async setSyncSettings(status: ISyncSettings): Promise<void> {
        await this.setById('settings', 'syncSettings', status);
    }

    async export(_option: ExportOptions ): Promise<Blob> {
        return new Promise((resolve) => {
            cordova.plugins.sqlitePorter.exportDbToSql(this.db.sqlite, {
                successFn(sql: any, _count: any){
                    resolve(new Blob([sql],{type: 'text/json'}));
                },
                tables:['checklists','draft_tickets','draft_incidents'],
                separator: ';\u241E\n'
            })
        })
    }

    async import(exportedData: Blob): Promise<void> {
        const data = await (new Response(exportedData)).text();
        return new Promise((resolve, reject) => {
            cordova.plugins.sqlitePorter.importSqlToDb(this.db.sqlite, data, {
                successFn(count: any){
                    console.log('Imported '+count+' SQL statements');
                    resolve()
                },
                errorFn(error: Error){
                    logError(error)
                    reject('failed to import')
                },
                progressFn(current: any, total: any){
                    console.log('Imported '+current+'/'+total);
                },
                separator: ';\u241E\n'
            });
        })
    }

    async getLastSyncTimeForPeople(): Promise<string | null> {
        const status = await this.getById<ISyncMetadata>('settings', 'syncStatus');
        if (status && status.lastSync && status.lastSync.people_list) {
            return status.lastSync.people_list;
        }
        return null;
    }

    async getLastSyncTimeForPersonTypes(): Promise<string | null> {
        const status = await this.getById<ISyncMetadata>('settings', 'syncStatus');
        if (status && status.lastSync && status.lastSync.person_types) {
            return status.lastSync.person_types;
        }
        return null;
    }

    // used on checklist/incident/ticket details page
    async getPeopleListForPeopleControl(franchiseId: number, franchiseeId: number, siteId: number, ticketCategoryId: number, incidentTypeId: number, checklistTemplateId: number): Promise<IPerson[]> {

        const peopleIds = await this.getPeopleIdByPersonTypes(incidentTypeId, ticketCategoryId, checklistTemplateId);
        // TODO: when person types is empty, we should return super users list

        const rawPeople: IPerson[] = [];
        let filteredPeople: IPerson[] = [];

        const peopleListFromPersonTypes: IPerson[] = await this.getEntityByIds('people',peopleIds, 'id');

        // find distinct people and store in rawPeople
        peopleListFromPersonTypes.forEach((p: IPerson) =>{
            if(!rawPeople.includes(p)){
                rawPeople.push(p);
            }
        });

        if (siteId > 0) {
            filteredPeople = rawPeople.filter((p) => p.siteIds!.includes(siteId));
        }else if (franchiseeId > 0){
            // all sites
            const sitesList: ISites = await this.db.get('sites', String(franchiseId));
            const filteredSitesList: ISite[] = [];

            // sites for selected franchisee
            sitesList.sites.filter((s: ISite) => s.franchiseeId == franchiseeId)
                .forEach((s1: any) => {
                    if(!filteredSitesList.includes(s1)){
                        filteredSitesList.push(s1);
                    }
                });
            const franchiseeSitesIds = filteredSitesList.map((s) => s.id);

            filteredPeople = rawPeople.filter((p) =>
                p.siteIds!.some((s: number) => franchiseeSitesIds.includes(s))
            );
        }
        return filteredPeople;
    }


    async getPeopleIdByPersonTypes(incidentTypeId: number | null,
        ticketCategoryId: number | null, checklistTemplateId: number | null): Promise<number[]> {

        const peopleIds: number[] = [];


        let idAndType: [number, string];
        if(incidentTypeId && incidentTypeId > 0) {
            idAndType = [Number(incidentTypeId), 'incidentTypeIds']
        } else if (ticketCategoryId && ticketCategoryId > 0) {
            idAndType = [Number(ticketCategoryId), 'ticketCategoryIds']
        } else {
            idAndType = [Number(checklistTemplateId), 'checklistTemplateIds']
        }

        let personTypes: IPersonType[] = [];
        if (idAndType[1] == 'incidentTypeIds') {
            personTypes = await this.db.executeSql('select pt.data from person_types pt join person_types_incident_type  c on c.personTypeId = pt.id where c.id = ?', [incidentTypeId])
            .then((resultSet: any) => {
                const data: IPersonType[] = []
                for (let i = 0; i < resultSet.rows.length; i++){
                    data.push(JSON.parse(resultSet.rows.item(i).data))
                }
                return data
            }).catch((err: Error) => {
                console.log(err)
                throw err
            })
        } else if (idAndType[1] == 'ticketCategoryIds') {
            personTypes = await this.db.executeSql('select pt.data from person_types pt join person_types_ticket_category c on c.personTypeId = pt.id where c.id = ?', [ticketCategoryId])
            .then((resultSet: any) => {
                const data: IPersonType[] = []
                for (let i = 0; i < resultSet.rows.length; i++){
                    data.push(JSON.parse(resultSet.rows.item(i).data))
                }
                return data
            }).catch((err: Error) => {
                console.log(err)
                throw err
            })
        } else if (idAndType[1] == 'checklistTemplateIds') {
            personTypes = await this.db.executeSql('select pt.data from person_types pt join person_types_checklist_template c on c.personTypeId = pt.id where c.id = ?', [checklistTemplateId])
            .then((resultSet: any) => {
                const data: IPersonType[] = []
                for (let i = 0; i < resultSet.rows.length; i++){
                    data.push(JSON.parse(resultSet.rows.item(i).data))
                }
                return data
            }).catch((err: Error) => {
                console.log(err)
                throw err
            })
        }
        personTypes.forEach(pt => peopleIds.push(...pt.peopleIds!))
        return peopleIds;
    }

    async isTableEmpty(tableName: string): Promise<boolean> {
        const  rowCount = await this.db.count('select count(*) as count FROM ' + tableName);
        return rowCount <1;
    }

    async setPersonTypes(entityId: string, data: any): Promise<void> {
        await this.db.put('person_types', data, entityId);
        if (data.checklistTemplateIds) {
            data.checklistTemplateIds.forEach(async (id: number) =>{
                const result = await this.db.executeSql('SELECT count(*) FROM person_types_checklist_template  where id = ? and  personTypeId = ?', [id, entityId]);
                if (result.rows.length === 0) {
                    await this.db.executeSql('INSERT INTO person_types_checklist_template (id, personTypeId) VALUES (?, ?)', [id, entityId]);
                }
            } )
        }
        if (data.incidentTypeIds) {
            data.incidentTypeIds.forEach(async (id: number) =>{
                const result = await this.db.executeSql('SELECT count(*) FROM person_types_incident_type  where id = ? and  personTypeId = ?', [id, entityId]);
                if (result.rows.length === 0) {
                    await this.db.executeSql('INSERT INTO person_types_incident_type (id, personTypeId) VALUES (?, ?)', [id, entityId]);
                }
            } )
        }
        if (data.ticketCategoryIds) {
            data.ticketCategoryIds.forEach(async (id: number) =>{
                const result = await this.db.executeSql('SELECT count(*) FROM person_types_ticket_category  where id = ? and  personTypeId = ?', [id, entityId]);
                if (result.rows.length === 0) {
                    await this.db.executeSql('INSERT INTO person_types_ticket_category (id, personTypeId) VALUES (?, ?)', [id, entityId]);
                }
            } )
        }
    }

    async deletePersonTypes(entityId: string | number): Promise<void> {
        return new Promise<void>((resolve, reject) => {
            this.db.sqlBatch(
                [
                    ['delete FROM person_types_checklist_template  where personTypeId = ?', [entityId]],
                    ['delete FROM person_types_incident_type  where personTypeId = ?', [entityId]],
                    ['delete FROM person_types_ticket_category  where personTypeId = ?', [entityId]],
                    ['delete FROM person_types where id = ?', [entityId]]
                ],
                () =>{
                    console.log('deletePersonType OK.');
                    resolve();
                },
                (error: any) =>{
                    console.error(`error deletePersonTypes for ${entityId}: ${error.name} : ${error.message}`);
                    reject(error)
            });
        })
    }

    async getDraftImagesIds(): Promise<number[]> {
        let ids: number[]=[];
        const storedChecklists = await this.getDraftChecklistList();
        for (const storedChecklist of storedChecklists) {
            const draftChecklist = await this.loadChecklist(storedChecklist.localId);
            if (draftChecklist?.checklist) {
                ids = ids.concat(exportLocalPhotos(draftChecklist?.checklist));
            }
        }

        const storedTickets = await this.getDraftTicketsList();
        for (const storedTicket of storedTickets) {
            const draftTicket = await this.loadTicket(storedTicket.localId);
            if (draftTicket?.ticket) {
                ids = ids.concat(exportTicketLocalPhotos(draftTicket?.ticket));
                if(draftTicket.ticket.checklist){
                    ids = ids.concat(exportLocalPhotos(draftTicket.ticket.checklist));
                }
            }
        }

        const storedIncidents = await this.getDraftIncidentsList();
        for (const storedIncident of storedIncidents) {
            const draftIncident = await this.loadIncident(storedIncident.localId);
            if (draftIncident?.incident) {
                ids = ids.concat(exportAttachmentLocalPhotos(draftIncident?.attachments));
            }
        }
        return ids
    }

    async getAssetCacheList(): Promise<string[]> {
        const res = await this.db.executeSql('select data FROM asset_cache', [])
        .then((resultSet: any) => {
            const assetList: string[] = [];
            for (let i = 0; i < resultSet.rows.length; i++){
                assetList.push(resultSet.rows.item(i).data);
            }
            return assetList;
        }).catch((err: Error) => {
            console.log(err)
            throw err
        })
        return res
    }
}
