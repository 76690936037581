import * as React from 'react';
import { createStyles, withStyles, WithStyles } from '@material-ui/core/styles';
import Refresh from '@material-ui/icons/Refresh';
import Paper from '@material-ui/core/Paper';
import CircularProgress from '@material-ui/core/CircularProgress';

const styles = () => createStyles({
    root: {
    },
    paper: {
        position: 'relative',
        margin: '0 auto 100px',
        top: 100,
        width: 100,
        height: 100,
        zIndex: 1299,
        transition: 'none',
    }
});

const iconStyle = {
    width: '100%',
    height: '100%',
};

export default interface IRefreshIndicatorProps extends
    WithStyles<typeof styles> {
 /**
  * An indicator on whether or not the indicator is busy.
  *
  * If true, a circular progress will be tendered, otherwise a refresh icon.
  */
    busy: boolean;
 /**
  * The diameter of the refresh indicator.
  */
    size: number;
 /**
  * How much the refresh indicator has been pulled down.
  */
    drag: number;
}

export const RefreshIndicator = withStyles(styles)(
  class extends React.Component<IRefreshIndicatorProps> {

    render() {
        const { busy, size, drag } = this.props;
        return (
            <Paper style={{
                position: 'relative',
                margin: `0 auto ${-size}px`,
                top: drag - size,
                width: size,
                height: size,
                zIndex: 1000000,
                transition: 'null',
            }}>
            {busy ? (
                <CircularProgress
                    size={size * 0.8}
                    style={{
                    margin: '0 auto',
                    position: 'absolute',
                    left: 0,
                    right: 0,
                    top: size * 0.1,
                    }}
                />
            ) : (
                <Refresh style={{
                ...iconStyle,
                transition: 'none',
                transform: 'rotate(100deg)',
            }}
            />
        )}
        </Paper>
      );
    }
  }
);
