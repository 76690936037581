/* eslint-disable @typescript-eslint/unbound-method */ // since we're using bind to handle 'this', we can ignore this warning

import * as React from 'react';
import { withStyles, Theme, createStyles, WithStyles } from '@material-ui/core/styles';
import {
    TabsComponent,
    ITabDefinition,
    IFranchise,
    PageLoader,
    IStoredChecklistMeta
} from 'oneplace-components';
import FavouriteIcon from '@material-ui/icons/StarBorder';
import { NewItemFAB } from '../common/NewItemFAB';
import { INavigationContextProp, withNavigationContext } from '../navigation/Navigation';
import { i18n } from '../../i18n';
import { IFranchiseData, FranchiseData } from '../../data_sources/FranchiseData';
import { withAppContext, IAppContextProp } from '../AppContext';
import { withRouter, RouteComponentProps } from 'react-router';
import { IChecklistData, ChecklistData } from '../../data_sources/ChecklistData';
import { IDashboardData, IDashboardChecklist, IDashboardTicket, IChecklistDashboardFilter } from '../../models/Dashboard';
import { ExpandingChecklistList } from './ExpandingChecklistList';
import { addLocalIdsToCLList } from './utils/addLocalIdsToCLList';
import { getChecklistUrl } from './utils/getChecklistUrl';
import { logError } from '../../logging';
import { TicketList } from './TicketList';
import { getTicketUrl } from './utils/getTicketUrl';
import Button from '../../../node_modules/@material-ui/core/Button';
import { OnePlaceIcon } from '../common/OnePlaceIcon';
import { CONFIG } from '../../config';
import Badge from '@material-ui/core/Badge';
import { getDefaultSummary } from './getDefaultSummary';
import { Typography } from '@material-ui/core';
import { loadPreviousPageState, scrollToPosition, removePreviousPage, IStockedPreviousPage, updateMyTicketsView, clearPreviousPages, updateMyIncidentsView } from '../../data_sources/PreviousPage';
import { Incidents, IncidentsState } from './Incidents';
import ChecklistDashboardHeaderPanel from './ChecklistDashboardHeaderPanel';

const styles = (theme: Theme) => createStyles({
    root: {
        backgroundColor: theme.palette.primary.main,
        color: '#FFF',
        padding: 16,
        paddingBottom: 8,
    },
    badgeItem: {
        padding: '0 10px 0 0'
    },
    button: {
        color: '#fff'
    }
});

export interface IOverviewDashboardProps extends
        WithStyles<typeof styles>,
        IAppContextProp,
        INavigationContextProp,
        RouteComponentProps<any> {
    mock_franchiseData?: IFranchiseData;
    mock_checklistData?: IChecklistData;
}

type LoadState = 'not_loaded' | 'loading' | 'loaded' | 'load_error';

export interface IOverviewDashboardState {
    loadState: LoadState;
    relatedDataLoadState: LoadState;
    franchise: IFranchise;
    dashboard: IDashboardData | null;
    drafts: IStoredChecklistMeta[];

    todaysChecklists_loadState: LoadState;
    todaysChecklists: IDashboardChecklist[] | null;
    todaysChecklists_totalCount: number;
    todaysChecklists_loadingMore: boolean;
    todaysCompletedChecklists_loadState: LoadState;
    todaysCompletedChecklists: IDashboardChecklist[];
    todaysCompletedChecklists_loadingMore: boolean;
    overdueChecklists_loadState: LoadState;
    overdueChecklists: IDashboardChecklist[];
    overdueChecklists_loadingMore: boolean;

    accessNotes: boolean;
    myTickets_loadState: LoadState;
    myTickets: IDashboardTicket[];
    myTickets_loadingMore: boolean;
    totalDrafts: number;
    totalFavourites: number;
    totalIncidents: number;
    incidentsState: IncidentsState | null;

    checklistFilter: IChecklistDashboardFilter | null;
}

export const OverviewDashboard = withStyles(styles)(withRouter(withAppContext(withNavigationContext(
    class extends React.Component<IOverviewDashboardProps, IOverviewDashboardState> {
        franchiseData: IFranchiseData;
        checklistData: IChecklistData;
        componentActive = true;
        currentTabIndex = 0;

        constructor(props: IOverviewDashboardProps) {
            super(props);
            this.openChecklist = this.openChecklist.bind(this);
            this.openTicket = this.openTicket.bind(this);
            this.searchTickets = this.searchTickets.bind(this);
            this.searchChecklists = this.searchChecklists.bind(this);
            this.onTodaysChecklistsShowMore = this.onTodaysChecklistsShowMore.bind(this);
            this.onTodaysCompletedChecklistsExpand = this.onTodaysCompletedChecklistsExpand.bind(this);
            this.onTodaysCompletedChecklistsShowMore = this.onTodaysCompletedChecklistsShowMore.bind(this);
            this.onOverdueChecklistsExpand = this.onOverdueChecklistsExpand.bind(this);
            this.onOverdueChecklistsShowMore = this.onOverdueChecklistsShowMore.bind(this);
            this.onMyTicketsShowMore = this.onMyTicketsShowMore.bind(this);
            this.reloadTickets = this.reloadTickets.bind(this);

            this.franchiseData = this.props.mock_franchiseData
                || new FranchiseData(this.props.ctx.db, this.props.ctx.api, this.props.ctx.auth.user);
            this.checklistData = this.props.mock_checklistData
                || new ChecklistData(this.props.ctx.db, this.props.ctx.api, this.props.ctx.auth.user);

            const accessNotesFlag = this.props.ctx.auth.user.capabilities.notes;

            this.state = {
                loadState: 'loading',
                relatedDataLoadState: 'not_loaded',
                franchise: null as any,
                dashboard: null as any,
                drafts: [],

                todaysChecklists_loadState: 'loading',
                todaysChecklists: [],
                todaysChecklists_totalCount: -1,
                todaysChecklists_loadingMore: false,
                todaysCompletedChecklists_loadState: 'not_loaded',
                todaysCompletedChecklists: [],
                todaysCompletedChecklists_loadingMore: false,
                overdueChecklists_loadState: 'not_loaded',
                overdueChecklists: [],
                overdueChecklists_loadingMore: false,

                accessNotes: accessNotesFlag,
                myTickets_loadState: 'not_loaded',
                myTickets: [],
                myTickets_loadingMore: false,
                totalDrafts: 0,
                totalFavourites: 0,
                totalIncidents: 0,
                incidentsState: null,

                checklistFilter: null
            };

            this.props.nav.updateNavigation({
                title: i18n.t('home')
            });

            void this.initPage();
        }

        componentWillUnmount() {
            this.componentActive = false;
        }

        async reloadTickets() {
            this.setState({
                myTickets_loadState: 'not_loaded',
                myTickets: [],
                myTickets_loadingMore: false,
                relatedDataLoadState: 'loaded'
            });
            await this.loadData();
        }

        initPage = async () => {
            const ticketState = await loadPreviousPageState(this.props.ctx.db, 'overviewDashboardTickets')
            const incidentState = await loadPreviousPageState(this.props.ctx.db, 'overviewDashboardIncidents')
            if (!ticketState && !incidentState) {
                await this.loadData();
            } else {
                if(ticketState){ //restore ticket state
                    this.currentTabIndex = this.props.ctx.auth.user.capabilities.checklists ? 1 : 0
                    if (ticketState.lastEditedItem) {
                        // update the view for last edited item
                        updateMyTicketsView(ticketState, i18n)
                    }
                    this.setState(ticketState.states)
                    scrollToPosition(ticketState.scrollPositionY)
                } else if(incidentState) {//restore incident state
                     // possible tabs: checklists, tickets, incidents
                     let defaultIncidentTabIndex = 2;
                     if (!this.props.ctx.auth.user.capabilities.checklists) defaultIncidentTabIndex--;
                     if (!this.props.ctx.auth.user.capabilities.tickets) defaultIncidentTabIndex--;
                     this.currentTabIndex = defaultIncidentTabIndex
                    if(incidentState.lastEditedItem){
                        updateMyIncidentsView(incidentState)
                    }
                    this.setState(incidentState.states)
                    scrollToPosition(incidentState.scrollPositionY)
                }
                void clearPreviousPages(this.props.ctx.db)//after restored state, we can clear previous page
            }
        }

        async loadData() {
            // Data required for initial render
            const [ franchise, drafts ]  = await Promise.all([
                this.franchiseData.getFranchise().getData(),
                this.props.ctx.db.getDraftChecklistList()
            ]);
            const totalDrafts = await this.props.ctx.db.getDraftsTotal();

            this.setState({
                franchise,
                drafts,
                totalDrafts,
                loadState: 'loaded'
            });

            await this.checkAndLoadChecklistDashboardFilter()
            await this.loadRelatedData();
        }

        async loadRelatedData() {
            // Other Data
            this.setState({
                relatedDataLoadState: 'loading',
            });
            const user = this.props.ctx.auth.user;
            const franchiseId = user.capabilities.franchiseId;
            let dashboard: IDashboardData | null = null;
            let todaysChecklists: IDashboardChecklist[] | null = null;
            let todaysChecklists_totalCount = -1;
            let myTickets: IDashboardTicket[] = [];
            let totalFavourites = 0;
            let totalIncidents = 0;
            if (this.componentActive) {
                try {
                    const summary = getDefaultSummary();
                    dashboard = await this.checklistData.getOverviewDashboard(franchiseId, this.state.checklistFilter).getData();
                    if (dashboard.dashboard.ticketSummary) {
                        summary.summary.ticketSummary = dashboard.dashboard.ticketSummary;
                    }
                    void this.props.ctx.db.saveSummary(summary);
                    // favourites are only available in online mode
                    totalFavourites = this.props.ctx.net && this.props.ctx.net.isOffline ? 0 : dashboard.dashboard.favouriteChecklistNumber;
                    totalIncidents = dashboard.dashboard.incidentSummary?.unresolved || 0;
                    myTickets = dashboard.dashboard.myTickets || [];
                }
                catch (e) {
                    console.error(e);
                    logError(e, 'Unable to load overview dashboard getOverviewDashboard');
                }
            }

            if (this.componentActive) {
                try {
                    const checklists = await this.checklistData.getTodaysChecklists(
                        null, null, this.state.checklistFilter, CONFIG.checklistsPerDashboardSection);
                    todaysChecklists = checklists.checklists;
                    if (dashboard) {
                        todaysChecklists_totalCount = dashboard.dashboard.checklistSummary.dueToday;
                    }
                    else {
                        todaysChecklists_totalCount = checklists.totalCount;
                    }
                }
                catch (e) {
                    console.error(e);
                    logError(e, 'Unable to load overview dashboard getTodaysChecklists');
                    if (todaysChecklists == null) {
                        todaysChecklists =[]
                    }
                }
            }

            if (this.componentActive) {
                if (todaysChecklists) {
                    addLocalIdsToCLList(todaysChecklists, this.state.drafts);
                }
                this.setState({
                    dashboard,
                    relatedDataLoadState: 'loaded',
                    todaysChecklists_loadState: 'loaded',
                    todaysChecklists,
                    todaysChecklists_totalCount,
                    myTickets_loadState: 'loaded',
                    myTickets,
                    totalFavourites,
                    totalIncidents
                });
            }
        }

        goTo =  (location: string) :void => this.props.history.push(location)

        openChecklist(checklist: IDashboardChecklist) {
            this.savePageDetails('editChecklistPage')
            const url = getChecklistUrl(checklist);
            this.goTo(url);
        }

        openTicket(ticket: IDashboardTicket) {
            const previousPageData = {pageId: 'overviewDashboardTickets',
                states: this.state,
                scrollPositionY: window.scrollY,
                searchFields: null,
                targetPage: 'editTicketPage',
                lastEditedItem: null
                } as IStockedPreviousPage;
            void this.props.ctx.db.savePreviousPage(previousPageData);

            const url = getTicketUrl(ticket);
            this.goTo(url);
        }

        onOpenIncident = (incidentsState: IncidentsState) => {
            const previousPageData = {pageId: 'overviewDashboardIncidents',
                states: {...this.state, incidentsState: incidentsState},
                scrollPositionY: window.scrollY,
                searchFields: null,
                targetPage: 'editIncidentPage',
                lastEditedItem: null
                } as IStockedPreviousPage;
            void this.props.ctx.db.savePreviousPage(previousPageData);
        }

        searchTickets() {
            const url = '/tickets';
            this.goTo(url);
        }

        searchChecklists() {
            this.savePageDetails('checklistSearch')
            const url = '/checklists';
            this.goTo(url);
        }

        searchIncidents = () => {
            void removePreviousPage(this.props.ctx.db,'incidentSearch');
            this.savePageDetails('incidentSearch');// this is not the pageId above but the target page
            const url = '/incidents';
            this.goTo(url);
        }

        async onTodaysChecklistsShowMore() {
            if (!this.state.todaysChecklists_loadingMore && this.state.todaysChecklists) {
                const lastId = this.state.todaysChecklists[this.state.todaysChecklists.length - 1].id;
                this.setState({
                    todaysChecklists_loadingMore: true
                });
                try {
                    const checklists = await this.checklistData
                        .getTodaysChecklists(null, null, this.state.checklistFilter, CONFIG.checklistsPerDashboardSection, lastId);
                    addLocalIdsToCLList(checklists.checklists, this.state.drafts);
                    const newList = [...this.state.todaysChecklists, ...checklists.checklists];
                    this.setState({
                        todaysChecklists: newList,
                        todaysChecklists_loadingMore: false
                    });
                }
                catch (e) {
                    logError(e, 'Error getting more overview checklists due today');
                    this.setState({
                        todaysChecklists_loadingMore: false
                    });
                }
            }
        }

        async onTodaysCompletedChecklistsExpand() {
            const franchiseId = this.props.ctx.auth.user.capabilities.franchiseId;
            if (this.state.todaysCompletedChecklists_loadState == 'not_loaded') {
                this.setState({
                    todaysCompletedChecklists_loadState: 'loading'
                });
                try {
                    const schedule = await this.checklistData
                        .getOverviewSchedule(franchiseId, this.state.checklistFilter, 'completed').getData();
                    addLocalIdsToCLList(schedule.checklists, this.state.drafts);
                    this.setState({
                        todaysCompletedChecklists: schedule.checklists,
                        todaysCompletedChecklists_loadState: 'loaded'
                    });
                }
                catch (e) {
                    logError(e, 'Error getting completed schedule');
                    this.setState({
                        todaysCompletedChecklists_loadState: 'load_error'
                    });
                }
            }
        }

        async onTodaysCompletedChecklistsShowMore() {
            const franchiseId = this.props.ctx.auth.user.capabilities.franchiseId;
            if (!this.state.todaysCompletedChecklists_loadingMore) {
                const lastId = this.state.todaysCompletedChecklists[this.state.todaysCompletedChecklists.length - 1].id;
                this.setState({
                    todaysCompletedChecklists_loadingMore: true
                });
                try {
                    const schedule = await this.checklistData
                        .getOverviewSchedule(franchiseId, this.state.checklistFilter, 'completed', lastId).getData();
                    addLocalIdsToCLList(schedule.checklists, this.state.drafts);
                    const newList = [...this.state.todaysCompletedChecklists, ...schedule.checklists];
                    this.setState({
                        todaysCompletedChecklists: newList,
                        todaysCompletedChecklists_loadingMore: false
                    });
                }
                catch (e) {
                    logError(e, 'Error getting more completed schedule items');
                    this.setState({
                        todaysCompletedChecklists_loadingMore: false
                    });
                }
            }
        }

        async onOverdueChecklistsExpand() {
            const franchiseId = this.props.ctx.auth.user.capabilities.franchiseId;
            if (this.state.overdueChecklists_loadState == 'not_loaded') {
                this.setState({
                    overdueChecklists_loadState: 'loading'
                });
                try {
                    const schedule = await this.checklistData
                        .getOverviewSchedule(franchiseId, this.state.checklistFilter, 'overdue').getData();
                    addLocalIdsToCLList(schedule.checklists, this.state.drafts);
                    this.setState({
                        overdueChecklists: schedule.checklists,
                        overdueChecklists_loadState: 'loaded'
                    });
                }
                catch (e) {
                    logError(e, 'Error getting overdue schedule');
                    this.setState({
                        overdueChecklists_loadState: 'load_error'
                    });
                }
            }
        }

        async onOverdueChecklistsShowMore() {
            const franchiseId = this.props.ctx.auth.user.capabilities.franchiseId;
            if (!this.state.overdueChecklists_loadingMore) {
                const lastId = this.state.overdueChecklists[this.state.overdueChecklists.length - 1].id;
                this.setState({
                    overdueChecklists_loadingMore: true
                });
                try {
                    const schedule = await this.checklistData
                        .getOverviewSchedule(franchiseId, this.state.checklistFilter, 'overdue', lastId).getData();
                    addLocalIdsToCLList(schedule.checklists, this.state.drafts);
                    const newList = [...this.state.overdueChecklists, ...schedule.checklists];
                    this.setState({
                        overdueChecklists: newList,
                        overdueChecklists_loadingMore: false
                    });
                }
                catch (e) {
                    logError(e, 'Error getting more overdue schedule items');
                    this.setState({
                        overdueChecklists_loadingMore: false
                    });
                }
            }
        }

        async onMyTicketsShowMore() {
            const api = this.props.ctx.api;
            const franchiseId = this.props.ctx.auth.user.capabilities.franchiseId;
            if (!this.state.myTickets_loadingMore) {
                const lastId = this.state.myTickets[this.state.myTickets.length - 1].id;
                this.setState({
                    myTickets_loadingMore: true
                });
                try {
                    const tickets = await api.getOverviewMyTickets(franchiseId, lastId);
                    const newList = [...this.state.myTickets, ...tickets];
                    this.setState({
                        myTickets: newList,
                        myTickets_loadingMore: false
                    });
                }
                catch (e) {
                    logError(e, 'Error getting more myTickets items');
                    this.setState({
                        myTickets_loadingMore: false
                    });
                }
            }
        }

        onApplyChecklistFilter = async (appliedFilter: IChecklistDashboardFilter): Promise<void> => {
            this.setState({checklistFilter: appliedFilter,
                todaysCompletedChecklists_loadState: 'not_loaded', //force list reload
                overdueChecklists_loadState: 'not_loaded' //force list reload
            })
            await this.loadData();
        }

        checkAndLoadChecklistDashboardFilter = async(): Promise<void> => {
            const franchiseId = this.props.ctx.auth.user.capabilities.franchiseId;
            //if no filter is applied for current view, then we check local DB
            if(!this.state.checklistFilter){
                const checklistFilter = await this.checklistData.getChecklistDashboardFilter(franchiseId)
                if(checklistFilter) this.setState({checklistFilter})
            }
        }

        getChecklistsTab() {
            const t = i18n.t;
            const dashboard = this.state.dashboard && this.state.dashboard.dashboard;

            return (
            <>
                {dashboard &&
                    <ChecklistDashboardHeaderPanel
                        filter={this.state.checklistFilter}
                        onClickSearchChecklist={this.searchChecklists}
                        onApplyChecklistFilter={this.onApplyChecklistFilter}
                    />
                }
                {this.state.todaysChecklists &&
                    // total count requires dashboard data. -1 = dont show (for now)
                    <ExpandingChecklistList
                        title={t('checklists_due_today')}
                        totalCount={this.state.todaysChecklists_totalCount}
                        checklists={this.state.todaysChecklists}
                        defaultExpanded={false}
                        onShowMore={this.onTodaysChecklistsShowMore}
                        openChecklist={this.openChecklist}
                        loadingMore={this.state.todaysChecklists_loadingMore}
                        currentUser={this.props.ctx.auth.user}
                    />}
                {!dashboard &&
                    <PageLoader loading={false} paddingTop={30}
                        status={
                            t('offline_data_not_available_for_entity', {
                                entityName: t('dashboard')
                            })
                        } />
                }
                {dashboard && <>
                    <ExpandingChecklistList
                        title={t('checklists_completed_today')}
                        totalCount={dashboard.checklistSummary.completedToday}
                        checklists={this.state.todaysCompletedChecklists}
                        onExpand={this.onTodaysCompletedChecklistsExpand}
                        onShowMore={this.onTodaysCompletedChecklistsShowMore}
                        openChecklist={this.openChecklist}
                        loading={this.state.todaysCompletedChecklists_loadState != 'loaded'}
                        loadingMore={this.state.todaysCompletedChecklists_loadingMore}
                        currentUser={this.props.ctx.auth.user}
                    />

                    <ExpandingChecklistList
                        title={t('checklists_overdue')}
                        totalCount={dashboard.checklistSummary.overDue}
                        checklists={this.state.overdueChecklists}
                        onExpand={this.onOverdueChecklistsExpand}
                        onShowMore={this.onOverdueChecklistsShowMore}
                        openChecklist={this.openChecklist}
                        loading={this.state.overdueChecklists_loadState != 'loaded'}
                        loadingMore={this.state.overdueChecklists_loadingMore}
                        currentUser={this.props.ctx.auth.user}
                    />
                </>}
            </>);
        }

        getTicketsTab() {
            const t = i18n.t;
            let ticketsCount = 0;
            try {
                if(this.state.dashboard && this.state.dashboard.dashboard.ticketSummary)
                    ticketsCount = this.state.dashboard.dashboard.ticketSummary.myTickets;
            }
            catch (e) { /* ignore */ }
            return (
                <TicketList
                    title={t('my_tickets')}
                    totalCount={ticketsCount || 0}
                    tickets={this.state.myTickets}
                    onShowMore={this.onMyTicketsShowMore}
                    openTicket={this.openTicket}
                    onAllTicketsTapped={this.searchTickets}
                    loading={this.state.myTickets_loadState != 'loaded'}
                    loadingMore={this.state.myTickets_loadingMore}
                    currentUser={this.props.ctx.auth.user}
                    onRefresh={this.reloadTickets}
                />
            );
        }

        onClickTab = (tabname: string) => {
            /*Whenever user changes tabs (to or from incident) we want to make sure we
            clear incident state and force API call. As incidents can be signed
            (thus moving from 'unsigned' to 'signed'), we could end up showing
            outdated data in the listing page if we keep using data from state.*/
            if(this.state.incidentsState){
                this.setState({incidentsState: null})
            }
        }

        getTabs() {
            const t = i18n.t;
            const capabs = this.props.ctx.auth.user.capabilities;
            let ticketsBadge: number | undefined;
            try {
                if(this.state.dashboard && this.state.dashboard.dashboard.ticketSummary)
                    ticketsBadge = this.state.dashboard.dashboard.ticketSummary.myTickets;
            }
            catch (e) { /* ignore */ }

            const tabs: ITabDefinition[] = [];

            if (capabs.checklists) {
                tabs.push({
                    name: 'checklists',
                    label: t('customLabel_checklists'),
                    component: (
                        <div style={{ marginTop: 16 }}>
                            {this.state.relatedDataLoadState != 'loaded' &&
                                <PageLoader loading={true} />}
                            {this.state.relatedDataLoadState == 'loaded' &&
                                this.getChecklistsTab()}
                        </div>
                    )
                });
            }

            if (capabs.tickets) {
                tabs.push({
                    name: 'tickets',
                    label: t('customLabel_tickets'),
                    badgeContent: ticketsBadge ? String(ticketsBadge) : undefined,
                    component: (
                        <div style={{ marginTop: 16 }}>
                            {this.state.relatedDataLoadState != 'loaded' &&
                                <PageLoader loading={true} />}
                            {this.state.relatedDataLoadState == 'loaded' &&
                                this.getTicketsTab()}
                        </div>
                    )
                });
            }
            if (capabs.hsFranchiseeMenu || capabs.hsMainMenu) {
                const incidentsTab = {
                    name: 'incidents',
                    label: t('customLabel_incidents'),
                    badgeContent: this.state.totalIncidents > 0 ? String(this.state.totalIncidents) : undefined,
                    component: (
                        <div style={{ marginTop: 16 }}>
                            <Incidents
                                ctx={this.props.ctx}
                                navigate={this.props.history.push}
                                pageState={this.state.incidentsState}
                                sumaryData={this.state.dashboard?.dashboard.incidentSummary}
                                onIncidentClick={this.onOpenIncident}
                            />
                        </div>
                    )
                }
                tabs.push(incidentsTab)
            }

            return (
                <TabsComponent
                    color="light"
                    tabs={[
                        ...tabs
                    ]}
                    hasDetailsTab={false}
                    initialTabIdx={this.currentTabIndex}
                    onClickTab={this.onClickTab}
                />
            );
        }

        savePageDetails = (targetPage: string) =>{
            const previousPageData = {
                pageId: 'franchiseDashboard',
                states: null, // we don't need this for instance dashboard
                scrollPositionY: window.scrollY,
                searchFields: null,
                targetPage,
                lastEditedItem: null
            } as IStockedPreviousPage;
            void this.props.ctx.db.savePreviousPage(previousPageData);
        }

        render() {
            const t = i18n.t;
            const user = this.props.ctx.auth.user;
            const capabs = user.capabilities;

            if (this.state.loadState == 'loaded') {
                return (
                    <div>
                        <div className={this.props.classes.root}>
                            <Typography variant="h5" color="inherit">
                                {this.state.franchise.name}
                            </Typography>
                            <div style={{ padding: 8 }}>
                                {this.state.totalDrafts > 0 &&
                                    <Button className={this.props.classes.button}
                                        onClick={() => this.goTo('/drafts')}>
                                        <OnePlaceIcon icon="save-draft" />
                                            <Badge color="secondary"
                                                overlap="rectangular"
                                                className={this.props.classes.badgeItem}
                                                badgeContent= {this.state.totalDrafts}>
                                                {t('drafts')}
                                            </Badge>
                                    </Button>
                                }
                                {capabs.checklists &&
                                    this.state.totalFavourites > 0 &&
                                    <Button className={this.props.classes.button}
                                        onClick={() => this.goTo('/favourites')}>
                                        <FavouriteIcon/>
                                            <Badge color="secondary"
                                                overlap="rectangular"
                                                className={this.props.classes.badgeItem}
                                                badgeContent= {this.state.totalFavourites}>
                                                {t('favourites')}
                                            </Badge>
                                    </Button>
                                }
                            </div>
                        </div>
                        {this.getTabs()}
                        <div style={{ height: 100 }}></div>
                        <NewItemFAB currentUser={user}
                            accessNotes={this.state.accessNotes}
                            onNewChecklist={() => this.savePageDetails('newChecklistPage')}
                            onNewIncident={() => this.savePageDetails('newIncidentPage')}/>
                    </div>
                );
            }
            else {
                return (
                    <PageLoader loading={true} />
                );
            }
        }
    }
))));
