
import * as React from 'react';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import BackIcon from '@material-ui/icons/ArrowBack';
import Typography from '@material-ui/core/Typography';
import { Theme, createStyles, withStyles, WithStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import { IAppContextProp, withAppContext } from '../AppContext';
import { withPrompts, IPromptsProp } from '../common/PromptProvider';
import { ConnectionStatus } from '../connection/ConnectionStatus';
import NotificationCenter from '../notifications/NotificationCenter';
import Alert from '@material-ui/lab/Alert';

const styles = (theme: Theme) => createStyles({
    root: {
        backgroundColor: theme.palette.primary.dark
    },
    menuButton: {
        marginLeft: -12,
        marginRight: 20,
    },
    rightIcon: {
        marginLeft: theme.spacing(12)
    },
});

export interface ITopNavProps extends
        WithStyles<typeof styles>,
        IAppContextProp,
        IPromptsProp {
    title: string;
    showBackButton?: boolean;
    onMainButtonClicked: () => void;

    mainActionLabel?: string;
    onMainActionClicked?: () => void;
}

export const TopNav = withStyles(styles)(withAppContext(withPrompts((props: ITopNavProps) => {

    const mainActionStyle = {
        backgroundColor: 'white',
        color: '#223d79',
        padding: '5px 15px'
    }
    const capabilities = props.ctx.auth.user.capabilities
    const plan = capabilities.planType
    const isFreePlan = ['Trial', 'Freemium'].includes(plan)
    return (
        <AppBar position="fixed" className={props.classes.root} >
            {
                isFreePlan && 
                <Alert severity="warning"
                    style={{
                        width: '100%', 
                        borderRadius: 0, 
                        paddingTop: 0, 
                        paddingBottom: 0
                    }}
                >
                    {plan === 'Trial' ? `Free TRIAL: ${capabilities.trialRemainingDays} days remain` : '1Place Free Plan'}
                </Alert>
            }
            <Toolbar>            
                <IconButton color="inherit" aria-label="Menu" className={props.classes.menuButton}
                    onClick={props.onMainButtonClicked}
                    id="main-menu-btn"
                >
                    {props.showBackButton ? <BackIcon /> : <MenuIcon />}
                </IconButton>
                <Typography variant="h6" color="inherit" style={{whiteSpace: 'normal'}}>
                    {props.title}
                </Typography>
                <div style={{ flexGrow: 1, textAlign: 'right' }}>
                    <NotificationCenter ctx={props.ctx}/>
                    <ConnectionStatus ctx={props.ctx} />
                </div>
                {props.mainActionLabel &&
                    <Button color="inherit" style={mainActionStyle} onClick={props.onMainActionClicked}>
                        {props.mainActionLabel}
                    </Button>}
            </Toolbar>
        </AppBar>
    );
})));
